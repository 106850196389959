export default [
  {
    section: 'navbar.panel.management',
    auth: ['systemoperator','agency', 'co_worker'],
    items: [
      {
        label: 'Campaigns',
        route: '/campaigns',
        auth: ['systemoperator']
      },
      {
        label: 'Booking Template',
        route: '/bookingtemplate',
        auth: ['systemoperator'],
        tourStep: '3'
      },
      {
        label: 'Programs',
        route: '/programs',
        auth: ['systemoperator']
      },
      {
        label: 'Contracts',
        route: '/contracts',
        auth: ['systemoperator']
      },
      {
        label: 'Transactions',
        route: '/transactions',
        auth: ['systemoperator']
      },
      {
        label: 'Bookings',
        route: '/bookings',
        auth: ['systemoperator'],
        tourStep: '4'
      },
      {
        label: 'general.domainlist',
        route: '/domainlists',
        auth: ['systemoperator','agency', 'co_worker']
      },
      {
        label: 'general.blogpost',
        route: '/blogposts',
        auth: ['systemoperator']
      },
      {
        label: 'general.audience',
        route: '/audiences',
        auth: ['systemoperator','agency', 'co_worker']
      },
      {
        label: 'general.subaccount',
        route: '/subaccounts',
        auth: ['systemoperator','agency', 'co_worker']
      },
      {
        label: 'general.bookingtemplate',
        route: '/bookingtemplate',
        auth: ['systemoperator','agency', 'co_worker']
      },
      {
        label: 'general.accounting',
        route: '/accounting',
        auth: ['systemoperator', 'billing']
      },
    ],
  },
  {
    section: 'Ad Tech',
    auth: ['systemoperator', 'wizard', 'co-worker', 'agency'],
    items: [
      {
        label: 'general.segment',
        route: '/segments',
        auth: ['systemoperator','agency', 'co_worker']
      },
      {
        label: 'general.catalog',
        route: '/catalogs',
        auth: ['agency', 'co_worker', 'systemoperator']
      },
      {
        label: 'general.ci',
        route: '/cis',
        auth: ['agency', 'co_worker', 'systemoperator']
      },
      {
        label: 'general.bannertemplate',
        route: '/bannertemplates',
        auth: ['agency', 'co_worker', 'systemoperator']
      },
      {
        label: 'general.graphic',
        route: '/graphics',
        auth: ['systemoperator', 'wizard', 'agency', 'co_worker']
      },
      {
        label: 'Videos',
        route: '/videos',
        auth: ['systemoperator', 'wizard']
      },
      {
        label: 'Audios',
        route: '/audios',
        auth: ['systemoperator', 'wizard']
      },
      {
        label: 'Ad Tags',
        route: '/banners',
        auth: ['systemoperator', 'wizard']
      },
      {
        label: 'VAST',
        route: '/vasts',
        auth: ['systemoperator', 'wizard']
      },
      {
        label: 'Splitters',
        route: '/splitters',
        auth: ['systemoperator']
      },
      {
        label: 'Tag Management',
        route: '/trackingcodes',
        auth: ['systemoperator']
      },
      {
        label: 'Rich Videos',
        route: '/richvideos',
        auth: ['systemoperator']
      },
      {
        label: 'Line Items',
        route: '/lineitems',
        auth: ['systemoperator']
      },
      {
        label: 'Network Monitoring',
        route: '/network-monitoring',
        auth: ['systemoperator']
      },
    ],
  },
  {
    section: 'Admin',
    auth: ['systemoperator', 'wizard'],
    items: [
      {
        label: 'Wizards',
        route: '/wizards',
        auth: ['systemoperator', 'wizard']
      },
      {
        label: 'Logins',
        route: '/logins',
        auth: ['systemoperator']
      },
      {
        label: 'Subaccounts',
        route: '/subaccounts',
        auth: ['systemoperator']
      },
      {
        label: 'Publishers',
        route: '/publishers',
        auth: ['systemoperator']
      },
      {
        label: 'Hosts',
        route: '/hosts',
        auth: ['systemoperator']
      },
      {
        label: 'Media Plans',
        route: '/mediaplans',
        auth: ['systemoperator']
      },
      {
        label: 'Cost Revenue Ratios',
        route: '/crrs',
        auth: ['systemoperator']
      },
      {
        label: 'Rich Video Templates',
        route: '/richvideotemplates',
        auth: ['systemoperator']
      },
      {
        label: 'Verticals',
        route: '/verticals',
        auth: ['systemoperator']
      },
      {
        label: 'Approvals',
        route: '/approvals',
        auth: ['systemoperator']
      },
      {
        label: 'Sync Queue',
        route: '/syncqueue',
        auth: ['systemoperator']
      },
    ],
  },
  {
    section: 'Sysop',
    auth: ['systemoperator'],
    items: [
      {
        label: 'Advertisers',
        route: '/advertisers',
        auth: ['systemoperator']
      },
      {
        label: 'Optimizers',
        route: '/optimizers',
        auth: ['systemoperator']
      },
      {
        label: 'Top Products',
        route: '/topproducts',
        auth: ['systemoperator']
      },
      {
        label: 'Events',
        route: '/events',
        auth: ['systemoperator']
      },
      {
        label: 'Brandad Assignments',
        route: '/assignments',
        auth: ['systemoperator']
      },
      {
        label: 'Settings',
        route: '/settings',
        auth: ['systemoperator']
      },
      {
        label: 'Coupons',
        route: '/coupons',
        auth: ['systemoperator']
      },
      {
        label: 'Affiliate Dashboard',
        route: '/affiliate',
        auth: ['systemoperator']
      },
      {
        label: 'Affiliate (Legacy)',
        route: '/affiliateold',
        auth: ['systemoperator']
      },
      {
        label: 'Banner Template',
        route: '/banner/template',
        auth: ['systemoperator']
      },
      {
        label: 'Campaign Logs',
        route: '/campaignlogsnew',
        auth: ['systemoperator']
      },
    ],
  },
]