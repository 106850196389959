<template>
  <form class="forms-sample">
    <div v-if="loading" class="flip-square-loader"></div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
                Are you sure you want to {{archived ? 'recover' : 'delete'}} this {{type}}?
            </div>
          </div>
        </div>
        <b-button size="sm" variant="outline-secondary" @click="cancel()">
            Forget it
        </b-button>
        <b-button class="ml-2" size="sm" :variant="archived ? 'success' : 'danger'" @click="submit()">
            {{archived ? 'Recover' : 'Delete'}}
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RecoverDeleteModal",
  props: ['type'],
  computed: {
    ...mapGetters([
      "archived",
      "error",
      "loading"
    ]),
  },
  methods: {
    async submit() {
        this.$emit("recdelEntity");
        this.$bvModal.hide("deleteModal");
    },
    cancel() {
        this.$bvModal.hide("deleteModal");
    },
  },
};
</script>

<style scoped>

.fs-3 {
  font-size: 3rem;
}
</style>
