<template>
    <div class="row bg-light pt-2">
        <div class="col-4 form-group">
            <label for="searchfield_daterange" class="mb-1">{{ $t('general.startdate') }} / {{ $t('general.enddate') }} (max. 3 {{ 
           $tc("general.day",2) }})</label>
            <DateRangePickerCustom opens="right" @update="setDates" />
        </div>
        <div class="col-4 form-group">
            <label for="searchfield_campaign" class="mb-1">{{ $tc('general.campaign', 2) }}</label>
            <SearchEntity entity="campaign" :value="campaign" multiple @selected="setSearchInput"/>
        </div>
    </div>
</template>
  
<script>
  import SearchEntity from "./SearchEntity";
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import { mapGetters } from 'vuex';

  export default {
    name: "EntityFilterCampaignlogs",
    components: {
        SearchEntity,
        DateRangePickerCustom
    },
    props: {
        filter: {
            type: Object,
            required: false
        }
    },
    created(){
        if(this.filter){
            if(this.filter.campaign)        this.campaign = this.filter.campaign;
        }
    },
    data() {
      return {
        campaign: null
      };
    },
    computed: {
        ...mapGetters(["dateRange"]),
        dateRange: {
            get() {
                return this.$store.state.store.dateRange;
            }
        },
    },
    methods: {
        setDates(){
            this.$emit("filter", 'start', this.dateRange.startDate);
            this.$emit("filter", 'end', this.dateRange.endDate);
        },
        setSearchInput(data){
            this[data.entity] = data.value;
            this.reportInput(data.entity);
        },
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
    },
  };
</script>
