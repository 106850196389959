<template>
    <div class="row bg-light pt-2">
        <div class="col-4 form-group">
            <label for="searchfield_campaign" class="mb-1">{{ $tc('general.campaign', 2) }}</label>
            <SearchEntity entity="campaign" :value="campaign" multiple @selected="setSearchInput"/>
        </div>
        <div class="col-4 form-group">
            <label for="searchfield_contract" class="mb-1">Contracts</label>
            <SearchEntity entity="contract" multiple @selected="setSearchInput"/>
        </div>
        <div class="col-4 form-group">
            <label for="searchfield_lineitem" class="mb-1">{{ $tc('general.lineitem', 2) }}</label>
            <SearchEntity entity="lineitem" multiple @selected="setSearchInput"/>
        </div>
        <div class="col-4 form-group">
            <label for="searchfield_lineitem" class="mb-1">{{ $t('general.startdate') }} / {{ $t('general.enddate') }} (max. 3 {{ 
           $tc("general.day",2) }})</label>
            <DateRangePickerCustom opens="right" @update="setDates" />
        </div>
        <div class="col-4 form-group">
            <label for="cls" class="mb-1">CLS</label>
            <v-select
                id="cls"
                v-model="cls"
                label="name"
                class="form-control"
                :filterable="false"
                :reduce="(type) => type.value"
                :options="clss"
                @input="reportInput('cls')"
            >
            </v-select>
        </div>
    </div>
</template>
  
<script>
  import SearchEntity from "./SearchEntity";
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import { mapGetters } from 'vuex';

  export default {
    name: "EntityFilterEvents",
    components: {
        SearchEntity,
        DateRangePickerCustom
    },
    props: {
        filter: {
            type: Object,
            required: false
        }
    },
    created(){
        if(this.filter){
            if(this.filter.cls)             this.cls = this.filter.cls;
            if(this.filter.campaign)        this.campaign = this.filter.campaign;
        }
    },
    data() {
      return {
        campaign: null,
        contract: null,
        lineitem: null,
        cls: null,
        clss: [
            { value: 'cart', name: 'cart' },
            { value: 'front', name: 'front' },
            { value: 'product', name: 'product' },
            { value: 'catalog', name: 'catalog' },
            { value: 'generic', name: 'generic' },
            { value: 'search', name: 'search' },
            { value: 'checkout', name: 'checkout' },
            { value: 'order', name: 'order' },
            { value: 'wishlist', name: 'wishlist' },
        ],
      };
    },
    computed: {
        ...mapGetters(["dateRange"]),
        dateRange: {
            get() {
                return this.$store.state.store.dateRange;
            }
        },
    },
    methods: {
        setDates(){
            // get from local storage and set to filter
            this.$emit("filter", 'start', this.dateRange.startDate);
            this.$emit("filter", 'end', this.dateRange.endDate);
        },
        setSearchInput(data){
            this[data.entity] = data.value;
            this.reportInput(data.entity);
        },
        reportInput(field){
            this.$emit("filter", field, this[field]);
        }
    },
  };
</script>
