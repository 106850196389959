<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <nav class="navbar horizontal-layout col-lg-12 col-12 p-0" style="position: relative">
      <div class="nav-top flex-grow-1">
        <div class="container-fluid d-flex flex-row h-100">
          <div class="text-center navbar-brand-wrapper d-flex align-items-top">
            <router-link class="navbar-brand brand-logo" to="/">
              <img :src="headerlogo" id="logo" alt="logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img :src="headerlogo" alt="logo" />
            </router-link>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item dropdown mr-4" style="cursor: pointer;">
                <a class="nav-link dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                  <i class="flag-icon" :class="selectedLanguage === 'en' ? 'flag-icon-gb' : 'flag-icon-de'"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list p-0" style="top: calc(45px - 6px);" aria-labelledby="notificationDropdown">
                  <a class="dropdown-item preview-item" @click="changeLanguage('en')">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-light" :class="selectedLanguage === 'en' ? 'bg-background' : ''">
                        <i class="flag-icon flag-icon-gb mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">English</h6>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" @click="changeLanguage('de')">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-light" :class="selectedLanguage == 'de' ? 'bg-background' : ''">
                        <i class="flag-icon flag-icon-de mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">German</h6>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            
            <p class="my-0 ml-3">{{ $t("messages.noaccount") }}</p>
            <router-link class="btn get-started-btn text-uppercase" to="/register">{{ $t("actions.register") }}</router-link>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" style="height: calc(100vh - 70px); overflow-y: auto;">
      <div class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center px-0">
        <div class="slide-content bg-2" :style="'background-image: url('+authimage+')'"></div>
      </div>
      <div class="col-md-8 bg-white d-flex align-items-center justify-content-center" style="overflow-y: auto;">
         <div class="col-sm-12 col-md-8 col-xl-5 mt-5">
           <div v-if="hideForm">
             <form v-if="createPasswordForm && !success" @submit.prevent="checkConfirmation">
              <div class="alert alert-warning" v-if="error">{{error}}</div>
              <div class="alert alert-warning" v-if="warning">{{warning}}</div>
              <div class="alert alert-success" v-if="success">{{success}}</div>
              <h3 v-if="!resetpassword" class="mr-auto">{{ $t("headers.activateacc") }}</h3>
              <p v-if="!resetpassword" class="mb-3 mr-auto">{{ $t("messages.enterpassword") }}</p>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    autocomplete="current-password"
                  />
                </div>
              </div>
              <div class="row" v-if="!loading">
                <div class="col-6">
                  <button
                    class="btn btn-success submit-btn btn-block"
                    type="submit"
                  >{{$t("actions.savex", {name: $t("forms.password")})}}</button>
                </div>
              </div>
            </form>
            <div class="alert alert-success" v-if="success">{{success}}</div>
            <div class="row" v-if="success">
              <div class="col-6">
                <button
                  @click="$router.push('/login')"
                  class="btn btn-success submit-btn btn-block"
                  type="submit"
                >{{ $t("actions.signin") }}</button>
              </div>
            </div>
           </div>
           <form v-else @submit.prevent="formsubmit">
                <h3 v-if="!resetpassword" class="mr-auto">{{ $t("headers.login") }}</h3>
                <p v-if="!resetpassword" class="mb-5 mr-auto">{{ $t("messages.enterdetails") }}</p>

                <h3 v-if="resetpassword" class="mr-auto">{{ $t("headers.resetpw") }}</h3>
                <p v-if="resetpassword" class="mb-5 mr-auto">{{ $t("messages.enteremail") }}</p>

                <div class="alert alert-warning" v-if="error">{{error}}</div>
                <div class="alert alert-warning" v-if="warning">{{warning}}</div>
                <div class="alert alert-success" v-if="success">{{success}}</div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-envelope"></i>
                      </span>
                    </div>
                    <input
                      v-model="email"
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="E-Mail"
                      required
                      minlength="6"
                      autocomplete="username"
                    />
                  </div>
                </div>
                <div v-if="!resetpassword" class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-lock"></i>
                      </span>
                    </div>
                    <input
                      v-model="password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                  </div>
                </div>
                <div class="row" v-if="!loading">
                  <div class="col-6">
                    <button
                      class="btn btn-success submit-btn btn-block"
                      v-if="!resetpassword"
                      type="submit"
                    >{{ $t("actions.signin") }}</button>
                    <button
                      class="btn btn-success submit-btn btn-block"
                      v-if="resetpassword"
                      type="submit"
                    >Reset Password</button>
                  </div>
                  <div class="col-6 text-center my-auto">
                    <a href="#" v-if="!resetpassword" @click="resetpassword=true">{{ $t("actions.resetpw") }}</a>
                    <a href="#" v-if="resetpassword" @click="resetpassword=false">{{ $t("actions.signin") }}</a>
                  </div>
                </div>
           </form>

            <div v-if="loading" class="flip-square-loader"></div>
            <div class="wrapper mt-5 text-gray">
              <p class="footer-text" >Copyright © {{currentYear}} {{companyName}}. All rights reserved.</p>
              <!-- <ul class="auth-footer text-gray">
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Cookie Policy</a>
                </li>
              </ul>-->
            </div>
         </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  async created() {
    this.selectedLanguage = localStorage.getItem(process.env.VUE_APP_LANGUAGE) || process.env.VUE_APP_LANGUAGE_DEFAULT;

    if(this.token !== ""){
      const { login, valid } = await this.$api.user.validToken(this.token);
      if(login && valid){
        if(login.accountType != 'subaccount'){
          await this.checkConfirmation();
        } else {
          this.hideForm = true;
          this.createPasswordForm = true;
        }
      }
    }
    if(this.unauthorized){
      this.$bvToast.toast(this.getNoAuthMessage, {
        title: this.$t("headers.login-required"),
        noAutoHide: true,
        variant: "warning",
        solid: true,
      });
    }

  },
  name: "Login",
  props: {
    token: {
      type: String,
      required: false,
      default: "",
    },
    unauthorized: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedLanguage: process.env.VUE_APP_LANGUAGE_DEFAULT,
      email: null,
      password: null,
      resetpassword: false,
      hideForm: false,
      createPasswordForm: false,
    };
  },
  computed: {
    ...mapGetters(["loading", "error", "success", "warning", "registered"]),
    currentYear: () => new Date().getFullYear(),
    companyName: () => process.env.VUE_APP_COMPANY_NAME,
    authimage: () => "/wl/" + process.env.VUE_APP_NAME + "/bg_auth.jpg",
    headerlogo: () => '/wl/'+process.env.VUE_APP_NAME+'/logo.png',
    getNoAuthMessage(){
      if(localStorage.getItem(process.env.VUE_APP_JWT)){
        return this.$t("messages.session-expired");
      } else {
        return this.$t("messages.no-authentication");
      }
    }
  },
  methods: {
    changeLanguage(ln) {
      this.selectedLanguage = ln
      localStorage.setItem(process.env.VUE_APP_LANGUAGE, ln)
      this.$root.$i18n.locale = ln;
    },
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async checkConfirmation(){
      let result = await this.$api.user.checkConfirm(this.token, this.password);
      if(result.accountType == 'agency'){
        this.hideForm = true;
        this.$store.dispatch(
            "setSuccess",
            this.$t("messages.verified-agency")
          );
      }
      else if(result.accountType == 'company'){
        this.$store.dispatch(
            "setSuccess",
            this.$t("messages.verified-company")
          );
      }
      else if(result.accountType == 'subaccount'){
        this.$store.dispatch(
            "setSuccess",
            this.$t("messages.passwordset")
          );
      }
      else if(result.accountType == 'publisher'){
        this.$store.dispatch(
            "setSuccess",
            this.$t("messages.passwordset")
          );
      }
      else{
        this.$store.dispatch(
            "setSuccess",
            this.$t("messages.verified")
          );
      }
    },

    async sendResetPasswordMail() {
      if (this.email && this.validateEmail(this.email)) {
        // send mail
        const payload = {
          email: this.email
        };
        const sendMailResult = await this.$store.dispatch(
          "resetpassword",
          payload
        );
        if (sendMailResult) {
          this.$store.dispatch(
            "setSuccess",
            this.$t("messages.checkmails")
          );
          this.email = null;
        }
      } else {
        this.$store.dispatch("setError", this.$t("messages.entervalidemail"));
      }
    },

    formsubmit: function() {
      this.$store.dispatch("setError", null);
      this.$store.dispatch("setSuccess", null);

      if (this.resetpassword) this.sendResetPasswordMail();
      else this.signin();
    },

    async signin() {
      if (!this.email || !this.validateEmail(this.email)) {
        this.$store.dispatch("setError", this.$t("messages.entervalidemail"));
      } else if (!this.password || this.password.length < 8) {
        this.$store.dispatch("setError", this.$t("messages.enterpassword"));
      } else {
        const payload = {
          email: this.email,
          password: this.password
        };
        try {
          await this.$store.dispatch("signin", payload);
          if (localStorage.getItem(process.env.VUE_APP_JWT)) {

            const lsPublisher = localStorage.getItem(process.env.VUE_APP_PUBLISHER);
            // const lsAdvertiser = localStorage.getItem(process.env.VUE_APP_ADVERTISER);

            // if(lsPublisher && lsPublisher !== null && lsPublisher !== "null") { /* console.log("publisher set to", lsPublisher) */ }
            // if(lsAdvertiser && lsAdvertiser !== null && lsAdvertiser !== "null") { /* console.log("advertiser set to", lsAdvertiser) */ }

            if(lsPublisher && lsPublisher !== null && lsPublisher !== "null"){
              this.$router.push("/p");
            }
            else{
              this.$router.push("/");
            }
          }
        } catch(error) {
          if(error.message == "Network Error" || (error.status && error.status === 500)) {
            this.$store.dispatch("setError", this.$t("messages.systemerror", { email: process.env.VUE_APP_EMAIL_SUPPORT }));
          }
        }
        
      }
    }
  },
};
</script>

<style scoped>

  #logo {
      height: 43px;
      margin-top: 5px;
  }
  .bg-background {
    border-color: #00b297;
    border-style: solid;
    border-width: 2px;
  }

  .banner-section .slide-content.bg-2 {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .get-started-btn{
    border: 1px solid #fff;
      padding: 10px 20px;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      margin-left: 20px;
      border-radius: 50px;
  }
</style>

<style>

</style>