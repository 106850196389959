var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.entities),function(subentities,topIndex){return _c('div',{key:topIndex},[(subentities.length)?_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(topIndex.toUpperCase()))])])]),_c('tbody',_vm._l((subentities),function(entity){return _c('tr',{key:entity._id},[_vm._l((_vm.fields),function(field,index){return _c('td',{key:'fieldcontent' + index,style:('width: ' +
              (100 /
                Math.ceil(
                  (_vm.fields ? _vm.fields.length : 0) +
                    (_vm.populates ? _vm.populates.length : 0)
                ) -
                10) +
              '%'),domProps:{"innerHTML":_vm._s(_vm.getEntityFieldValues(entity, field, topIndex))}})}),_vm._l((_vm.populates),function(populate,index){return _c('td',{key:'popcontent' + index,style:('width: ' +
              (100 /
                Math.ceil(
                  (_vm.fields ? _vm.fields.length : 0) +
                    (_vm.populates ? _vm.populates.length : 0)
                ) -
                10) +
              '%')},[_vm._v(" "+_vm._s(_vm.getEntityPopulation(entity, populate.ref, populate.select))+" ")])}),_c('td',{style:('width: ' +
              (100 -
                (100 /
                  Math.ceil(
                    (_vm.fields ? _vm.fields.length : 0) +
                      (_vm.populates ? _vm.populates.length : 0)
                  ) -
                  10)) +
              '%')},[_c('i',{staticClass:"fa fa-copy",staticStyle:{"cursor":"pointer"},attrs:{"title":'Copy ID to clipboard: ' + entity._id},on:{"click":function($event){return _vm.copyIdToClipboard(entity._id)}}}),_c('router-link',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"to":("/" + (topIndex.substring(0, topIndex.length - 1)) + "/edit/" + (entity._id))}},[_c('i',{staticClass:"fa fa-edit",on:{"click":function($event){return _vm.log(topIndex)}}})]),(topIndex == 'campaigns')?_c('router-link',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"to":("/campaign/" + (entity._id))}},[_c('i',{staticClass:"fa fa-bar-chart"})]):_vm._e()],1)],2)}),0),_c('input',{attrs:{"type":"hidden","id":"clipboard","value":""}})]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }