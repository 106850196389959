<template>
    <v-select
        :id="getFieldId()"
        v-model="entities"
        @search="onSearchEntities"
        label="name"
        class="form-control"
        :filterable="false"
        :reduce="(entities) => entities._id"
        :options="allEntities"
        @input="reportInput"
        :multiple="multiple"
        :close-on-select="!multiple"
    >
        <template slot="no-options">
        {{ $t("messages.typetosearch", {name: getSearchString()} ) }}
        </template>
    </v-select>
</template>
  
<script>
  import _debounce from "lodash.debounce";

  export default {
    name: "SearchEntity",
    props: {
        entity: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false

        },
        value: {
            type: [String, Array],
            required: false
        }
    },
    created(){
        if(this.value){
            this.entities = this.value;
        }
    },
    data() {
      return {
        allEntities: [],
        entities: null,
      };
    },
    watch: {
        value() {
            this.entities = this.value;
        },
    },
    methods: {
        getFieldId(){
            return `searchfield_${this.entity}`;
        },
        getSearchString(){
            return this.$te(`general.${this.entity}`) ? this.$tc(`general.${this.entity}`,2) : this.entity;
        },
        reportInput(){
            this.$emit("selected", { entity: this.entity, value: this.entities});
        },
        onSearchEntities(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _debounce((loading, search, vm) => {
        vm.$api[vm.entity]
            .search(search, { enableLoading: false })
                .then((entities) => {
                    vm.allEntities = [];
                    for(const item of entities.items){
                        if(!item.name)  item['name'] = item._id;
                        vm.allEntities.push(item);
                    }
                loading(false);
            });
        }, 350),
    },
  };
</script>
