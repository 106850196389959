<template>
  <div class="card rounded border mb-2">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-2">
          <AdListPreview :creative="creative" :size="60" />
        </div>
        <!-- preview end -->
        <div class="align-items-center d-flex" :class="showExternalLink ? 'col-6' : 'col-7'">
          <p class="m-0">
            {{ getName() }}<br />
            <small> {{ getFileResolution(creative) }}</small>
          </p>
        </div>

        <div class="col-3 align-items-center d-flex">
          <b-img
            v-for="(channel, index) in creative.channels"
            :key="index"
            :src="channel.image"
            :alt="channel.name"
            :title="channel.name"
            style="width: 20px; height: 20px;"
            class="mr-1"
          ></b-img>
        </div>
        <div class="col-1 align-items-center d-flex" v-if="showExternalLink && creative.bannerType != 'search_ad'">
          <a
            target="_blank"
            :href="
              `https://${adServerUrl}/banner?id=${creative._id}&desc=bookingwizard`
            "
            ><i class="fa fa-external-link" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdListPreview from "@/views/ads/adlist/components/AdListPreview.vue";
export default {
  name: "CreativeCard",
  components: { AdListPreview },
  props: {
    creative: Object,
    showExternalLink: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  created(){

  },
  data() {
    return {
      adServerUrl: process.env.VUE_APP_ADSERVER,
    };
  },
  methods: {
    getName(){
      if(this.creative?.name) return this.creative.name;
      if(this.creative?.video?.name)  return this.creative.video.name;
      if(this.creative?.adCode) return "AdCode";
      if(this.creative.descriptions && this.creative.headlines) return "Search Ad";
      return 'N/A';
    },
    // searchAdText(creative) {
    //   const parts = [];
    //   if (creative?.headlines?.length)
    //     parts.push(
    //       creative.headlines.length +
    //         " " +
    //         this.$tc(
    //           "wizard.label.headline",
    //           creative.headlines.length == 1 ? 1 : 2
    //         )
    //     );
    //   if (creative?.descriptions?.length)
    //     parts.push(
    //       creative.descriptions.length +
    //         " " +
    //         this.$tc(
    //           "wizard.label.description",
    //           creative.descriptions.length == 1 ? 1 : 2
    //         )
    //     );
    //   if (creative?.keywords?.length)
    //     parts.push(
    //       creative.keywords.length +
    //         " " +
    //         this.$tc("wizard.label.keyword", creative.keywords.length == 1 ? 1 : 2)
    //     );
    //   return parts.join(", ");
    // },
    getFileResolution(file) {
      if (file.bannerType == "search_ad" || file?.descriptions?.length && file?.headlines?.length) {
        const parts = [];
        if (file?.headlines?.length)
          parts.push(
            file.headlines.length +
              " " +
              this.$tc(
                "wizard.label.headline",
                file.headlines.length == 1 ? 1 : 2
              )
          );
        if (file?.descriptions?.length)
          parts.push(
            file.descriptions.length +
              " " +
              this.$tc(
                "wizard.label.description",
                file.descriptions.length == 1 ? 1 : 2
              )
          );
        if (file?.keywords?.length)
          parts.push(
            file.keywords.length +
              " " +
              this.$tc(
                "wizard.label.keyword",
                file.keywords.length == 1 ? 1 : 2
              )
          );
        return parts.join(", ");
      } else if (file?.video?.width)
        return `${file.video.width} x ${file.video.height} Px`;
      else if (file?.width)
        return `${file.width} x ${file.height} Px`;

      return ``;
    },
    // getWidth(){
    //   return this.creative?.thumbnail ? this.creative.thumbnail.width : this.creative.width;
    // },
    // getHeight(){
    //   return this.creative?.thumbnail ? this.creative.thumbnail.height : this.creative.height;
    // },
    getPreviewWidthRect(width, height, fix) {
      return (fix * width) / height > fix ? fix : (fix * width) / height;
    },
    getPreviewHeightRect(width, height, fix) {
      return (fix * height) / width > fix ? fix : (fix * height) / width;
    },
    getThumbnail(id) {
      if (this.pendingBookings?.lookups?.thumbnails) {
        const graphic = this.pendingBookings.lookups.thumbnails.find(
          (g) => g._id == id
        );
        return graphic.downloadLink;
      }
      return "#"; // TODO: add dummy image
    },
    searchAdText(creative) {
      const parts = [];
      if (creative?.headlines?.length)
        parts.push(
          creative.headlines.length +
            " " +
            this.$tc(
              "wizard.label.headline",
              creative.headlines.length == 1 ? 1 : 2
            )
        );
      if (creative?.descriptions?.length)
        parts.push(
          creative.descriptions.length +
            " " +
            this.$tc(
              "wizard.label.description",
              creative.descriptions.length == 1 ? 1 : 2
            )
        );
      if (creative?.keywords?.length)
        parts.push(
          creative.keywords.length +
            " " +
            this.$tc("wizard.label.keyword", creative.keywords.length == 1 ? 1 : 2)
        );
      return parts.join(", ");
    },
  },
};
</script>
