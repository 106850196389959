<template>
  <div>
    <div v-show="loading" class="flip-square-loader"></div>
    <div class="row" v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager') && !loading">

        <div class="col-md-8">
            <h3>
              <span class="handwriting mr-2">Campaign Optimization</span>
            </h3>
        </div>

        <div class="col-md-4 grid-margin">  
          <DateRangePickerCustom opens="left" @update="updateDashboard" />
        </div>
    </div>
    <div class="row">



        <div class="col-md-12 grid-margin stretch-card" v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager') && !loading">
          <div class="card">
            <div class="card-body">
              <span class="pull-right badge badge-info">Total: {{ totalRevenue }}€ / {{ totalCost }}€</span>
              <h4 class="card-title">Revenue</h4>
              
              <div class="mb-3" v-for="(network, index) in networkRevenue" :key="index" >
                <p class="d-flex mb-2">
                  {{network.network.toUpperCase()}} <small class="ml-2">({{(network.perc*100).toFixed(2)}}%)</small>
                  <span class="ml-auto font-weight-bold">{{(network.revenue).toFixed(2)}} &euro;</span>
                </p>
                <div class="progress progress-xs">
                  <div :class="`progress-bar bg-${colorRange[index]}`" role="progressbar" :style="`width: ${(network.perc*100).toFixed(2)}%`" :aria-valuenow="`${(network.perc*100).toFixed(2)}`" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <p><a :href="getGenCrrCronUrl" target="_blank"><i class="fa fa-refresh"></i> re-run generateCRR cron for yesterday</a></p>
            </div>
          </div>
        </div>

        <div
            class="col-lg-12 grid-margin stretch-card"
            v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager') && !loading && amountOfDays(1) > 1"
        >
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Commission & Costs</h4>
              <line-chart :chart-data="revenueRangeChart" :options="chartOptions" />
            </div>
          </div>
        </div>
        
        <div
            class="col-md-12 grid-margin stretch-card"
            v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager') && !loading"
        >
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col-md-3 col-sm-12">
                        <h4 class="card-title">Campaign Optimization</h4>
                    </div>
                    <div class="col-md-9 col-sm-12 mb-3">
                        <div class="row justify-content-between" v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'">
                        <span class="badge badge-dark">
                            <small class="ml-1 mr-1" v-for="(amount, icon) in groupedIcons" :key="icon">
                            <i :class="`fa ${icon}`"></i>
                            {{ amount }}
                            </small>
                        </span>
                        <span :class="amountOf.errors ? 'badge badge-danger' : 'badge badge-warning'">ERR/WARN: {{ amountOf.errors }}/{{ amountOf.warnings }}</span>
                        <span class="badge badge-primary">OPR/CLICK/VIEW: {{ amountOf.opr }}/{{ amountOf.clicks }}/{{ amountOf.views }}</span>
                        <span class="badge badge-success">
                            PC/PV: {{ amountOf.pcConversions }}/{{
                            amountOf.pvConversions
                            }}
                        </span>
                        
                        </div>
                    </div>
                    </div>

                    <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="pl-0">Campaign</th>
                            <th>Clicks/Views (CTR/CR)</th>
                            <th v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'">OPRs</th>
                            <th>NW / PC-PV / Def (MS)</th>
                            <th v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'">Health</th>
                            <th v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'">Revenue</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="optimizer in optimizerDashboard" :key="optimizer._id">
                            <td class="pl-0">
                            <i
                                v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'"
                                :class="'mr-2 fa ' + getStatus(optimizer)"
                            ></i>
                            <i
                                class="fa fa-copy mr-2"
                                v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'"
                                :title="'Copy ID to clipboard: ' + optimizer.id"
                                style="cursor: pointer"
                                @click="copyIdToClipboard(optimizer.id)"
                            ></i>
                            {{ optimizer.campaign.name }} ({{
                            optimizer.mediaPlans[0] ? optimizer.mediaPlans[0].revenueSource : "n/a"
                            }})
                            <span
                                v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'"
                            >
                                <br />
                                <br />
                                <router-link
                                class="text-small"
                                :to="`/campaign/${optimizer.campaign._id}`"
                                style="cursor: pointer"
                                >
                                <i class="fa fa-bar-chart"></i>
                                </router-link>
                                <router-link class="ml-2" :to="`/optimizer/edit/${optimizer.id}`">
                                <i class="fa fa-rocket"></i>
                                </router-link>
                                <router-link
                                v-if="optimizer.detailedLogs"
                                class="ml-2 text-small"
                                :to="`/campaignlogs?campaign=${optimizer.campaign._id}`"
                                style="cursor: pointer"
                                >
                                <i class="fa fa-history"></i>
                                </router-link>
                                <span
                                class="ml-2 text-small"
                                v-for="(trackingCode, trindex) in optimizer.trackingCodes"
                                :key="'tr' + trindex"
                                >
                                <router-link
                                    v-if="trindex < 3"
                                    class="text-small"
                                    :to="`/trackingcode/edit/${trackingCode._id}`"
                                    style="cursor: pointer"
                                >
                                    <i class="fa fa-code"></i>
                                </router-link>
                                </span>
                                <span
                                class="ml-2 text-small"
                                v-for="(contract, ctindex) in optimizer.contracts"
                                :key="'ct' + ctindex"
                                >
                                <router-link
                                    v-if="ctindex < 3"
                                    class="text-small"
                                    :to="`/contract/edit/${contract._id}`"
                                    style="cursor: pointer"
                                >
                                    <i class="fa fa-file"></i>
                                </router-link>
                                </span>
                                <span
                                class="ml-2 text-small"
                                v-for="(mediaplan, mpindex) in optimizer.mediaPlans"
                                :key="'mp' + mpindex"
                                >
                                <router-link
                                    v-if="mpindex < 3"
                                    :to="`/mediaplan/edit/${mediaplan._id}`"
                                    style="cursor: pointer"
                                >
                                    <i class="fa fa-clipboard"></i>
                                </router-link>
                                </span>
                                <span
                                class="text-small"
                                v-for="(host, htindex) in optimizer.hosts"
                                :key="'ht' + htindex"
                                >
                                <router-link
                                    v-if="htindex < 3"
                                    :to="`/host/edit/${host._id}`"
                                    style="cursor: pointer"
                                    class="ml-2"
                                >
                                    <i class="fa fa-flag"></i>
                                </router-link>
                                </span>
                                <span
                                class="ml-2 text-small"
                                v-for="(lineitem, liindex) in optimizer.lineItems"
                                :key="'li' + liindex"
                                >
                                <router-link
                                    v-if="
                                    liindex < 3 &&
                                    lineitem.active &&
                                    new Date(lineitem.archived) <= new Date(0)
                                "
                                    :to="`/lineitem/edit/${lineitem._id}`"
                                >
                                    <i class="fa fa-cubes"></i>
                                </router-link>
                                <router-link
                                    v-else-if="
                                    liindex < 3 &&
                                    !lineitem.active &&
                                    new Date(lineitem.archived) <= new Date(0)
                                "
                                    style="color: #777"
                                    :to="`/lineitem/edit/${lineitem._id}`"
                                >
                                    <i class="fa fa-cubes"></i>
                                </router-link>
                                <router-link
                                    v-else-if="
                                    liindex < 3 &&
                                    lineitem.active &&
                                    new Date(lineitem.archived) > new Date(0)
                                "
                                    style="color: #f00"
                                    :to="`/lineitem/edit/${lineitem._id}`"
                                >
                                    <i class="fa fa-cubes"></i>
                                </router-link>
                                </span>
                                <span
                                class="ml-2 text-small"
                                v-for="(catalog, caindex) in optimizer.catalogs"
                                :key="'ca' + caindex"
                                >
                                <router-link
                                    v-if="caindex < 3"
                                    :to="`/catalog/edit/${catalog._id}`"
                                    style="cursor: pointer"
                                >
                                    <i class="fa fa-table"></i>
                                </router-link>
                                </span>
                            </span>
                            </td>
                            <td>
                            {{ optimizer.clicks }} / {{ optimizer.impressions }} ({{
                            optimizer.ctr
                            }}% / {{
                            optimizer.pcCr
                            }}%)
                            </td>
                            <td
                            v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'"
                            >{{ optimizer.opr }}/{{ amountOfDays(optimizer.qty) }}</td>
                            <td>
                            {{ optimizer.crrConversions }} / {{ optimizer.pc }}-{{
                            optimizer.pv
                            }}
                            / {{ optimizer.sales }} ({{ optimizer.pcMs }}%)
                            </td>
                            <td v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'">
                            <label
                                :class="'badge badge-' + getHealthBadge(optimizer)"
                                :title="listInstructions(optimizer)"
                            >{{ optimizer.health }}</label>
                            <br>
                            <small>P:{{ optimizer.topProducts }} R:{{ optimizer.topReferers }}</small>
                            <small v-for="(icon, index) in optimizer.icons" :key="index">
                                <i :class="`ml-1 fa ${icon}`"></i>
                            </small>
                            </td>
                            <td
                            v-if="getRole == 'systemoperator' || getRole == 'affiliatemanager'"
                            class="text-right"
                            >{{ optimizer.crrRevenue.toFixed(2) }}€ / {{ optimizer.crrCost.toFixed(2) }}€</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        
        <div
            class="col-lg-12 grid-margin stretch-card"
            v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager') && !loading"
        >
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Access Logs</h4>
                    <p>Unauthorized accesses to tracking script</p>
                    <bar-chart :chart-data="accessLogsChart" :options="chartOptions" />

                    <div class="row">
                    <span v-for="(accessLog, alindex) in accessLogs" :key="alindex">
                        <router-link
                        :to="`/accesslogs?search=${accessLog.host}`"
                        >
                        <span
                            class="badge badge-light mr-2 mb-2"
                            style="color: #333"
                        >{{ accessLog.host }} ({{ accessLog.count }})</span>
                        </router-link>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BarChart from "../../charts/Bar";
import LineChart from "../../charts/Line";
import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";

export default {
  name: "AffiliateDashboard",
  props: {
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      currentDaterange: null,
      accessLogLimit: 250,
      optimizerDashboard: [],
      accessLogs: [],
      accessLogsChart: {},
      networkRevenue: [],
      colorRange: ['danger', 'success', 'primary', 'warning', 'info', 'danger', 'success', 'primary', 'warning', 'info', 'danger', 'success', 'primary', 'warning', 'info']
    };
  },
  async created() {
    this.currentDaterange = this.dateRange;
    this.updateDashboard();
  },
  methods: {
    async updateDashboard() {
      if (localStorage.getItem(process.env.VUE_APP_ROLE) == "systemoperator" || localStorage.getItem(process.env.VUE_APP_ROLE) == "wizard"  || localStorage.getItem(process.env.VUE_APP_ROLE) == 'affiliatemanager') {
        this.optimizerDashboard = await this.$api.optimizer.getDashboard(this.dateRange);
        this.accessLogs = await this.$api.accesslog.getAccesslogs({
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate,
          limit: this.accessLogLimit,
        });
        const labels = [];
        const data = [];
        let counter = 0;
        for (var r in this.accessLogs) {
          labels.push(this.accessLogs[r].host);
          data.push(this.accessLogs[r].count);
          if (counter++ > 20) break;
        }
        this.accessLogsChart = {
          labels: labels,
          datasets: [{
            label: "Access logs",
            data: data,
          }]
        }
        let networkRevenueTmp = {}
        for(var p in this.optimizerDashboard) {
          let optimizer = this.optimizerDashboard[p];
          if(optimizer.mediaPlans && optimizer.mediaPlans[0] && optimizer.mediaPlans[0].revenueSource) {
            if(networkRevenueTmp[optimizer.mediaPlans[0].revenueSource]) {
              networkRevenueTmp[optimizer.mediaPlans[0].revenueSource]['revenue'] += optimizer.crrRevenue;
            }
            else {
              networkRevenueTmp[optimizer.mediaPlans[0].revenueSource] = {
                revenue: optimizer.crrRevenue,
                perc: 0
              }
            }
          }
        }
        let networks = Object.keys(networkRevenueTmp);
        let total = this.totalRevenue;

        this.networkRevenue = [];
        for (var t in networks) {
          this.networkRevenue.push({
            network: networks[t],
            revenue: networkRevenueTmp[networks[t]].revenue,
            perc: networkRevenueTmp[networks[t]].revenue / total,
          })
        }
        
        this.networkRevenue.sort((a,b) => (a.perc > b.perc) ? -1 : 1)
      }
    },
    amountOfDays(qty) {
      let startD = new Date(this.dateRange.startDate);
      let endD = new Date(this.dateRange.endDate);

      let aod = parseInt(
        (new Date(endD.getUTCFullYear(), endD.getUTCMonth(), endD.getUTCDate()) -
          new Date(startD.getUTCFullYear(), startD.getUTCMonth(), startD.getUTCDate())) /
          (1000 * 3600 * 24) +
          1
      );
      return aod * qty;
    },
    getStatus(optimizer) {
      if (optimizer.promote && optimizer.sticky) return "fa-pause text-warning";
      if (optimizer.promote) return "fa-play text-success";
      return "fa-stop text-danger";
    },
    getHealthBadge(optimizer) {
      switch (optimizer.health) {
        case "ERROR":
          return "danger";
        case "WARNING":
          return "warning";
        case "OK":
          return "success";
      }
    },
    listInstructions(optimizer) {
      let instructionsString = null;
      for (var i in optimizer.instructions) {
        if (instructionsString !== null) {
          instructionsString += "\n";
        } else {
          instructionsString = "";
        }
        instructionsString += `${optimizer.instructions[i].level}: ${optimizer.instructions[i].message}`;
      }
      return instructionsString;
    },
    copyIdToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
  computed: {
    ...mapGetters([
      "loading",
      "error",
      "success",
      "warning",
      "dateRange",
    ]),
    dateRange: {
      get() {
        return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      }
    },
    getStartTS() {
      return new Date(this.dateRange.startDate).getUTC(true);
    },
    getEndTS() {
      return new Date(this.dateRange.endDate).getUTC(false);
    },
    appTitle() {
      return process.env.VUE_APP_APP_TITLE;
    },
    revenueRangeChart() {
      const revChartTotal = {};
      const costChartTotal = {};
      for (var o in this.optimizerDashboard) {
        for(var i=0; i<this.optimizerDashboard[o]['revenueRangeChart']['labels'].length; i++ ) {
          if(!revChartTotal[this.optimizerDashboard[o]['revenueRangeChart']['labels'][i]]) revChartTotal[this.optimizerDashboard[o]['revenueRangeChart']['labels'][i]] = 0;
          revChartTotal[this.optimizerDashboard[o]['revenueRangeChart']['labels'][i]] += this.optimizerDashboard[o]['revenueRangeChart']['data'][i];
          if(!costChartTotal[this.optimizerDashboard[o]['costRangeChart']['labels'][i]]) costChartTotal[this.optimizerDashboard[o]['costRangeChart']['labels'][i]] = 0;
          costChartTotal[this.optimizerDashboard[o]['costRangeChart']['labels'][i]] += this.optimizerDashboard[o]['costRangeChart']['data'][i];
        }
      }
      return {
        labels: Object.keys(revChartTotal),
        datasets: [{
          label: "Commission",
          data: Object.values(revChartTotal).map(x => Math.round(x * 100)/100),
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1
        }, {
          label: "Cost",
          data: Object.values(costChartTotal).map(x => Math.round(x * 100)/100),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgba(255,99,132,1)',
          ],
          borderWidth: 1
        }]
      };
    },
    totalRevenue() {
      let totalRevenue = 0;
      for (var o in this.optimizerDashboard) {
        totalRevenue += this.optimizerDashboard[o].crrRevenue;
      }
      return totalRevenue.toFixed(2);
    },
    totalCost() {
      let totalCost = 0;
      for (var o in this.optimizerDashboard) {
        totalCost += this.optimizerDashboard[o].crrCost;
      }
      return totalCost.toFixed(2);
    },
    groupedIcons() {
      const iconGroup = {};
      for (var o in this.optimizerDashboard) {
        if (this.optimizerDashboard[o].icons.length) {
          for (var p in this.optimizerDashboard[o].icons) {
            if (
              typeof iconGroup[this.optimizerDashboard[o].icons[p]] !==
              "undefined"
            ) {
              iconGroup[this.optimizerDashboard[o].icons[p]]++;
            } else {
              iconGroup[this.optimizerDashboard[o].icons[p]] = 1;
            }
          }
        }
      }
      return iconGroup;
    },
    amountOf() {
      let amountOfErrors = 0;
      let amountOfWarnings = 0;
      let amountOfOPR = 0;
      let amountOfClicks = 0;
      let amountOfViews = 0;
      let pcConversions = 0;
      let pvConversions = 0;
      for (var o in this.optimizerDashboard) {
        amountOfOPR += this.optimizerDashboard[o].opr;
        amountOfClicks += this.optimizerDashboard[o].clicks;
        amountOfViews += this.optimizerDashboard[o].impressions;
        pcConversions += this.optimizerDashboard[o].pc;
        pvConversions += this.optimizerDashboard[o].pv;
        for (var i in this.optimizerDashboard[o].instructions) {
          if (this.optimizerDashboard[o].instructions[i].level === "ERROR") {
            amountOfErrors++;
          }
          if (this.optimizerDashboard[o].instructions[i].level === "WARNING") {
            amountOfWarnings++;
          }
        }
      }
      return {
        errors: amountOfErrors,
        warnings: amountOfWarnings,
        opr: amountOfOPR,
        clicks: amountOfClicks,
        views: amountOfViews,
        pcConversions: pcConversions,
        pvConversions: pvConversions,
      };
    },
    getName: () => localStorage.getItem(process.env.VUE_APP_NAME),
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    getGenCrrCronUrl: () => `${process.env.VUE_APP_API}/crr/generatecrr/kpoe4joidfakpjojafdgFHF26734`,
  },
  components: {
    BarChart,
    LineChart,
    DateRangePickerCustom
  },
};
</script>

<style> 

.daterange-chooser {
  /* border: none; */
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.daterange-chooser .reportrange-text {
  background-color: #fff;
  /* color: #ffffff; */
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400;
  padding: 13px 17px;
  /* border: none; */
  border-radius: 0px;
}

.daterange-chooser .daterangepicker {
  top: 100%;
}
</style>