<template>
  <div id="page-top">
        <!-- Navigation-->
        <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
            <div class="container">
                <img class="navbar-brand" :class="resp >= 3 ? 'logo-mobile' : 'logo-desktop'" src="/wl/42oNM/logo.png" />
                <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <a class="nav-link text-white py-2 px-0 px-lg-3 rounded active" href="#register">Register Now</a>
                </button>
                <div class="mr-0 my-auto" style="cursor: pointer;" v-if="resp >= 3">
                    <a class="nav-link language-dropdown" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i class="flag-icon" :class="selectedLanguage === 'en' ? 'flag-icon-gb' : 'flag-icon-de'"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" style="top: 70px;" aria-labelledby="notificationDropdown">
                        <a class="dropdown-item preview-item" @click="changeLanguage('en')" style="display: flex">
                            <div class="preview-thumbnail">
                                <div class="preview-icon bg-light" :class="selectedLanguage === 'en' ? 'bg-background' : ''">
                                    <i class="flag-icon flag-icon-gb"></i>
                                </div>
                            </div>
                            <div class="preview-item-content my-auto">
                                <h6 class="preview-subject font-weight-normal text-dark mb-1">English</h6>
                            </div>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item preview-item" @click="changeLanguage('de')" style="display: flex">
                            <div class="preview-thumbnail">
                                <div class="preview-icon bg-light" :class="selectedLanguage == 'de' ? 'bg-background' : ''">
                                    <i class="flag-icon flag-icon-de mx-0 mt-1"></i>
                                </div>
                            </div>
                            <div class="preview-item-content my-auto">
                                <h6 class="preview-subject font-weight-normal text-dark mb-1">German</h6>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item mx-0 mx-lg-1"><router-link class="nav-link py-3 px-0 px-lg-3 rounded" to="/login">Sign In</router-link></li>
                        <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded active" href="#register">Register Now</a></li>
                        <li class="nav-item dropdown mx-lg-5 my-auto" style="cursor: pointer;">
                            <a class="nav-link language-dropdown" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i class="flag-icon" :class="selectedLanguage === 'en' ? 'flag-icon-gb' : 'flag-icon-de'"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" style="top: 44px;" aria-labelledby="notificationDropdown">
                                <a class="dropdown-item preview-item" @click="changeLanguage('en')" style="display: flex">
                                    <div class="preview-thumbnail">
                                        <div class="preview-icon bg-light" :class="selectedLanguage === 'en' ? 'bg-background' : ''">
                                            <i class="flag-icon flag-icon-gb mx-0"></i>
                                        </div>
                                    </div>
                                    <div class="preview-item-content my-auto">
                                        <h6 class="preview-subject font-weight-normal text-dark mb-1">English</h6>
                                    </div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item preview-item" @click="changeLanguage('de')" style="display: flex">
                                    <div class="preview-thumbnail">
                                        <div class="preview-icon bg-light" :class="selectedLanguage == 'de' ? 'bg-background' : ''">
                                            <i class="flag-icon flag-icon-de mx-0"></i>
                                        </div>
                                    </div>
                                    <div class="preview-item-content my-auto">
                                        <h6 class="preview-subject font-weight-normal text-dark mb-1">German</h6>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Masthead-->
        <header class="masthead bg-primary text-white text-center">
            <div class="container d-flex align-items-center flex-column">
                <!-- Masthead Avatar Image-->
                <!-- <img class="masthead-avatar mb-5" src="/wl/42oNM/logo.png" alt="..." /> -->
                <!-- Masthead Heading-->
                <h1 class=" text-uppercase mb-0">All your marketing campaigns</h1>
                
                <!-- Icon Divider-->
                <div class="divider-custom divider-light">
                    <div class="divider-custom-line"></div>
                    <!-- <div class="divider-custom-icon"><i class="fas fa-star"></i></div> -->
                    <div class="divider-custom-line"></div>
                </div>
                <h1 class=" text-uppercase mb-0">In one place</h1>
                <!-- Masthead Subheading-->
                <p class="masthead-subheading font-weight-light mt-5 mb-0">Create Bookings, Upload Creatives, View Statistics. All with just a few clicks.</p>
                <p class="ribbon">
                    <span class="text"><strong class="bold">Start today:</strong> use the code <strong>NEW25</strong> to get € 25 coupon for your first campaign</span>
                </p>
                <p class="my-0" style="font-size: 0.75rem;">**after you deposit min. € 50 to your account</p>
            </div>
        </header>
        <!-- Portfolio Section-->
        <section class="page-section portfolio" id="channels" v-if="Object.keys(channels).length">
            <div class="container">
                <!-- Portfolio Section Heading-->
                <h2 class="page-section-heading text-center text-uppercase text-secondary">Channels</h2>
                <!-- Icon Divider-->
                <div class="divider-custom mb-3">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-line"></div>
                </div>
                <h5 class="mb-5 text-center text-secondary">Manage all marketing channels in one place, with one login</h5>
                <!-- Portfolio Grid Items-->
                <div class="row justify-content-center">
                    <!-- Portfolio Item 1-->
            
                    <div class="col-md-6 col-lg-3 col-sm-12 mb-5" v-for="(categoryChannels, category, index) in channels" :key="index">
                        <b-card class="h-100">
                            <b-card-header :header-class="['transparent-background', 'text-center']">
                                <span class="fluid-font">{{ category }}</span>
                            </b-card-header>
                            <b-card-body>
                                <div class="row mt-2 justify-content-center align-items-center">
                                    <!-- <b-img fluid v-for="(channel, index) in categoryChannels" :key="index" :src="channel.image" :title="channel.name" width="50" height="50" /> -->
                                    <div class="col-3 mb-3" v-for="(channel, index) in categoryChannels" :key="index">
                                        <b-img :src="channel.image" :title="channel.name" width="50" height="50" />
                                        <!-- <p>some text here</p> -->
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>
            </div>
        </section>
        <!-- About Section-->
        <section class="mb-0" style="padding-bottom: 6rem !important;" id="banner">
                <div class="row">
                    <div class="col-md-6 text-secondary " :class="resp < 4 ? 'px-5 pb-5' : ''">
                        <div class="container">
                            <h2 class="text-center mb-3">Automatic Banner Generator</h2>
                            <div class="divider-custom">
                                <div class="divider-custom-line"></div>
                                <div class="divider-custom-line"></div>
                            </div>
                            <ul>
                                <li>
                                    <p class="lead font-weight-normal">
                                        Speed up your workflow and achieve more in less time. Keep things consistent and create full sets of static or animated banner ads online for your marketing campaign.
                                    </p>
                                </li>
                                <li>
                                    <p class="lead font-weight-normal">
                                        Auto-generate personalized social & advertising banners that engage your audience and maximize performance.
                                    </p>
                                </li>
                            </ul>
                        </div>   
                    </div>
                    <div class="col-md-6">
                        <b-img fluid center rounded src="/images/banner-ads.jpg" alt="" />
                    </div>
                </div>
        </section>
        <section class="page-section bg-primary text-white mb-0" id="about">
            <div class="container">
                <!-- About Section Heading-->
                <h2 class="page-section-heading text-center text-uppercase text-white">WARUM WHY USE THE 42ADSUITE?</h2>
                <!-- Icon Divider-->
                <div class="divider-custom divider-light">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-line"></div>
                </div>
                <!-- About Section Content-->
                <div class="row">
                    <div class="col-lg-5 col-md-6 ms-auto">
                        <ul>
                            <li><p class="lead mb-1">Use the 42ADSUITE as a data aggregator</p></li>
                            <li><p class="lead mb-1">Save time with one tool to manage all channels</p></li>
                            <li><p class="lead mb-1">Quickly training new employees</p></li>
                            <li><p class="lead mb-1">Different roles and permissions</p></li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-lg-5 me-auto">
                        <ul>
                            <li><p class="lead mb-1">Manage all your creatives and videos and adTags</p></li>
                            <li><p class="lead mb-1">Manage channel based budgets</p></li>
                            <li><p class="lead mb-1">Create booking in 3 simple steps</p></li>
                            <li><p class="lead mb-1">Extended settings for professional users</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-0" id="gallery" style="padding-bottom: 0rem !important;">
            <h2 class="page-section-heading text-center text-uppercase text-secondary">Gallery</h2>
            <!-- Icon Divider-->
            <div class="divider-custom mb-3">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-line"></div>
            </div>
            <h5 class="mb-5 text-center text-secondary">Get a peak of what our platform has to offer.</h5>
            <div class="row">
                <div class="col-md-10 mx-auto">
                    <vueper-slides class="no-shadow" lazy lazy-load-on-drag arrows-outside bullets-outside :slide-ratio="1 / 2" :fixed-height="resp < 4 ? '640px' : '240px'">
                        <vueper-slide v-for="(slide, i) in slides" :key="i" :title="slide.title" :content="slide.content" :image="slide.image">
                            <template #content>
                                <div class="d-flex flex-column justify-content-center align-items-end" v-if="resp < 4">
                                    <div class="gallery-content px-4 py-2 text-center">
                                        <span class="h5">{{ slide.title }}</span>
                                        <p>{{ slide.content }}</p>
                                    </div>
                                </div>
                                <div v-else class="d-flex flex-column justify-content-center align-items-end">
                                    <!-- No Content if on mobile -->
                                </div>
                            </template>
                        </vueper-slide>
                    </vueper-slides>
                </div>
            </div>
        </section>
        <!-- Contact Section-->
        <section class="page-section" id="register">
            <div class="container" v-if="!registered">
                <!-- Contact Section Heading-->
                <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Register Now</h2>
                <!-- Icon Divider-->
                <div class="divider-custom">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-line"></div>
                </div>
                <h6 class="text-center text-secondary">Enter your details below. A sales representative will get in touch with you shortly through the email address you'll provide.</h6>
                <!-- Register Section Form-->
                <form id="contactForm">
                    <div class="alert alert-danger" v-if="error">{{error}}</div>
                    <div class="row justify-content-center mt-4">
                        <div class="col-lg-5 col-xl-5">
                                    <!-- Name input-->
                                    <div class="form-floating mb-3">
                                        <input v-model="name" class="form-control" id="name" type="text" placeholder="Enter your name..." />
                                        <label for="name">Name</label>
                                        <div v-if="$v.name.$dirty && !$v.name.required" class="invalid-feedback">A name is required.</div>
                                        <div v-if="!$v.name.minLength" class="invalid-feedback">Name should be longer than 2 characters.</div>
                                    </div>
                        </div>
                    
                        <div class="col-lg-5 col-xl-5">
                                <!-- Surname input-->
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="surname" type="text" v-model="surname" placeholder="Enter your surname..." />
                                    <label for="name">Surname</label>
                                    <div v-if="$v.surname.$dirty && !$v.surname.required" class="invalid-feedback">A surname is required.</div>
                                    <div v-if="!$v.surname.minLength" class="invalid-feedback">Surname should be longer than 2 characters.</div>
                                </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                            <div class="col-lg-5 col-xl-5">
                                <!-- Email address input-->
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="email" type="email" v-model="email" placeholder="name@example.com" />
                                    <label for="email">Email</label>
                                    <div v-if="$v.email.$dirty && !$v.email.required" class="invalid-feedback" >An email is required.</div>
                                    <div v-if="!$v.email.email" class="invalid-feedback" >Email is not valid.</div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-xl-5">       
                                <!-- Bussines Role input-->
                                <div class="form-floating mb-3">
                                    <div class="mb-3">
                                        <label for="role" style="margin-bottom: 1px;">Business Role</label>
                                        <b-form-select class="form-control" id="role" v-model="jobRole" :options="roles" style="height: calc(2.25rem + 12px);"></b-form-select>
                                        <div v-if="$v.jobRole.$dirty && !$v.jobRole.required" class="invalid-feedback">A role is required.</div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="row justify-content-center">
                            <div class="col-lg-5 col-xl-5">
                                <!-- Advertiser address input-->
                                <div class="form-floating mb-3">
                                    <input v-model="advertiser" class="form-control" id="advertiser" type="text" placeholder="Your offer, company or venture" />
                                    <label for="email">Your offer, company or venture</label>
                                    <div v-if="$v.name.$dirty && !$v.advertiser.required" class="invalid-feedback">An advertiser is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-xl-5">
                                <div class="form-floating mb-3">
                                    <div class="mb-3">
                                        <label for="industry" style="margin-bottom: 1px;">Industry</label>
                                        <b-form-select id="industry" class="form-control" v-model="industry" :options="industries" style="height: calc(2.25rem + 12px);"></b-form-select>
                                        <div v-if="$v.industry.$dirty && !$v.industry.required" class="invalid-feedback">An industry is required.</div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="row">
                            <div class="col-lg-5 col-xl-5 offset-lg-1">
                                <!-- Budget input-->
                                    <div class="form-floating mb-3">
                                        <input class="form-control" v-model.number="dailyBudget" id="budget" type="number" placeholder="Expected daily budget"/>
                                        <label for="budget">Expected Daily Budget (€)</label>
                                        <div v-if="$v.dailyBudget.$dirty && !$v.dailyBudget.required" class="invalid-feedback">A daily budget is required.</div>
                                    </div>
                            </div>
                            
                            
                    </div>
                    <div class="row justify-content-center">
                            <div class="col-lg-5 col-xl-5">
                                <!-- Password input-->
                                <div class="form-floating mb-3">
                                    <input v-model="password" class="form-control" id="password" type="password" placeholder="Enter your password" autocomplete="off"/>
                                    <label for="password">Password</label>
                                    <div v-if="$v.password.$dirty && !$v.password.required" class="invalid-feedback">A password is required.</div>
                                    <div v-if="$v.password2.$dirty && !$v.password.minLength" class="invalid-feedback">Password should be longer than 6 characters.</div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-xl-5">
                                
                                <!-- Phone number input-->
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="password2" type="password" v-model="password2" placeholder="Confirmation password"/>
                                    <label for="password">Confirm Password</label>
                                    <div v-if="$v.password2.$dirty && !$v.password2.required" class="invalid-feedback">Confirmation password is required</div>
                                    
                                    <div v-if="$v.password2.$dirty && $v.password2.required && !$v.password2.sameAsPassword" class="invalid-feedback">Confirmation password does not match</div>
                                </div>
                            </div>
                        
                    </div>
                    <div class="row justify-content-center mt-3">
                        <div class="col-md-8 col-lg-6 offset-lg-2">
                            <b-form-checkbox
                                type="checkbox"
                                v-model="terms_privacy"
                                class="mb-2 mt-3 text-left"
                                :value="true"
                                :unchecked-value="false">
                                I read and agree to
                                <router-link target="_blank" to="/terms">Terms &amp; Conditions</router-link> and
                                <router-link target="_blank" to="/privacy">Privacy Policy</router-link>
                            </b-form-checkbox>
                            <b-form-checkbox
                                type="checkbox"
                                v-model="newsletter"
                                class="text-left"
                                :value="true"
                                :unchecked-value="false">
                                I agree to receive a newsletter
                            </b-form-checkbox>
                            <div v-if="$v.terms_privacy.$dirty && !$v.terms_privacy.sameAs" class="invalid-feedback">You need to accept the terms & conditions.</div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-3">
                        <div class="col-md-4">
                            <button :disabled="loading" class="btn btn-primary btn-block btn-xl mt-3" id="submitButton" @click.prevent="register">Send</button>
                        </div>
                    </div>       
                </form>
            </div>
            <div v-else>
                <div class="p-5 alert alert-success text-center">
                    <h4>Thank you, we have received your registration. Please check your mails and click the confirmation link.</h4> 
                </div>
            </div>
        </section>
        <!-- Footer-->
        <footer class="footer text-center">
            <div class="container">
                <div class="row">
                    <!-- Footer Location-->
                    <div class="col-lg-4 mb-5 mb-lg-0">
                        <h4 class="text-uppercase mb-4">Location</h4>
                        <p class="lead mb-0">
                            SPEDITIONSTRASSE 21
                            <br />
                            40221 DÜSSELDORF, GERMANY
                        </p>
                    </div>
                    <!-- Footer Social Icons-->
                    <div class="col-lg-4 mb-5 mb-lg-0">
                        <h4 class="text-uppercase mb-4">Contact</h4>
                        <p class="mx-1 mb-3"><i class="fab fa-fw fa fa-phone mr-1"></i>+49 (0) 151 649 642 37</p>
                        
                        <p class=" mx-1"><i class="fab fa-fw fa fa-envelope-o mr-3"></i>SUPPORT@42ADS.IO</p>
                        
                    </div>
                    <!-- Footer About Text-->
                    <div class="col-lg-4">
                        <h4 class="text-uppercase mb-2">Register</h4>
                        <p class="lead mb-0">
                            Register court: Düsseldorf District Court.
                            <br />
                            Register number: HRB 88123
                            <br />
                            VAT ID: DE328736871
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <!-- Copyright Section-->
        <div class="copyright py-4 text-center text-white">
            <div class="container"><small>Copyright © {{ new Date().getFullYear() }} 42 Ads GmbH. All rights reserved.</small></div>
        </div>
    </div>
</template>

<script>
import defaultChannels from "@/constants/channels.js";

import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
import _groupBy from 'lodash.groupby'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "LandingPage",
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  created() {
      this.channels = _groupBy(defaultChannels.filter(c => c.active && c.key != 'affiliate'), 'category');
      this.selectedLanguage = this.$root.$i18n.locale;
  },
  data() {
    let locale = 'en';
    if(this.$i18n.locale) locale = this.$i18n.locale;

    return {
      selectedLanguage: "en",
      resp: 1,
      error: '',
      channels: [],
      roles: require(`@/locales/businessRoles.${locale}.js`),
      industries: require(`@/locales/industries.${locale}.js`),
      //industries: require(`@/locales/industries.${this.$i18n.locale}.js`),
      registered: false,
      loading: false,
      email: "",
      name: "",
      advertiser: "",
      surname: "",
      password: "",
      password2: "",
      terms_privacy: false,
      newsletter: false,
      dailyBudget: 5,
      jobRole: '',
      industry: '',
      slides: [
        {
          title: 'Dashboard',
          content: 'Quick view of all your campaigns',
          image: require('@/assets/gallery/42ads_activbookings_1280x640.png')
        },
        {
          title: 'Statistics',
          content: 'Detailed statistics including graphics.',
          image: require('@/assets/gallery/42ads_reporting_1280x640.png')
        },
        {
          title: 'Booking Wizard',
          content: 'Create a booking in judt a few steps',
          image: require('@/assets/gallery/42ads_wizardscreen1_1280x640.png')
        },
        {
          title: 'Booking Wizard',
          content: 'Select your prefered targetings.',
          image: require('@/assets/gallery/42ads_wizardscreen2_1280x640.png')
        },
        {
          title: 'Booking Wizard',
          content: 'A small overview before it can start.',
          image: require('@/assets/gallery/42ads_wizardscreen3_1280x640.png')
        },
        {
          title: 'Reporting',
          content: 'Simply compare your campaigns',
          image: require('@/assets/gallery/42ads_toptemplates_1280x640.png')
        },
        {
          title: 'Top Websites',
          content: 'View your top performing websites.',
          image: require('@/assets/gallery/42ads_topwebsites_1280x640.png')
        },
      ],
    }
  },
  components: { VueperSlides, VueperSlide },
  provide() {
    return {
      $v: this.$v
    };
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    changeLanguage(ln) {
      this.selectedLanguage = ln
      localStorage.setItem(process.env.VUE_APP_LANGUAGE, ln)
      this.$root.$i18n.locale = ln;
      this.$router.go();
    },
    async register() {
        this.error = false;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.loading = true;
        const payload = {
          email: this.email,
          name: this.name,
          advertiser: this.advertiser,
          surname: this.surname,
          password: this.password,
          newsletter: this.newsletter,
          dailyBudget: this.dailyBudget,
          jobRole: this.jobRole,
          industry: this.industry,
        };
        try {
            const result = await this.$store.dispatch("register", payload);
            if(result) {
            // clear form
                this.email = "",
                this.name = "",
                this.advertiser = "",
                this.surname = "",
                this.password = "",
                this.password2 = "",
                this.terms_privacy = false,
                this.newsletter = false

                // load tracking pixel
                this.executeTrackingscript();
            }
            
        } catch (error) {
            this.error = error.message
        }
        this.loading = false;
        this.registered = true
    },
    onResize() {
       if(window.innerWidth >= 1400) {
        this.resp = 1;
      } else if(window.innerWidth >= 1200 && window.innerWidth < 1400) {
        this.resp = 2;
      } else if(window.innerWidth >= 992 && window.innerWidth < 1200) {
        this.resp = 3;
      } else if(window.innerWidth < 992) {
        this.resp = 4;
      } else {
        this.resp = 1;
      }
    },
    executeTrackingscript(){
        let trackingScript = document.createElement('script');
        trackingScript.setAttribute('type', 'text/javascript')
        trackingScript.setAttribute('src', 'https://ai.trk42.net/')
        document.head.appendChild(trackingScript);
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(2)
    },
    surname: {
      required,
      minLength: minLength(2)
    },
    email: {
      required,
      email
    },
    advertiser: {
      required,
    },
    jobRole: {
      required,
    },
    industry: {
      required,
    },
    password: {
      required,
      minLength: minLength(6)
    },
    password2: {
      required,
      sameAsPassword: sameAs('password')
    },
    terms_privacy: {
      sameAs: sameAs( () => true ),
    },
    dailyBudget: {
      required,
    }
  }
}
</script>

<style scoped src="../../assets/landing/styles.css" lang="css"></style>
<style scoped>
    .logo-mobile {
        width: 80px;
        height: 40px
    }

    .logo-desktop {
        width: 140px;
        height: 60px
    }

    .bg-background {
        border-color: #00b297;
        border-style: solid;
        border-width: 2px;
        padding-right: 0.7rem !important;
        padding-left: 0.3rem !important;
    }

    .language-dropdown {
      border: 2px solid rgba(26, 188, 156, 1);
      border-style: solid;
      border-radius: 100%;
      padding-right: 0.7rem !important;
      padding-left: 0.7rem !important;
    }

    .vueperslide {
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }

    .fluid-font {
        font-size: 1.3vw;
        font-weight: 800;
    }

    @media screen and (max-width: 992px) {
        .fluid-font {
            font-size: 1.5rem !important;
        }
    }

    .custom-select-lg {
        height: calc(2.875rem + 14px) !important;
        padding-top: 0.375rem !important;
        padding-bottom: 0.375rem !important;
        font-size: 125% !important;
    }

    .transparent-background {
        background-color: transparent !important;
    }

    .invalid-feedback {
        display: inline-block !important;
    }

    .gallery-content {
        background-color: #00a48b;
        color: aliceblue;
    }

    .alert-success {
        color: #008773;
        background-color: rgba(0, 178, 151, 0.2);
        border-color: #00a48b;
    }

    .ribbon{
        font-size: 20px;
        position: relative;
        display: inline-block;
        margin: 2em 2em 0 2em;
        text-align: center;
    }
    .text{
        display:inline-block;
        padding:0.5em 1em;
        min-width:20em;
        line-height:1.2em;
        background: #ffa500;
        position:relative;
    }
    .ribbon:after,.ribbon:before,
    .text:before,.text:after,
    .bold:before{
        content:'';
        position:absolute;
        border-style:solid;
    }
    .ribbon:before{
        top:0.3em; left:0.2em;
        width:100%; height:100%;
        border:none;
        background:#EBECED;
        z-index:-2;
    }
    .text:before{
        bottom:100%; left:0;
        border-width: .5em .7em 0 0;
        border-color: transparent #fb6e00 transparent transparent;
    }
    .text:after{
        top:100%; right:0;
        border-width: .5em 2em 0 0;
        border-color: #fb6e00 transparent transparent transparent;
    }
    .ribbon:after, .bold:before{
        top:0.5em;right:-2em;
        border-width: 1.1em 1em 1.1em 3em;
        border-color: #FECC30 transparent #FECC30 #FECC30;
        z-index:-1;
    }
    .bold:before{
        border-color: #EBECED transparent #EBECED #EBECED;
        top:0.7em;
        right:-2.3em;
    }
</style>