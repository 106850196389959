import Vue from 'vue'
import * as GmapVue from 'gmap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import { StripePlugin } from '@vue-stripe/vue-stripe';
// import VueTour from 'vue-tour'
import VueI18n from 'vue-i18n'
import VueCompositionAPI from '@vue/composition-api'


// Services
import './serviceInjection';
// import 'tui-image-editor/dist/tui-image-editor.css';
// import 'tui-color-picker/dist/tui-color-picker.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import '@/assets/plugins/v-select.scss'
import '@/assets/plugins/dropzone.scss'
import '@/assets/plugins/custom-modal.scss'
// import 'vue-tour/dist/vue-tour.css'

import en from './locales/en.json'
import de from './locales/de.json'
import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;


Vue.component('v-select', vSelect)

Vue.config.productionTip = false

// Install BootstrapVue
Vue.use(BootstrapVue)

//Use composition API
Vue.use(VueCompositionAPI)


// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
    libraries: 'places',
    language: 'en'
  },
  installComponents: true
})

Vue.use(Vuelidate)
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_API_KEY,
  api_version: "3",
  locale: "en",
  stripeAccount: "acct_1Hiy9yDdGqUh7YD4"
});

Vue.use(VueI18n);

const messages = { en: en, de: de };
const languageCache = localStorage.getItem(process.env.VUE_APP_LANGUAGE);
if(!languageCache) {
  localStorage.setItem(process.env.VUE_APP_LANGUAGE, process.env.VUE_APP_LANGUAGE_DEFAULT);
}
const locale = languageCache || process.env.VUE_APP_LANGUAGE_DEFAULT;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANGUAGE_DEFAULT,
  messages,
  numberFormats: {
    'de': {
      currency:{
        style:'currency',
        currency: 'EUR'
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      percent: {
        style: 'percent'
      }
    },
    'en': {
      currency:{
        style:'currency',
        currency: 'EUR'
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      percent: {
        style: 'percent'
      }
    }
  }
});

// Vue.use(VueTour)

Vue.mixin({
  methods: {
    goBack() {
      this.$router.push(this.$route.meta.prevRoute);
    },
    toDate(timestamp) {
      const ts = new Date(timestamp);
      return `${ts.getDate().toString().length > 1 ? ts.getDate() : `0${ts.getDate()}`}-${(ts.getMonth() + 1).toString().length > 1 ? ts.getMonth() + 1 : `0${ts.getMonth() + 1}`}-${ts.getFullYear()}`
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
})

Date.prototype.getUTC = function(type = true){
  return Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), type ? 0 : 23, type ? 0 : 59, type ? 0 : 59, type ? 0 : 999);
};

Date.prototype.formatDate = function(isUTC=false){
  if(isUTC){
    return `${this.getFullYear()}-${(this.getMonth()+1) < 10 ? '0'+(this.getMonth()+1) : this.getMonth()+1}-${(this.getDate()) < 10 ? '0'+(this.getDate()) : this.getDate()}`;
  }
  else{
    return `${this.getUTCFullYear()}-${(this.getUTCMonth()+1) < 10 ? '0'+(this.getUTCMonth()+1) : this.getUTCMonth()+1}-${(this.getUTCDate()) < 10 ? '0'+(this.getUTCDate()) : this.getUTCDate()}`;
  }
};

Date.prototype.formatTime = function(isUTC=false){
  if(isUTC){
    return `${(this.getHours()) < 10 ? '0'+(this.getHours()) : this.getHours()}:${(this.getMinutes()) < 10 ? '0'+(this.getMinutes()) : this.getMinutes()}:${(this.getSeconds()) < 10 ? '0'+(this.getSeconds()) : this.getSeconds()}`;
  }
  else{
    return `${(this.getUTCHours()) < 10 ? '0'+(this.getUTCHours()) : this.getUTCHours()}:${(this.getUTCMinutes()) < 10 ? '0'+(this.getUTCMinutes()) : this.getUTCMinutes()}:${(this.getUTCSeconds()) < 10 ? '0'+(this.getUTCSeconds()) : this.getUTCSeconds()}`;
  }
};

Date.prototype.formatDatetime = function(isUTC=false, delimiter=", "){
  return `${this.formatDate(isUTC)}${delimiter}${this.formatTime(isUTC)}`;
};

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if(process.env.VUE_APP_NAME == "42SoNM" || process.env.VUE_APP_NAME == "42DoNM") {
  // eslint-disable-next-line
  console.log(`BUILDINFO -- COMMIT_SHA: ${process.env.VUE_APP_COMMIT_SHA} -- BUILD: ${process.env.VUE_APP_BUILD} -- REV: ${process.env.VUE_APP_REV}`);
}