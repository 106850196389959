const roles = [
  { value: null, text: "Choose your business role", disabled: true},
  { value: 	9	, text: 'Owner' },
  { value: 	10	, text: 'Chief Executive Officer (CEO)' },
  { value: 	20	, text: 'Chief Operating Officer (COO)' },
  { value: 	30	, text: 'Chief Financial Officer (CFO)' },
  { value: 	40	, text: 'Chief Marketing Officer (CMO)' },
  { value: 	50	, text: 'Chief Technology Officer (CTO)' },
  { value: 	60	, text: 'Marketing manager' },
  { value: 	70	, text: 'Product manager' },
  { value: 	80	, text: 'Project manager' },
  { value: 	90	, text: 'Finance manager' },
  { value: 	100	, text: 'Human resources manager' },
  { value: 	110	, text: 'Marketing specialist' },
  { value: 	120	, text: 'Business analyst' },
  { value: 	130	, text: 'Human resource personnel' },
  { value: 	140	, text: 'Accountant' },
  { value: 	150	, text: 'Sales representative' },
  { value: 	160	, text: 'Customer service representative' },
  { value: 	170	, text: 'Administrative assistant' },
]

module.exports = roles;