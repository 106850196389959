<template>
  <form class="forms-sample">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div
            style="white-space: pre-wrap; word-break: break-all; background-color: #ddd"
            class="rounded p-3">
            <p class="text-monospace">&lt;iframe
                src=&quot;https://{{ getAdserver }}/banner?id={{entity._id}}&amp;l=&amp;c=&amp;desc=d_{{entity.width}}x{{entity.height}}&amp;pubid=${Pub}&amp;ssp=${Pub}&amp;pubdomain=${DetectedDomain}&amp;clickUrl=${ClickURLEnc}&quot;
                width=&quot;{{entity.width}}&quot;
                height=&quot;{{entity.height}}&quot;
                scrolling=&quot;no&quot;
                frameborder=&quot;0&quot;
                marginwidth=&quot;0px&quot;
                marginheight=&quot;0px&quot;
                style=&quot;width: {{entity.width}}px; height: {{entity.height}}px;border:0px;overflow:hidden;&quot;&gt;&lt;/iframe&gt;
            </p>
          </div>
        </div>
          <b-button
            variant="outline-secondary"
            type="button"
            @click="submit"
            class="btn mr-2 pull-right"
          >
            Close
          </b-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "BannerContainerCodesModal",
  props: ["entity"],
  data() {
    return {
      getAdserver: process.env.VUE_APP_ADSERVER,
    };
  },
  methods: {
    async submit() {
        this.$bvModal.hide("banner-container-codes-modal");
    }
  },
};
</script>