import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../components/pages/Dashboard.vue';
import AffiliateDashboard from '../components/pages/AffiliateDashboard.vue';
import AffiliateDashboard2 from '../components/pages/AffiliateDashboard2.vue';
import CampaignStats from '../components/pages/CampaignStats.vue';
import CampaignStatsNew from '../components/pages/CampaignStatsNew.vue';
import ConversionPage from '../components/pages/ConversionPage.vue';
import ClickPage from '../components/pages/ClickPage.vue';
// import Logins from '../components/admin/Logins.vue';
import SubaccountsTable from '../components/agency/SubaccountsTable.vue';
import LandingPageEN from '../components/auth/LandingPageEN';
import LandingPageDE from '../components/auth/LandingPageDE';
import SearchResults from '../components/pages/SearchResults.vue';
import Resetpassword from '../components/auth/Resetpassword.vue';
import Entities from '../components/admin/Entities.vue';
import WizardForm from '../components/admin/WizardForm.vue';
import Privacy from '../components/pages/Privacy.vue';
import TermsAndConditions from '../components/pages/TermsAndConditions.vue';
import Login from '../components/auth/Login.vue';
import Register from '../components/auth/Register.vue';
import FAQ from '../components/auth/FAQ.vue';
import Error404 from '../components/error/Error404.vue';
import Error403 from '../components/error/Error403.vue';
import store from '../store/index';
import CampaignLogsTable from '../components/admin/CampaignLogsTable.vue';
import Bookings from '../components/pages/Bookings.vue'
import CreativeApproval from '../components/admin/approval/ApprovalPage';
import { canActivate } from '../services/role-guard.js';
import { LoginRole } from '../constants/roles';

Vue.use(VueRouter);

const pageTitleSuffix = process.env.VUE_APP_APP_TITLE;
const language = localStorage.getItem(process.env.VUE_APP_LANGUAGE);
const role = localStorage.getItem(process.env.VUE_APP_ROLE);

const routes = [
  {
    path: '/landing',
    name: 'LandingPage',
    component: language == 'en' ? LandingPageEN : LandingPageDE,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/terms',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      title: 'Terms of Service',
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: 'Privacy Policy',
    },
  },

  /*** FOR PUBLISHERS */
  {
    path: '/p',
    name: 'Dashboard',
    component: () => import('../components/pages/PublisherDashboard.vue'),
    props: true,
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'Dashboard',
    },
  },
  {
    path: '/p/campaigns',
    name: 'Campaigns',
    component: () => import('../components/pages/PublisherCampaigns.vue'),
    props: true,
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'Campaigns',
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Dashboard',
    },
  },
  {
    path: '/affiliateold',
    name: 'Affiliate Dashboard',
    component: AffiliateDashboard,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Affiliate Dashboard',
    },
  },
  {
    path: '/campaign/conversions/:id',
    name: 'Conversion Page',
    component: ConversionPage,
    props: true,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER, LoginRole.ACCOUNTING],
      title: 'Conversion Page'
    }
  },
  {
    path: '/campaign/clicks/:id',
    name: 'Clicks Page',
    component: ClickPage,
    props: true,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER, LoginRole.ACCOUNTING],
      title: 'Click Page'
    }
  },
  {
    path: '/affiliate',
    name: 'Affiliate Dashboard 2.0',
    component: AffiliateDashboard2,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Affiliate Dashboard 2.0',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/general/Profile'),
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Your Profile',
    },
  },
  {
    path: '/logins',
    name: 'Logins',
    component: Entities,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Logins',
      entity: 'auth',
      fields: ['avatar','email', 'name', 'role', 'advertiser', 'loginAllowed', 'lastLogin'],
      populate: [
        {
          path: 'logins',
          ref: 'parent',
          select: 'email',
        },
      ],
    },
  },
  {
    path: '/subaccounts',
    name: 'Subaccounts',
    component: SubaccountsTable,
    meta: {
      auth: [LoginRole.CO_WORKER],
      title: 'Subaccounts',
    },
  },
  {
    path: '/subaccount/new',
    name: 'NewSubaccount',
    component: () => import(/* webpackChunkName: "new-subaccount" */ '@/views/agency/subaccount/Create'),
    meta: {
      auth: [LoginRole.CO_WORKER],
      title: 'Create Login',
    },
  },
  {
    path: '/subaccount/edit/:id',
    name: 'EditSubaccount',
    component: () => import(/* webpackChunkName: "edit-subaccount" */ '@/views/agency/subaccount/Edit'),
    meta: {
      auth: [LoginRole.CO_WORKER],
      title: 'Edit Login',
    },
  },
  {
    path: "/approvals",
    name: "CreativeApproval",
    component: CreativeApproval,
    meta: {
      auth: [LoginRole.ADMIN],
      title: "Creative Approval"
    }
  },
  {
    path: '/login/new',
    name: 'NewLogin',
    component: () => import(/* webpackChunkName: "new-logintemplate" */ '@/views/management/login/Create'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Create Login',
    },
  },
  {
    path: '/network-monitoring',
    name: 'NetworkMonitoring',
    component: () => import(/* webpackChunkName: "network-monitoring" */ '@/views/general/NetworkMonitoring'),
    props: true,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Network Monitoring',
    },
  },
  {
    path: '/accounting',
    name: 'Accounting',
    component: () => import(/* webpackChunkName: "accounting" */ '@/views/general/Accounting'),
    props: true,
    meta: {
      auth: [LoginRole.ACCOUNTING],
      title: 'Accounting',
    },
  },
  {
    path: '/login/edit/:id',
    name: 'EditLogin',
    component: () => import(/* webpackChunkName: "edit-login" */ '@/views/general/Profile'),
    props: true,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Edit Login',
    },
  },
  {
    path: '/trackingcodes',
    name: 'TrackingCodes',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Tracking Codes',
      entity: 'trackingcode',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name'],
    },
  },
  {
    path: '/trackingcode/new',
    name: 'NewTrackingCode',
    component: () => import(/* webpackChunkName: "new-trackingcode" */ '@/components/forms/TrackingCodeForm'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'New Tracking Code',
    },
  },
  {
    path: '/trackingcode/edit/:id',
    name: 'EditTrackingCode',
    component: () => import(/* webpackChunkName: "edit-trackingcode" */ '@/components/forms/TrackingCodeForm'),
    props: true,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Edit Tracking Code',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Entities,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Settings',
      entity: 'setting',
      fields: ['name'],
    },
  },
  {
    path: '/setting/new',
    name: 'NewSetting',
    component: () => import(/* webpackChunkName: "new-blacklist" */ '@/views/settings/Create'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'New Setting',
    },
  },
  {
    path: '/setting/edit/:id',
    name: 'EditSetting',
    component: () => import(/* webpackChunkName: "edit-blacklist" */ '@/views/settings/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Edit Setting',
    },
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: Entities,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER],
      title: 'Coupons',
      entity: 'coupon',
      fields: ['name', 'code', 'validFrom', 'validTo', 'active'],
    },
  },
  {
    path: '/coupon/new',
    name: 'NewCoupon',
    component: () => import(/* webpackChunkName: "new-coupons" */ '@/views/admin/coupon/Create'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'New Coupon',
    },
  },
  {
    path: '/coupon/edit/:id',
    name: 'EditCoupon',
    component: () => import(/* webpackChunkName: "edit-coupons" */ '@/views/admin/coupon/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Edit Coupon',
    },
  },
  {
    path: '/bookingtemplate',
    name: 'BookingTemplate',
    component: Entities,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Booking Template',
      entity: 'bookingtemplate',
      populate: [
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'start', 'end', 'channels', 'domains', 'creatives', 'budgetMax'],
    },
  },
  {
    path: '/bookingtemplate/create',
    name: 'NewBookingTemplateWizard',
    component: () => import(/* webpackChunkName: "new-wizard" */ '@/views/bookingtemplate'),
    meta: {
      auth: [LoginRole.AGENCY],
      title: 'Create BookingTemplate',
    },
  },
  {
    path: '/bookingtemplate/update/:id',
    name: 'UpdateBookingTemplateWizard',
    component: () => import(/* webpackChunkName: "new-wizard" */ '@/views/bookingtemplate'),
    meta: {
      auth: [LoginRole.AGENCY],
      title: 'Update BookingTemplate',
    },
  },
  {
    path: '/richvideos',
    name: 'RichVideos',
    component: Entities,
    meta: {
      auth: [LoginRole.VIEWER],
      title: 'Rich Videos',
      entity: 'richvideo',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name'],
    },
  },

  {
    path: '/richvideo/new',
    name: 'NewRichVideo',
    component: () => import(/* webpackChunkName: "new-bookingtemplate" */ '@/views/ads/richVideos/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'New Rich Video',
    },
  },
  {
    path: '/richvideo/edit/:id',
    name: 'EditRichVideo',
    component: () => import(/* webpackChunkName: "edit-bookingtemplate" */ '@/views/ads/richVideos/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Rich Video',
    },
  },
  {
    path: '/audiences',
    name: 'Audiences',
    component: Entities,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER],
      title: 'Audiences',
      entity: 'audience',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name', 'created'],
    },
  },
  {
    path: '/audience/new',
    name: 'NewAudience',
    component: () => import(/* webpackChunkName: "new-audience" */ '@/views/ads/audiences/Create'),
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER],
      title: 'New Audience',
    },
  },
  {
    path: '/audience/edit/:id',
    name: 'EditAudience',
    component: () => import(/* webpackChunkName: "edit-audience" */ '@/views/ads/audiences/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER],
      title: 'Edit Audience',
    },
  },
  {
    path: '/segments',
    name: 'Segments',
    component: Entities,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Segments',
      entity: 'segment',
      populate: [
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'identifier'],
    },
  },
  {
    path: '/segment/new',
    name: 'NewSegment',
    component: () => import(/* webpackChunkName: "new-segment" */ '@/views/ads/segments/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'New Segment',
    },
  },
  {
    path: '/segment/edit/:id',
    name: 'EditSegment',
    component: () => import(/* webpackChunkName: "edit-segment" */ '@/views/ads/segments/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Segment',
    },
  },
  {
    path: '/bannertemplates',
    name: 'Bannertemplates',
    component: Entities,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Bannertemplates',
      entity: 'bannertemplate',
      populate: [
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'widthheight'],
    },
  },
  {
    path: '/bannertemplate/new',
    name: 'NewBannertemplate',
    component: () => import(/* webpackChunkName: "new-bannertemplate" */ '@/views/ads/bannertemplates/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'New Bannertemplate',
    },
  },
  {
    path: '/bannertemplate/edit/:id',
    name: 'EditBannertemplate',
    component: () => import(/* webpackChunkName: "edit-bannertemplate" */ '@/views/ads/bannertemplates/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Bannertemplate',
    },
  },
  {
    path: '/cis',
    name: 'Corporate identities',
    component: Entities,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Corporate identities',
      entity: 'ci',
      populate: [
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'logo'],
    },
  },
  {
    path: '/ci/new',
    name: 'NewCorporateIdentity',
    component: () => import(/* webpackChunkName: "new-ci" */ '@/views/ads/cis/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'New Corporate Identity',
    },
  },
  {
    path: '/ci/edit/:id',
    name: 'EditCorporateIdentity',
    component: () => import(/* webpackChunkName: "edit-ci" */ '@/views/ads/cis/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Corporate Identity',
    },
  },
  {
    path: '/optimizers',
    name: 'Optimizers',
    component: Entities,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Optimizers',
      entity: 'optimizer',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['promote', 'internal', 'sticky', 'qty', 'ms', 'crp', 'ctr'],
    },
  },
  {
    path: '/optimizer/new',
    name: 'NewOptiomizer',
    component: () => import(/* webpackChunkName: "new-optimizer" */ '@/views/sysops/optimizers/Create'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'New Optimizer',
    },
  },
  {
    path: '/optimizer/edit/:id',
    name: 'EditOptimizer',
    component: () => import(/* webpackChunkName: "edit-optimizer" */ '@/views/sysops/optimizers/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Edit Optimizer',
    },
  },
  {
    path: '/splitters',
    name: 'Splitters',
    component: Entities,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Splitters',
      entity: 'splitter',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name'],
    },
  },
  {
    path: '/splitter/new',
    name: 'NewSplitter',
    component: () => import(/* webpackChunkName: "new-splitter" */ '@/views/ads/splitters/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'New Splitter',
    },
  },
  {
    path: '/splitter/edit/:id',
    name: 'EditSplitter',
    component: () => import(/* webpackChunkName: "edit-splitter" */ '@/views/ads/splitters/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Splitter',
    },
  },
  {
    path: '/wizards',
    name: 'Wizards',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD, LoginRole.AFFILIATEMANAGER],
      title: 'Wizards',
      entity: 'wizard',
      fields: ['name', 'revenueSource', 'programid'],
    },
  },
  {
    path: '/wizard/new',
    name: 'NewWizard',
    component: WizardForm,
    meta: {
      auth: [LoginRole.WIZARD, LoginRole.AFFILIATEMANAGER],
      title: 'New Wizard',
    },
  },
  {
    path: '/wizard/edit/:id',
    name: 'EditWizard',
    component: WizardForm,
    props: true,
    meta: {
      auth: [LoginRole.WIZARD, LoginRole.AFFILIATEMANAGER],
      title: 'Edit Wizard',
    },
  },
  {
    path: '/mediaplans',
    name: 'MediaPlans',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Media Plans',
      entity: 'mediaplan',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['_id', 'totalDailySpend'],
    },
  },
  {
    path: '/mediaplan/new',
    name: 'NewMediaPlan',
    component: () => import(/* webpackChunkName: "new-media-plan" */ '@/views/admin/mediaplans/Create'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'New Media Plan',
    },
  },
  {
    path: '/mediaplan/edit/:id',
    name: 'EditMediaPlan',
    component: () => import(/* webpackChunkName: "edit-media-plan" */ '@/views/admin/mediaplans/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Edit Media Plan',
    },
  },
  {
    path: '/richvideotemplates',
    name: 'RichVideoTemplates',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Rich Video Templates',
      entity: 'richvideotemplate',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name'],
    },
  },
  {
    path: '/verticals',
    name: 'Verticals',
    component: Entities,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Verticals',
      entity: 'vertical',
      fields: ['name'],
    },
  },
  {
    path: '/vertical/new',
    name: 'NewVertical',
    component: () => import(/* webpackChunkName: "new-vertical" */ '@/components/forms/VerticalForm'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'New Vertical',
    },
  },
  {
    path: '/vertical/edit/:id',
    name: 'EditVertical',
    component: () => import(/* webpackChunkName: "edit-vertical" */ '@/components/forms/VerticalForm'),
    props: true,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Edit Vertical',
    },
  },
  {
    path: '/topproducts',
    name: 'TopProducts',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Top Products',
      entity: 'topproduct',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
        {
          path: 'products',
          ref: 'product',
          select: 'title',
        },
      ],
      fields: ['_id', 'count', 'created'],
    },
  },
  {
    path: '/topproduct/new',
    name: 'NewTopProduct',
    component: () => import(/* webpackChunkName: "new-topproduct" */ '@/views/topproduct/Create'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Create Top Product',
    },
  },
  {
    path: '/topproduct/edit/:id',
    name: 'EditTopProduct',
    component: () => import(/* webpackChunkName: "edit-topproduct" */ '@/views/topproduct/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Update Top Product',
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Events',
      entity: 'event',
      // populate: [
      //   {
      //     path: 'campaign',
      //     select: 'name',
      //   },
      // ],
      fields: [
        'cls',
        'campaign',
        'createdOn',
        'uid',
        'contract',
        'cost',
        'xdid',
        'agent',
        'ip',
        'clickId',
        'lineItem',
        'connection',
        'isp',
        'aecity',
        'aecountry',
        'pcConversionTime',
        'pvConversionTime',
        'pcCreativeId',
        'pvCreativeId',
        'newcustomer',
        'orderid',
        'ordervalue',
        'currency',
        'products',
        'shipping',
        'crawler',
        'screenWidth',
        'screenHeight',
        'plugins',
        'creativeId',
        'creativeType',
        'events',
        'target',
        'postbackUrl',
        'category',
        'searchterm',
        'productid',
        '_id',
        'description',
        'location',
        'referer',
        'comment',
      ],
    },
  },
  {
    path: '/campaignlogs',
    name: 'Campaignlogs',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Campaignlogs',
      entity: 'campaignlog',
      // populate: [
      //   {
      //     path: 'campaign',
      //     select: 'name',
      //   },
      // ],
      fields: ['createdOn', 'message', 'location', 'referer', 'userAgent', 'userIp', 'user', 'login', 'campaign', 'level', 'category', 'version', 'entityId', 'entityType'],
    },
  },
  // {
  //   path: '/campaignlogsnew',
  //   name: 'campaignlogsnew',
  //   component: CampaignLogsTable,
  //   meta: {
  //     auth: [LoginRole.ADMIN],
  //     title: 'Campaignlogs',
  //     entity: 'campaignlog',
  //   },
  // },
  {
    path: '/campaignlogsnew',
    name: 'campaignlogsnew',
    component: CampaignLogsTable,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Campaignlogs',
      entity: 'campaignlog',
    },
  },
  {
    path: '/accesslogs',
    name: 'Accesslogs',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Accesslogs',
      entity: 'accesslog',
      // populate: [
      //   {
      //     path: 'campaign',
      //     select: 'name',
      //   },
      // ],
      fields: ['host', 'reason', 'createdOn', 'referer'],
    },
  },
  {
    path: '/advertisers',
    name: 'Advertisers',
    component: Entities,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Advertisers',
      entity: 'advertiser',
      fields: ['name', 'url', 'industry'],
      populate: role == 'agency' ? [] : [
        {
          path: 'logins',
          ref: 'reseller',
          select: 'name',
        },
      ],
    },
  },
  {
    path: '/advertiser/new',
    name: 'NewAdvertiser',
    component: () => import(/* webpackChunkName: "new-advertiser" */ '@/views/sysops/advertisers/Create'),
    meta: {
      auth: [LoginRole.WIZARD, LoginRole.CO_WORKER],
      title: 'New Advertiser',
    },
  },
  {
    path: '/advertiser/transaction',
    name: 'AdvertiserTransaction',
    component: () => import(/* webpackChunkName: "new-advertiser" */ '@/views/sysops/advertisers/Transaction'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Advertiser Transaction',
    },
  },
  {
    path: '/advertiser/edit/:id',
    name: 'EditAdvertiser',
    component: () => import(/* webpackChunkName: "edit-advertiser" */ '@/views/sysops/advertisers/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.WIZARD, LoginRole.ADVERTISER],
      title: 'Edit Advertiser',
    },
  },
  {
    path: '/advertiser/finances',
    name: 'FinanceArea',
    component: () => import(/* webpackChunkName: "finance-area" */ '@/components/pages/FinanceArea'),
    props: true,
    meta: {
      auth: [LoginRole.WIZARD, LoginRole.ACCOUNTING],
      title: 'Finance Area'
    },
  },
  {
    path: '/p/finances',
    name: 'FinanceArea',
    component: () => import(/* webpackChunkName: "finance-area" */ '@/components/pages/PublisherFinanceArea'),
    props: true,
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'Finance Area'
    },
  },
  {
    path: '/publishers',
    name: 'Publishers',
    component: Entities,
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'Publishers',
      entity: 'publisher',
      fields: ['name', 'url', 'active', 'created'],
    },
  },
  {
    path: '/publisher/new',
    name: 'NewPublisher',
    component: () => import(/* webpackChunkName: "new-publisher" */ '@/views/admin/publishers/Create'),
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'New Publisher',
    },
  },
  {
    path: '/publisher/edit/:id',
    name: 'EditPublisher',
    component: () => import(/* webpackChunkName: "edit-publisher" */ '@/views/admin/publishers/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'Edit Publisher',
    },
  },
  {
    path: '/assignments',
    name: 'Assignments',
    component: Entities,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Assignments',
      entity: 'assignment',
      fields: ['budget', 'createdBooking', 'name', 'bnr', 'campaignname', 'createdOn', 'start', 'end', 'link', 'zipcodes'],
    },
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: Bookings,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Bookings',
    }
  },
  {
    path: '/booking/create',
    name: 'NewAdvertiserWizard',
    component: () => import(/* webpackChunkName: "new-wizard" */ '@/views/advertiserwizard'),
    meta: {
      auth: [LoginRole.DEALER],
      title: 'New Booking',
    },
  },
  {
    path: '/booking/update/:id',
    name: 'NewAdvertiserWizardEdit',
    component: () => import(/* webpackChunkName: "new-wizard" */ '@/views/advertiserwizard'),
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Update Booking',
    },
    props: true
  },
  {
    path: '/campaign/new',
    name: 'NewCampaign',
    component: () => import(/* webpackChunkName: "new-camnpaign" */ '@/views/campaign/Create'),
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER],
      title: 'Create Campaign',
    },
  },
  {
    path: '/campaign/edit/:id',
    name: 'EditCampaign',
    component: () => import(/* webpackChunkName: "edit-campaign" */ '@/views/campaign/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER, LoginRole.ACCOUNTING],
      title: 'Update Campaign',
    },
  },
  {
    path: '/campaign/:id',
    name: 'CampaignStats',
    component: CampaignStats,
    props: true,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER, LoginRole.ACCOUNTING],
      title: 'Expert Campaign Analytics',
    },
  },
  {
    path: '/campaign/stats/:id',
    name: 'CampaignStatsNew',
    component: CampaignStatsNew,
    props: true,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER, LoginRole.ACCOUNTING],
      title: 'Instant Campaign Report',
    },
  },
  {
    path: '/p/campaign/stats/:id',
    name: 'CampaignStatsNewPublisher',
    component: () => import(/* webpackChunkName: "profile" */ '@/components/pages/CampaignStatsPublisher.vue'),
    props: true,
    meta: {
      auth: [LoginRole.PUBLISHER],
      title: 'Instant Campaign Report',
    },
  },
  {
    path: '/product/new/:catalog',
    name: 'NewProduct',
    component: () => import(/* webpackChunkName: "new-product" */ '@/views/product/Create'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Add Product to Catalog',
    },
  },
  {
    path: '/product/edit/:id',
    name: 'EditProduct',
    component: () => import(/* webpackChunkName: "edit-product" */ '@/views/product/Edit'),
    props: true,
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Update Product',
    },
  },
  {
    path: '/products',
    name: 'Products',
    component: Entities,
    meta: {
      auth: [LoginRole.VIEWER],
      title: 'Products',
      entity: 'product',
      populate: [],
      fields: ['id', 'title', 'segments', 'link', 'imageLink', 'price', 'salePrice', 'googleProductCategory', 'created', 'updated'],
    },
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Entities,
    meta: {
      auth: [LoginRole.CAMPAIGNMANAGER, LoginRole.ACCOUNTING],
      title: 'Campaigns',
      entity: 'campaign',
      populate: [
        {
          path: 'verticals',
          ref: 'vertical',
          select: 'name',
        },
        {
          path: "bookings",
          ref: "booking",
          select: "end,start"
        }
      ],
      fields: ['name', 'created'],
      lookup: [
        {
          collection: 'topproducts',
          ref: 'campaign',
          as: 'topp',
          count: true,
        },
        {
          collection: 'topreferers',
          ref: 'campaign',
          as: 'topr',
          count: true,
        }
      ],
    },
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Programs',
      entity: 'program',
    },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Entities,
    meta: {
      auth: [LoginRole.BILLING],
      title: 'Transactions',
      entity: 'transaction',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['amount', 'created'],
    },
  },
  {
    path: '/transaction/new',
    name: 'NewTransaction',
    component: () => import(/* webpackChunkName: "new-transaction" */ '@/views/management/transactions/Create'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'New Transaction',
    },
  },
  {
    path: '/graphics',
    name: 'Graphics',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Graphics',
      entity: 'graphic',
      populate: [
        {
          path: 'graphicfolders',
          ref: 'folder',
          select: 'name',
        },
      ],
      fields: ['downloadLink', 'name', 'segments', 'reviews', 'widthheight'],
    },
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "graphicgallery" */ '@/components/pages/GraphicGallery'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Graphics',
    },
  },
  {
    path: '/graphic/new',
    name: 'NewGraphic',
    component: () => import(/* webpackChunkName: "new-graphic" */ '@/views/ads/graphics/Create'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'New Graphic',
    },
  },
  {
    path: '/graphic/edit/:id',
    name: 'EditGraphic',
    component: () => import(/* webpackChunkName: "edit-graphics" */ '@/views/ads/graphics/Edit'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Edit Graphic',
    },
  },
  {
    path: '/audio/new',
    name: 'NewAudio',
    component: () => import(/* webpackChunkName: "new-audio" */ '@/views/ads/audios/Create'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'New Audio',
    },
  },
  {
    path: '/audio/edit/:id',
    name: 'EditAudio',
    component: () => import(/* webpackChunkName: "edit-audios" */ '@/views/ads/audios/Edit'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Edit Graphic',
    },
  },
  {
    path: '/audios',
    name: 'Audios',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Audios',
      entity: 'audio',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
        {
          path: 'logins',
          ref: 'login',
          select: 'email',
        },
      ],
      fields: ['downloadLink'],
    },
  },
  {
    path: '/video/new',
    name: 'NewVideo',
    component: () => import(/* webpackChunkName: "new-videos" */ '@/views/ads/videos/Create'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'New Video',
    },
  },
  {
    path: '/video/edit/:id',
    name: 'EditVideo',
    component: () => import(/* webpackChunkName: "edit-videos" */ '@/views/ads/videos/Edit'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Edit Graphic',
    },
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Videos',
      entity: 'video',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
        {
          path: 'logins',
          ref: 'login',
          select: 'email',
        },
      ],
      fields: ['downloadLink', 'reviews', 'width', 'height', 'length'],
    },
  },
  {
    path: '/vasts',
    name: 'Vasts',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Vasts',
      entity: 'vast',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name', '_id', 'created'],
    },
  },
  {
    path: '/vast/new',
    name: 'NewVast',
    component: () => import(/* webpackChunkName: "new-banner" */ '@/views/ads/vasts/Create'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'New Vast',
    },
  },
  {
    path: '/vast/edit/:id',
    name: 'EditVast',
    component: () => import(/* webpackChunkName: "edit-banner" */ '@/views/ads/vasts/Edit'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Edit Vast',
    },
  },
  {
    path: '/banners',
    name: 'Banners',
    component: Entities,
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Banners',
      entity: 'banner',
      populate: [
        {
          path: 'graphics',
          ref: 'graphic',
          select: 'approved',
        },
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['name', 'created', 'reviews', 'bannerType'],
    },
  },
  {
    path: '/banner/new',
    name: 'NewBanner',
    component: () => import(/* webpackChunkName: "new-banner" */ '@/views/ads/banners/Create'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'New Banner',
    },
  },
  {
    path: '/banner/edit/:id',
    name: 'EditBanner',
    component: () => import(/* webpackChunkName: "edit-banner" */ '@/views/ads/banners/Edit'),
    meta: {
      auth: [LoginRole.WIZARD],
      title: 'Edit Banner',
    },
  },
  {
    path: '/catalogs',
    name: 'Catalogs',
    component: Entities,
    meta: {
      auth: [LoginRole.VIEWER],
      title: 'Catalogs',
      entity: 'catalog',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'type', 'compression', 'lastImport'],
      lookup: [
        {
          collection: 'products',
          ref: 'catalog',
          as: 'count',
          count: true,
        },
      ],
    },
  },
  {
    path: '/catalog/new',
    name: 'NewCatalog',
    component: () => import(/* webpackChunkName: "new-catalog" */ '@/views/ads/catalogs/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'New Catalog',
    },
  },
  {
    path: '/catalog/edit/:id',
    name: 'EditCatalog',
    component: () => import(/* webpackChunkName: "edit-catalog" */ '@/views/ads/catalogs/Edit'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Catalog',
    },
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Entities,
    meta: {
      auth: [LoginRole.VIEWER],
      title: 'Contracts',
      entity: 'contract',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
        {
          path: 'publishers',
          ref: 'publisher',
          select: 'name',
        },
      ],
      fields: ['created', 'channel'],
    },
  },
  {
    path: '/contract/new',
    name: 'NewContract',
    component: () => import(/* webpackChunkName: "new-contract" */ '@/views/management/contracts/Create'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'New Contract',
    },
  },
  {
    path: '/contract/edit/:id',
    name: 'EditContract',
    component: () => import(/* webpackChunkName: "edit-contract" */ '@/views/management/contracts/Edit'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Edit Contract',
    },
  },
  {
    path: '/lineitems',
    name: 'LineItems',
    component: Entities,
    meta: {
      auth: [LoginRole.VIEWER],
      title: 'LineItems',
      entity: 'lineitem',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
        {
          path: 'contracts',
          ref: 'contract',
          select: 'channel',
        },
      ],
      fields: ['name', 'created', 'active', 'userpaused', 'autopaused'],
    },
  },
  {
    path: '/lineitem/new',
    name: 'NewLineItem',
    component: () => import(/* webpackChunkName: "new-contract" */ '@/views/ads/lineItems/Create'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Add Line Item',
    },
  },
  {
    path: '/lineitem/oldedit/:id',
    name: 'EditLineItem',
    component: () => import(/* webpackChunkName: "edit-contract" */ '@/views/ads/lineItems/Edit'),
    meta: {
      auth: [LoginRole.AGENCY],
      title: 'Edit LineItem',
    },
  },
  {
    path: '/lineitem/create',
    name: 'LineItemWizardCreate',
    component: () => import(/* webpackChunkName: "wizard-lineitem" */ '@/views/ads/advertiserlineitem'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Create LineItem',
    },
  },
  {
    path: '/lineitem/edit/:id',
    name: 'LineItemEdit',
    component: () => import(/* webpackChunkName: "wizard-lineitem" */ '@/views/ads/advertiserlineitem'),
    meta: {
      auth: [LoginRole.AGENCY],
      title: 'Update LineItem',
    },
  },
  {
    path: '/lineitem/update/:id',
    name: 'LineItemUpdate',
    component: () => import(/* webpackChunkName: "wizard-lineitem" */ '@/views/ads/advertiserlineitem'),
    meta: {
      auth: [LoginRole.AGENCY],
      title: 'Update LineItem',
    },
  },
  {
    path: '/hosts',
    name: 'Hosts',
    component: Entities,
    meta: {
      auth: [LoginRole.VIEWER],
      title: 'Hosts',
      entity: 'host',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['host', 'refin', 'adserver', 'active'],
    },
  },
  {
    path: '/host/new',
    name: 'NewHost',
    component: () => import(/* webpackChunkName: "new-host" */ '@/views/admin/hosts/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Add Host',
    },
  },
  {
    path: '/host/edit/:id',
    name: 'EditHost',
    component: () => import(/* webpackChunkName: "edit-host" */ '@/views/admin/hosts/Edit'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Host',
    },
  },
  {
    path: '/blogposts',
    name: 'Blogposts',
    component: Entities,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Blogposts',
      entity: 'blogpost',
      populate: [
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'created', 'updated'],
    },
  },
  {
    path: '/blogpost/new',
    name: 'NewBlogpost',
    component: () => import(/* webpackChunkName: "new-blogpost" */ '@/views/admin/blogpost/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Add Blogpost',
    },
  },
  {
    path: '/blogpost/edit/:id',
    name: 'EditBlogpost',
    component: () => import(/* webpackChunkName: "edit-blogpost" */ '@/views/admin/blogpost/Edit'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Blogpost',
    },
  },
  {
    path: '/domainlists',
    name: 'Domainlists',
    component: Entities,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Domainlists',
      entity: 'domainlist',
      populate: [
        {
          path: 'advertisers',
          ref: 'advertiser',
          select: 'name',
        },
      ],
      fields: ['name', 'domains' , 'created', 'updated'],
    },
  },
  {
    path: '/domainlist/new',
    name: 'NewDomainlist',
    component: () => import(/* webpackChunkName: "new-domainlist" */ '@/views/admin/domainlist/Create'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Add Domainlist',
    },
  },
  {
    path: '/domainlist/edit/:id',
    name: 'EditDomainlist',
    component: () => import(/* webpackChunkName: "edit-domainlist" */ '@/views/admin/domainlist/Edit'),
    meta: {
      auth: [LoginRole.ADVERTISER],
      title: 'Edit Domainlist',
    },
  },
  {
    path: '/crrs',
    name: 'CRR',
    component: Entities,
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'CRR',
      entity: 'crr',
      populate: [
        {
          path: 'campaigns',
          ref: 'campaign',
          select: 'name',
        },
      ],
      fields: ['totalCost', 'totalRevenue', 'crr', 'created'],
    },
  },
  {
    path: '/crr/new',
    name: 'NewCRR',
    component: () => import(/* webpackChunkName: "new-crr" */ '@/views/admin/crr/Create'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Add CRR',
    },
  },
  {
    path: '/crr/edit/:id',
    name: 'EditCRR',
    component: () => import(/* webpackChunkName: "edit-crr" */ '@/views/admin/crr/Edit'),
    meta: {
      auth: [LoginRole.ADMIN],
      title: 'Edit CRR',
    },
  },
  {
    path: '/queue',
    name: 'SyncQueueLegacy',
    component: () => import(/* webpackChunkName: "SyncQueue" */ '@/views/syncqueue/index'),
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Sync Queue'
    }
  },
  {
    path: '/syncqueue',
    name: 'SyncQueue',
    component: Entities,
    meta: {
      auth: [LoginRole.SYSOP],
      title: 'Sync Queue',
      entity: 'sync-queue',
      fields: ['status', 'sname' , 'channel', 'type', 'action', 'createdOn', 'lastRun', 'runCount', 'notified'],
    }
  },
  {
    path: '/search/:term',
    name: 'SearchResults',
    component: SearchResults,
    props: true,
    meta: {
      auth: [LoginRole.DEALER],
      title: 'Search Results',
      fields: ['name', 'created'],
    },
  },
  // {
  //   path: '/banner/template',
  //   name: 'CreateBannerTemplate',
  //   component: () => import(/* webpackChunkName: "create-template" */ '@/views/bannerEditor/Template'),
  //   props: true,
  //   meta: {
  //     auth: [LoginRole.SYSOP]
  //   }
  // },
  // {
  //   path: '/banner/create',
  //   name: 'CreateBanner',
  //   component: () => import(/* webpackChunkName: "create-banner" */ '@/views/bannerEditor/Create'),
  //   props: true,
  //   meta: {
  //     auth: [LoginRole.SYSOP]
  //   }
  // },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      title: 'FAQ',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/confirm/:token?',
    name: 'Confirm',
    component: Login,
    props: true,
    meta: {
      title: 'Confirm',
    },
  },
  {
    path: '/resetpassword/:token',
    name: 'RestPassword',
    component: Resetpassword,
    props: true,
    meta: {
      title: 'Reset password',
    },
  },
  {
    path: '/404',
    component: Error404,
    meta: {
      title: 'Page not found :-(',
    },
  },
  {
    path: '/403',
    component: Error403,
    meta: {
      title: 'Forbidden Resource',
    },
  },
  {
    path: '/_ah/warmup',
    component: Error404,
    meta: {
      title: 'Page not found :-(',
    },
  },
  {
    path: '/_ah/health',
    component: Error404,
    meta: {
      title: 'Page not found :-(',
    },
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      title: 'Page not found :-(',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.


  to.meta.prevRoute = from.path;
  // Clear notifications
  store.dispatch("setError", "");
  store.dispatch("setSuccess", "");

  //If loading is still true, due to failed xhr requests, reset this to false on router change.
  store.dispatch('setLoadingInactive');
  const { auth } = to.meta;
  if (auth) {
    const jwt = localStorage.getItem(process.env.VUE_APP_JWT);
    const active = localStorage.getItem(process.env.VUE_APP_ACTIVE);
    if (jwt && active) {
      if (
        new Date(active) <
        new Date(Date.now() - process.env.VUE_APP_EXPIRATION)
      ) {
        next({ name: `Login`, params: { unauthorized: true } });
      } else if(!canActivate(auth)) {
        next('/403');
      }
    } else {
      next({ name: `Login`, params: { unauthorized: true } });
    }
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title + ' | ' + pageTitleSuffix;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // Save previous route
  next();
});

export default router;
