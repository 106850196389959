import { Workbox } from "workbox-window";
let wb;
if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("externalactivated", () => {
    window.localStorage.clear();
    window.localStorage.setItem("updated", "Application updated");

    /* console.log(`SW: Event::externalactivated, update ready - reloading page`); */
    window.location.reload();
  });

  wb.register()
    .then((r) => {
      setInterval(() => {
        r.update().catch((/* e */) => {
          /* console.warn(`SW: could not execute update()`, e); */
        });
      }, 30000);
    })
    .catch((/* e */) => {
      /* console.log(`SW: could not execute register()`, e); */
    });
} else {
  wb = null;
}
export default wb;
