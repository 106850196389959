<template>
  <div class="col">
    <div class="row" v-if="error">
      <div class="col mt-2">
        <h1 class="alert alert-danger text-center">{{ error }}</h1>
      </div>
    </div>
    <div class="row" v-if="total == 0">
      <div class="col mt-2 text-center">
        <b-skeleton-table v-if="loading" :rows="10" :columns="5"></b-skeleton-table>
        <b-alert v-else :show="true" variant="warning">No results found</b-alert>
      </div>
    </div>
    <div class="row" v-if="!error && total > 0">
      <div class="col">
        <b-pagination
          class="my-2"
          :disabled="loading"
          align="center"
          :per-page="10"
          v-model="page"
          :total-rows="total"
        />
      </div>
    </div>
    <div class="row" v-if="!error && total > 0">
      <div class="col">
        <b-skeleton-table v-if="loading" :rows="10" :columns="4" />
        <div class="table-responsive" v-else>
          <b-table :items="items">
            <template #head(actions)="data">
              <div class="text-right">{{ data.label }}</div>
            </template>
            <template #cell(name)="data">
              <router-link :to="`/${data.value.type}/edit/${data.value.id}`">{{data.value.name}}</router-link>
            </template>
            <template #cell(booking)="data">
              <router-link :to="'/booking/update/' + data.value.id">{{data.value.name}}</router-link>
              <br/>
              <img class="mt-1 mx-1" v-for="channel of getChannelData(data.value.channels)" :key="channel.key" v-b-tooltip.hover :title="channel.name" style="width: 15px; height: 15px;" :src="channel.image"/>
            </template>
            <template #cell(preview)="data">
              <div class="text-center" v-if="data.value.type == 'image_ad'">
                <a :href="data.value.graphic" target="_blank">
                  <img class="preview" :src="data.value.graphic" :alt="data.value.graphic"/>
                </a>
              </div>
              <div class="text-center" v-else-if="data.value.type == 'video_ad'">
                <video class="preview" :src="data.value.video" controls></video>
                <br/>
                <span v-if="data.value.thumbnail"><a :href="data.value.thumbnail">Thumbnail</a></span>
                <span v-else>No Thumbnail</span>
              </div>
              <div v-else-if="data.value.type == 'html_ad'">
                <b-button variant="primary" @click="$bvModal.show(data.value.id + '-modal')">View AdCode</b-button>
                <ApprovalAdCodePreview :modalId="data.value.id + '-modal'" :adCode="data.value.adCode" :bannerId="data.value.bannerId" :name="data.value.name" />
              </div>
              <div v-else-if="data.value.type == 'search_ad'">
                <b-button variant="primary" @click="$bvModal.show(data.value.id + '-modal')">View SearchAd</b-button>
                <ApprovalSearchAdPreview :modalId="data.value.id + '-modal'" :headlines="data.value.headlines" :descriptions="data.value.descriptions" :name="data.value.name" />
              </div>
              <div v-else-if="data.value.type == 'lineitem'">
                <b-button variant="primary" @click="$bvModal.show(data.value.id + '-modal')">View Changes</b-button>
                <ApprovalLineItemChanges :modalId="data.value.id + '-modal'" :current="data.value.current" :changes="data.value.changes" />
              </div>
            </template>
            <template #cell(advertiser)="data">
              <router-link :to="`/advertiser/edit/${data.value.id}`" target="_blank">{{data.value.name}}</router-link>
            </template>
            <template #cell(channels)="data">
              <b-badge
                pill
                class="mx-1 p-1"
                style="cursor: pointer;"
                v-for="channel of channelsInReviews(data.value.reviews)"
                :key="channel.key"
                :variant="
                  data.value.reviews[channel.key].status === 'rejected'
                    ? 'danger'
                    : data.value.reviews[channel.key].status === 'approved'
                    ? 'success'
                    : 'secondary'
                "
                @click.prevent="approve(channel.key, data.value.target)"
              >
                <img
                  class="p-1 bg-white"
                  :src="channel.image"
                  v-b-tooltip.hover
                  :title="channel.name"
                />
              </b-badge>
              <ApprovalModal @reasoned="save" :reviews="data.value.reviews" :target="data.value.target" :modalId="`${data.value.target}-reasons`"/>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column ml-auto">
                  <b-button
                    @click="save(data.value.target)"
                    class="d-flex ml-auto"
                    variant="success"
                    :disabled="saving || saved.includes(data.value.target)"
                    >{{saved.includes(data.value.target) ? 'Saved' : 'Save Changes'}}</b-button
                  >
                  <small
                    v-if="data.value.error"
                    class="text-danger text-center mt-1"
                    >{{ data.value.error }}</small
                  >
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!error && total > 0">
      <div class="col">
        <b-pagination
          class="my-2"
          :disabled="loading"
          align="center"
          :per-page="10"
          v-model="page"
          :total-rows="total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from "lodash.clonedeep";
import ApprovalLineItemChanges from './ApprovalLineItemChanges';
import ApprovalSearchAdPreview from './ApprovalSearchAdPreview';
import ApprovalAdCodePreview from './ApprovalAdCodePreview';
import ApprovalModal from './ApprovalModal.vue';
import defaultChannels from "@/constants/channels.js";

export default {
  name: "ApprovalTable",
  components: {
    ApprovalLineItemChanges,
    ApprovalSearchAdPreview,
    ApprovalAdCodePreview,
    ApprovalModal
  },
  props: {
    total: {
      default: 10,
      type: Number,
    },
    type: {
      default: "banner",
      type: String
    },
    propItems: {
      default: () => [],
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    error: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      selected: {},
      errors: {},
      saved: [],
      page: 1,
      saving: false,
    };
  },
  mounted() {
    this.$forceUpdate();
  },
  computed: {
    items() {
      let returnValue = [];

      for (let originalItem of this.propItems) {
        let localItem = _cloneDeep(originalItem);
        let item = {
          name: {
            name: localItem.name,
            id: localItem._id,
            type: this.type
          },
          preview: {
            type: localItem.bannerType,
            id: localItem._id
          },
          advertiser: {
            name: localItem?.advertiser?.name || localItem?.bookingadvertiser?.name,
            id: localItem?.advertiser?._id || localItem?.bookingadvertiser?._id
          },
          booking: {
            name: localItem.booking.name,
            channels: localItem.booking.channels,
            id: localItem.booking._id
          },
          channels: {
            target: localItem._id,
            reviews: localItem.reviews ? localItem.reviews : {},
          },
          actions: {
            target: localItem._id,
            error: "",
          },
        };

        for (let channel in item.channels.reviews) { // mapping because of old data
          if (typeof item.channels.reviews[channel] == "boolean" ||
              item.channels.reviews[channel] == null ) {
                let status = item.channels.reviews[channel];

                if (status === true) status = 'approved';
                else if (status === false) status = 'rejected';
                else if (status === null) status = 'not_reviewed';

                item.channels.reviews[channel] = {
                  status: status
                };
              }
        }

        if (localItem.bannerType) {
          switch (localItem.bannerType) {
            case "html_ad":
              item.preview.adCode = localItem?.adCode;
              item.preview.bannerId = localItem?._id;
              item.preview.name = localItem?.name;
              break;
            case "image_ad":
              item.preview.graphic = localItem?.graphic?.downloadLink
              break;
            case "video_ad":
              item.preview.video = localItem?.video?.downloadLink;
              item.preview.thumbnail = localItem?.thumbnail?.downloadLink
              break;
            case "search_ad":
              item.preview.headlines = localItem?.headlines;
              item.preview.descriptions = localItem?.descriptions;
              item.preview.name = localItem?.name;
              break;
          }
        } else if (this.type == 'lineitem') {
          item.preview.type = this.type;
          item.preview.current = localItem;
          item.preview.changes = Object.assign({}, localItem.changes);
          delete item.preview.current.changes;
        }

        if (typeof this.selected[item.channels.target] === "object") {

          if (typeof item.channels !== "object") item.channels = {};

          for (let channel in this.selected[item.channels.target]) {
            item.channels.reviews[channel] =
              this.selected[item.channels.target][channel];
          }
        }

        if (typeof this.errors[item.actions.target] === "string") {
          item.actions.error = this.errors[item.actions.target];
        }

        returnValue.push(item);
      }

      return returnValue;
    },
    itemCount() {
      return this.items.length;
    }
  },
  watch: {
    page() {
      this.$emit('pageChanged', this.page ? this.page : 1);
    },
    loading() {},
    propItems() {
      this.$forceUpdate();
    },
  },
  methods: {
    getChannelData(channels){
      return defaultChannels.filter(c => channels.includes(c.key));
    },
    channelsInReviews(reviews){
      return defaultChannels.filter(c => Object.keys(reviews).includes(c.key));
    },
    approve(channel, target) {
      if (this.saved.includes(target)) return;
      let reviews =
        typeof this.selected === "object"
          ? Object.assign({}, this.selected)
          : {};

      let currentReview = this.items?.find((e) => e?.channels?.target == target)
        ?.channels?.reviews?.[channel];
      let originalReview = this.propItems?.find(
        (e) => e?._id == target
      )?.reviews?.[channel];

      if (originalReview?.status == 'approved') return;

      if (typeof reviews[target] === "undefined") reviews[target] = {};

      if (typeof reviews?.[target]?.[channel] == "undefined") {
          if (typeof reviews[target] === "undefined") {
            reviews = {};
            reviews[target] = {};
            reviews[target][channel] = {
              status: 'not_reviewed'
            };
          } else {
            reviews[target][channel] = {
              status: 'not_reviewed'
            };
          }
        } else {
          reviews[target][channel] = currentReview;
        }

      if (currentReview?.status !== "not_reviewed" && typeof currentReview?.status !== 'undefined') {
        reviews[target][channel] = currentReview;
      }

      reviews[target][channel].status =  reviews[target][channel]?.status == 'rejected' ||
                                          reviews[target][channel]?.status == 'not_reviewed' ? 'approved' : 'rejected';

      this.selected = reviews;
    },
    async save(target, reasons = undefined, comment = undefined) {
      this.saving = true;
      let reviews = this.items?.find((e) => e?.channels?.target == target)
        ?.channels?.reviews;
      let reviewsPayload = [];
      if (typeof reviews === "undefined") {
        this.saving = false;
        return;
      }
      for (let channel in reviews) {
        let review = reviews[channel];
        if (review.status === 'not_reviewed') {
          let errors =
            typeof this.errors === "object"
              ? Object.assign({}, this.errors)
              : {};
          errors[target] = `Channel ${channel} was not reviewed`;
          this.errors = errors;
          this.saving = false;
          return;
        }
        if (review.status === 'rejected') {
          if (reasons?.[channel]) {
            reviews[channel].reason = reasons[channel];
          }
        }
      }
      let errors =
        typeof this.errors === "object" ? Object.assign({}, this.errors) : {};
      errors[target] = ``;
      this.errors = errors;

      for (let channel of Object.keys(reviews)) {
        if (reviews[channel]?.status == 'approved') continue;
        if (reviews[channel]?.status == 'rejected' && typeof reviews[channel]?.reason == "string" && reviews[channel]?.reason?.length > 0) {
          continue;
        } else {
          this.$bvModal.show(`${target}-reasons`);
          this.saving = false;
          return;
        }
      }
      /* console.log(reviews) */
      for (let channel in reviews) {
        let review = reviews[channel];
        reviewsPayload.push({
          channel,
          reason: review.reason,
          status: review.status,
          comment
        })
      }

      try {
        /* console.log(reviewsPayload) */
        await this.$api.approval.review(this.type, target, reviewsPayload);
      } catch (e) {
        this.errors[target] = `${e}`
        this.saving = false;
        return;
      }
      this.saving = false;
      this.saved.push(target);

    },
  },
};
</script>

<style scoped>
.preview {
  width: unset !important;
  height: unset !important;
  max-width: 150px !important;
  max-height: 150px !important;
  border-radius: unset !important;
}
</style>