<template>
<div>
  <div class="table-responsive">
    <b-table ref="itemtable" id="itemtable" hover :items="tableData" :fields="dataTableFields()" @sort-changed="sortData" :busy.sync="busy" :no-local-sorting="true">
      <template #cell(name)="data">
        <span v-if="data.value">
          <span v-if="type == 'auth'">
            {{ data.value }} {{ data.item.surname }}
          </span>
          <span v-else>
            {{ data.value }}
            <i v-if="type == 'publisher/select'" v-b-tooltip.hover.right class="fa fa-info-circle ml-1 cursor-pointer" title="Show more details" @click="openWebsiteDetails(data.item)"></i>
          </span>
        </span>
        <span v-else>N/A</span>
      </template>

      <template #cell(segments)="data">
        <span v-for="(segment, index) in data.value" :key="`segment-${index}`">
          <b-badge v-if="segment._id" variant="info" :title="segment.identifier" class="mr-1" v-b-tooltip.hover>{{ segment.name }}</b-badge>
          <b-badge v-else variant="secondary" :title="segment" class="mr-1" v-b-tooltip.hover>{{ segment }}</b-badge>
        </span>
      </template>

      <template #cell(logo)="data">
        <img class="rounded-0" v-if="data.value.toString().length > 0 && data.value.substring(0,4) == 'http'" :src="data.value" style="height: 25px; width: auto;" />
      </template>

      <template #cell(widthheight)="data">
        <span v-if="data.item.width && data.item.width.toString().length > 0 && data.item.height && data.item.height.toString().length > 0">{{ data.item.width }} x {{ data.item.height }}</span>
      </template>

      <template #cell(link)="data">
        <a :href="data.value" target="_blank" style="cursor: pointer" ><i class="fa fa-external-link"></i> <span :title="data.value" v-b-tooltip.hober>{{ data.value.length > 25 ? data.value.substring(0,25) : data.value }}&hellip;</span></a>
      </template>

      <template #cell(imageLink)="data">
        <a :href="data.value" target="_blank" style="cursor: pointer" ><i class="fa fa-external-link"></i> <span :title="data.value" v-b-tooltip.hober>{{ data.value.length > 25 ? data.value.substring(0,25) : data.value }}&hellip;</span></a>
      </template>

      <template #cell(sname)="data">
        <span v-if="data.item.foreignObject">
          {{ data.item.foreignObject.name }}
        </span>
        <span v-else>N/A</span>
      </template>

      <template #cell(industry)="data">
        {{ getIndustryByID(data.value) }}
      </template>

      <template #cell(avatar)="data">
        <span class="profileimgtable"
          :style="'background-image: url(\''+(data.value || '/images/avatar.jpg')+'\');'"
        >&nbsp;</span>
      </template>

      <template #cell(url)="data">
        <span v-if="data.item.url">
          <a :href="data.value" target="_blank">{{ getHostname(data.value) }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
        </span>
        <span v-else> - </span>
      </template>

      <template #cell(status)="data">
        <i v-if="data.value == 'not run'" class="fa fa-2x fa-clock-o" />
        <i v-else-if="data.value == 'failed'" class="fa fa-2x fa-times text-danger" />
        <i v-else-if="data.value == 'partial' || data.value == 'successful' && data.item.completed === false" class="fa fa-2x fa-exclamation text-warning" />
        <i v-else-if="data.value == 'successful'" class="fa fa-2x fa-check text-success" />
      </template>

      <template #cell(pInvitationStatus)="data">
        <i v-if="data.item.invitationStatus == 'retracted'" v-b-tooltip.hover :title="$t('wizard.invitestatus.retracted')" class="fa fa-ban" />
        <i v-else-if="data.item.invitationStatus == 'rejected'" v-b-tooltip.hover :title="$t('wizard.invitestatus.rejected')" class="fa fa-times" />
        <span v-else-if="data.item.invitationStatus == 'invited'">
          <i v-if="!data.item.continously && new Date() > new Date(data.item.end)" v-b-tooltip.hover :title="$t('wizard.invitestatus.invited_outdated')" class="fa fa-check-circle-o" />
          <i v-else v-b-tooltip.hover :title="$t('wizard.invitestatus.invitation_received')" class="fa fa-envelope" />
        </span>
        <span v-else-if="data.item.invitationStatus == 'accepted'">
          <i v-if="!data.item.continously && new Date() > new Date(data.item.end)" v-b-tooltip.hover :title="$t('wizard.invitestatus.accepted_finished')" class="fa fa-check" />
          <i v-else-if="new Date(data.item.start) >= tomorrow" v-b-tooltip.hover :title="$t('wizard.invitestatus.accepted_upcoming')" class="fa fa-hourglass-half" />
          <i v-else v-b-tooltip.hover :title="$t('wizard.invitestatus.active')" class="fa fa-play" />
        </span>
        <span v-else>N/A</span>
      </template>

      <template #cell(invitationStatus)="data">
        <b-badge v-if="getCurrentStatus(data.item) == 'not_invited'" variant="inverse-info">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
        <b-badge v-else-if="getCurrentStatus(data.item) == 'to_invite'" variant="info">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
        <b-badge v-else-if="getCurrentStatus(data.item) == 'invited'" variant="primary">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
        <b-badge v-else-if="getCurrentStatus(data.item) == 'rejected'" variant="danger">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
        <b-badge v-else-if="getCurrentStatus(data.item) == 'accepted'" variant="success">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
        <b-badge v-else-if="getCurrentStatus(data.item) == 'to_retract'" variant="inverse-dark">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
        <b-badge v-else-if="getCurrentStatus(data.item) == 'retracted'" variant="dark">{{ $t('wizard.invitestatus.'+getCurrentStatus(data.item)) }}</b-badge>
      </template>

      <template #cell(type)="data">
        <span v-if="data.value == 'lineitem'">{{ $tc('general.lineitem', 1) }}</span>
        <span v-else-if="data.value == 'banner'">{{ $tc('general.creative', 1) }}</span>
        <span v-else-if="data.value == 'campaign'">{{ $tc('general.campaign', 1) }}</span>
        <span v-else>{{ data.value }}</span>
      </template>

      <template #cell(reviews)="data">
        <span v-for="(review, channel) in getReviews(data.value)" :key="channel"
          class="border-3 mr-1 d-inline-block"
          v-b-tooltip.hover
          :class="review.status == 'approved' ? 'border-success' : review.status == 'rejected' ? 'border-danger' : 'border-warning'"
          :title="review.status == 'approved' ? `${review.channelData.name} - ${$t('review.approved')}` : review.status == 'rejected' ? `${review.channelData.name} - ${$t('review.rejected')} - ${$t('review.reasonx', {value: review.reason})}` : `${review.channelData.name} - ${$t('review.processpending')}`">
          <img :src="review.channelData.image" class="p-1" :alt="channel" style="width: 25px; height: 25px;" />
        </span>
      </template>

      <template #cell(email)="data">
        {{ data.value }}
        <i v-if="data.item.doi && (new Date(data.item.doi).getUTC() != new Date(0).getUTC())"
              class="icon-check ml-2 text-success"
              :title="`Verified by ${data.item.doiIP} on ${new Date(data.item.doi).formatDatetime()}`"
            />
        <p class="mt-2 small text-info pt-2" v-if="data.item.parent.length">{{ data.item.parent[0].email}} 
          <router-link :to="`/login/edit/${data.item.parent[0]._id}`" style="cursor: pointer" ><i class="text-info fa fa-edit"></i></router-link>
        </p>
      </template>
      <template #cell(start)="data">
        <span v-if="data.item.start">
          {{ new Date(data.value).formatDate() }}
        </span>
        <span v-else>N/A</span>
      </template>

      <template #cell(end)="data">
        <span v-if="data.item.continously">
          {{ $t("labels.openend") }}
        </span>
        <span v-else-if="data.item.end">
          {{ new Date(data.value).formatDate() }}
        </span>
        <span v-else>N/A</span>
      </template>

      <template #cell(lastLogin)="data">
        <span v-if="data.value" :title="new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date(data.value).formatDate() }}
        </span>
        <span v-else>
          <em>never</em>
        </span>
      </template>

      <template #cell(lastImport)="data">
        <span v-if="!['automatic', 'manual'].includes(data.item.source) && data.value" :title="new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date(data.value).formatDate() }}
        </span>
        <i title="Parsing error" v-if="data.item.parsingError" class="ml-1 fa fa-exclamation-circle" v-b-tooltip.hover></i>
      </template>      


      <template #cell(created)="data">
        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
        </span>
      </template>

      <template #cell(createdon)="data">
        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
        </span>
      </template> 

      <template #cell(lastRun)="data">
        <span v-if="data.value.length && new Date(data.value).getTime() !== new Date(0).getTime()" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
        </span>
        <span v-else>-</span>
      </template> 

      <template #cell(updated)="data">
        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
        </span>
      </template>

      <template #cell(autopaused)="data">
        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
        </span>
      </template>

      <template #cell(userpaused)="data">
        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
        </span>
      </template>

      <template #cell(validFrom)="data">
        <span :title="new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date(data.value).formatDate() }}
        </span>
      </template>      

      <template #cell(validTo)="data">
        <span :title="new Date(data.value).formatTime()" v-b-tooltip.hover>
          {{ new Date(data.value).formatDate() }}
        </span>
      </template>

      <template #cell(bookings)="data">
        <span class="d-block" v-for="(booking, index) in data.item.booking" :key="'booking'+index"> {{ booking.name }} <router-link :to="`/booking/update/${booking._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(advertisers)="data">
        <span class="d-block" v-for="(advertiser, index) in data.item.advertiser" :key="'advertiser'+index"> {{ advertiser.name }} <router-link v-if="isSysOp || isAgency" :to="`/advertiser/edit/${advertiser._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(advertiser)="data">
        {{ data.value }}
        <br /><small v-if="data.item.industry">{{ getIndustryByID(data.item.industry) }}</small>
      </template>

      <template #cell(campaigns)="data">
        {{  data.value }}
        <div v-if="Array.isArray(data.item.campaign) && data.item.campaign.length > 0 && data.item.campaign[0]._id">
          
          <span class="d-block" v-for="(campaign, index) in data.item.campaign" :key="'campaign'+index"> {{ campaign.name }} <router-link v-if="isSysOp" :to="`/campaign/edit/${campaign._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
        </div>
      </template>

      <template #cell(campaign)="data">
        <span v-if="data.value.length" class="d-block">{{ data.value }} <router-link v-if="isSysOp" :to="`/lineitem/edit/${data.item._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(lineItem)="data">
        <span v-if="data.value.length" class="d-block">{{ data.value }} <router-link v-if="isSysOp" :to="`/lineitem/edit/${data.value}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(creativeId)="data">
        <span v-if="data.value.length" class="d-block">{{ data.value }} <router-link v-if="isSysOp" :to="`/banner/edit/${data.value}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(publishers)="data">
        <span class="d-block" v-for="(publisher, index) in data.item.publisher" :key="'publisher'+index"> {{ publisher.name }} <router-link :to="`/publisher/edit/${publisher._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(products)="data">
        <span class="d-block" v-for="(product, index) in data.item.product" :key="'product'+index"> {{ product.title }} <router-link :to="`/product/edit/${product._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
      </template>

      <template #cell(verticals)="data">
        <span class="d-block" v-for="(vertical, index) in data.item.vertical" :key="'vertical'+index"> {{ vertical.name }}</span>
      </template>

      <template #cell(length)="data">
        {{ parseInt(data.value) }} s
      </template>

      <template #cell(clicks)="data">
        {{ Math.round(data.item.budget / 2.5) }}
      </template>

      <template #cell(topp)="data">
        {{ getTopProducts(data.item) }}
      </template>

      <template #cell(topr)="data">
        {{ getTopReferer(data.item) }}
      </template>

      <template #cell(days)="data">
        {{ Math.round((new Date(data.item.end).getTime() - new Date(data.item.start).getTime()) / 1000 / 60 / 60 / 24) }}
      </template>

      <template #cell(count)="data">
        <span v-if="type == 'catalog'">
          {{ getCountIfAvailable(data) }}
        </span>
        <span v-else>
          {{ data.value }}
        </span>
      </template>

      <template #cell(action)="data">
        <b-badge class="text-uppercase" variant="info">{{ data.value }}</b-badge>
      </template>

      <template #cell(runCount)="data">
        <b-badge class="text-uppercase" :variant="`${data.value >= 10 ? 'danger' : data.value < 10 && data.value > 1 ? 'warning' : 'success'}`">{{ data.value }}</b-badge>
      </template>

      <template #cell(loginAllowed)="data">
        <b-badge v-if="updatingLoginAllowed == data.item.email" variant="info">updating...</b-badge>
        <b-badge v-else class="text-uppercase" style="cursor: pointer" @click="updateLoginAllowed(data.item)" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(notified)="data">
        <b-badge class="text-uppercase" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(active)="data">
        <b-badge class="text-uppercase" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(sticky)="data">
        <b-badge class="text-uppercase" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(internal)="data">
        <b-badge class="text-uppercase" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(promote)="data">
        <b-badge class="text-uppercase" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(createdBooking)="data">
        <b-badge class="text-uppercase" :variant="`${data.value === true ? 'success' : 'danger'}`">{{ `${data.value === true ? $t("general.yes") : $t("general.no")}` }}</b-badge>
      </template>

      <template #cell(bannerType)="data">
        <span style="text-transform: uppercase;">
          {{ data.value.substring(0,data.value.length-3) }}        
        </span>
      </template>

      <template #cell(runtime)="data">
        {{ getRuntime(data.item) }}
      </template>

      <template #cell(invoiceItemName)="data">
        <pre>{{ data.value }}</pre>
      </template>

      <template #cell(logins)="data">
        <div v-if="data.item.login && Array.isArray(data.item.login) && data.item.login.length">
          <span class="d-block" v-for="(login, index) in data.item.login" :key="'login'+index"> {{ login.name }} {{ login.surname }} <router-link v-if="isSysOp" :to="`/login/edit/${login._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
        </div>
        <span v-else-if="data.item.kickbackValue && data.item.kickbackValue > 0"> {{ Math.round((data.item.kickbackValue*100 + Number.EPSILON) * 100) / 100 }} %</span>
        <span v-else>-</span>
        <span v-if="data.item.reseller && data.item.reseller.length">
          <br>
          {{ data.item.reseller[0].name }} {{ data.item.reseller[0].surname }} <router-link :to="`/login/edit/${data.item.reseller[0]._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link>
        </span>
      </template>

      <template #cell(pixel)="data">
        <b-badge
          class="mr-1"
          variant="success"
          v-if="pixels && pixels[data.item._id] && pixels[data.item._id].pixel == true"
          title="The Converison Pixel was fired in the last 48 hours"
        >
          <i class="fa fa-check" ></i>
        </b-badge>
        <b-badge
          class="mr-1"
          variant="danger"
          v-else-if="pixels && pixels[data.item._id] && pixels[data.item._id].pixel == false"
        >
          <i class="fa fas fa-times-circle" title="The Converison Pixel was not fired in the last 48 hours"></i>
        </b-badge>
        <b-badge
          class="mr-1"
          variant="info"
          v-else-if="pixels && pixels[data.item._id] && pixels[data.item._id] == 'loading'"
        >
          <i class="fa fa-spinner fa-spin"></i>
        </b-badge>
        <b-badge
          class="mr-1"
          style="cursor: pointer"
          variant="dark"
          @click="loadPixel(data.item._id)"
          title="Check Conversion Pixel"
          v-else
        >
          <i class="fa fa-question"></i>
        </b-badge>
      </template>

      <template #cell(approved)="data">
        <b-img
          v-for="(channel, index) in data.value"
          v-b-tooltip.hover
          :key="index"
          :src="channel.image"
          :alt="channel.name"
          :title="`${channel.name}`"
          style="width: 20px; height: 20px"
          class="mr-1"
        ></b-img>
      </template>

      <template #cell(channels)="data">
        <b-img
          v-for="(channel, index) in data.value"
          v-b-tooltip.hover
          :key="index"
          :src="channel.image"
          :alt="channel.name"
          :title="`${channel.name}`"
          style="width: 20px; height: 20px"
          class="mr-1"
        ></b-img>
      </template>

      <template #cell(role)="data">

        <b-badge v-if="data.item.accountType == 'subaccount' && data.value == 'advertiser'" variant="info">{{ $t('role.client') }}</b-badge>
        <b-badge v-else-if="data.item.accountType == 'subaccount'" variant="info">{{ $t(`role.${data.value}`) }}</b-badge>
        <b-form-select
          v-else
          :disabled="updatingRole == data.item.email"
          class="form-control"
          style="width: auto;"
          :options="selectableRoles"
          :value="data.value"
          @change="updateLogin($event, data.item.email)"
        >
        </b-form-select>
        
      </template>

      <template #cell(channel)="data">
        <b-img
          v-b-tooltip.hover
          :src="data.value.image"
          :alt="data.value.name"
          :title="`${data.value.name}`"
          style="width: 20px; height: 20px"
          class="mr-1"
        ></b-img>
      </template>

      <template #cell(contracts)="data">
        <div v-if="data.item.contract && Array.isArray(data.item.contract) && data.item.contract.length">
          <span class="d-block" v-for="(contract, index) in data.item.contract" :key="index">
            <b-img
              v-b-tooltip.hover
              :src="contract.channel.image"
              :alt="contract.channel.name"
              :title="`${contract.channel.name}`"
              style="width: 20px; height: 20px"
              class="mr-1"
            ></b-img>
            {{ contract.channel.name }}
            <router-link :to="`/contract/edit/${contract._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link>
          </span>
        </div>
        <span v-else>-</span>
      </template>

      <template #cell(creatives)="data">
        <i v-if="Array.isArray(data.value) && data.value.length" class="text-success fa fa-check" v-b-tooltip.hover :title="`${data.value.length} Graphics available`"></i>
        <i v-else class="fa fa-times"></i>
      </template>

      <template #cell(graphics)="data">
        <div v-if="data.item.graphic && Array.isArray(data.item.graphic) && data.item.graphic.length">
          <span class="d-block" v-for="(graphic, index) in data.item.graphic" :key="'graphic'+index"> {{ graphic.name }} <router-link :to="`/graphic/edit/${graphic._id}`" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link></span>
        </div>
        <i v-else-if="data.value.length" class="text-success fa fa-check" v-b-tooltip.hover :title="`${data.value.length} Graphics available`"></i>
        <span v-else>-</span>
      </template>

      <template #cell(downloadLink)="data">
        <a v-if="isImage(data.value)" :href="data.value" target="_blank">
          <b-img
            :src="data.value"
            class="rounded-0"
            style="width: 50px; height: auto">
          </b-img>
        </a>
        <i class="fa fa-3x fa-folder text-primary" v-else-if="data.value == 'folder'"></i>
        <video
          v-else
          :src="data.value"
          style="width: 150px; height: auto"
          controls>
        </video>
      </template>

      <template #cell(domains)="data">
        <i v-if="Array.isArray(data.value) && data.value.length" class="text-success fa fa-check" v-b-tooltip.hover :title="`${data.value.length} Domains available`"></i>
        <span v-else-if="data.value"> {{ data.value.split(/\r\n|\r|\n/).length }}</span>
        <span v-else>-</span>
      </template>

      <template #cell(actions)="data">
        <i v-if="isSysOp && !['accesslog', 'publisher/select', 'publishercampaigns'].includes(type)" class="text-primary fa fa-copy" v-b-tooltip.hover :title="'Copy ID to clipboard: ' + data.item._id" style="cursor: pointer;" @click="copyIdToClipboard(type, data.item)"></i>
        <router-link v-if="type == 'campaign' && !isAccounting" class="ml-2" :to="`/${type}/${data.item._id}`" style="cursor: pointer"><i class="text-primary fa fa-bar-chart"></i></router-link>
        <a target="_blank" v-if="type == 'banner'" class="ml-2" :href="`https://${adserver}/banner?id=${data.item._id}&c=&desc=systempreview`" style="cursor: pointer"><i class="text-primary fa fa-eye"></i></a>
        <i v-if="type == 'sync-queue'" class="text-primary fa fa-eye ml-2" style="cursor: pointer;" @click="syncQueueShowTree(data.item._id)"></i>
        <router-link v-if="!['accesslog', 'publisher/select', 'publishercampaigns'].includes(type) && data.item.downloadLink !== 'folder'" class="ml-2" :to="getEditURL(data.item)" style="cursor: pointer" ><i class="text-primary fa fa-edit"></i></router-link>
        <i v-if="type === 'graphic' && data.item.downloadLink === 'folder'" class="fa ml-2 fa-edit text-primary cursor-pointer" @click.prevent="updateFolder(data.item)"></i>
        <i v-if="type === 'graphic' && data.item.downloadLink === 'folder'" class="fa ml-2 fa-folder-open-o text-primary cursor-pointer" @click.prevent="$emit('openFolder', data.item)"></i>
        <i v-if="type == 'sync-queue'" class="text-primary fa fa-refresh ml-2" title="Force Job" style="cursor: pointer;" @click="syncQueueForceJob(data.item._id)"></i>
        <i v-if="isSysOp && type == 'assignment' && runningAssignment != data.item._id" class="text-primary fa fa-refresh ml-2" style="cursor: pointer;" title="Push again" @click="redoAssignment(data.item._id)"></i>
        <i v-if="isSysOp && type == 'assignment' && runningAssignment == data.item._id" class="text-primary fa fa-spinner fa-spin ml-2"></i>
        <i v-if="type == 'advertiser' || type == 'publisher'" @click="goToFinance(type, data.item._id)" style="cursor: pointer" class='text-primary fa fa-credit-card ml-2'></i>
        <a v-if="type == 'wizard' && !data.item.live && !data.item.status" :href="`https://${data.item.adserver}/vldtcmp/${data.item._id}`" class="ml-2" target="_blank"><i class="text-primary fa fa-tasks"></i></a>
        <i v-if="type == 'wizard' && !data.item.live && data.item.status && synching != `${data.item._id}`" class="text-primary fa fa-check ml-2" :title="'Go live'" style="cursor: pointer;" @click="setWizardLive(data.item)"></i>
        <a v-if="type == 'catalog'" :href="`/products?catalog=${data.item._id}`" class="ml-2"><i class="text-primary fa fa-table"></i></a>
        <i v-if="type == 'campaign' && synching != `${data.item._id}` && !isAccounting" class="text-primary fa fa-arrow-circle-up ml-2" :title="'Generate top products'" style="cursor: pointer;" @click="generateTopProducts(data.item)"></i>
        <i v-if="type == 'campaign' && synching != `${data.item._id}` && !isAccounting" class="text-primary fa fa-arrow-circle-o-up ml-2" :title="'Generate top referer'" style="cursor: pointer;" @click="generateTopReferer(data.item)"></i>
        <i style="cursor: pointer;" v-if="type == 'coupon'" class="text-primary ml-2 fa fa-history" @click="openCouponHistory(data.item)"></i>
        <i style="cursor: pointer;" v-if="type == 'catalog' && data.item.source !== 'automatic' && data.item.source !== 'manual'" class="text-primary ml-2 fa fa-refresh" @click="importProductsFromSource(data.item)"></i>
        <i v-if="synching == `${data.item._id}`" class="text-primary fa fa-spinner fa-spin ml-2"></i>
        <i style="cursor: pointer;" v-if="data.item.importRunning" @click="cancelImport(data.item)" class="text-primary fa fa-spinner fa-spin ml-2"></i>
        <i style="cursor: pointer;" v-if="type == 'catalog' && synching != `${data.item._id}` && !data.item.importRunning" class="text-primary ml-2 fa fa-remove" @click="deleteProductsFromCatalog(data.item)"></i>
        <i class="text-primary fa fa-upload ml-2" v-if="type == 'bookingtemplate'" :title="'File Upload'" @click="importCSV(data.item._id)" style="cursor: pointer;"></i>
        <i class="text-primary fa fa-download ml-2" v-if="type == 'bookingtemplate'" :title="'Download CSV'" @click="downloadCSV(data.item)" style="cursor: pointer;"></i>
        <i class="text-primary fa fa-code ml-2" v-if="type == 'banner'" :title="'Show Banner Container Codes'" @click="showBannerCodes(data.item)" style="cursor: pointer;"></i>
        <i v-if="cancelCampaignSynch[data.item._id] && type == 'campaign' && data.item.status !== 'interrupted'" class="text-primary ml-2 fa fa-spinner fa-spin"></i>
        <i v-else-if="type == 'campaign' && data.item.status !== 'interrupted'" class="text-primary ml-2 fa fa-remove" title="Interrupt campaign" style="cursor: pointer" @click="cancelCampaignConfirmModal(data.item._id)"></i>
        <i v-if="isSysOp && !['accesslog', 'auth', 'sync-queue', 'publisher/select', 'publishercampaigns'].includes(type) && data.item.downloadLink !== 'folder'" class="text-primary ml-2 fa fa-trash" :title="`${archived ? 'Recover' : 'Delete'} ${type.charAt(0).toUpperCase() + type.slice(1)}`" style="cursor: pointer" @click="confirmation(data.item)"></i>
        <div v-if="type == 'publisher/select'">
          <i v-if="isSysOp" class="text-primary fa fa-copy mr-2" v-b-tooltip.hover :title="'Copy ID to clipboard: ' + data.item._id" style="cursor: pointer;" @click="copyIdToClipboard(type, data.item)"></i>

          <button v-if="getCurrentStatus(data.item) == 'not_invited'" @click="setNewStatus(data.item, 'to_invite')" class="btn btn-ty btn-success">{{ $t('wizard.action.invite') }}</button>
          <button v-if="getCurrentStatus(data.item) == 'to_invite'" @click="setNewStatus(data.item, 'reset')" class="btn btn-ty btn-danger">{{ $t('actions.cancel') }}</button>
          <button v-if="getCurrentStatus(data.item) == 'invited'" @click="setNewStatus(data.item, 'to_retract')" class="btn btn-ty btn-danger">{{ $t('wizard.action.retract') }}</button>
          <button v-if="getCurrentStatus(data.item) == 'to_retract'" @click="setNewStatus(data.item, 'reset')" class="btn btn-ty btn-success">{{ $t('wizard.action.invite') }}</button>
          <button v-if="getCurrentStatus(data.item) == 'retracted'" @click="setNewStatus(data.item, 'to_invite')" class="btn btn-ty btn-success">{{ $t('wizard.action.invite') }}</button>
          <button v-if="getCurrentStatus(data.item) == 'accepted'" @click="setNewStatus(data.item, 'to_retract')" class="btn btn-ty btn-danger">{{ $t('wizard.action.retract') }}</button>
        </div>
        <div v-if="type == 'publishercampaigns'">
          <i v-if="isSysOp" class="text-primary fa fa-copy mr-1" v-b-tooltip.hover :title="'Copy ID to clipboard: ' + data.item._id" style="cursor: pointer;" @click="copyIdToClipboard(type, data.item)"></i>
          <b-badge class="cursor-pointer mr-1" variant="primary" href="#" :title="$t('labels.viewdetails')" v-b-tooltip.hover @click="$emit('campaignDetails', data.item._id)"><i class="fa fa-eye"></i></b-badge>
          <b-badge v-if="!['invited', 'rejected'].includes(data.item.invitationStatus)" class="cursor-pointer mr-1" variant="info" :href="`/p/campaign/stats/${data.item.campaignId}`" title="Reporting" v-b-tooltip.hover><i class="fa fa-bar-chart"></i></b-badge>
          <div class="d-inline" v-if="invitationResponsePossible(data.item)">
            <b-badge :title="$t('labels.accept_invitation')" v-b-tooltip.hover class="cursor-pointer mr-1" variant="success" href="#" @click="$emit('accept', data.item._id)"><i class="fa fa-thumbs-o-up"></i></b-badge>
            <b-badge :title="$t('labels.decline_invitation')" v-b-tooltip.hover class="cursor-pointer" variant="danger" href="#" @click="$emit('decline', data.item._id)"><i class="fa fa-thumbs-o-down"></i></b-badge>
          </div>
        </div>
      </template>

    </b-table>
  </div>
  <input type="hidden" id="clipboard" value />

  <b-modal id="deleteModal" :hide-footer="true" size="sm" :title="`${archived ? 'Recover' : 'Delete'} ${type.charAt(0).toUpperCase() + type.slice(1)}`">
    <RecoverDeleteModal :type="type" @recdelEntity="recoverEnt()" />
  </b-modal>
  <b-modal id="file-upload-modal" title="File Upload" :hide-footer="true" >
    <FileUploadModal @uploadSuccess="showImportStats" :entity_id="selectedEntityId" />
  </b-modal>
  <b-modal id="import-stats-modal" size="lg" title="Imported Bookings" :hide-footer="true" >
    <ImportStatsModal :stats="stats" />
  </b-modal>
  <b-modal id="tracking-code-modal" size="lg" title="Container Code" :hide-footer="true" >
    <TrackingCodeModal :programid="selectedEntityId"/>
  </b-modal>
  <b-modal id="banner-container-codes-modal" size="lg" title="Banner Container Codes" :hide-footer="true" >
    <BannerContainerCodesModal :entity="bannerEntity"/>
  </b-modal>
  <b-modal id="folder-modal" :hide-footer="true" :title="$t('actions.updatex', {name: $tc('general.folder',1)})">
    <CreateFolderModal :parentFolder="currentFolder" :targetFolder="targetFolder" @updated="resetFolderModalValuesAndHide" />
  </b-modal>
  <CouponHistoryModal @closeCouponHistory="closeCouponHistory" :coupon="couponEntity" />
  <ConfirmModal
    :id="'confirmModalEntityTable'"
    :titleText="confirmModalTitleText"
    :bodyText="confirmModalBodyText"
    :finishButtonText="confirmModalFinishButtonText"
    :cancelButtonText="confirmModalCancelButtonText"
    :finishButtonVariant="confirmModalFinishButtonVariant"
    :cancelButtonVariant="confirmModalCancelButtonVariant"
    :finishEvent="confirmModalFinishEvent"
    @cancelCampaign="cancelCampaign"
  />
  <b-modal @hidden="syncQueueHideTree()" id="tree-modal" size="xl" ok-only>
    <template #modal-title>
      {{$t('syncqueue.tree.heading', {id: syncQueueTree._id})}} <img :title="syncQueueTree.channel.name" :src="syncQueueTree.channel.image" style="width: 20px; height: 20px;">
    </template>
    <div class="row">
      <div class="col">
        <b-breadcrumb>
          <b-breadcrumb-item v-for="treeEntry in syncQueueTreeStruct" :key="treeEntry._id" @click="syncQueueSetActive(treeEntry, false)">{{treeEntry.name}}</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <div class="row" v-if="syncQueueTree">
      <tree-entry :tree="syncQueueActive" @setActive="syncQueueSetActive"/>
    </div>
  </b-modal>

  <WebsiteDetailsModal @close="closeWebsiteDetails" :publisher="websiteDetailsPublisher" />

</div>
</template>

<script>
import FileUploadModal from "@/components/modals/FileUploadModal";
import ImportStatsModal from "@/components/modals/ImportStatsModal";
import TrackingCodeModal from "@/components/modals/TrackingCodeModal";
import RecoverDeleteModal from "@/components/modals/RecoverDeleteModal";
import CouponHistoryModal from '@/components/modals/CouponHistoryModal';
import WebsiteDetailsModal from '@/components/modals/WebsiteDetailsModal';
import BannerContainerCodesModal from "@/components/modals/BannerContainerCodesModal";
import CreateFolderModal from '@/components/modals/CreateFolderModal';
import { mapGetters } from "vuex";
import CHANNELS from "@/constants/channels.js";
import ConfirmModal from "@/components/modals/ConfirmModal";
import TreeEntry from '@/views/syncqueue/TreeEntry.vue';

const jwtDecode = require('jwt-decode');
export default {
  name: "EntityTable",
  components: {
    FileUploadModal,
    ImportStatsModal,
    TrackingCodeModal,
    RecoverDeleteModal,
    BannerContainerCodesModal,
    ConfirmModal,
    CouponHistoryModal,
    TreeEntry,
    WebsiteDetailsModal,
    CreateFolderModal
  },
  created(){
    if(this.type == 'campaign' && this.isViewer) this.$router.push('/403');

    if(this.type == 'auth' && this.isSysOp){
      this.selectableRoles.push({ text : 'systemoperator', value: 'systemoperator' })
    }
  },
  data() {

    let locale = process.env.VUE_APP_LANGUAGE_DEFAULT;
    if(this.$i18n.locale) locale = this.$i18n.locale;

    return {
      couponEntity: null,
      industries: require(`@/locales/industries.${locale}.js`),
      synching: null,
      channelSynching: null,
      selectedEntityId: null,
      deleteEntity: null,
      stats: null,
      activeChannels: CHANNELS.filter(x => x.active),
      lineItemChangeStatus: null,
      pixels: {},
      bannerEntity: null,
      publishing: null,
      targetFolder: null,

      confirmModalBodyText: '',
      confirmModalTitleText: '',
      confirmModalFinishButtonText: '',
      confirmModalCancelButtonText: this.$t("actions.cancel"),
      confirmModalFinishButtonVariant: '',
      confirmModalCancelButtonVariant: 'secondary',
      confirmModalFinishEvent: '',
      currentCampaign: null,
      cancelCampaignSynch: {},
      modalBooking: null,
      runningAssignment: 0,
      updatingRole: false,
      updatingLoginAllowed: false,

      selectableRoles: [
        { text : 'Publisher', value: 'publisher' },
        { text : 'Advertiser', value: 'advertiser' },
        //'analyst',
        { text : 'Administrator', value: 'administrator' },
        //'billing',
        { text : 'Registered', value: 'registered' },
        //'wizard',
        { text : 'Accounting', value: 'accounting' },
        { text : 'Dealer', value: 'dealer' },
        { text : 'Affiliatemanager', value: 'affiliatemanager' },
        { text : 'Agency', value: 'agency' },
      ],

      syncQueueRemoteTree: null,
      syncQueueTreeStruct: [],
      syncQueueActive: {},
      syncQueueForcedRun: false,
      syncQueueExecRun: false,

      websiteDetailsPublisher: null,
    };
  },
  props: ["entities", "type", "populates", "fields", "lookups", "busy", "invites", "currentFolder"],
  computed: {
    ...mapGetters([
      "archived"
    ]),
    tomorrow(){
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return tomorrow.getUTC();
    },
    adserver() {
      return process.env.VUE_APP_ADSERVER;
    },
    isAdmin: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "administrator" ? true : false,
    isSysOp: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "systemoperator" ? true : false,
    isAccounting: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "accounting" ? true : false,
    isAdvertiser: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "advertiser" ? true : false,
    isAgency: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "agency" ? true : false,
    isViewer: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "viewer" ? true : false,
    tableData() {
      const output = [];
      for(const entity of this.entities){
        if(entity?.approved?.length){
          const newChannels = [];
          for(const c of entity.approved){
            newChannels.push(this.getChannelDetails(c));
          }
          entity.approved = newChannels;
        }

        if(entity?.channels?.length){
          const newChannels = [];
          for(const c of entity.channels){
            newChannels.push(this.getChannelDetails(c));
          }
          entity.channels = newChannels;
        }

        if(entity?.channel?.length){
          entity.channel = this.getChannelDetails(entity.channel);
        }

        if(Array.isArray(entity?.contract) && entity.contract.length){
          for(const contract of entity.contract){
            contract.channel = this.getChannelDetails(contract.channel);
          }
        }

        output.push(entity);
      }

      return output;
    },
    syncQueueTree() {
      if (this.syncQueueRemoteTree === null) return null;
      return this.syncQueueResolveTree(this.syncQueueRemoteTree.tree);
    },
  },
  watch: {
    entities(){
      this.$refs.itemtable.refresh();
    }
  },
  methods: {
    updateFolder(target) {
      this.targetFolder = target;
      this.$bvModal.show('folder-modal');
      this.$emit('forceUpdate');
    },
    deleteFolder(target) {
      this.$api.graphic.deleteFolder(target);
      this.$emit('forceUpdate')
    },
    resetFolderModalValuesAndHide() {
      this.targetFolder = null; 
      this.$emit('folderUpdated');
    },
    async goToFinance(type, id){
      if(type == 'advertiser'){
        await this.$api.user.update({
          email: localStorage.getItem(process.env.VUE_APP_EMAIL),
          selectedAdvertiser: id,
        });
        this.$store.commit("SET_ADVERTISER", id);
        window.location="/advertiser/finances";
      }
      else{
        await this.$api.user.update({
          email: localStorage.getItem(process.env.VUE_APP_EMAIL),
          selectedPublisher: id,
        });
        this.$store.commit("SET_PUBLISHER", id);
        window.location="/p/finances";
      }
    },
    invitationResponsePossible(invitation){
      return invitation.invitationStatus === 'invited' && (invitation.continously || new Date(invitation.end) > new Date());
    },
    getCurrentStatus(item){
      if(item?.newInvitationStatus?.length)  return item.newInvitationStatus;
      else return item.invitationStatus;
    },
    setNewStatus(item, newStatus){
      if(newStatus == 'reset'){
        item.newInvitationStatus = '';
        this.$emit("updateInvitationStatus", item._id, item.invitationStatus);
      }
      else{
        item.newInvitationStatus = newStatus;
        this.$emit("updateInvitationStatus", item._id, newStatus);
      }
      // this.$refs.itemtable.refresh();
    },
    getHostname(url) {
      try {
        const urlObj = new URL(url);
        return urlObj.hostname;
      } catch (e) {
        return "N/A";
      }
    },
    getReviews(reviews){
      if(reviews){
        var output = {};
        for(const channel in reviews){
          output[channel] = reviews[channel];
          output[channel]['channelData'] = this.getChannelDetails(channel);
        }
        return output;
      }
      else{
        return {};
      }
    },
    async runJobs() {
      try {
        this.syncQueueExecRun = true;
        await this.$api.syncqueue.runJobs();
        this.$emit('reload');
      } catch {
        this.$emit('reload');
      }
    },
    async syncQueueForceJob(id){
      if(this.syncQueueForcedRun) return;
      try {
        this.syncQueueForcedRun = true;
        await this.$api.syncqueue.forceJob(id);
        this.syncQueueForcedRun = false;
        this.$emit('reload');
      } catch(e) {
        /* console.log(e) */
        this.syncQueueForcedRun = false;
        this.$emit('reload');
      }
      
    },
    syncQueueHideTree() {
      this.syncQueueRemoteTree = null;
      this.syncQueueTreeStruct = [];
    },
    async syncQueueShowTree(id) {
      this.syncQueueRemoteTree = await this.$api.syncqueue.getTree(id);
      this.syncQueueRemoteTree.tree.channel = this.getChannelDetails(this.syncQueueRemoteTree.tree.channel);
      this.syncQueueActive = this.syncQueueTree;
      this.syncQueueTreeStruct.push(this.syncQueueActive);
      this.$bvModal.show('tree-modal');
    },
    syncQueueSetActive(active, forward) {
      if (active._id == this.syncQueueActive._id) return;
      if (forward) this.syncQueueTreeStruct.push(active);
      else this.syncQueueTreeStruct.splice(this.syncQueueTreeStruct.indexOf(active)+1);
      this.syncQueueActive = active;
    },
    async updateLogin(event, email) {
      this.updatingRole = email;
      await this.$api.user.update({
        email: email,
        role: event
      });
      this.updatingRole = false;
    },
    async updateLoginAllowed(login){
      if(typeof login.loginAllowed !== 'undefined'){
        this.updatingLoginAllowed = login.email;
        login.loginAllowed = !login.loginAllowed;
        await this.$api.user.update(login);
        this.updatingLoginAllowed = false;
      }
    },
    getParentEmail(login){
      if(Array.isArray(login.parent) && login.parent.length){
        return parent[0].email;
      }
      return '';
    },
    getRuntime(item){
      let tmp;

      if(item?.booking?.[0]){
        tmp = Object.create(item.booking[0]);
      }
      else if(item?.start){
        tmp = Object.create(item);
      }
      else{
        return '';
      }

      tmp.start = new Date(tmp.start).formatDate();
      tmp.end = tmp.continously ? this.$t("labels.openend") : new Date(tmp.end).formatDate();

      return `${tmp.start} - ${tmp.end}`;
    },
    getTopProducts(campaign){
      return campaign?.topproducts?.[0]?.topp || 0;
    },
    getTopReferer(campaign){
      return campaign?.topreferers?.[0]?.topr || 0;
    },
    getCountIfAvailable(data){
      return data?.item?.products?.[0]?.count || 0;
    },
    isImage(url){
      return (url.split("?")[0].endsWith("jpg") ||
        url.split("?")[0].endsWith("gif") ||
        url.split("?")[0].endsWith("png") ||
        url.split("?")[0].endsWith("jpeg"));
    },
    sortData(sort){      
      this.$emit("sort", sort);
    },
    dataTableFields(){
      const tableFields = [];

      if(this.type == 'campaign' && !this.isAccounting)
        tableFields.push({ key: 'pixel', label: 'PIXEL', sortable: false });
      if(this.type == 'assignment'){
        tableFields.push({ key: 'clicks', label: this.$tc("general.click",2).toUpperCase(), sortable: false });
        tableFields.push({ key: 'days', label: this.$tc("general.day",2).toUpperCase(), sortable: false });
      }

      // defaults
      const fieldsRight = ['topp', 'topr'];
      const fieldsCenter = ['budgetMax', 'creatives', 'pInvitationStatus', 'invitationStatus', 'url', 'channel', 'domains', 'approved', 'bannerType', 'lastImport', 'count', 'active', 'promote', 'internal', 'sticky', 'createdBooking', 'avatar', 'role', 'loginAllowed', 'lastLogin', 'autopaused', 'userpaused', 'type', 'notified', 'lastRun', 'createdOn', 'runCount', 'action'];
      const sortAllowed = ['invitationStatus', 'bannerType', 'name', 'created', 'start', 'end', 'email', 'role', 'lastLogin', 'lastImport'];

      for(const field of this.fields){
        // console.log("add field: "+field);
        const textAlign = fieldsRight.includes(field) ? 'text-right' : fieldsCenter.includes(field) ? 'text-center' : 'text-left';
        const sortable = sortAllowed.includes(field);
        tableFields.push({ key: field, label: this.getLabel(field), sortable, class: textAlign });
      }

      // populates
      if(this.populates && !this.isAccounting){
        for(const populate of this.populates){
          // console.log("add populate field: "+populate.path);
          if(this.isAdvertiser && populate.path == 'advertisers')
            continue;
          if(this.type == 'auth' && populate.ref == 'parent')
            continue;

          const textAlign = fieldsRight.includes(populate.path) ? 'text-right' : fieldsCenter.includes(populate.path) ? 'text-center' : 'text-left';
          const sortable = sortAllowed.includes(populate.path);
          tableFields.push({ key: populate.path, label: this.getLabelPopulate(populate.path), sortable, class: textAlign });
        }
      }

      // lookups
      if(this.lookups){
        for(const lookup of this.lookups){
          // console.log("add lookup field: "+lookup.as);
          const textAlign = fieldsRight.includes(lookup.as) ? 'text-right' : fieldsCenter.includes(lookup.as) ? 'text-center' : 'text-left';
          const sortable = sortAllowed.includes(lookup.as);
          tableFields.push({ key: lookup.as, label: this.isAccounting ? "" : lookup.as.toUpperCase(), sortable, class: textAlign });
        }
      }

      // entityspecific
      if(this.isAccounting && this.type == 'campaign'){
        tableFields.push({ key: 'runtime', label: this.$t("general.runtime").toUpperCase(), sortable: false });
        tableFields.push({ key: 'invoiceItemName', label: 'ALTERNATIVE INVOICE NAME', sortable: true });
      }

      if(this.isAgency && this.type == 'bookingtemplate'){
        // remove advertisers
        tableFields.splice(tableFields.findIndex(e=>e.key == 'advertisers'), 1);
      }

      tableFields.push({ key: 'actions', label: this.$tc("general.action", 2).toUpperCase(), sortable: false, class: "text-right" });

      // remove when not specific account type
      if(!this.isSysOp && ['bannertemplate', 'segment', 'ci', 'catalog'].includes(this.type)){
        tableFields.splice(tableFields.findIndex(e => e.key == 'advertisers'), 1);
      }

      if(!this.isSysOp && ['catalog'].includes(this.type)){
        tableFields.splice(tableFields.findIndex(e => e.key == 'campaigns'), 1);
      }

      if (this.type === 'graphic') {
        tableFields.splice(tableFields.findIndex(e => e.key == 'graphicfolders'), 1)
      }

      return tableFields;
    },
    async redoAssignment(assignmentId){
      if (this.runningAssignment) return; // do not publish multiple times
      this.runningAssignment = assignmentId;

      try{
        const response = await this.$api.assignment.push(assignmentId);
        if(response._id)  this.makeToast('Booking created', `Booking created with name: ${response.name} (ID: ${response._id})`, 'success');
        else              this.makeToast('Create booking failed', `No booking was returned`, 'danger');
      }
      catch(e){
        this.makeToast('Create booking failed', `${e?.response?.data?.message ? e?.response?.data?.message : 'Unkown Error. If the error continuous, please contact us.'}`, 'danger');
      }

      this.runningAssignment = 0;
    },

    openWebsiteDetails(entity) {
      this.websiteDetailsPublisher = entity;
    },
    closeWebsiteDetails() {
      this.websiteDetailsPublisher = null;
    },

    openCouponHistory(entity) {
      this.couponEntity = entity;
    },
    closeCouponHistory() {
      this.couponEntity = null;
    },
    makeToast(title, text, variant) {
      this.$bvToast.toast(text, {
        title,
        variant,
        noAutoHide: true,
      })
    },
    async cancelCampaign(){
      if(!this.currentCampaign) return;
      this.$set(this.cancelCampaignSynch, this.currentCampaign, true);
      await this.$api.campaign.cancel(this.currentCampaign);
      this.makeToast(this.$t("toasts.canceled-campaign-title"), this.$t("toasts.canceled-campaign"), 'success');
      this.$delete(this.cancelCampaignSynch, this.currentCampaign);
      this.$parent.changePage();
    },
    async cancelCampaignConfirmModal(campaign){
      this.currentCampaign = campaign;
      this.confirmModalTitleText = this.$t("actions.cancel-campaign");
      this.confirmModalBodyText = this.$t("messages.cancel-campaign");
      this.confirmModalFinishButtonText = this.$t("actions.cancel-campaign");
      this.confirmModalFinishButtonVariant = 'danger';
      this.confirmModalFinishEvent = 'cancelCampaign';
      this.$bvModal.show('confirmModalEntityTable');
    },
    getIndustryByID(id){
      const found = this.industries.find(element => element.value == id);
      if(found) return found.text;
      else      return id;
    },
    getLabelPopulate(path){
      if(this.type == 'advertiser' && path == 'logins') return this.$t('labels.kickback');
      return path.toUpperCase();
    },
    getLabel(field){
      switch(field){
        case 'sname':
        case 'name':          return this.$t("general.name").toUpperCase();
        case 'advertiser':    return this.$tc("general.advertiser", 1).toUpperCase();
        case 'channels':      return this.$tc("general.channel", 2).toUpperCase();
        case 'start':         return this.$t("general.startdate").toUpperCase();
        case 'end':           return this.$t("general.enddate").toUpperCase();
        case 'role':          return this.$t("general.role").toUpperCase();
        case 'loginAllowed':  return this.$t("forms.loginallowed").toUpperCase();
        case 'lastLogin':     return this.$t("forms.lastlogin").toUpperCase();
        case 'status':        return '';
        case 'invitationStatus': return this.$t("general.status").toUpperCase();
        case 'pInvitationStatus': return this.$t("general.status").toUpperCase();
        case 'channel':       return this.$tc('general.channel', 1).toUpperCase();
        case 'type':          return this.$t('syncqueue.table.type').toUpperCase();
        case 'lastRun':       return this.$t('syncqueue.table.last-run').toUpperCase();
        case 'createdOn':     return this.$t('syncqueue.table.created-on').toUpperCase();
        case 'runCount':      return this.$t('syncqueue.table.run-count').toUpperCase();
        case 'notified':      return this.$t('syncqueue.table.notified').toUpperCase();
        case 'action':        return this.$tc('general.action', 1).toUpperCase();
        case 'segments':      return this.$tc('general.segment', 2).toUpperCase();
        case 'folder':        return this.$tc('general.folder', 1).toUpperCase();
        case 'widthheight':   return `${this.$t('general.width', 1).toUpperCase()} x ${this.$t('general.height', 1).toUpperCase()}`;
        case 'width':         return `${this.$t('general.width', 1).toUpperCase()}`;
        case 'height':        return `${this.$t('general.height', 1).toUpperCase()}`;
        case 'budgetMax':     return this.$tc('general.maxbudget', 1).toUpperCase();
        case 'downloadLink':  return '';
      }
      return field.toUpperCase();
    },
    getEditURL(entity){
      if(this.isAdvertiser){
        switch (this.type){
          case "campaign":
            if(entity?.booking?._id || entity?.booking?.find?.(e=>e._id)){
              const bookingId = entity.booking._id ? entity.booking._id : entity.booking.find(e=>e._id)._id;
              return `/booking/update/${bookingId}`;
            }
            return `/${this.type}/edit/${entity._id}`;
          case "bookingtemplate":
            return `/${this.type}/update/${entity._id}`;
          default:
            return `/${this.type}/edit/${entity._id}`;
        }
      } else {
        switch (this.type){
          case "bookingtemplate":
          case "lineitem":
            return `/${this.type}/update/${entity._id}`;
          case "auth":
            return `/login/edit/${entity._id}`;
          case "sync-queue":
            if(entity.type == 'lineitem') return `/lineitem/update/${entity.foreignId}`;
            return `/${entity.type}/edit/${entity.foreignId}`;
          default:
            return `/${this.type}/edit/${entity._id}`;
        }
      }
    },
    showBannerCodes(entity){
      this.bannerEntity = entity;
      this.$bvModal.show('banner-container-codes-modal');
    },
    async recoverEnt(){
      this.$store.dispatch("setLoadingActive");
        try {
          if(this.archived){
            let entity = await this.$api[this.type].get(this.deleteEntity['_id'] ? this.deleteEntity['_id'] : this.deleteEntity);
            entity.archived = new Date(0);
            await this.$api[this.type].update(this.deleteEntity);
          } else {
            await this.$api[this.type].delete(this.deleteEntity['_id'] ? this.deleteEntity['_id'] : this.deleteEntity);
          }
          this.$parent.changePage();
          // this.$emit('reloadEntities');
          this.$store.dispatch("setLoadingInactive");
          this.$store.dispatch("setError", "");
          this.$store.dispatch("setSuccess", `${this.type.charAt(0).toUpperCase() + this.type.slice(1)} ${this.archived ? 'recovered' : 'deleted'}.`);
        } catch (err) {
          /* console.log(err) */
          this.$store.dispatch("setError", err);
          this.$store.dispatch("setLoadingInactive");
        }
    },
    async loadPixel(id){
      var s = this.pixels;
      this.pixels = null;
      s[id] = "loading";
      this.pixels = s;
      s[id] = await this.$api.campaign.getPixel(id);
      this.pixels = null;
      this.pixels = s;
    },
    async downloadCSV(entity) {
        let csv = 'Name,Status,Budget,Start,End,Created\r\n';
        let bookings = (await this.$api.booking.getByTemplate(entity._id.toString(), { enableLoading: false })).items;
        for(var i = 0; i < bookings.length; i++){
          csv += `${bookings[i].name},`;
          csv += `${bookings[i].status},`;
          csv += `${bookings[i].budget},`;
          csv += `${bookings[i].start},`;
          csv += `${bookings[i].end},`;
          csv += `${bookings[i].created},`;
          csv += "\r\n";
        }

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = `${entity.name} (${entity._id}).csv`;
        anchor.click();
    },
    showTrackingCode(advertiserID){
      this.selectedEntityId = advertiserID;
      this.$bvModal.show('tracking-code-modal');
    },
    getChannelDetails(channelKey){
      for(let ch = 0; ch < CHANNELS.length; ch++){
        if(channelKey == CHANNELS[ch].key){
          return CHANNELS[ch];
        }
      }
      return [];
    },
    syncQueueResolveTree(entity) {
      if (entity.type == "banner") {
          let banner = this.syncQueueRemoteTree.banners.filter((b) => b._id == entity.foreignId);
          entity.name = banner?.length == 1 ? banner[0].name : "Banner";
      } else if (entity.type == "lineitem") {
          let lineitem = this.syncQueueRemoteTree.lineitems.filter((l) => l._id == entity.foreignId);
          entity.name = lineitem?.length == 1 ? lineitem[0].name : "LineItem";
      } else if (entity.type == "campaign") {
          let campaign = this.syncQueueRemoteTree.campaigns.filter((c) => c._id == entity.foreignId);
          entity.name = campaign?.length == 1 ? campaign[0].name : "Campaign";
      }

      if (!entity?.next?.length) return entity;
      let nextArr = [];
      for (let next of entity.next) {
        nextArr.push(this.syncQueueResolveTree(next?.tree ? next.tree : next));
      }
      entity.next = nextArr;
      return entity;
    },
    importCSV(entityID) {
      this.selectedEntityId = entityID;
      this.$bvModal.show('file-upload-modal');
    },
    showImportStats(stats){
      this.stats = stats;
      this.$bvModal.show('import-stats-modal');
      this.$bvModal.hide("file-upload-modal");
    },
    confirmation(entity) {
      this.deleteEntity = entity;
      this.$bvModal.show("deleteModal");
    },
    async importProductsFromSource(entity) {
      this.$api.catalog.importProducts(entity);
      for(var e in this.entities) {
        if(this.entities[e]._id.toString() == entity._id.toString()) this.entities[e]['importRunning'] = true;
      }
    },
    async deleteProductsFromCatalog(entity) {
      this.synching = `${entity._id}`;
      await this.$api.catalog.deleteProducts(entity);
      for(var e in this.entities) {
        if(this.entities[e]._id.toString() == this.synching && this.entities[e].products.length) delete this.entities[e].products[0];
      }
      this.synching = null;
    },
    async setWizardLive(entity) {
      this.synching = `${entity._id}`;
      await this.$api.wizard.setWizardLive(entity);
      for(var e in this.entities) {
        if(this.entities[e]._id.toString() == this.synching) this.entities[e].live = true;
      }
      this.synching = null;
    },
    copyIdToClipboard(type, entity) {
      let str = "";
      switch(type) {
        case "splitter":
          str = `https://${process.env.VUE_APP_ADSERVER}/s/${entity._id}/${entity.lineItem ? entity.lineItem : "unknown"}/1/SUBID`;
          break;
        case "graphic":
          str = `<img src='${entity.downloadLink}'>`;
          break;
        default:
          str = entity._id;
          break;
      }
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    async cancelImport(entity) {
      this.$api.catalog.cancelImport(entity);
      for(var e in this.entities) {
        if(this.entities[e]._id.toString() == entity._id.toString()) this.entities[e]['importRunning'] = false;
      }
    },
    async generateTopProducts(entity) {
      this.synching = `${entity._id}`;
      const newTops = await this.$api.campaign.generateTopProducts(entity);
      for(var e in this.entities) {
        if(this.entities[e]._id.toString() == entity._id.toString()) this.entities[e]['topproducts'] = [{top: newTops}];
      }
      this.synching = null;
    },
    async generateTopReferer(entity) {
      this.synching = `${entity._id}`;
      const newTops = await this.$api.campaign.generateTopReferer(entity);
      for(var e in this.entities) {
        if(this.entities[e]._id.toString() == entity._id.toString()) this.entities[e]['topreferer'] = [{top: newTops}];
      }
      this.synching = null;
    },
  },
};
</script>

<style scoped>
span.border-3{
  border-width: 3px;
  border-style: solid;
}
.profileimgtable {
  border-radius: 50%;
  display: inline-block;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
}
</style>