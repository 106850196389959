<template>
    <b-modal id="website-details-modal" :title="localPublisher.name" ok-only @hidden="$emit('close')">
      <div class="row" v-if="loaded && !hasdata">
        <div class="col-12">
          <div class="alert alert-info">{{ $t("messages.nowebsitedetails") }}</div>
        </div>
      </div>
      <div class="row" v-if="loaded && hasdata">
        <div class="col-12">
          <div class="alert alert-info"><small>{{ $t("messages.website_details") }}</small></div>
        </div>
        <div class="col-6">
          <p><strong>{{ $tc("general.category",1) }} / {{ $tc("general.industry",1) }}</strong><br>{{ category }}</p>
        </div>
        <div class="col-6">
          <p><strong>{{ $t("forms.visitors_origin") }}</strong><br>
            <span v-if="visitorsOrigin.length && visitorsOrigin.length > 0">
            <b-badge class="mr-1" v-for="origin in visitorsOrigin" :key="origin" variant="primary">{{ origin }}</b-badge>
            </span>
            <span v-else>N/A</span></p>
        </div>
        <div class="col-6">
          <p><strong>{{ $t("forms.monthly_visitors") }}</strong><br>
            <span v-if="monthlyVisitors == 0">N/A</span>
            <span v-else :title="monthlyVisitors" v-b-tooltip.hover>
              <NumberAbbreviated :value="monthlyVisitors" />
            </span>
          </p>
        </div>
        <div class="col-6">
          <p><strong>{{ $t("forms.monthly_uniques") }}</strong><br>
            <span v-if="monthlyUniques == 0">N/A</span>
            <span v-else :title="monthlyUniques" v-b-tooltip.hover>
              <NumberAbbreviated :value="monthlyUniques" />
            </span></p>
        </div>
        <div class="col-6">
          <p><strong>{{ $t("forms.monthly_pageviews") }}</strong><br>
            <span v-if="monthlyPageviews == 0">N/A</span>
            <span v-else :title="monthlyPageviews" v-b-tooltip.hover>
              <NumberAbbreviated :value="monthlyPageviews" />
            </span></p>
        </div>
        <div class="col-6">
          <p><strong>{{ $t("forms.avg_visit_duration") }}</strong><br>{{ avgVisitDuration }}</p>
        </div>
      </div>
  </b-modal>
</template>

<script>
import NumberAbbreviated from "@/components/NumberAbbreviated";
export default {
  name: "WebsiteDetailsModal",
  props: ["publisher"],
  data() {
    let locale = process.env.VUE_APP_LANGUAGE_DEFAULT;
    if(this.$i18n.locale) locale = this.$i18n.locale;

    return {
      localPublisher: {},
      loaded: false,
      industries: require(`@/locales/industries.${locale}.js`),
    }
  },
  created() {
    
  },
  methods: {

  },
  computed: {
    hasdata(){
      return this.category == "N/A" && this.visitorsOrigin.length == 0 && this.monthlyVisitors == 0 && this.monthlyUniques == 0 && this.monthlyPageviews == 0 && this.avgVisitDuration == "N/A" ? false : true;
    },
    category() {
      if (!this.localPublisher?.websitedata?.industry) return "N/A";
      return this.industries.find(i => i.value == this.localPublisher.websitedata.industry)?.text || 'N/A';
    },
    visitorsOrigin(){
      if (!this.localPublisher?.websitedata?.visitors_origin) return [];
      return this.localPublisher.websitedata.visitors_origin;
    },
    monthlyVisitors(){
      if (!this.localPublisher?.websitedata?.monthly_visitors) return 0;
      return parseInt(this.localPublisher.websitedata.monthly_visitors);
    },
    monthlyUniques(){
      if (!this.localPublisher?.websitedata?.monthly_uniques) return 0;
      return parseInt(this.localPublisher.websitedata.monthly_uniques);
    },
    monthlyPageviews(){
      if (!this.localPublisher?.websitedata?.monthly_pageviews) return 0;
      return parseInt(this.localPublisher.websitedata.monthly_pageviews);
    },
    avgVisitDuration() {
      if (!this.localPublisher?.websitedata?.avg_visit_duration) return "N/A";
      return this.localPublisher.websitedata.avg_visit_duration;
    },
  },
  watch: {
    publisher: function () {
      if (!this.publisher) return; 
      this.localPublisher = this.publisher;
      this.$bvModal.show("website-details-modal");
      this.loaded = true;
    }
  },
  components: {
    NumberAbbreviated
  }
};
</script>
