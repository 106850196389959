<template>
  <div>
    <div class="row">
      <div class="col-md-12 mt-3">
        <h3>
          <span class="handwriting mr-2">{{ $tc("general.click",2) }}</span>
          {{ campaignName }}
        </h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <EntityFilterConversions class="pt-3" :filter="filter" @filter="addFilter" :lineitems="lineItems" :creatives="creatives" />
        <div class="row bg-light pt-3">
          <div class="col-12 form-group">
            <label for="search" class="mb-1">{{ $t("general.searchfor")}}</label>
            <input
              id="search"
              class="form-control"
              v-model="filter.q"
              @keydown.enter="changePage(1)"
              :disabled="isBusy"
            />
          </div>
        </div>
        <div class="row bg-light py-3">
          <div class="col text-right">
            <b-button variant="success" :disabled="isBusy" @click="changePage(1)">{{ $t("general.search") }}</b-button>
          </div>
        </div>
        <b-row v-if="!isBusy && total == 0" class="mt-3">
          <b-alert class="col-md-12 text-center" show variant="info">
            {{ $t("messages.nostats") }}
          </b-alert>
        </b-row>
        <b-row>
          <b-col>
            <div
              class="table-responsive mb-0"
              :class="!clickTableData.length ? 'invisible' : 'visible'"
            >
              <b-table
                hover
                :items="clickTableData"
                :fields="clickTableFields"
                sortBy="timestamp"
                sort-desc
                :busy.sync="isBusy"
              >
                <template #cell(timestamp)="data">
                  <span class="d-block">
                    <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                    {{ getFullDateFromDate(data.value) }}
                  </span>
                  <span class="d-block mt-1">
                    <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                    {{ getFullTimeFromDate(data.value) }}
                  </span>
                </template>
                <template #cell(timestampView)="data">
                  <span class="d-block">
                    <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                    {{ getFullDateFromDate(data.value) }}
                  </span>
                  <span class="d-block mt-1">
                    <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                    {{ getFullTimeFromDate(data.value) }}
                  </span>
                </template>
                <template #cell(approvalStatus)="data">
                  <i v-b-tooltip.hover :title="`${$t('review.approved-on')} ${new Date(data.item.approvalDate).formatDatetime()}`" v-if="data.value == 'approved'" class="fa fa-check-circle-o text-success"></i>
                  <i v-b-tooltip.hover :title="`${$t('review.rejected-on')}  ${new Date(data.item.approvalDate).formatDatetime()}`" v-else-if="data.value == 'rejected'" class="fa fa-times-circle-o text-danger"></i>
                  <i v-b-tooltip.hover :title="`${$t('review.pending')}`" v-else class="fa fa-clock-o text-warning"></i>
                </template>
                <template #cell(cost)="data">
                  <span class="d-block text-right"
                    ><NumberAbbreviated
                      :value="data.value"
                      :currency="true"
                      :locale="data.item.locale"
                  /></span>
                </template>
                <template #cell(bannerObj)="data">
                  <AdListPreview :creative="data.value" :onlyButton="true" />
                </template>
                <template #cell(actions)="data">
                  <div>
                    <span v-if="data.value.readonly">{{data.value.status}}</span>
                    <b-dropdown :disabled="approvedIds.includes(data.value._id)" split id="action-dropdown" text="Approve" variant="success" @click="approveClick(data.value._id)">
                      <b-dropdown-item :disabled="approvedIds.includes(data.value._id)" @click="rejectClick(data.value._id)">Reject</b-dropdown-item>
                      <b-dropdown-item :disabled="approvedIds.includes(data.value._id)" @click="showApprovalModal(data.value._id)" >Update</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <Pagination class="my-4" :itemCount="total" :currentPage="filter.page" :perPage="filter.size" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
              <p v-if="clickTableData.length" class="text-right mb-2">
                <b-button
                  @click="dlStats()"
                  class="btn btn-outline-primary btn-sm"
                  variant="secondary"
                  ><i class="fa fa-floppy-o m-0" aria-hidden="true"></i
                ></b-button>
              </p>
            </div>
          </b-col>
        </b-row>
        <b-modal
          id="click-approval-modal"
          title="Click Approval"
          size="md"
          @ok="saveApprovalModal(true)"
          @cancel="saveApprovalModal(false)"
          :ok-title="$t('conversion-modal.approve')"
          :cancel-title="$t('conversion-modal.reject')"
          cancel-variant="danger"
          ok-variant="success"
        >
          <b-row>
            <b-col>
              <h5 class="text-center mb-4">
                {{ $t('click-modal.title') }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="table">
                <tr>
                  <td>{{ $t('click-modal.website') }}</td>
                  <td class="text-right">{{ clickTableEntry.location }}</td>
                </tr>
                <tr>
                  <td>{{ $t('click-modal.time') }}</td>
                  <td class="text-right">
                    <span v-if="clickTableEntry.timestamp">
                      {{ (clickTableEntry.timestamp.getTime()-clickTableEntry.timestampView.getTime())/1000 }}
                    </span>
                    &nbsp;{{ $t('click-modal.seconds') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('click-modal.provision') }}</td>
                  <td class="text-right">
                    <b-input-group class="w-75 ml-auto">
                      <b-input
                        type="number"
                        step="1"
                        v-model="clickTableEntry.provision"
                      />
                      <b-input-group-append>
                        <b-input-group-text>€</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import AdListPreview from '../../views/ads/adlist/components/AdListPreview.vue'
import NumberAbbreviated from "../NumberAbbreviated.vue";

// import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import EntityFilterConversions from '@/components/admin/assets/EntityFilterConversions.vue';
import Pagination from "@/components/pages/assets/Pagination.vue";
// import { mapGetters } from 'vuex';

export default {
  components: {
    NumberAbbreviated,
    EntityFilterConversions,
    Pagination,
    AdListPreview
  },
  name: "ClickPage",
  data() {
    return {
      clickTableEntry: {},
      pagination: 10,
      total: 0,
      clickTableData: [],
      clickTableFields: [
        {
          key: 'timestamp',
          label: this.$t('general.date'),
          class: "text-left",
          sortable: true,
        },
        {
          key: 'approvalStatus',
          label: 'Status',
          class: 'text-center',
          sortable: true
        },
        {
          key: 'bannerObj',
          label: this.$tc('general.creative', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'location',
          label: this.$tc('general.website', 1),
          class: "text-left",
          sortable: true
        },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'timestampView',
          label: `${this.$tc('general.view',1)}`,
          class: "text-left",
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
          sortable: false,
        }
      ],
      rawLineItems: [],
      lineItems: [],
      lineItem: null,
      rawCreatives: [],
      creatives: [],
      creative: null,
      search: "",
      isBusy: false,
      approvedIds: [],
      campaignName: "",
      filter: {
        size: 10,
        page: 1,
        sort: 'createdOn',
        direction: 'DESC',
        q: null,
        campaign: null,
        lineitemIds: null,
        creativeIds: null,
        start_date: null,
        end_date: null,
      },
    };
  },
  props: ["id"],
  created() {
    // this.dateRange.startDate.setHours(0, 0, 0, 0);
    // this.dateRange.startDate.setDate(this.dateRange.startDate.getDate() - 1);
    // this.dateRange.endDate.setHours(23, 59, 59, 999);
    this.filter.campaign = this.id;
    this.filter.start_date = new Date(this.dateRange.startDate);
    this.filter.end_date = new Date(this.dateRange.endDate);

    this.fetchCreatives();
    this.fetchLineItems();
  },
  mounted() {
    this.fetchStats();
  },
  computed: {
    dateRange: {
      get() {
          return this.$store.state.store.dateRange;
      }
    },
  },
  watch: {
  },
  methods: {
    changePage(page){
      this.filter.page = page;
      this.fetchStats();
    },
    changeQuantity(quantity){
      this.filter.size = quantity;
      this.changePage(1);
    },
    addFilter(field, value){
      /* console.log(field, value) */
      this.filter[field] = value;
    },
    showApprovalModal(clickId) {
      this.$set(
        this,
        "clickTableEntry",
        this.clickTableData.find((t) => t.clickId == clickId) ||
          {}
      );
      this.clickTableEntry.provision = this.clickTableEntry.cost;
      this.clickTableEntry.approved = false;
      this.$bvModal.show("click-approval-modal");
    },
    approveClick(clickId) {
      this.$set(
        this,
        "clickTableEntry",
        this.clickTableData.find((t) => t.clickId == clickId) ||
          {}
      );
      this.clickTableEntry.provision = +this.clickTableEntry.provision;
      this.clickTableEntry.approved = true;
      this.$api.campaign.saveClickChange(
        this.id,
        this.clickTableEntry
      );
      this.approvedIds.push(this.clickTableEntry.clickId);
    },
    rejectClick(clickId) {
      this.$set(
        this,
        "clickTableEntry",
        this.clickTableData.find((t) => t.clickId == clickId) ||
          {}
      );
      this.clickTableEntry.provision = 0;
      this.clickTableEntry.approved = false;
      this.$api.campaign.saveClickChange(
        this.id,
        this.clickTableEntry
      );
      this.approvedIds.push(this.clickTableEntry.clickId);
    },
    saveApprovalModal(approved) {
      this.clickTableEntry.approved = approved;
      if(approved === false){
        this.clickTableEntry.provision = 0;
      }
      else{
        this.clickTableEntry.provision = +this.clickTableEntry.provision;
      }
      this.$api.campaign.saveClickChange(
        this.id,
        this.clickTableEntry
      );
      this.approvedIds.push(this.clickTableEntry.clickId);
    },
    fetchCreatives() {
      this.$api.banner.getAll({
        campaign: [ this.id ]
      }).then((response) => {
        this.creatives = response.items.map((e) => {
          return {
            _id: e._id,
            name: e.name,
          }
        })
      });
    },
    fetchLineItems() {
      this.$api.lineitem.getAll().then((response) => {
        this.lineItems = response.items.filter(li => li.campaign[0]._id == this.id).map((e) => {
          return {
            _id: e._id,
            name: e.name
          }
        })
      });
    },
    close() {
      this.updateStartAndEndHours();
      // this.fetchStats();
    },
    dlStats() {
      const filter = Object.assign({}, this.filter);
      filter.size = 500;
      filter.page = 1;
      this.$api.campaign
        .getClicks(
          filter,
          {
            enableLoading: false,
          }
        )
        .then((response) => {
          if (response) {
            const clickTableData = response.items.map((clickEntity) => {
              clickEntity.timestamp = new Date(clickEntity.timestamp);
              clickEntity.timestampView = clickEntity.timestampView
                ? new Date(clickEntity.timestampView)
                : "";
                clickEntity.cost = clickEntity.cost ? +clickEntity.cost : 0;
              delete clickEntity.currency;

              const banner = this.creatives.find(x => x._id.toString() == clickEntity.creative.toString());
              clickEntity.bannerObj = banner ? banner : { _id: clickEntity.creative, name: "" };

              clickEntity.actions = { _id: clickEntity.clickId };
              clickEntity.approvalDate = clickEntity?.approvalDate ? new Date(clickEntity.approvalDate) : null;

              if (clickEntity.approvalStatus == 'approved' || clickEntity.approvalStatus == 'rejected') {
                this.approvedIds.push(clickEntity.clickId);
              }
              return clickEntity;
            });
            const columns = Object.values(this.clickTableFields.map((c) => c.key).filter((e) => e !== 'actions'));
            let csvStr = `${columns.join(',')}\r\n`;

            for (let tableData of clickTableData) {
              const tableColumns = [];
              for (let column of columns) {
                let tmp = tableData[column];
                if (tmp instanceof Date) tmp = tmp.toISOString();
                else if (column === 'approvalStatus' && !tmp) tmp = 'not_reviewed';
                else if (column === 'bannerObj')  tmp = tmp.name;
                tableColumns.push(tmp);
              }
              csvStr += `"${tableColumns.join('","')}"\r\n`;
            }

            const blob = new Blob([csvStr], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const linkElem = document.createElement('a');
            linkElem.href = url;
            linkElem.download = 'clicks.csv';
            linkElem.click();
          }
        });
    },
    fetchStats() {
      this.isBusy = true;
      this.$api.campaign
        .getClicks(
          this.filter,
          {
            enableLoading: false,
          }
        )
        .then((response) => {
          if (response) {
            this.campaignName = response.campaignName;
            this.total = response.count;
            this.clickTableData = response.items.map((clickEntity) => {
              clickEntity.timestamp = new Date(clickEntity.timestamp);
              clickEntity.timestampView = clickEntity.timestampView
                ? new Date(clickEntity.timestampView)
                : "";
                clickEntity.cost = clickEntity.cost ? +clickEntity.cost : 0;
              delete clickEntity.currency;

              const banner = this.creatives.find(x => x._id.toString() == clickEntity.creative.toString());
              clickEntity.bannerObj = banner ? banner : { _id: clickEntity.creative, name: "" };

              clickEntity.actions = { _id: clickEntity.clickId };
              clickEntity.approvalDate = clickEntity?.approvalDate ? new Date(clickEntity.approvalDate) : null;

              if (clickEntity.approvalStatus == 'approved' || clickEntity.approvalStatus == 'rejected') {
                this.approvedIds.push(clickEntity.clickId);
              }
              return clickEntity;
            });
            this.isBusy = false;
          }
        });
    },
    getLocaleFromCurrency(currency) {
      switch (currency) {
        case "EUR":
          return "de";
        case "USD":
          return "en";
        default:
          return "en";
      }
    },
    getFullDateFromDate(date) {
      // in format dd/mm/yyyy
      const d = new Date(date);
      return d.toString() === 'Invalid Date' ? '-' : d.formatDate();
      // return `${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`;
    },
    getFullTimeFromDate(date) {
      // in format hh:mm:ss (24h)
      const d = new Date(date);
      return d.toString() === 'Invalid Date' ? '-' : d.formatTime();
      // return `${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}:${('0' + d.getSeconds()).slice(-2)}`;
    },
  },
};
</script>

<style scoped>
.daterange-chooser {
  /* border: none; */
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>
