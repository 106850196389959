<template>
  <footer class="footer">
    <div class="w-100 clearfix">
      <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
        Copyright © {{new Date().getFullYear()}}
        <a :href="companyUrl" target="_blank">{{companyName}}</a>. All rights reserved. | <a href="/privacy">{{ $t('forms.privacypolicy') }}</a> | <a href="/faq">{{ $t('faq.title') }}</a>
      </span>
      <span v-if="isSysOp" class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
        <i class="icon-clock text-success"></i> Application time (GMT) {{appTime}}
      </span>
    </div>
    <link v-if="branding" rel="stylesheet" :href="`/branding/${branding}/style.css`">
  </footer>
</template>

<script>
const jwtDecode = require("jwt-decode");
export default {
    name: "PageFooter",
    data() {
      return {
        appTime: "..."
      }
    },
    computed: {
      companyName: () => process.env.VUE_APP_COMPANY_NAME,
      companyUrl: () => process.env.VUE_APP_COMPANY_URL,
      branding: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT))?.branding ?? false,
      isSysOp: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT))?.role == "systemoperator" ? true : false,
    },
    created() {
      setInterval(() => {
        this.appTime = new Date().formatDatetime()
      }, 1000);
    }
}
</script>
