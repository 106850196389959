<template>
    <div class="row bg-light pt-3">
        <div class="col-4 form-group">
            <label for="role" class="mb-1">{{ $t('general.role')}}</label>
            <v-select
                id="role"
                v-model="role"
                label="text"
                class="form-control"
                :filterable="false"
                :reduce="(type) => type.value"
                :options="roles"
                @input="reportInput('role')"
            >
            </v-select>
        </div>
        <div class="col-4 form-group">
            <label for="loginAllowed" class="mb-1">{{ $t('forms.loginallowed')}}</label>
            <v-select
                id="role"
                v-model="loginAllowed"
                label="name"
                class="form-control"
                :filterable="false"
                :reduce="(type) => type.value"
                :options="yesno"
                @input="reportInput('loginAllowed')"
            >
            </v-select>
        </div>
    </div>
</template>
  
<script>
  import _debounce from "lodash.debounce";

  export default {
    name: "EntityFilterLogins",
    props: {
    },
    created(){

    },
    data() {
      return {
        yesno: [
            { name : this.$t('general.yes'), value: 'true' },
            { name : this.$t('general.no'), value: 'false' },
        ],
        roles: [
            //'publisher',
            { text : 'advertiser', value: 'advertiser' },
            //'analyst',
            { text : 'administrator', value: 'administrator' },
            //'billing',
            { text : 'registered', value: 'registered' },
            //'wizard',
            { text : 'accounting', value: 'accounting' },
            { text : 'dealer', value: 'dealer' },
            { text : 'affiliatemanager', value: 'affiliatemanager' },
            { text : 'agency', value: 'agency' },
        ],
        role: null,
        loginAllowed: null,

      };
    },
    methods: {
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
        onSearchCampaigns(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _debounce((loading, search, vm) => {
        vm.$api.campaign
            .search(search, { enableLoading: false })
                .then((campaigns) => {
                vm.allCampaigns = campaigns.items;
                loading(false);
            });
        }, 350),
    },
  };
</script>
