const industries = [
  { value: 	100	, text: 'Administration' },
  { value: 	200	, text: 'Air Transport / Aerospace / Airlines' },
  { value: 	300	, text: 'Alcoholic Beverages' },
  { value: 	400	, text: 'Arts, Culture & Entertainment' },
  { value: 	500	, text: 'Automotive' },
  { value: 	600	, text: 'Banking' },
  { value: 	700	, text: 'Bars & Restaurants, Catering' },
  { value: 	800	, text: 'Beauty, cosmetics and hairdressing industry' },
  { value: 	900	, text: 'Biotechnology & Chemical Engineering' },
  { value: 	1000	, text: 'Business Services' },
  { value: 	1100	, text: 'Casinos / Gambling' },
  { value: 	1200	, text: 'Clubs / Associations' },
  { value: 	1300	, text: 'Computer Software' },
  { value: 	1400	, text: 'Construction, building industry & architecture' },
  { value: 	1500	, text: 'Consulting' },
  { value: 	1600	, text: 'Craft industry' },
  { value: 	1700	, text: 'Cruise Ships & Lines' },
  { value: 	1800	, text: 'Dentists' },
  { value: 	1900	, text: 'Doctors & Other Health Professionals' },
  { value: 	2000	, text: 'Education / Colleges, Universities & Schools' },
  { value: 	2100	, text: 'Electronics' },
  { value: 	2200	, text: 'Energy supply & energy industry' },
  { value: 	2300	, text: 'Environment' },
  { value: 	2400	, text: 'Farming, Forestry & Fishing' },
  { value: 	2500	, text: 'Finance & Venture Capital' },
  { value: 	2600	, text: 'Food & Beverage' },
  { value: 	2700	, text: 'Food Stores' },
  { value: 	2800	, text: 'Funeral Services' },
  { value: 	2900	, text: 'Garbage Collection / Waste Management' },
  { value: 	3000	, text: 'Health / Social Care' },
  { value: 	3100	, text: 'Hotels, Motels, Tourism & Lodguing' },
  { value: 	3200	, text: 'Human Resources & Recruitment' },
  { value: 	3300	, text: 'Industry' },
  { value: 	3400	, text: 'Insurance' },
  { value: 	3500	, text: 'Internet / Information Technology' },
  { value: 	3600	, text: 'Law' },
  { value: 	3700	, text: 'Market research' },
  { value: 	3800	, text: 'Marketing / Advertising / PR' },
  { value: 	3900	, text: 'Mechanical engineering' },
  { value: 	4000	, text: 'Media' },
  { value: 	4100	, text: 'Medical Technology' },
  { value: 	4200	, text: 'Medicine / Pharmaceuticals' },
  { value: 	4300	, text: 'Mining & Mining Industry' },
  { value: 	4400	, text: 'Other industries' },
  { value: 	4500	, text: 'Pharmaceutical industry' },
  { value: 	4600	, text: 'Printing / Packaging' },
  { value: 	4700	, text: 'Public Administration' },
  { value: 	4800	, text: 'Real estate, property and housing' },
  { value: 	4900	, text: 'Research / Development' },
  { value: 	5000	, text: 'Retail Sales' },
  { value: 	5100	, text: 'Seminars / Exhibition' },
  { value: 	5200	, text: 'Shipbuilding and marine technology' },
  { value: 	5300	, text: 'Sports' },
  { value: 	5400	, text: 'Tax consultancy / auditing' },
  { value: 	5500	, text: 'Telecommunications, Phone companies' },
  { value: 	5600	, text: 'Textile and clothing manufacturing' },
  { value: 	5700	, text: 'Tourism' },
  { value: 	5800	, text: 'Toy industry' },
  { value: 	5900	, text: 'Transport / Traffic / Logistics' },
  { value: 	6000	, text: 'Travel' },
  { value: 	6100	, text: 'Unions' },
]

module.exports = [ { value: null, text: "Please select an industry", disabled: true} ].concat(industries.sort((a, b) => a.text.localeCompare(b.text, "de-DE")));
