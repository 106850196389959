<template>
  <span :class="{ h4: !small }">
    {{ tree.name }}
    <b-badge variant="inverse-dark">{{ tree._id }}</b-badge>
    <span class="badge ml-1 font-weight-bold badge-primary" :title="'The type of this job is ' + tree.type">
      {{
        tree.type == "lineitem" ? $tc('general.lineitem', 1) :
        tree.type == "campaign" ? $tc('general.campaign', 1) :
        tree.type
      }}
    </span>
    <span
      class="badge font-weight-bold ml-1"
      :title="shownStatusTitle"
      :class="{
        'badge-success': shownStatus == $t('syncqueue.status.successful'),
        'badge-warning': shownStatus == $t('syncqueue.status.partial'),
        'badge-danger': shownStatus == $t('syncqueue.status.failed'),
        'badge-dark': shownStatus == $t('syncqueue.status.not-run'),
      }"
      >{{ shownStatus }}</span
    >
    <span
      class="badge ml-1"
      :title="$t('syncqueue.title.run-count', { count: tree.runCount })"
      :class="{
        'badge-danger': tree.runCount >= 10,
        'badge-warning': tree.runCount < 10 && tree.runCount > 1,
        'badge-success': tree.runCount <= 1,
      }"
      >{{tree.runCount}}</span
    >
    <span class="badge ml-1" :title="$tc('syncqueue.title.notified', tree.notified ? 1 : 0)" :class="{ 'badge-success': tree.notified, 'badge-danger': !tree.notified }">
      <i class="fa" style="height: 10px;" :class="{ 'fa-check': tree.notified, 'fa-times': !tree.notified }"></i>
    </span>
  </span>
</template>

<script>
export default {
  name: "Status",
  props: ["small", "tree"],
  methods: {
    makeUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1);
    }
  },
  computed: {
    shownStatusTitle() {
      if (this.tree.status == "successful") {
        if (this.tree.completed) return this.$tc('syncqueue.title.status', 1);
        else return this.$tc('syncqueue.title.status', 0);
      } else if (this.tree.status == "failed") return this.$tc('syncqueue.title.status', 2);
      else return this.$tc('syncqueue.title.status', 3);
    },
    shownStatus() {
      if (this.tree.status == "successful") {
        if (this.tree.completed) return this.$t('syncqueue.status.successful');
        else return this.$t('syncqueue.status.partial');
      } else if (this.tree.status == "failed") return this.$t('syncqueue.status.failed');
      else return this.$t('syncqueue.status.not-run');
    },
  },
};
</script>

<style>
</style>