const channels = [
  {
    key: "platform161",
    name: "Platform 161",
    shortName: "P161",
    image: "/images/channels/platform161.png",
    color: "#d4d729",
    id: "p161",
    programmaticFee: 0.5,
    endpoints: {
      markers: "/p161",
      reach: null,
    },
    minBudget: 0.1,
    maxDomains: 2000,
    locationneeded: false,
    targetUrlNeeded: true,
    active: true,
    dailyBudget: true,
    category: "Banner Ads",
    adtagsAllowed: true,
    targeting: {
      gender: false,
      age: false,
      income: true,
      audience: true,
      domainlist: true,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "google",
    name: "Display & Video 360",
    shortName: "DV360",
    image: "/images/channels/google.png",
    color: "#5bb974",
    id: "dv360",
    programmaticFee: 0.5,
    endpoints: {
      markers: "/dv360",
      reach: null,
    },
    minBudget: 0.1,
    locationneeded: false,
    targetUrlNeeded: true,
    active: true,
    dailyBudget: true,
    category: "Banner Ads",
    adtagsAllowed: true,
    networkHostedCreatives: true,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: true,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: true,
      requireLocation: false,
      area: true,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: true,
    },
  },
  {
    key: "adform",
    name: "Adform",
    shortName: "adform",
    image: "/images/channels/adform.png",
    color: "#003b57",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    endpoints: {
      markers: "",
      reach: null,
    },
    id: "adform",
    programmaticFee: 0.5,
    active: false,
    dailyBudget: true,
    category: "Banner Ads",
    adtagsAllowed: true,
    targeting: {
      gender: false,
      age: false,
      income: false,
      audience: false,
      domainlist: false,
      countries: false,
      geotargeting: false,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "facebook",
    name: "Facebook",
    shortName: "Facebook",
    image: "/images/channels/facebook.png",
    color: "#0f8cf1",
    id: "fb",
    programmaticFee: 0.5,
    endpoints: {
      markers: "/facebook",
      reach: "/facebook",
    },
    minBudget: 2,
    locationneeded: false,
    targetUrlNeeded: true,
    active: true,
    dailyBudget: true,
    category: "Native Ads",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: true,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      education: true,
      geosplitting: false,
      requireLocation: true,
      area: true,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "tiktok",
    name: "TikTok",
    shortName: "TikTok",
    image: "/images/channels/tiktok.png",
    color: "#25f4ee",
    id: "tiktok",
    programmaticFee: 0.5,
    endpoints: {
      markers: "/tiktok",
      reach: null,
    },
    minBudget: 20,
    locationneeded: false,
    targetUrlNeeded: true,
    active: false,
    dailyBudget: true,
    category: "Social Media Video",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "instagram",
    name: "Instagram",
    shortName: "Instagram",
    image: "/images/channels/instagram.png",
    color: "#ba358d",
    minBudget: 2,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'instagram',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/facebook",
      reach: "/facebook",
    },
    active: true,
    dailyBudget: true,
    category: "Native Ads",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: true,
      area: true,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "youtube",
    name: "Youtube",
    shortName: "Youtube",
    image: "/images/channels/youtube.png",
    color: "#ff0002",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'youtube',
    programmaticFee: 0.5,
    endpoints: {
      markers: "",
      reach: null,
    },
    active: false,
    dailyBudget: true,
    category: "Social Media Video",
    adtagsAllowed: false,
    targeting: {
      gender: false,
      age: false,
      income: false,
      audience: false,
      domainlist: false,
      countries: false,
      geotargeting: false,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "uze",
    name: "UZE Ads",
    shortName: "uze",
    image: "/images/channels/uze.png",
    color: "#ff6a39",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: false,
    id: 'uze',
    programmaticFee: 0.5,
    endpoints: {
      markers: "",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Out-of-home",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: true,
      cdpSegments: false,
    },
  },
  {
    key: "tradedesk",
    name: "The Trade Desk",
    shortName: "tradedesk",
    image: "/images/channels/thetradedesk.png",
    color: "#1f9bde",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: "ttd",
    programmaticFee: 0.5,
    endpoints: {
      markers: "",
      reach: null,
    },
    active: false,
    dailyBudget: true,
    category: "Banner Ads",
    adtagsAllowed: true,
    targeting: {
      gender: false,
      age: false,
      income: false,
      audience: false,
      domainlist: false,
      countries: false,
      geotargeting: false,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "ssp1",
    name: "SSP1",
    shortName: "ssp1",
    image: "/images/channels/ssp1.png",
    color: "#000000",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: false,
    id: 'ssp1',
    programmaticFee: 0.5,
    endpoints: {
      markers: "",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Out-of-home",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: false,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: true,
      cdpSegments: false,
    },
  },
  {
    key: "stroer",
    name: "Stroer",
    shortName: "stroer",
    image: "/images/channels/stroer.png",
    color: "#000000",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: false,
    id: 'stroer',
    programmaticFee: 0.5,
    endpoints: {
      markers: "",
      reach: null,
    },
    active: false,
    dailyBudget: true,
    category: "Out-of-home",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: false,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: true,
      cdpSegments: false,
    },
  },
  {
    key: "outbrain",
    name: "Outbrain",
    shortName: "outbrain",
    image: "/images/channels/outbrain.png",
    color: "#ee6513",
    minBudget: 30,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'outbrain',
    programmaticFee: 0.3,
    endpoints: {
      markers: "/outbrain",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Native Ads",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: true,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: true,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "punlimited",
    name: "Proxy Unlimited",
    shortName: "PUnlimited",
    image: "/images/channels/proxy-unlimited.png",
    color: "#ff4400",
    id: "punlimited",
    programmaticFee: 0.5,
    endpoints: {
      markers: "/punlimited",
      reach: null,
    },
    minBudget: 0.1,
    locationneeded: false,
    targetUrlNeeded: true,
    active: false,
    dailyBudget: true,
    category: "Banner Ads",
    adtagsAllowed: true,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "audienceserv",
    name: "Audience Serv",
    shortName: "Audience Serv",
    image: "/images/channels/audienceserv.png",
    color: "#000000",
    id: "punlimited",
    programmaticFee: 0.5,
    endpoints: {
      markers: "/audienceserv",
      reach: null,
    },
    minBudget: 0.1,
    locationneeded: false,
    targetUrlNeeded: true,
    active: true,
    dailyBudget: true,
    category: "E-Mail Marketing",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: true,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "gads",
    name: "Google Ads",
    shortName: "gads",
    image: "/images/channels/googleads.png",
    color: "#3c8bd9",
    minBudget: 1,
    locationneeded: 1,
    targetUrlNeeded: true,
    id: 'gads',
    programmaticFee: 0.15,
    endpoints: {
      markers: "/google-ads",
      reach: "/google-ads",
    },
    active: true,
    dailyBudget: true,
    category: "Search Ads",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: false,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "affiliate_cpc",
    name: "Affiliate CPC",
    shortName: "affiliate_cpc",
    image: "/images/channels/affiliate_cpc.png",
    color: "#33146b",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'affiliate_cpc',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/affiliate",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Affiliate",
    adtagsAllowed: true,
    targeting: {
      gender: true,
      age: true,
      income: false,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "affiliate",
    name: "Affiliate CPO",
    shortName: "affiliate",
    image: "/images/channels/affiliate.png",
    color: "#36bec7",
    minBudget: 0,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'affiliate',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/affiliate",
      reach: null,
    },
    active: true,
    dailyBudget: false,
    category: "Affiliate",
    adtagsAllowed: true,
    targeting: {
      gender: true,
      age: true,
      income: false,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "decentraland",
    name: "Decentraland",
    shortName: "decentraland",
    image: "/images/channels/decentraland.png",
    color: "#ff2e57",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'decentraland',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/decentraland",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Metaverse & Gaming",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: false,
      income: false,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: true,
      wearable: true,
      ethbalance: true,
      dealId: true,
      cdpSegments: false,
    },
  },
  {
    key: "cyrl",
    name: "CYRL",
    shortName: "cyrl",
    image: "/images/channels/cyrl.png",
    color: "#3d4b63",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'cyrl',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/cyrl",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Banner Ads",
    adtagsAllowed: true,
    targeting: {
      gender: false,
      age: false,
      income: false,
      audience: false,
      domainlist: true,
      countries: true,
      geotargeting: true,
      timeslots: true,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: false,
      cdpSegments: false,
    },
  },
  {
    key: "voxels",
    name: "Voxels",
    shortName: "voxels",
    image: "/images/channels/voxels.png",
    color: "#562d8b",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'voxels',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/voxels",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Metaverse & Gaming",
    adtagsAllowed: false,
    targeting: {
      gender: false,
      age: false,
      income: false,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: true,
      wearable: true,
      ethbalance: true,
      dealId: true,
      cdpSegments: false,
    },
  },
  {
    key: "anzu",
    name: "Anzu",
    shortName: "anzu",
    image: "/images/channels/anzu.png",
    color: "#000000",
    minBudget: 1,
    locationneeded: false,
    targetUrlNeeded: true,
    id: 'anzu',
    programmaticFee: 0.5,
    endpoints: {
      markers: "/anzu",
      reach: null,
    },
    active: true,
    dailyBudget: true,
    category: "Metaverse & Gaming",
    adtagsAllowed: false,
    targeting: {
      gender: true,
      age: true,
      income: false,
      audience: false,
      domainlist: false,
      countries: true,
      geotargeting: true,
      timeslots: false,
      geosplitting: false,
      requireLocation: false,
      area: false,
      nft: false,
      wearable: false,
      ethbalance: false,
      dealId: true,
      cdpSegments: false,
    },
  },
];

module.exports =
  process.env.NODE_ENV !== "developmentcc"
    ? channels.filter((c) => c.active)
    : channels;
