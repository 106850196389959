<template>
  <form class="forms-sample">
    <div class="flip-square-loader" v-show="loading"></div>
    <div class="row" v-show="!loading">
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="error">{{error}}</div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <vue2Dropzone
                    ref="myVueDropzone"
                    :id="'dropzone'"
                    v-if="!error && !success"
                    :destroyDropzone="false"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="uploadStart"
                    @vdropzone-success="uploadSuccess"
                ></vue2Dropzone>
                <label v-else>
                  File upload successful
                </label>
              </div>
            </div>
          </div>
        <b-button
            @click="submit"
            :disabled="loading"
            class="btn btn-primary ml-2"
            :class=" success ? 'pull-right' : 'pull-left'"
        >
          {{ success ? "Done" : "Cancel"}}
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "FileUploadMoal",
  props: ["entity_id"],
  components: {
      vue2Dropzone
  },
  data() {
    return {
      loading: false,
      success: false,
      error: "",
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API}/booking/import/${this.entity_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            process.env.VUE_APP_JWT
          )}`,
        },
        thumbnailWidth: null,
        thumbnailHeight: null,
        maxFilesize: 10,
        previewTemplate: `<div></div>`,
      },
    };
  },
  methods: {
    submit() {
        this.$bvModal.hide("file-upload-modal");
    },
    uploadStart(){
        this.loading = true;
    },
    uploadSuccess(file, response){
        this.$emit("uploadSuccess", response);
        this.loading = false;
        this.success = true;
    },
  },
};
</script>