var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-scroller"},[(
      ![
        '/terms',
        '/login',
        '/privacy',
        '/404',
        '/403',
        '/faq',
        '/register',
        '/_ah/warmup',
        '/_ah/health',
        '/landing' ].includes(_vm.$route.path) &&
        _vm.$route.path.indexOf('/resetpassword') == -1 &&
        _vm.$route.path.indexOf('/confirm') == -1
    )?_c('div',[_c('PageNavbar'),_c('div',{staticClass:"container-fluid page-body-wrapper"},[(['/banner/create', '/banner/template'].includes(_vm.$route.path))?_c('router-view',{key:_vm.$route.path}):_c('div',{staticClass:"main-panel",class:_vm.$route.path == '/affiliate' ? 'fullwidth' : ''},[_c('div',{staticClass:"content-wrapper"},[_c('router-view',{key:_vm.$route.path})],1),_c('PageFooter')],1)],1)],1):(['/landing'].includes(_vm.$route.path))?_c('div',{key:_vm.$route.path},[_c('router-view',{key:_vm.$route.path})],1):_c('div',[_c('router-view',{key:_vm.$route.path})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }