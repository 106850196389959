<template>
  <form class="forms-sample">
    <div class="flip-square-loader" v-if="loading"></div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="error">{{error}}</div>
          <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <div class="form-group mr-5">
                    <pre><h4>Total bookings in CSV:   {{stats.total}}</h4></pre>
                    </div>
                    <div class="form-group mr-5">
                    <pre><h4>Created bookings:   {{stats.created}}</h4></pre>
                    </div>
                    <div class="form-group mr-5" v-if="stats.failed || stats.errors.length">
                    <pre><h4>Failed bookings:   {{stats.failed}}</h4></pre>
                    </div>
                </div>
                <div class="form-group d-flex justify-content-center">
                    <b-table striped hover :items="stats.errors" :fields="fields" v-if="stats.failed || stats.errors.length">
                        <template #cell(indexerrors)="data">
                            <div v-for="(err, index) in data.item.errors"
                            :key="index"
                            >
                                <p>#{{index+1}} {{  err }}</p>
                            </div>
                        </template>
                    </b-table>
                    <label v-else>
                        <h5>Great! We parsed the whole CSV without errors.</h5>
                    </label>
                </div>
                <div class="form-group d-flex justify-content-around">
                    <b-button
                        @click="toDashboard"
                        :disabled="loading"
                        class="btn btn-success ml-2"
                        >
                        To the Dashboard
                    </b-button>
                    <b-button
                        @click="submit"
                        :disabled="loading"
                        class="btn btn-primary ml-2"
                        >
                        Done
                    </b-button>
                </div>
            </div>
          </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "ImportStatsModal",
  props: ["stats"],
  data() {
    return {
        loading: false,
        error: "",
        fields: [{label: 'Booking Name', key: "name"}, {label: 'Line in CSV', key: "line"}, {label: 'Errors', key: "indexerrors"}],
    };
  },
  methods: {
    submit() {
        this.$bvModal.hide("import-stats-modal");
    },
    showErrors(){
        this.displayErrors = !this.displayErrors;
    },
    toDashboard() {
        this.$bvModal.hide("import-stats-modal");
        this.$router.push('/');
    }
  },
};
</script>