<template>
  <div>
   <div class="row">
     <div class="col">
      <h5 class="">{{ $tc("wizard.label.headline", 2) }}</h5>
     </div>
     <div class="col">
      <h5 class="">{{ $tc("wizard.label.description", 2) }}</h5>
     </div>
   </div>
   <div class="row">
    <div class="col-6">
      <div class="list-group">
        <div class="list-group-item" v-for="(item, hindex) in headlinesD" :key="hindex">{{item}}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="list-group">
        <div class="list-group-item" v-for="(item, dindex) in descriptionsD" :key="dindex">{{item}}</div>
      </div>
    </div>
   </div>
  </div>
</template>

<script>
export default {
  name: "GadsBannerModal",
  props: {
    creative: {
      type: String,
      required: false,
      default: '',
    },
    descriptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    headlines: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      error: "",
      uri: process.env.VUE_APP_ADSERVER,
      headlinesD: [],
      descriptionsD: [],
    };
  },
  async created() {
    if(this.creative == ''){
      if(this.descriptions) this.descriptionsD = this.descriptions;
      if(this.headlines) this.headlinesD = this.headlines;
    }
    else{
      const { headlines, descriptions } = await this.$api.banner.get(this.creative, { enableLoading: false });
      this.headlinesD = headlines;
      this.descriptionsD = descriptions;
    }
  },
  methods: {
    submit() {
      this.$bvModal.hide("gads-preview-modal");
    },
  },
};
</script>