module.exports = [
    {
        name: "Brand Yves Saint Laurent",
        value: "Brand Yves Saint Laurent"
    },
    {
        name: "Brand L'Oreal Paris",
        value: "Brand L'Oreal Paris"
    },
    {
        name: "Brand Biotherm",
        value: "Brand Biotherm"
    },
    {
        name: "Category Fragrance",
        value: "Category Fragrance"
    },
    {
        name: "Category Face",
        value: "Category Face"
    },
    {
        name: "Category Body",
        value: "Category Body"
    },
    {
        name: "Customer Lifetime Value Cl 3",
        value: "Customer Lifetime Value Cl 3"
    },
    {
        name: "Customer Lifetime Value Cl 2",
        value: "Customer Lifetime Value Cl 2"
    },
    {
        name: "Customer Lifetime Value Cl 1",
        value: "Customer Lifetime Value Cl 1"
    }
  ];

  