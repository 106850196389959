<template>
    <div class="row bg-light pt-2">
        <div class="col-4 form-group">
            <label for="bannerType" class="mb-1">bannerType</label>
            <v-select
                id="bannerType"
                multiple
                :close-on-select="false"
                v-model="bannerType"
                label="name"
                class="form-control"
                :filterable="false"
                :reduce="(type) => type.value"
                :selectable="option => !bannerType.includes(option.value)"
                :options="bannerTypes"
                @input="reportInput('bannerType')"
            >
            </v-select>
        </div>
    <!-- <div class="col-4 form-group">
        <label for="campaigns" class="mb-1">{{ $tc('general.campaign', 2) }}</label>
        <v-select
            id="campaigns"
            multiple
            :close-on-select="false"
            v-model="campaign"
            @search="onSearchCampaigns"
            label="name"
            class="form-control"
            :filterable="false"
            :reduce="(campaign) => campaign._id"
            :options="allCampaigns"
            @input="reportInput('campaign')"
        >
            <template slot="no-options">
            {{ $t("messages.typetosearch", {name: $tc("general.campaign", 2)}) }}
            </template>
        </v-select>
        </div> -->
    </div>
</template>
  
<script>
  import _debounce from "lodash.debounce";

  export default {
    name: "EntityFilterBanner",
    props: {
    },
    created(){

    },
    data() {
      return {
        bannerTypes: [
            { value: 'html_ad', name: 'HTML' },
            { value: 'image_ad', name: 'IMAGE' },
            { value: 'video_ad', name: 'VIDEO' },
            { value: 'search_ad', name: 'SEARCH' },
        ],
        bannerType: [],
        allCampaigns: [],
        campaign: [],
      };
    },
    methods: {
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
        onSearchCampaigns(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _debounce((loading, search, vm) => {
        vm.$api.campaign
            .search(search, { enableLoading: false })
                .then((campaigns) => {
                vm.allCampaigns = campaigns.items;
                loading(false);
            });
        }, 350),
    },
  };
</script>
