<template>
    <div class="row bg-light pt-2">
        <div class="col-4 form-group">
            <label for="searchfield_daterange" class="mb-1">{{ $t('general.startdate') }} / {{ $t('general.enddate') }} (max. 3 {{ 
           $tc("general.day",2) }})</label>
            <DateRangePickerCustom opens="right" @update="setDates" />
        </div>
    </div>
</template>
  
<script>
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import { mapGetters } from 'vuex';

  export default {
    name: "EntityFilterDateOnly",
    components: {
        DateRangePickerCustom
    },
    props: {
        filter: {
            type: Object,
            required: false
        }
    },
    created(){
    },
    data() {
      return {
      };
    },
    computed: {
        ...mapGetters(["dateRange"]),
        dateRange: {
            get() {
                return this.$store.state.store.dateRange;
            }
        },
    },
    methods: {
        setDates(){
            this.$emit("filter", 'start', this.dateRange.startDate);
            this.$emit("filter", 'end', this.dateRange.endDate);
        },
    },
  };
</script>
