<template>
    <div class="row bg-light pt-2">
      <!-- <div class="col-4 form-group" v-if="isSysOp">
        <label for="advertisers" class="mb-1">{{ $tc('general.advertiser', 2) }}</label>
        <v-select
            id="advertisers"
            :close-on-select="true"
            v-model="advertiser"
            @search="onSearchAdvertisers"
            label="name"
            class="form-control"
            :filterable="false"
            :reduce="(advertiser) => advertiser._id"
            :options="allAdvertisers"
            @input="reportInput('advertiser')"
        >
            <template slot="no-options">
            {{ $t("messages.typetosearch", {name: $tc("general.advertiser", 2)}) }}
            </template>
        </v-select>
      </div>
      <div class="col-4 form-group" v-if="isSysOp">
        <label for="campaigns" class="mb-1">{{ $tc('general.campaign', 2) }}</label>
        <v-select
            id="campaigns"
            :close-on-select="true"
            v-model="campaign"
            @search="onSearchCampaigns"
            label="name"
            class="form-control"
            :filterable="false"
            :reduce="(campaign) => campaign._id"
            :options="allCampaigns"
            @input="reportInput('campaign')"
        >
            <template slot="no-options">
            {{ $t("messages.typetosearch", {name: $tc("general.campaign", 2)}) }}
            </template>
        </v-select>
      </div> -->
    </div>
</template>
  
<script>
  import _debounce from "lodash.debounce";
  const jwtDecode = require('jwt-decode');

  export default {
    name: "EntityFilterCatalogs",
    props: {
    },
    created(){

    },
    data() {
      return {
        bannerType: [],
        allAdvertisers: [],
        advertiser: null,
        allCampaigns: [],
        campaign: null,
      };
    },
    methods: {
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
        onSearchAdvertisers(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.searchAdvertisers(loading, search, this);
            }
        },
        searchAdvertisers: _debounce((loading, search, vm) => {
          vm.$api.advertiser
            .search(search, { enableLoading: false })
                .then((advertisers) => {
                vm.allAdvertisers = advertisers.items;
                loading(false);
            });
        }, 350),
        onSearchCampaigns(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.searchCampaigns(loading, search, this);
            }
        },
        searchCampaigns: _debounce((loading, search, vm) => {
          vm.$api.campaign
            .search(search, { enableLoading: false })
                .then((campaigns) => {
                vm.allCampaigns = campaigns.items;
                loading(false);
            });
        }, 350),
    },

  computed: {
    isSysOp: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "systemoperator" ? true : false,
    },
  };
</script>
