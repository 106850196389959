<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <nav class="navbar horizontal-layout col-lg-12 col-12 p-0" style="position: relative">
      <div class="nav-top flex-grow-1">
        <div class="container-fluid d-flex flex-row h-100">
          <div class="text-center navbar-brand-wrapper d-flex align-items-top">
            <router-link class="navbar-brand brand-logo" to="/">
              <img :src="headerlogo" id="logo" alt="logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img :src="headerlogo" alt="logo" />
            </router-link>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item dropdown mr-4" style="cursor: pointer;">
                <a
                  class="nav-link dropdown-toggle"
                  id="notificationDropdown"
                  href="#"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    class="flag-icon"
                    :class="selectedLanguage === 'en' ? 'flag-icon-gb' : 'flag-icon-de'"
                  ></i>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list p-0"
                  style="top: calc(45px - 6px);"
                  aria-labelledby="notificationDropdown"
                >
                  <a class="dropdown-item preview-item" @click="changeLanguage('en')">
                    <div class="preview-thumbnail">
                      <div
                        class="preview-icon bg-light"
                        :class="selectedLanguage === 'en' ? 'bg-background' : ''"
                      >
                        <i class="flag-icon flag-icon-gb mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">English</h6>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" @click="changeLanguage('de')">
                    <div class="preview-thumbnail">
                      <div
                        class="preview-icon bg-light"
                        :class="selectedLanguage == 'de' ? 'bg-background' : ''"
                      >
                        <i class="flag-icon flag-icon-de mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">German</h6>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            <p class="my-0 ml-3">{{ $t("messages.alreadyregistered") }}</p>
            <router-link
              class="btn get-started-btn text-uppercase"
              to="/login"
            >{{ $t("actions.signin") }}</router-link>
          </div>
        </div>
      </div>
    </nav>
      <!-- <div class="content-wrapper">  -->
    <div class="row" style="height: calc(100vh - 70px); overflow-y: auto;" ref="containerScroll">
      <div class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center px-0">
        <div class="slide-content bg-2" :style="'background-image: url('+authimage+')'"></div>
      </div>
      <div class="col-md-8 bg-white d-flex align-items-center justify-content-center" style="overflow: auto;">
        <div class="col-sm-12 col-md-10 col-lg-10 col-xl-8 mt-5">
          <div class="alert alert-success" v-if="registered">{{ $t("messages.registersuccess") }}</div>
          <form @submit.prevent="register" v-if="!registered" id="registerForm">
            <h3 v-if="userType == 'advertiser' && type == 'agency'" class="mb-3">{{ $t("headers.register-agency") }}</h3>
            <h3 v-else-if="userType == 'advertiser' && type == 'company'" class="mb-3">{{ $t("headers.register-company") }}</h3>
            <h3 v-else-if="userType == 'publisher'" class="mb-3">{{ $t("headers.register-publisher") }}</h3>
            <h3 v-else class="mb-3">{{ $t("headers.register") }}</h3>

            <div class="alert alert-danger" v-if="error">{{error}}</div>
            <div class="alert alert-warning" v-if="warning">{{warning}}</div>

            <div v-if="userType == ''">
              <p class="mb-4 mr-auto">{{ $t("messages.registertext") }}</p>

              <div class="jumbotron px-4 py-3">
                <h4 class="display-4">{{ $t("headers.reg-advertiser") }}<i class="fa fa-home float-right"></i></h4>
                <p
                  class="m-0"
                  v-html="$t('messages.reg-advertiser')"
                ></p>
                <hr class="my-3" />
                <b-button @click="setUserType('advertiser')" size="lg" variant="primary">{{ $t("actions.reg-advertiser") }}</b-button>
              </div>

              <div class="jumbotron px-4 py-3">
                <h4 class="display-4">{{ $t("headers.reg-publisher") }}<i class="fa fa-photo float-right"></i></h4>
                <p
                  class="m-0"
                  v-html="$t('messages.reg-publisher')"
                ></p>
                <hr class="my-3" />
                <b-button @click="setUserType('publisher')" size="lg" variant="primary">{{ $t("actions.reg-publisher") }}</b-button>
              </div>
            </div>

            <div v-if="userType == 'advertiser' && type == ''">
              <p class="mb-4 mr-auto">{{ $t("messages.registertext-advertiser") }}</p>

              <div class="jumbotron px-4 py-3">
                <h4 class="display-4">{{ $t("headers.reg-company") }}<i class="fa fa-home float-right"></i></h4>
                <p
                  class="m-0"
                  v-html="$t('messages.reg-company')"
                ></p>
                <hr class="my-3" />
                <b-button @click="setType('company')" size="lg" variant="primary">{{ $t("actions.reg-company") }}</b-button>
              </div>

              <div class="jumbotron px-4 py-3">
                <h4 class="display-4">{{ $t("headers.reg-agency") }}<i class="fa fa-building float-right"></i></h4>
                <p
                  class="m-0"
                  v-html="$t('messages.reg-agency')"
                ></p>
                <hr class="my-3" />
                <b-button @click="setType('agency')" size="lg" variant="primary">{{ $t("actions.reg-agency") }}</b-button>
              </div>
            </div>

            <div v-if="userType == 'publisher'">
              <p class="mb-4 mr-auto">{{ $t("messages.reg-publisher-text") }}</p>
              <div class="form-group">
                <div class="input-group form-inline">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <input
                    v-model="name"
                    type="text"
                    class="form-control mr-1"
                    :placeholder="$t('forms.firstname')"
                    required
                    minlength="2"
                  />
                  <div class="input-group-prepend ml-1">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <input
                    v-model="surname"
                    type="text"
                    class="form-control"
                    :placeholder="$t('forms.lastname')"
                    required
                    minlength="2"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-globe"></i>
                    </span>
                  </div>
                  <input
                    v-model="website"
                    type="url"
                    class="form-control"
                    :placeholder="$t('forms.regwebsite')"
                    required
                    minlength="3"
                  />
                </div>
                <p class="small text-center">You can add more websites later. Please type here the website with the most daily visitors.</p>
              </div>
              <hr />
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-envelope"></i>
                    </span>
                  </div>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('forms.email')"
                    required
                    minlength="6"
                    autocomplete="username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group form-inline">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control mr-1"
                    :placeholder="$t('forms.password')"
                    required
                    minlength="8"
                  />

                  <div class="input-group-prepend ml-1">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password2"
                    type="password"
                    class="form-control"
                    :placeholder="$t('forms.passwordconfirm')"
                    required
                    minlength="8"
                  />
                </div>
              </div>
            </div>

            <div v-if="userType == 'advertiser' && type=='company'">
              <p class="mb-4 mr-auto">{{ $t("messages.reg-company-text") }}</p>
              <div class="form-group">
                <div class="input-group form-inline">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <input
                    v-model="name"
                    type="text"
                    class="form-control mr-1"
                    :placeholder="$t('forms.firstname')"
                    required
                    minlength="2"
                  />
                  <div class="input-group-prepend ml-1">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <input
                    v-model="surname"
                    type="text"
                    class="form-control"
                    :placeholder="$t('forms.lastname')"
                    required
                    minlength="2"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-briefcase"></i>
                    </span>
                  </div>
                  <input
                    v-model="advertiser"
                    type="text"
                    class="form-control"
                    :placeholder="$t('forms.regadvertiser')"
                    required
                    minlength="3"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-briefcase"></i>
                    </span>
                  </div>
                  <b-form-select
                    size="lg"
                    id="industry"
                    class="form-control"
                    style="height: auto;"
                    v-model="industry"
                    :options="industries"
                  ></b-form-select>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-envelope"></i>
                    </span>
                  </div>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('forms.email')"
                    required
                    minlength="6"
                    autocomplete="username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group form-inline">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control mr-1"
                    :placeholder="$t('forms.password')"
                    required
                    minlength="8"
                  />

                  <div class="input-group-prepend ml-1">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password2"
                    type="password"
                    class="form-control"
                    :placeholder="$t('forms.passwordconfirm')"
                    required
                    minlength="8"
                  />
                </div>
              </div>
            </div>

            <div v-if="userType == 'advertiser' && type=='agency'">
              <p class="mb-4 mr-auto">{{ $t("messages.reg-agency-text") }}</p>
              <div class="form-group">
                <div class="input-group form-inline">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <input
                    v-model="name"
                    type="text"
                    class="form-control mr-1"
                    :placeholder="$t('forms.firstname')"
                    required
                    minlength="2"
                  />
                  <div class="input-group-prepend ml-1">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <input
                    v-model="surname"
                    type="text"
                    class="form-control"
                    :placeholder="$t('forms.lastname')"
                    required
                    minlength="2"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                  </div>
                  <b-form-select
                    size="lg"
                    id="role"
                    class="form-control"
                    style="height: auto;"
                    v-model="jobRole"
                    :options="roles"
                  ></b-form-select>
                </div>
              </div>
              <hr />
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-briefcase"></i>
                    </span>
                  </div>
                  <input
                    v-model="advertiser"
                    type="text"
                    class="form-control"
                    :placeholder="$t('forms.regadvertiser')"
                    required
                    minlength="3"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-briefcase"></i>
                    </span>
                  </div>
                  <b-form-select
                    size="lg"
                    id="industry"
                    class="form-control"
                    style="height: auto;"
                    v-model="industry"
                    :options="industries"
                  ></b-form-select>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      &euro;
                    </span>
                  </div>
                  <input
                    v-model.number="dailyBudget"
                    type="number"
                    class="form-control"
                    :placeholder="$t('forms.expected-budget')"
                    required
                    min="1"
                    step="1"
                  />
                </div>
              </div>
              <hr />
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-phone"></i>
                    </span>
                  </div>
                  <input
                    v-model="phone"
                    type="text"
                    class="form-control"
                    :placeholder="$t('forms.phone')"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-envelope"></i>
                    </span>
                  </div>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('forms.email')"
                    required
                    minlength="6"
                    autocomplete="username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group form-inline">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control mr-1"
                    :placeholder="$t('forms.password')"
                    required
                    minlength="8"
                  />

                  <div class="input-group-prepend ml-1">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password2"
                    type="password"
                    class="form-control"
                    :placeholder="$t('forms.passwordconfirm')"
                    required
                    minlength="8"
                  />
                </div>
              </div>
            </div>

            <div v-if="userType == 'publisher' || type != ''">
              <b-form-checkbox
                type="checkbox"
                v-model="terms_privacy"
                class="mb-2 text-left"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t('forms.readandagree_pre') }}
                <router-link to="/terms">{{ $t('forms.terms') }}</router-link>
                {{ $t('general.and') }}
                <router-link to="/privacy">{{ $t('forms.privacypolicy') }}</router-link>
                {{ $t('forms.readandagree_post') }}
              </b-form-checkbox>

              <b-form-checkbox
                type="checkbox"
                v-model="newsletter"
                class="mb-4 text-left"
                :value="true"
                :unchecked-value="false"
              >{{ $t('forms.regnewsletter') }}</b-form-checkbox>

              <button
                class="btn btn-success submit-btn btn-block"
                v-if="!loading"
              >{{ $t("actions.registerbtn") }}</button>
              <div v-if="loading" class="flip-square-loader"></div>
            </div>
          </form>
          <div class="wrapper mt-5 text-gray">
            <p class="footer-text">Copyright © {{currentYear}} {{companyName}}. All rights reserved.</p>
          </div>
        </div> 
      </div>
    </div>
      <!-- </div> -->
      
    
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Register",
  created() {
    this.selectedLanguage = this.$root.$i18n.locale;
  },
  data() {
    let locale = "en";
    if (this.$i18n.locale) locale = this.$i18n.locale;

    return {
      selectedLanguage: "en",
      email: "",
      name: "",
      advertiser: "",
      website: "",
      surname: "",
      password: "",
      password2: "",
      terms_privacy: false,
      newsletter: false,
      industry: null,
      jobRole: null,
      phone: "",
      dailyBudget: null,
      type: "", // agency or advertiser
      userType: "",  // advertoser or publisher
      industries: require(`@/locales/industries.${locale}.js`),
      roles: require(`@/locales/businessRoles.${locale}.js`)
    };
  },
  computed: {
    ...mapGetters(["loading", "error", "success", "warning", "registered"]),
    currentYear: () => new Date().getFullYear(),
    companyName: () => process.env.VUE_APP_COMPANY_NAME,
    authimage: () => "/wl/" + process.env.VUE_APP_NAME + "/bg_auth.jpg",
    headerlogo: () => "/wl/" + process.env.VUE_APP_NAME + "/logo.png",
    getLanguage: () =>
      localStorage.getItem(process.env.VUE_APP_LANGUAGE)
        ? localStorage.getItem(process.env.VUE_APP_LANGUAGE)
        : process.env.VUE_APP_LANGUAGE_DEFAULT
  },
  methods: {
    setUserType(type) {
      this.userType = type;
      this.$refs.conatinerScroll.scrollTo(0, 0)
    },
    setType(type) {
      this.type = type;
      this.$refs.conatinerScroll.scrollTo(0, 0)
    },
    changeLanguage(ln) {
      this.selectedLanguage = ln;
      localStorage.setItem(process.env.VUE_APP_LANGUAGE, ln);
      this.$root.$i18n.locale = ln;
      this.industries = require(`@/locales/industries.${ln}.js`);
      this.roles = require(`@/locales/businessRoles.${ln}.js`)
    },
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearForm(){
      this.email = "";
      this.name = "";
      this.advertiser = "";
      this.surname = "";
      this.password = "";
      this.password2 = "";
      this.terms_privacy = false;
      this.newsletter = false;
      this.dailyBudget = null;
      this.jobRole = null;
      this.industry = null;
      this.phone = '';
      this.userType = '';
    },
    async register() {
      this.$store.dispatch("setError", null);

      if (this.name.length < 2 || this.surname.length < 2) {
        this.$store.dispatch("setError", this.$t("messages.enternames"));
      } else if (this.email.length < 6 || !this.validateEmail(this.email)) {
        this.$store.dispatch("setError", this.$t("messages.entervalidemail"));
      } else if (!this.terms_privacy) {
        this.$store.dispatch("setError", this.$t("messages.agreeterms"));
      } else if (this.password != this.password2 || this.password.length < 8) {
        this.$store.dispatch("setError", this.$t("messages.passwordnotmatch"));
      } else {

        // check special values for advertiser
        if(this.userType == "advertiser"){
          if (this.advertiser.length < 3) {
            this.$store.dispatch("setError", this.$t("messages.enteradvertiser"));
          }
          else if (this.industry === null) {
            this.$store.dispatch("setError", this.$t("messages.enterindustry"));
          }
          else{
            this.registered = false;
            const payload = {
              email: this.email,
              name: this.name,
              advertiser: this.advertiser,
              surname: this.surname,
              password: this.password,
              newsletter: this.newsletter,
              dailyBudget: this.type == 'agency' ? this.dailyBudget : null,
              jobRole: this.jobRole,
              industry: this.industry,
              language: this.getLanguage,
              accountType: this.type,
              phone: this.phone,
              userType: "advertiser"
            };

            const result = await this.$store.dispatch("register", payload);
            if (result) {
              this.clearForm();
            }
          }
        }
        else{
          this.registered = false;
          const payload = {
            email: this.email,
            name: this.name,
            website: this.website,
            surname: this.surname,
            password: this.password,
            newsletter: this.newsletter,
            language: this.getLanguage,
            userType: "publisher"
          };

          const result = await this.$store.dispatch("register", payload);
          if (result) {
            this.clearForm();
          }
        }
      }
    }
  }
}
</script>

<style scoped>
#logo {
  height: 43px;
  margin-top: 5px;
}
.banner-section .slide-content.bg-2 {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.get-started-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin-left: 20px;
  border-radius: 50px;
}

.bg-background {
  border-color: #00b297;
  border-style: solid;
  border-width: 2px;
}
</style>
