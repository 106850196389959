<template>
  <div class="container-scroller">
    <!-- partial:partials/_horizontal-navbar.html -->
    <div
      v-if="
        ![
          '/terms',
          '/login',
          '/privacy',
          '/404',
          '/403',
          '/faq',
          '/register',
          '/_ah/warmup',
          '/_ah/health',
          '/landing',
        ].includes($route.path) &&
          $route.path.indexOf('/resetpassword') == -1 &&
          $route.path.indexOf('/confirm') == -1
      "
    >
      <PageNavbar />
      <!-- partial -->

      <div class="container-fluid page-body-wrapper">
        <router-view
          v-if="['/banner/create', '/banner/template'].includes($route.path)"
          :key="$route.path"
        ></router-view>
        <div
          v-else
          class="main-panel"
          :class="$route.path == '/affiliate' ? 'fullwidth' : ''"
        >
          <div class="content-wrapper">
            <router-view :key="$route.path"></router-view>
          </div>
          <!-- content-wrapper ends -->
          <!-- partial:../../partials/_footer.html -->
          <PageFooter />
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
    </div>
    <div v-else-if="['/landing'].includes($route.path)" :key="$route.path">
      <router-view :key="$route.path"></router-view>
    </div>
    <div v-else>
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
  <!-- container-scroller -->
</template>

<script>
import PageNavbar from "@/containers/Navbar";
import PageFooter from "@/containers/Footer";

export default {
  name: "App",
  components: {
    PageNavbar,
    PageFooter,
  },
  watch: {
    $route() {
      const jwt = localStorage.getItem(process.env.VUE_APP_JWT);
      const active = localStorage.getItem(process.env.VUE_APP_ACTIVE);
      if (jwt && active) {
        if (
          new Date(active) <
          new Date(Date.now() - process.env.VUE_APP_EXPIRATION)
        ) {
          localStorage.removeItem(process.env.VUE_APP_JWT);
          localStorage.removeItem(process.env.VUE_APP_ACTIVE);

          this.$router.push("/login");
        } else {
          localStorage.setItem(process.env.VUE_APP_ACTIVE, new Date());
        }
      }
    },
  },
  mounted() {
    const showUpdateUI = localStorage.getItem("updated");
    if (showUpdateUI) {
      this.$bvToast.toast(this.$t("toasts.app-update.message"), {
        title: this.$t("toasts.app-update.title"),
        noAutoHide: true,
        variant: "warning",
        solid: true,
        // toaster: "b-toaster-top-full"
      });
      localStorage.removeItem("updated");
    }
  },
};
</script>

<style lang="css" scoped>
.fullwidth {
  max-width: 100%;
}

.notice-top {
  padding: 5px;
  position: absolute;
  top: 0;
  left: 160px;
  right: 400px;
  z-index: 9999;
  height: 70px;
  background-color: aquamarine;
}
.notice-top div {
  display: flex;
  align-items: stretch;
  height: 100%;
}
.notice-top span {
  display: flex;
  flex-grow: 1;
  font-size: 1.5em;
  font-weight: bold;
  align-items: center;
}
</style>

<style>
.btn-ty{
  padding: 0.25rem 0.6rem;
  font-size: 0.65rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled{
  cursor: not-allowed;
}
</style>
