<template>
  <div class="col">
    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="toggleShowAll()">{{ showAll ? $tc('syncqueue.tree.show-only-last-message') : $tc('syncqueue.tree.show-all-messages') }}</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-list-group class="mt-2">
          <b-list-group-item v-for="message in shownMessages" :key="message.date">
            <div class="row">
              <div class="col">
                <small>{{message.date}}</small>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <pre class="px-0 pt-1 pb-0 mb-0">{{message.message}}</pre>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Messages",
  data() {
    return {
      showAll: false,
    }
  },
  props: ["messages"],
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  },
  computed: {
    shownMessages() {
      if (!this.messages?.length) return [{
        date: new Date().toLocaleString(),
        message: "There are no messages"
      }];
      else return this.showAll ? [...this.messages].reverse() : [this.messages[this.messages.length-1]];
    }
  }
}
</script>

<style>

</style>