<template>
  <div class="row dba-topchart">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <bar-chart :chart-data="chartData" :options="defaultOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/charts/Bar";

export default {
  name: "DashboardAnalyticsTopChart",
  props: {
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              id: "left-y",
              position: "left"
            },
            {
              id: "right-y",
              position: "right",
              ticks: {
                callback: function(value) {
                  return value + "%";
                }
              }
            }
          ]
        },
        tooltips: {
          mode: "index",
          intersect: true
        }
      }
    };
  },
  computed: {
    chartData() {

      for(const date in this.stats){
        this.stats[date].conversions = this.stats[date].pcConversions+this.stats[date].pvConversions;
      }

      let data = {
        labels: Object.keys(this.stats),
        datasets: [
          {
            label: this.$tc("general.view", 2),
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            data: [],
            yAxisID: "left-y"
          },
          {
            fill: true,
            label: this.$tc("general.click", 2),
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            data: [],
            yAxisID: "left-y"
          },
          {
            fill: true,
            label: this.$tc("general.conversion", 2),
            backgroundColor: "rgba(255, 196, 0, 0.12)",
            borderColor: "rgba(255, 176, 39, 1)",
            borderWidth: 1,
            data: [],
            yAxisID: "left-y"
          },
          {
            type: "line",
            label: "CTR",
            backgroundColor: ["rgba(7, 102, 198, 0.2)"],
            borderColor: ["rgba(7, 102, 198, 1)"],
            borderWidth: 1,
            data: [],
            yAxisID: "right-y"
          }
        ]
      };

      for(const s in this.stats){
        let cObj = this.stats[s];
        data.datasets[0].data.push(cObj.impressions);
        data.datasets[1].data.push(cObj.clicks);
        data.datasets[2].data.push(cObj.conversions);
        data.datasets[3].data.push(this.getCTR(cObj.impressions, cObj.clicks, 2));
      }
// console.log(data);
      return data;
    }
  },
  methods: {
    getCTR(views, clicks, precision) {
      let ctr = 0;
      if (views > 0) {
        ctr = Number(((clicks * 100) / views).toFixed(precision));
      }

      return ctr;
    }
  },
  components: {
    BarChart
  }
};
</script>

<style lang="scss" scoped>
.dba-topchart .card-body {
  padding: 15px;
}
</style>>
