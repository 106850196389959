const faq = [
  {
    icon: "icon-user",
    title: "Registrieren & Login",
    contents: [
      {
        title: "Wie kann ich mich registrieren?",
        text: `<ol><li>Besuchen Sie unsere Webseite <a href="https://www.42ads.io">www.42ads.io</a>.</li>
        <li>Klicken Sie in der Menüleiste oben rechts auf den <strong>Login</strong>-Button.</li>
        <li>Auf der erscheinenden Login-Seite können Sie oben rechts auf <strong>Registrieren</strong> klicken.</li>
        <li>Geben Sie nun Ihre Daten an und wählen Sie Ihre Branche.</li>
        <li>Lesen und bestätigen Sie die Datenschutzerklärung und die allgemeinen Geschäftsbedingungen.</li>
        <li>Zum Schluss bestätigen Sie den Abschluss der Registrierung durch einen Klick auf <strong>Registrieren</strong>.</li></ol>
        <p>Sie erhalten nun eine E-Mail, mit der Bestätigung, dass Sie sich registriert haben.<br>
        Klicken Sie in der E-Mail auf den Button <strong>E-Mail-Adresse bestätigen</strong> um Ihre E-Mail-Adresse zu bestätigen.</p>`
      },
      {
        title: "Wie lange dauert es bis mein Account nach der Registrierung freigeschaltet wird?",
        text: `Wenn Sie sich als <strong>Unternehmen</strong> registriert haben, dann ist Ihr Account nach der Bestätigung Ihrer E-Mail-Adresse sofort freigeschaltet.<br><br>
        Haben Sie einen <strong>Agenturaccount</strong> angefragt, dann wird Ihre Registrierung von uns manuell überprüft und freigeschaltet. Eventuell kontaktieren wir Sie vorher.`
      },
      {
        title: "Auf welchen Sprachen ist die 42AdSuite verfügbar?",
        text: `Die 42AdSuite ist aktuell in den Sprachen <strong>Deutsch</strong> und der Weltsprache <strong>Englisch</strong> verfügbar.<br>Sie können die Sprache im Ihrem Profil ändern.`
      },
      {
        title: "Kostet der Zugang zu der 42AdSuite mich etwas?",
        text: `Nein, der Zugang zur 42AdSuite ist <strong>kostenlos</strong>. Nur die laufenden Kampagnen sind kostenpflichtig. Das Budget entscheiden Sie selbst.`
      },
      {
        title: "Wie kann ich mich einloggen?",
        text: `<ol><li>Besuchen Sie unsere Webseite <a href="https://www.42ads.io">www.42ads.io</a>.</li>
        <li>Klicken Sie in der Menüleiste oben rechts auf den Login-Button.</li>
        <li>Nun gelangen Sie auf die Login-Seite und können sich mit den von Ihnen festgelegten Daten einloggen.</li></ol>`
      },
      {
        title: "Ich habe mein Passwort vergessen, wie kann ich mein Passwort zurücksetzten?",
        text: `<p>Im <strong>Login</strong>-Bereich neben dem Login-Button finden Sie den Punkt <strong>Passwort zurücksetzten</strong>.</p>
        <ol><li>Klicken Sie diesen an und geben Sie dann ihre E-Mail Adresse ein</li>
        <li>Bestätigen Sie das zurücksetzten mit einem klick auf <strong>Passwort zurücksetzten</strong></li>
        <li>Nun erhalten Sie eine E-Mail mit weiteren Anweisungen, an die von Ihnen hinterlegte E-Mail Adresse.</li></ol>`
      }
    ]
  },
  {
    icon: "icon-basket",
    title: "Rund um Ihre Kampagnen",
    contents: [
      {
        title: "Wie kann ich eine Kampagne anlegen?",
        text: `<p>Sie können eine Kampagne über den <strong>Jetzt Starten</strong>-Button oder über den Punkt <strong>Buchung erstellen</strong> anlegen. Sie können eine Kampagne in nachfolgenden Schritten anlegen:</p>
        <ol><li>Legen Sie die Rahmenbedingungen wie Werbekanäle, Budget, usw. fest.</li>
        <li>Bestimmen Sie Ihre Zielgruppe und legen Sie fest, was Sie bewerben möchten.</li>
        <li>Laden Sie Ihre Werbemittel hoch oder nutzen Sie unseren Werbemittel-Service</li>
        <li>Prüfen Sie Ihre Angaben und klicken unten auf <strong>Buchung erstellen</strong></li></ol>
        <p>Wenn Sie den Werbemittel-Service gewählt haben, dann generieren wir passende Werbemittel für Ihre Kampagne. Sie können diese Werbemittel im Anschluss überprüfen, bearbeiten, austauschen oder löschen. Sind Sie mit der Auswahl zufrieden, dann können Sie die Buchung <strong>veröffentlichen</strong>.</p>
        <p>Haben Sie eigene Werbemittel hochgeladen, dann startet Ihre Kampagne automatisch sobald wir Ihre Werbemittel überprüft haben.</p>`
      },
      {
        title: "Auf welchen Kanälen kann ich meine Kampagnen anlegen?",
        text: `<p>Wir haben die verfügbaren Werbekanäle in Gruppen unterteilt:</p>
        <strong>Native Ads</strong>
          <ul><li>Facebook</li><li>Instagram</li></ul>
        
        <strong>Social Media Video</strong>
          <ul><li>TikTok</li></ul>
        
        <strong>Banner Ads</strong>
        <ul><li>Google Display & Video</li><li>Platform 161</li></ul>
        
        <strong>Digital Out-of-home</strong>
          <ul><li>UZE Ads</li><li>SSP1</li></ul>
        
        <p>Möglicherweise sind nach de Registrierung nicht alle Werbekanäle sichtbar. In diesem Fall, kontaktieren Sie uns bitte.</p>
        `
      },
      {
        title: "Kann ich alle Kanäle in einer Kampagne gleichzeitig bewerben?",
        text: `Ja. Mit der 42AdSuite können Sie mit einer Kampagne, alle Kanäle gleichzeitig bewerben.`
      },
      {
        title: "Gibt es ein Mindestbudget für die verschiedenen Kanäle?",
        text: `Bei allen Kanälen, TikTok ausgeschlossen, reicht 1,00 € Tagesbudget pro Kanal. Bei TikTok beträgt das tägliche Mindestbudget 20,00 € pro Tag.`
      },
      {
        title: "Kann ich selber bestimmen wie lange meine Kampagne läuft?",
        text: `Ja, Sie können bei dem erstellen der Kampagne das Start- und Enddatum flexibel festlegen.`
      },
      {
        title: "Kann ich meine Zielgruppe definieren?",
        text: `Ja, bei dem Erstellen der Kampagne können Sie die Zielgruppe definieren.<br>
        Bestimmen Sie den Ort in dem Sie werben möchten und legen Sie das Geschlecht und Alter Ihrer Zielgruppe fest.<br>
        Sie können die Daten zu Ihrer Zielgruppe im Nachhinein jederzeit bearbeiten.`
      },
      {
        title: "Wie kann ich Werbemittel hinterlegen?",
        text: `Bei der Erstellung der Buchung können Sie entscheiden, ob Sie unseren <strong>Werbemittel-Service</strong> in Anspruch nehmen möchten oder ob Sie eigene Werbemittel hochladen möchten.<br>Wählen Sie eigene Werbemittel, dann können Sie für jeden Werbekanal Werbemittel hochladen.`
      },
      {
        title: "Was passiert nach dem Anlegen einer Kampagne?",
        text: `<ol><li>Sie erhalten nach dem Anlegen einer Kampagne eine E-Mail mit den von Ihnen festgelegten Rahmenbedingungen.</li>
        <li>Haben Sie den Werbemittel-Service gewählt, dann erstellen wir für Sie passende Werbemittel. Sobald der Prozess fertig ist, erhalten Sie eine E-Mail.</li>
        <li>Ihre Kampagne wird im Hintergrund geprüft. Wenn Ihre Kampagne zur Veröffentlichung bereit ist, erhalten Sie eine Benachrichtigung per E-Mail.</li>
        <li>Nach dem Erhalt der E-Mail, können Sie Ihre Kampagne veröffentlichen.</li></ol>`
      },
      {
        title: "Wie kann ich meine Kampagne veröffentlichen?",
        text: `<p>Wenn Sie die E-Mail erhalten haben, dass Ihre Kampagne zur Veröffentlichung bereit steht, können Sie diese nun über Ihr Dashboard veröffentlichen.</p>
        <ol><li>Gehen Sie hierzu auf Ihrem Dashboard in den Bereich <strong>Ausstehende Buchungen</strong></li>
        <li>Klicken Sie im Status der Kampagne auf den Punkt <strong>Veröffentlichen</strong>.</li></ol>
        Nun wird Ihre Kampagne veröffentlicht, dies kann bis zu zwei Minuten dauern. Sobald die Veröffentlichung abgeschlossen ist, erhalten Sie eine E-Mail.`
      },
      {
        title: "Kann ich eine gestartete Kampagne pausieren und zu einem späteren Zeitpunkt wieder starten?",
        text: `Ihre Kampagne kann jederzeit pausiert und wieder gestartet werden.`
      },
      {
        title: "Kann eine auslaufende Kampagne verlängert werden?",
        text: `Ja, Klicken Sie dafür im Dashboard oder der Buchungsübersicht bei der Buchung auf <strong>Buchung aktualisieren</strong>. Aktualisieren Sie dann Das Enddatum Ihrer Buchung.`
      },
      {
        title: "Kann ich Werbekanäle bei aktiven Kampagnen im Nachhinein hinzufügen oder entfernen?",
        text: `Ja, Kanäle können jederzeit hinzugefügt und entfernt werden.`
      },
      {
        title: "Wie kann ich den Erfolg meiner Kampagnen einsehen?",
        text: `<p>Sie können den Erfolg Ihrer Kampagnen auf zwei Weisen einsehen.</p>
        <ol><li>Eine einfache und kurze Übersicht, finden Sie auf Ihrem Dashboard unter dem Punkt <strong>Aktive Buchungen</strong>.</li>
        <li>Unter dem Punkt <strong>Aktive Buchungen</strong> auf dem Dashboard haben Sie die Möglichkeit „Instant Reporting“ in den Aktionen auszuwählen. Hier finden Sie eine einfache aber ausführliche Übersicht zu Ihrer
        jeweiligen Kampagne. Diese Berichte können Sie einfach und unkompliziert an die von Ihnen hinterlegte E-Mail-Adresse senden lassen. Wählen Sie dafür die Häufigkeit auf der Berichtseite im oberen Bereich unter <strong>Statistiken abonnieren</strong> an.`
      }
    ]
  },
  {
    icon: "fa fa-photo",
    title: "Werbemittel-Service",
    contents: [
      {
        title: "Was ist der Werbemittel-Service?",
        text: `Der Werbemittel-Service generiert für Ihre Buchung die passenden und optimierten Werbemittel für jeden Werbekanal, den Sie gewählt haben.<br>Sie haben im Anschluss die Möglichkeit diese Werbemittel zu überprüfen. Werbemittel die Ihnen nicht gefallen, können Sie löschen. Außerdem können Sie eigene Werbemittel zusätzlich hochladen.`
      },
      {
        title: "Was kann ich bewerben?",
        text: `Sie haben die Möglichkeit Werbemittel für die Bewerbung Ihres <strong>Unternehmens</strong> oder eines <strong>Produkts</strong> generieren zu lassen.`
      },
      {
        title: "Kann ich auch ein Event bewerben?",
        text: `Ja, es können auch Events beworben werden. Wählen Sie im Werbemittel-Service dafür den Punkt <strong>Produkt</strong> aus.`
      },
      {
        title: "Wie und in welchen Dateiformaten kann ich mein Logo und/oder Produktbild hinterlegen?",
        text: `Sie können Ihr Logo und Ihr Produktbild als URL hinterlegen, wenn Sie das Logo oder das Produktbild bereits auf eine Webseite hochgeladen haben.<br>
        Alternativ können Sie die Dateien bei uns hochalden per Drag & Drop oder als Auswahl von Ihrer Festplatte.<br>
        Das Logo und das Produktbild müssen im PNG- oder JPG-Format sein.`
      },
      {
        title: "Kann ich meinen Slogan für die Werbemittel hinterlegen?",
        text: `Ja, wenn Sie Ihr Unternehmen bewerben möchten, können Sie Ihren Slogan unter dem Punkt <strong>Slogan / Handlungsaufforderung</strong> hinterlegen.`
      },
      {
        title: "Kann ich vor dem endgültigen Anlegen der Kampagne die angegebenen und ausgewählten Daten noch einmal abschließend überprüfen?",
        text: `Ja, der letzte Schritt zum Anlegen einer Kampagne, beinhaltet eine Übersicht mit den von Ihnen angegebenen und gewählten Daten.`
      },
    ]
  },
  {
    icon: "fa fa-euro",
    title: "Bezahlung & Rechnung",
    contents: [
      {
        title: "Wie kann ich mein Guthaben aufladen und welche Zahlungsmöglichkeiten stehen zur Verfügung?",
        text: `<p>Sie können Ihr Guthaben über den Punkt <strong>Finanzen</strong> aufladen. Klicken Sie dafür auf das <strong>€</strong>-Symbol in der Navigation.</p>
        <ol><li>Geben Sie in dem Bereich <strong>Konto aufladen</strong> den gewünschten Betrag ein und klicken aus den gleichnamigen Button.</li>
        <li>Wenn Sie noch keine Rechnungsdaten eingegeben haben, dann werden Sie jetzt aufgefordert, dies nachzuholen</li>
        <li>Geben Sie danach Ihre Kreditkartendaten ein und klicken Sie auf <strong>Jetzt bezahlen</strong>.</li>
        <li>Das Guthaben wird Ihnen gutgeschrieben und Sie erhalten eine Bestätigung per E-Mail</li>
        <li>Eine Rechung wird generiert und steht zum download bereit</li></ol>`
      },
      {
        title: "Wo kann ich mein Guthaben einsehen?",
        text: `Ihr aktuelles Guthaben sehen Sie unter dem Punkt <strong>Finanzen</strong>. Klicken Sie dafür auf das <strong>€</strong>-Symbol in der Navigation.`
      },
      {
        title: "Erhalte ich eine Benachrichtigung wenn mein Budget aufgebraucht ist?",
        text: `Ja, wir benachrichtigen Sie hierzu per E-Mail. Des Weiteren erhalten Sie eine Benachrichtigung auf dem Dashboard.`
      }
    ]
  },
]

module.exports = faq;
