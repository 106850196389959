<template>
    <b-row class="bg-light pt-2">
        <b-col cols="12">
            <b-row>
                <b-col cols="12">
                    <b-breadcrumb>
                        <b-breadcrumb-item :active="currentFolder === null" @click.prevent="openFolder(null)" >
                            <b-icon icon="house-fill"></b-icon>
                            Root
                        </b-breadcrumb-item>
                        <b-breadcrumb-item :key="folder._id" @click.prevent="openFolder(folder)" v-for="folder in folders" :active="currentFolder !== null && currentFolder === folder._id">
                            {{ folder.name }}
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="form-group">
                    <label for="segments" class="form-label">Segments</label>
                    <v-select class="form-control" :options="segments" id="segments" label="name" @input="updateSegmentFilter">
                    </v-select>
                </b-col>
                <b-col class="form-group" v-if="isSysOp">
                    <label for="advertisers" class="form-label">Advertisers</label>
                    <v-select class="form-control" id="advertisers" :options="advertisers" label="name" @input="updateAdvertiserFilter">
                    </v-select>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>
import jwtDecode from 'jwt-decode';

export default {
    name: 'FilterGraphic',
    props: {
        folders: {
            type: Array,
            default: () => [],
            required: false
        },
        currentFolder: {
            default: null,
            required: true
        },
    },
    data() {
        return {
            segments: [],
            advertisers: []
        }
    },
    computed: {
        isSysOp: () =>
            jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
            "systemoperator"
                ? true
                : false,
    },
    methods: {
        openFolder(folder) {
            if (this.currentFolder === folder?._id) return;
            const idx = this.folders.indexOf(folder);
            if (idx === -1) this.$emit('openFolder', null);
            else this.$emit('openFolder', folder);
        },
        updateSegmentFilter(segment) {
            this.$emit('filter', 'segment', segment?._id);
        },
        updateAdvertiserFilter(advertiser) {
            this.$emit('filter', 'advertiser', advertiser?._id);
        }
    },
    async mounted() {
        this.segments = (await this.$api.segment.getAll())?.items || [];
        this.advertisers = (await this.$api.advertiser.getAll())?.items || [];
    }
    // data() {
    //     return {
    //         folders: [],
    //         foundFolders: [],
    //     }
    // },
    // computed: {
    //     currentFolder() {
    //         if (!this.folders.length) return null;
    //         return this.folders[this.folders.length - 1]?._id;
    //     }
    // },
    // async created() {
    //     this.getFolders();
    // },
    // methods: {
    //     async getFolders() {
    //         this.foundFolders = await this.$api.graphic.listFolders(this.currentFolder, {
    //             enableLoading: false,
    //         });
    //         this.$emit('filter', 'folder', this.currentFolder);
    //     }
    // }
}
</script>

<style>

</style>