import request from "@/services/axios";

const cache = {};

const api = {
  syncqueue: {
    getEntries(page = 0, quantity = 10, completed = false) {
      return request.get(
        `/sync-queue/entries?page=${page}&quantity=${quantity}&completed=${completed}`
      );
    },
    getEntryCount(completed = false) {
      return request.get(`/sync-queue/entries/count?completed=${completed}`);
    },
    getTree(id) {
      return request.get(`/sync-queue/${id}/tree`);
    },
    runJobs() {
      return request.get(`/sync-queue/run/kpoe4joidfakpjojafdgFHF26734`);
    },
    forceJob(id) {
      return request.get(`/sync-queue/force/${id}`);
    },
  },
  entity: {
    getEntities(entity, payload) {
      return request.get(`/${entity}`, {
        params: payload,
      });
    },
    getEntityCount(entity, payload) {
      return request.get(`/${entity}/count`, {
        params: payload,
      });
    },
    syncToChannel(channel, type, id) {
      return request.post(
        `/${channel}/sync/${type}/${id}`,
        {},
        { enableLoading: false }
      );
    },
  },
  approval: {
    review(type, id, payload) {
      return request.post(`/approval/review/${type}/${id}`, payload);},
    list (page, qty, filter) {
      return request.get(`/approval?qty=${qty}&page=${page}`, {
        params: filter
      });
    },
    count (filter) {
      return request.get(`/approval/count`, {
        params: filter
      });
    },
  },
  user: {
    getAll(query) {
      return request.get("/auth/logins", { params: query });
    },
    get(id = "") {
      if (id === "") return request.get("/auth/login");
      else return request.get(`/auth/login/${id}`);
    },
    update(user) {
      //return request.post('/auth/update', user);
      return request.patch("/auth/login", user, { enableLoading: false });
    },
    newpassword(user) {
      return request.post("/auth/newpassword", user);
    },
    create(user) {
      return request.post("/auth/create", user);
    },
    checkConfirm(token, password) {
      return request.post("/auth/checkconfirm", { token, password });
    },
    validToken(token) {
      return request.get("/auth/validtoken", { params: { token } });
    },
    getSubaccounts(query) {
      return request.get("/auth/subaccounts", { params: query });
    },
    getSubaccount(id) {
      return request.get(`/auth/subaccount/${id}`);
    },
    createSubaccount(user) {
      return request.post("/auth/subaccount", user);
    },
    updateSubaccount(user) {
      return request.patch(`/auth/subaccount/${user._id}`, user);
    },
    deleteSubaccount(id) {
      return request.delete(`/auth/subaccount/${id}`, null, {
        enableLoading: false,
      });
    },
  },
  trackingcode: {
    getAll() {
      return request.get("/trackingcode");
    },
    getByAdvertiserId(advertiserId) {
      return request.get(`/trackingcode?advertiser=${advertiserId}`);
    },
    get(id) {
      return request.get(`/trackingcode/${id}`);
    },
    create(trackingCode) {
      return request.post("/trackingcode", trackingCode);
    },
    update(trackingCode) {
      return request.patch(`/trackingcode/${trackingCode._id}`, trackingCode);
    },
    delete(id) {
      return request.delete(`/trackingcode/${id}`);
    },
  },
  dashboard: {
    getDashboard(payload) {
      if (payload.campaigns) {
        payload.campaigns = JSON.stringify(payload.campaigns);
      }
      const queryStr = Object.keys(payload)
        .map((key) => key + "=" + payload[key])
        .join("&");
      return request.get(`/dashboard?${queryStr}`, { enableLoading: false });
    },
    getAnalytics(payload) {
      if (payload.campaigns) {
        payload.campaigns = JSON.stringify(payload.campaigns);
      }
      const queryStr = Object.keys(payload)
        .map((key) => key + "=" + payload[key])
        .join("&");

      //return request.get(`/dashboard/analytics?start=${payload.startDate}&end=${payload.endDate}&campaigns=${JSON.stringify(payload.campaigns)}`, {enableLoading: false});
      return request.get(`/dashboard/analytics?${queryStr}`, {
        enableLoading: false,
      });
    },
  },
  search: {
    get(payload) {
      return request.get("search", { params: payload });
    },
  },
  streamconfig: {
    getByAdvertiser(advertiser) {
      return request.get(`/streaming/${advertiser}`);
    },
  },
  campaignlog: {
    getAll(payload) {
      return request.get("/campaignlog", { params: payload });
    },
    count(payload) {
      return request.get("/campaignlog/count", { params: payload });
    },
    versions(payload) {
      return request.get("/campaignlog/versions", {
        params: payload,
        enableLoading: false,
      });
    },
  },
  campaign: {
    updateDomainlist(id, domainlistDto) {
      return request.patch(`/campaign/${id}/updatedomainlist`, domainlistDto);
    },
    getAll(filterdto = {}, options = {}) {
      return request.get("/campaign", { params: filterdto, ...options });
    },
    getCreatives(id) {
      return request.get(`/campaign/conversions/${id}/creatives`);
    },
    getConversions(filterDto) {
      return request.get(`/campaign/conversions`, { params: filterDto });
    },
    getClicks(filterDto) {
      return request.get(`/campaign/clicks`, { params: filterDto });
    },
    saveConversionChange(id, body) {
      return request.post(`/campaign/conversions/${id}`, body);
    },
    saveClickChange(id, body) {
      return request.post(`/campaign/clicks/${id}`, body);
    },
    bulkSaveConversionChange(id, body) {
      return request.post(`/campaign/conversions/${id}/bulk`, body);
    },
    getCampaignReport(payload, options) {
      const campaignId = payload.id;
      delete payload.id;
      // const queryStr = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');

      const query = [];

      for (let key of Object.keys(payload)) {
        if (typeof payload[key] == "object")
          query.push(key + "=" + JSON.stringify(payload[key]));
        else query.push(key + "=" + payload[key]);
      }

      return request.get(
        `/campaign/${campaignId}/report?${query.join("&")}`,
        options
      );
    },
    getByAdvertiser(advertiser) {
      return request.get(`/campaign?advertiser=${advertiser}`);
    },
    getByBooking(booking) {
      return request.get(`/campaign?booking=${booking}`);
    },
    get(id, options) {
      return request.get(`/campaign/${id}`, options);
    },
    getExt(payload, options) {
      const queryStr = Object.keys(payload)
        .map((key) => key + "=" + payload[key])
        .join("&");
      return request.get(`/campaign?${queryStr}`, options);
    },
    search(searchTerm, options) {
      return request.get(
        `/campaign?entity=campaign&search=${searchTerm}`,
        options
      );
    },
    generateTopReferer(campaign) {
      return request.get(`/topreferer/generate/${campaign._id}`, {
        enableLoading: false,
      });
    },
    generateTopProducts(campaign) {
      return request.get(`/topproduct/generate/${campaign._id}`, {
        enableLoading: false,
      });
    },
    create(campaign) {
      return request.post("/campaign", campaign);
    },
    update(campaign) {
      return request.patch(`/campaign/${campaign._id}`, campaign);
    },
    getPixel(campaignId) {
      return request.get(`/campaign/${campaignId}/pixel`, {
        enableLoading: false,
      });
    },
    getMultiple(campaignArr, fields = []) {
      return request.get(
        `/campaign/multiple?campaigns=${JSON.stringify(
          campaignArr
        )}&fields=${JSON.stringify(fields)}`
      );
    },
    delete(id) {
      return request.delete(`/campaign/${id}`);
    },
    pause(id) {
      return request.get(`/campaign/pause/${id}`);
    },
    resume(id) {
      return request.get(`/campaign/resume/${id}`);
    },
    cancel(id) {
      return request.patch(`/campaign/cancel/${id}`);
    },
  },
  channels: {
    mapMarkers(endpoint, markers, display) { // display for gads
      const key = markers.map((m) => m.properties.name).join("");
      const cached = cache[endpoint]?.[key];
      if (!cached)
        cache[endpoint] = {
          [key]: request.post(`${endpoint}/map/markers${typeof display !== "undefined" ? '?display=' + display : ''}`, markers),
        };
      return cache[endpoint][key];
    },
    reachestimate(endpoint, targeting) {
      return request.post(`${endpoint}/reachestimate`, targeting);
    },
  },
  advertiser: {
    getAll() {
      return request.get("/advertiser");
    },
    get(id) {
      return request.get(`/advertiser/${id}`);
    },
    getBalance(id) {
      return request.get(`/advertiser/balance/${id}`);
    },
    create(advertiser) {
      return request.post("/advertiser", advertiser);
    },
    update(advertiser) {
      return request.patch(`/advertiser/${advertiser._id}`, advertiser);
    },
    payment(id, vatHandle) {
      return request.post(`/advertiser/payment/pay?vatHandle=${vatHandle}`, {
        id: id,
      });
    },
    secret(amount, vatHandle) {
      return request.post("/advertiser/payment/secret?vatHandle=" + vatHandle, {
        amount: amount,
      });
    },
    delete(id) {
      return request.delete(`/advertiser/${id}`);
    },
    countries() {
      return request.get(`/advertiser/countries`);
    },
    search(searchTerm, options) {
      return request.get(
        `/advertiser?entity=advertiser&search=${searchTerm}`,
        options
      );
    },
    transaction(transactionDto) {
      return request.post(`/advertiser/transaction`, transactionDto);
    },
  },
  publisher: {
    getAll() {
      return request.get("/publisher");
    },
    get(id) {
      return request.get(`/publisher/${id}`);
    },
    create(publisher) {
      return request.post("/publisher", publisher);
    },
    update(publisher) {
      return request.patch(`/publisher/${publisher._id}`, publisher);
    },
    delete(id) {
      return request.delete(`/publisher/${id}`);
    },
    getInvitations(){
      return request.get(`/publisher/invitations`);
    },
    getCampaigns(payload){
      return request.get(`/publisher/campaigns`, {
        params: payload,
      });
    },
    getDashboard(payload){
      const queryStr = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    return request.get(`/publisher/dashboard?${queryStr}`, { enableLoading: false });
    },
    replyInvitation(id, status){
      return request.patch(`/publisher/invitation/${id}/${status}`);
    },
    getCampaignReport(payload, options) {
      const campaignId = payload.id;
      delete payload.id;
      // const queryStr = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');

      const query = [];

      for (let key of Object.keys(payload)) {
        if (typeof payload[key] == "object")
          query.push(key + "=" + JSON.stringify(payload[key]));
        else query.push(key + "=" + payload[key]);
      }

      return request.get(
        `/publisher/${campaignId}/report?${query.join("&")}`,
        options
      );
    },
    getBalance() {
      return request.get(`/publisher/balance`);
    },
    getWebsiteData(domain){
      return request.get(`/publisher/websitedata?domain=${domain}`);
    }
  },
  videoTemplate: {
    getAll() {
      return request.get("/richvideotemplate");
    },
    delete(id) {
      return request.delete(`/richvideotemplate/${id}`);
    },
  },
  vertical: {
    getAll() {
      return request.get("/vertical");
    },
    get(id) {
      return request.get(`/vertical/${id}`);
    },
    create(vertical) {
      return request.post("/vertical", vertical);
    },
    update(vertical) {
      return request.patch(`/vertical/${vertical._id}`, vertical);
    },
    delete(id) {
      return request.delete(`/vertical/${id}`);
    },
  },
  targeting: {
    getCountries() {
      return request.get("/targeting/country");
    },
    getCategories() {
      return request.get("/targeting/category");
    },
  },
  googleads: {
    generateKeywords(url) {
      return request.get(`/google-ads/keywords?url=${encodeURIComponent(url)}`);
    },
  },
  wizard: {
    get(id) {
      return request.get(`/wizard/${id}`);
    },
    create(wizard) {
      return request.post("/wizard", wizard);
    },
    update(id, wizard) {
      return request.patch(`/wizard/${id}`, wizard);
    },
    setWizardLive(entity) {
      return request.get(`/wizard/setlive/${entity._id}`, {
        enableLoading: false,
      });
    },
    delete(id) {
      return request.delete(`/wizard/${id}`);
    },
  },
  contract: {
    getAll() {
      return request.get("/contract");
    },
    get(id) {
      return request.get(`/contract/${id}`);
    },
    getByCampaignId(campaignId) {
      return request.get(
        `/contract?campaign=${campaignId?._id ? campaignId?._id : campaignId}`,
        { enableLoading: false }
      );
    },
    create(contract) {
      return request.post("/contract", contract);
    },
    update(contract) {
      return request.patch(`/contract/${contract._id}`, contract);
    },
    delete(id) {
      return request.delete(`/contract/${id}`);
    },
    search(searchTerm, options) {
      return request.get(
        `/contract?entity=contract&search=${searchTerm}`,
        options
      );
    },
  },
  transaction: {
    getAll() {
      return request.get("/transactions");
    },
    get(id) {
      return request.get(`/transaction/${id}`);
    },
    create(transaction) {
      return request.post("/transaction", transaction);
    },
    update(transaction) {
      return request.patch(`/transaction/${transaction._id}`, transaction);
    },
    delete(id) {
      return request.delete(`/transaction/${id}`);
    },
    search(payload) {
      return request.get("/transaction/bq", {
        params: payload,
      });
    },
  },
  catalog: {
    getAll() {
      return request.get("/catalog");
    },
    get(id) {
      return request.get(`/catalog/${id}`);
    },
    create(catalog) {
      return request.post("/catalog", catalog);
    },
    update(catalog) {
      return request.patch(`/catalog/${catalog._id}`, catalog);
    },
    cancelImport(catalog) {
      return request.get(`/catalog/cancelimport/${catalog._id}`, {
        enableLoading: false,
      });
    },
    countProducts(catalog) {
      return request.get(`/product/count`, {
        enableLoading: false,
        catalog: catalog._id,
      });
    },
    deleteProducts(catalog) {
      return request.delete(`/product/catalog/${catalog._id}`, {
        enableLoading: false,
      });
    },
    importProducts(catalog) {
      return request.get(`/catalog/import/${catalog._id}`, {
        enableLoading: false,
      });
    },
    delete(id) {
      return request.delete(`/catalog/${id}`);
    },
  },
  richVideo: {
    getAll() {
      return request.get("/richvideo");
    },
    get(id) {
      return request.get(`/richvideo/${id}`);
    },
    create(richVideo) {
      return request.post("/richvideo", richVideo);
    },
    update(richVideo) {
      return request.patch(`/richvideo/${richVideo._id}`, richVideo);
    },
    delete(id) {
      return request.delete(`/richvideo/${id}`);
    },
  },
  topproduct: {
    get(id) {
      return request.get(`/topproduct/${id}`);
    },
    create(topproduct) {
      return request.post("/topproduct", topproduct);
    },
    update(topproduct) {
      return request.patch(`/topproduct/${topproduct._id}`, topproduct);
    },
  },
  product: {
    get(id) {
      return request.get(`/product/${id}`);
    },
    getByCampaignId(campaignId) {
      return request.get(
        `/product?campaign=${campaignId?._id ? campaignId?._id : campaignId}`
      );
    },
    create(product) {
      return request.post("/product", product);
    },
    update(product) {
      return request.patch(`/product/${product._id}`, product);
    },
  },
  vast: {
    getAll() {
      return request.get("/vast");
    },
    get(id) {
      return request.get(`/vast/${id}`);
    },
    getByCampaignId(campaignId, options) {
      return request.get(
        `/vast?campaign=${campaignId?._id ? campaignId?._id : campaignId}`,
        options
      );
    },
    create(vast) {
      return request.post("/vast", vast);
    },
    update(vast) {
      return request.patch(`/vast/${vast._id}`, vast);
    },
    delete(id) {
      return request.delete(`/vast/${id}`);
    },
  },
  banner: {
    getAll(filterdto = {}, options = {}) {
      return request.get("/banner", { params: filterdto, ...options });
    },
    get(id, options) {
      return request.get(`/banner/${id}`, options);
    },
    getByCampaignId(campaignId, options) {
      return request.get(
        `/banner?campaign=${campaignId?._id ? campaignId?._id : campaignId}`,
        options
      );
    },
    getByAdvertiser(advertiserId, options) {
      return request.get(
        `/banner?advertiser=${
          advertiserId?._id ? advertiserId?._id : advertiserId
        }`,
        options
      );
    },
    create(banner) {
      return request.post("/banner", banner);
    },
    update(banner) {
      return request.patch(`/banner/${banner._id}`, banner);
    },
    delete(id) {
      return request.delete(`/banner/${id}`);
    },
    search(searchTerm, options) {
      return request.get(
        `/banner?entity=banner&search=${searchTerm}`,
        options
      );
    },
  },
  facebook: {
    search(dto) {
      return request.post("/facebook/search", dto);
    },
  },
  bannerbear: {
    getTemplates(filter) {
      return request.get(`/bannerbear/template`, { params: filter });
    },
    getTemplate(uid) {
      return request.get(`/bannerbear/template/${uid}`);
    },
    getBanners(filter) {
      return request.get(`/bannerbear/banner`, { params: filter });
    },
    getBanner(uid) {
      return request.get(`/bannerbear/banner/${uid}`);
    },
    createImage(data) {
      return request.post("/bannerbear/image", data);
    },
    createVideo(data) {
      return request.post("/bannerbear/video", data);
    },
    createCollection(data) {
      return request.post("/bannerbear/collection", data);
    },
  },
  lineitem: {
    getAll(filterdto = {}, options = {}) {
      return request.get("/lineitem", { params: filterdto, ...options });
    },
    get(id) {
      return request.get(`/lineitem/${id}`);
    },
    getByCampaign(campaignId) {
      return request.get(`/lineitem?campaign=${campaignId}`);
    },
    create(lineItem) {
      return request.post("/lineitem", lineItem);
    },
    update(lineItem) {
      return request.patch(`/lineitem/${lineItem._id}`, lineItem);
    },
    changeStatus(id, status) {
      return request.patch(
        `/lineitem/status/${id}/${status}`,
        {},
        { enableLoading: false }
      );
    },
    delete(id) {
      return request.delete(`/lineitem/${id}`);
    },
    getMonitoring(filterDto){
      return request.get("/lineitem/monitoring", { params: filterDto });
    },
    search(searchTerm, options) {
      return request.get(
        `/lineitem?entity=lineitem&search=${searchTerm}`,
        options
      );
    },
  },
  host: {
    get(id) {
      return request.get(`/host/${id}`);
    },
    create(host) {
      return request.post("/host", host);
    },
    update(host) {
      return request.patch(`/host/${host._id}`, host);
    },
    delete(id) {
      return request.delete(`/host/${id}`);
    },
  },
  domainlist: {
    getAll() {
      return request.get(`/domainlist/`);
    },
    getByAdvertiserId(advertiserId) {
      return request.get(`/domainlist?advertiser=${advertiserId}`);
    },
    get(id) {
      return request.get(`/domainlist/${id}`);
    },
    create(domainlist, advertiser = "") {
      return request.post("/domainlist/" + advertiser, domainlist);
    },
    update(domainlist) {
      return request.patch(`/domainlist/${domainlist._id}`, domainlist);
    },
    delete(id) {
      return request.delete(`/domainlist/${id}`);
    },
  },
  blogpost: {
    getAll() {
      return request.get(`/blogpost/`);
    },
    getByAdvertiserId(advertiserId) {
      return request.get(`/blogpost?advertiser=${advertiserId}`);
    },
    get(id) {
      return request.get(`/blogpost/${id}`);
    },
    create(blogpost, advertiser = "") {
      return request.post("/blogpost/" + advertiser, blogpost);
    },
    update(blogpost) {
      return request.patch(`/blogpost/${blogpost._id}`, blogpost);
    },
    delete(id) {
      return request.delete(`/blogpost/${id}`);
    },
  },
  crr: {
    get(id) {
      return request.get(`/crr/${id}`);
    },
    create(crr) {
      return request.post("/crr", crr);
    },
    update(crr) {
      return request.patch(`/crr/${crr._id}`, crr);
    },
  },
  segment: {
    getAll(name, options) {
      const query = new URLSearchParams();
      query.append('search', name);
      return !name ? request.get("/segment", { ...options }) : request.get(`/segment?${encodeURIComponent(query.toString())}`, { ...options });
    },
    get(id) {
      return request.get(`/segment/${id}`);
    },
    create(segment, options) {
      return request.post("/segment", segment, { ...options });
    },
    update(segment) {
      return request.patch(`/segment/${segment._id}`, segment);
    },
    delete(id) {
      return request.delete(`/segment/${id}`);
    },
  },
  bannertemplate: {
    get(id) {
      return request.get(`/bannertemplate/${id}`);
    },
    create(bannertemplate) {
      return request.post("/bannertemplate", bannertemplate);
    },
    update(bannertemplate) {
      return request.patch(`/bannertemplate/${bannertemplate._id}`, bannertemplate);
    },
    delete(id) {
      return request.delete(`/bannertemplate/${id}`);
    },
  },
  ci: {
    get(id) {
      return request.get(`/ci/${id}`);
    },
    create(ci) {
      return request.post("/ci", ci);
    },
    update(ci) {
      return request.patch(`/ci/${ci._id}`, ci);
    },
    delete(id) {
      return request.delete(`/ci/${id}`);
    },
  },
  audience: {
    get(id) {
      return request.get(`/audience/${id}`);
    },
    getByCampaignId(campaignId) {
      return request.get(
        `/audience?campaign=${campaignId?._id ? campaignId?._id : campaignId}`
      );
    },
    create(audience) {
      return request.post("/audience", audience);
    },
    update(audience) {
      return request.patch(`/audience/${audience._id}`, audience);
    },
    delete(id) {
      return request.delete(`/audience/${id}`);
    },
  },
  mediaplan: {
    get(id) {
      return request.get(`/mediaplan/${id}`);
    },
    create(mediaPlan) {
      return request.post("/mediaplan", mediaPlan);
    },
    update(mediaPlan) {
      return request.patch(`/mediaplan/${mediaPlan._id}`, mediaPlan);
    },
    delete(id) {
      return request.delete(`/mediaplan/${id}`);
    },
  },
  accesslog: {
    getAccesslogs(payload) {
      return request.get(
        `/accesslog/dashboard?start=${payload.startDate}&end=${payload.endDate}&limit=${payload.limit}`,
        { enableLoading: false }
      );
    },
  },
  optimizer: {
    get(id) {
      return request.get(`/optimizer/${id}`);
    },
    getDashboard(payload) {
      return request.get(
        `/optimizer/dashboard?start=${payload.startDate}&end=${payload.endDate}`
      );
    },
    getDashboard2(payload) {
      return request.get(
        `/optimizer/dashboard2?start=${payload.startDate}&end=${payload.endDate}&campaign=${payload.campaign.join(',')}&ignoresticky=${payload.ignoresticky}&revenueSource=${payload.revenueSource}`
      );
    },
    getCampaigns() {
      return request.get(
        `/optimizer/campaigns`
      );
    },
    calculateMct(payload){
      return request.get(
        `/optimizer/calculateMct/${payload.campaignId}?start=${payload.start}&end=${payload.end}&salesSuite=${payload.salesSuite}&salesShop=${payload.salesShop}&mct=${payload.mct}&step=${payload.step}`
      );
    },
    updateMct(optimizerId, mct){
      return request.patch(`/optimizer/mct/${optimizerId}`, { mct });
    },
    create(optimizer) {
      return request.post("/optimizer", optimizer);
    },
    update(optimizer) {
      return request.patch(`/optimizer/${optimizer._id}`, optimizer);
    },
  },
  splitter: {
    get(id) {
      return request.get(`/splitter/${id}`);
    },
    create(splitter) {
      return request.post("/splitter", splitter);
    },
    update(splitter) {
      return request.patch(`/splitter/${splitter._id}`, splitter);
    },
  },
  graphic: {
    getAll() {
      return request.get("/graphic");
    },
    get(id) {
      return request.get(`/graphic/${id}`);
    },
    getByAdvertiser(advertiser) {
      return request.get(`/graphic?advertiser=${advertiser}`);
    },
    create(graphic) {
      return request.post("/graphic", graphic);
    },
    update(graphic) {
      return request.patch(`/graphic/${graphic._id}`, graphic);
    },
    delete(id) {
      return request.delete(`/graphic/${id}`);
    },
    uploadBase64(graphic) {
      return request.post("graphic/uploadb64", graphic);
    },
    search(name, options) {
      return request.get(
        `/graphic?search=${encodeURIComponent(name)}`,
        options
      );
    },
    getFolders(options) {
      return request.get(
        '/graphic-folder',
        options
      )
    },
    createFolder(name, parent, options) {
      return request.post(
        '/graphic-folder',
        {
          name,
          parent
        },
        options
      )
    },
    updateFolder(id, name, parent, options) {
      return request.patch(
        '/graphic-folder/' + encodeURIComponent(id),
        {
          name,
          parent
        },
        options
      )
    },
    deleteFolder(id, options) {
      return request.delete(
        '/graphic-folder/' + encodeURIComponent(id),
        options
      )
    },
    listFolders(parent, options) {
      return request.get(
        `/graphic-folder${ parent ? `?parent=${encodeURIComponent(parent)}` : '' }`,
        options
      )
    },
    getFolder(id, options) {
      return request.get(
        `/graphic-folder/${id}`,
        options
      )
    }
  },
  audio: {
    get(id) {
      return request.get(`/audio/${id}`);
    },
    create(audio) {
      return request.post("/audio", audio);
    },
    update(audio) {
      return request.patch(`/audio/${audio._id}`, audio);
    },
    delete(id) {
      return request.delete(`/audio/${id}`);
    },
  },
  video: {
    get(id) {
      return request.get(`/video/${id}`);
    },
    getByAdvertiser(advertiser) {
      return request.get(`/video?advertiser=${advertiser}`);
    },
    create(video) {
      return request.post("/video", video);
    },
    update(video) {
      return request.patch(`/video/${video._id}`, video);
    },
    delete(id) {
      return request.delete(`/video/${id}`);
    },
    search(name, options) {
      return request.get(`/video?search=${encodeURIComponent(name)}`, options);
    },
  },
  coupon: {
    get(id) {
      return request.get(`/coupon/${id}`);
    },
    advertisers(id, options = {}) {
      return request.get(`/coupon/advertisers/${id}`, options);
    },
    create(coupon) {
      return request.post("/coupon", coupon);
    },
    update(coupon) {
      return request.patch(`/coupon/${coupon._id}`, coupon);
    },
    delete(id) {
      return request.delete(`/coupon/${id}`);
    },
    use(code) {
      return request.post(`/coupon/use`, {
        code: code,
      });
    },
  },
  creditnote: {
    download(id) {
      return request.get(`/creditnote/download/${id}`, {
        responseType: 'arraybuffer'
      });
    },
    list(advertiser_id, quantity = 10, offset = 0) {
      return request.get(`/creditnote/list/${advertiser_id}`, {
        params: {
          quantity,
          offset
        }
      });
    },
    search(params) {
      return request.get(`/creditnote/search`, { params });
    },
    count() {
      return request.get(`/creditnote/count`);
    },
    status(params) {
      return request.patch(`/creditnote/status`, params);
    },
  },
  invoice: {
    download(id) {
      return request.get(`/invoice/download/${id}`, {
        responseType: "arraybuffer",
      });
    },
    list(advertiser_id, quantity = 10, offset = 0) {
      return request.get(`/invoice/list/${advertiser_id}`, {
        params: {
          quantity,
          offset,
        },
      });
    },
    search(params) {
      return request.get(`/invoice/search`, { params });
    },
    count() {
      return request.get(`/invoice/count`);
    },
    status(params) {
      return request.patch(`/invoice/status`, params);
    },
  },
  booking: {
    get(id) {
      return request.get(`/booking/${id}`);
    },
    statuses(ids) {
      return request.post(`/booking/status`, { ids }, { enableLoading: false });
    },
    create(campaign) {
      return request.post("/booking", campaign);
    },
    update(id, campaign) {
      return request.patch(`/booking/${id}`, campaign);
    },
    changeStatus(id, status) {
      return request.patch(
        `/booking/status/${id}/${status}`,
        {},
        { enableLoading: false }
      );
    },
    getByTemplate(id, options) {
      return request.get(`/booking?bookingtemplate=${id}`, {}, options);
    },
    deleteCreative(bookingId, creativeId) {
      return request.delete(`/booking/${bookingId}/creative/${creativeId}`);
    },
    delete(id) {
      return request.delete(`/booking/${id}`);
    },
    search(searchTerm, options) {
      return request.get(
        `/booking?entity=booking&search=${searchTerm}`,
        options
      );
    },
  },
  bookingtemplate: {
    getAll() {
      return request.get("/bookingtemplate");
    },
    get(id) {
      return request.get(`/bookingtemplate/${id}`);
    },
    create(bookingTemplate) {
      return request.post("/bookingtemplate", bookingTemplate);
    },
    update(bookingTemplate) {
      return request.patch(
        `/bookingtemplate/${bookingTemplate._id}`,
        bookingTemplate
      );
    },
    delete(id) {
      return request.delete(`/bookingtemplate/${id}`);
    },
  },
  setting: {
    get(id) {
      return request.get(`/setting/${id}`);
    },
    create(setting) {
      return request.post("/setting", setting);
    },
    update(setting) {
      return request.patch(`/setting/${setting._id}`, setting);
    },
  },
  bannerTemplate: {
    get(id) {
      return request.get(`/banner-template/${id}`);
    },
    getAll() {
      return request.get("/banner-template");
    },
    create(banner) {
      return request.post("/banner-template", banner);
    },
    delete(id) {
      return request.delete(`/banner-template/${id}`);
    },
  },
  helpers: {
    validateImage(url) {
      return request.get(`/image-validator?url=${url}`);
    },
  },
  gads: {
    getVerticals(){
      return request.get("/google-ads/verticals");
    }
  },
  google: {
    getCategories() {
      return request.get("/dv360/googlecategories");
    },
    getLink(id) {
      return request.get(`/dv360/getlink/${id}`);
    }
  },
  assignment: {
    push(id){
      return request.get(`/assignment/fromassignment/${id}`, { enableLoading: false });
    }
  }
};

const apiProxy = (obj, path = "") => {
  Object.entries(obj).forEach(([key, func]) => {
    if (typeof func !== "function")
      return apiProxy(func, `${path}${path ? "." : ""}${key}`); // func is not a function, deep recursive
    obj[key] = async (...args) => {
      try {
        const result = await func(...args);
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line
          console.log(
            `API ${path}${path ? "." : ""}${key}, METHOD: ${
              result.config.method
            }, STATUS: ${result.status}, RESULT:`,
            result
          );
        }
        return result.data;
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          if (err.response) {
            /* console.error(
              `API ${path}${path ? "." : ""}${key}, STATUS: ${
                err.response.status
              }, ERROR:`,
              err
            ); */
          } else if (err.request) {
            /* console.error(
              `API ${path}${path ? "." : ""}${key}, EMPTY_RESPONSE, ERROR:`,
              err
            ); */
          } else {
            /* console.error(
              `API ${path}${path ? "." : ""}${key}, CONFIG_ERROR, ERROR:`,
              err
            ); */
          }
        }
        throw err;
      }
    };
  });
  return api;
};

export default apiProxy(api);
