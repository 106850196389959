<template>
  <div class="dba">
    <DashboardAnalyticsTopChart v-if="Object.keys(stats.daily).length > 1" :stats="dailyStats" />
    <div v-if="overview" class="row">
      <DashboardAnalyticsBox
        :title="`Top ${$tc('general.template', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
        :footerText="((templateOverview) ? $t('actions.hidetemplateoverview') : $t('actions.showtemplateoverview'))"
        @footerAction="switchView('templateOverview')"
        :tableData="topTemplates.data"
        :tableFields="topTemplates.fields"
        :size="6"
        class="pb-4"
      />
      <DashboardAnalyticsBox
        :title="`Top ${$tc('general.booking', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
        :footerText="((campaignOverview) ? $t('actions.hidebookingoverview') : $t('actions.showbookingoverview'))"
        @footerAction="switchView('campaignOverview')"
        :tableData="topCampaigns.data"
        :tableFields="topCampaigns.fields"
        :size="6"
        class="pb-4"
      />
    </div>
    <div class="row">
      <div v-if="loading" class="flip-square-loader mb-4"></div>
    </div>
    <div v-if="templateOverview" class="row">
      <DashboardAnalyticsBookingTemplateBox
        v-for="(template, index) in templates"
        :key="index"
        :template="template"
      />
    </div>
    <div v-if="campaignOverview" class="row">
      <DashboardAnalyticsBookingCampaignBox
        v-for="(campaign, index) in campaigns"
        :key="index"
        :campaign="campaign"
      />
    </div>
    <div v-if="overview" class="row">
      <DashboardAnalyticsBox
        :title="`Top ${$tc('general.website', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
        :footerText="((websiteOverview) ? $t('actions.hidewebsiteoverview') : $t('actions.showwebsiteoverview'))"
        @footerAction="switchView('websiteOverview')"
        :tableData="topWebsites.data"
        :tableFields="topWebsites.fields"
        :size="12"
        class="pb-4"
      />
    </div>
    <div class="row pb-4" v-else>
      <div class="col">
        <div class="card">
          <div class="card-body p-3">
            <em>{{ $t("messages.nostats") }}</em>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="loadingWebsites" class="flip-square-loader mb-4"></div>
    </div>


    <div class="row" v-if="websiteOverview">
      <div class="col-md-12 grid-margin">
        <div class="card">
          <div class="card-body smallpadding">
            <h4 class="card-title">
              {{ $tc('general.website', 2) }}
              <small>({{websitesFull.data.length}})</small>
            </h4>
            <b-pagination
              class="float-right"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="rowsPerPage"
              aria-controls="wbox-table"
            ></b-pagination>
            <div class="table-responsive fulltable">
              <b-table
                id="wbox-table"
                striped
                hover
                :items="websitesFull.data"
                :fields="websitesFull.fields"
                :current-page="currentPage"
                :per-page="rowsPerPage"
              >
                <template #cell(impressions)="data">
                  <span v-if="data.value == '' && data.value !== 0">-</span>
                  <span v-else v-b-tooltip.hover :title="$n(data.value)">
                    <NumberAbbreviated :value="data.value" />
                  </span>
                </template>

                <template #cell(clicks)="data">
                  <span v-if="data.value == '' && data.value !== 0">-</span>
                  <span v-else v-b-tooltip.hover :title="$n(data.value)">
                    <NumberAbbreviated :value="data.value" />
                  </span>
                </template>

                <template #cell(ctr_calc)="data">
                  {{ getCTR(data.item.impressions, data.item.clicks, 2) }} %
                </template>

                <template #cell(conversions)="data">
                  <span v-if="data.item.pvConversions+data.item.pcConversions == '0'">0</span>
                  <span v-else :title="`PC: ${$n(data.item.pcConversions)} | PV: ${$n(data.item.pvConversions)}`" v-b-tooltip.hover
                    >{{ data.item.pvConversions+data.item.pcConversions }}
                  </span>
                </template>

                <template #cell(name)="data">
                  <span v-if="data.item.isapp === true">
                    <i class="fa fa-mobile" aria-hidden="true"></i>
                  </span>
                  {{data.value}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberAbbreviated from "@/components/NumberAbbreviated";
import DashboardAnalyticsTopChart from "./components/DashboardAnalyticsTopChart";
import DashboardAnalyticsBox from "./components/DashboardAnalyticsBox";
import DashboardAnalyticsBookingTemplateBox from "./components/DashboardAnalyticsBookingTemplateBox";
import DashboardAnalyticsBookingCampaignBox from "./components/DashboardAnalyticsBookingCampaignBox";
//import _cloneDeep from "lodash.clonedeep";
//import CHANNELS from "@/constants/channels.js";

export default {
  name: "DashboardAnalytics",
  props: {
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      overview: false,
      templateOverview: false,
      campaignOverview: false,
      websiteOverview: false,

      currentPage: 1,
      rowsPerPage: 10,

      loading: false,
      loadingWebsites: false,
      templates: [],
      campaigns: [],
      websites: []
    };
  },
  computed: {
    totalRows() {
      return this.websitesFull.data.length;
    },
    dailyStats(){
      return this.stats.daily;
    },
    topTemplates() {
      let fields = [
        { key: "name", label: this.$tc("general.template", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right" }
      ];

      return {
        fields,
        data: this.stats.templates
      };
    },
    topCampaigns() {
      let fields = [
        { key: "name", label: this.$tc("general.booking", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right" }
      ];

      // add internal link
      for(let c = 0; c < this.stats.campaigns.length; c++){
        let cObj = this.stats.campaigns[c];
        cObj.link = `/campaign/stats/${cObj.id}`;
      }

      return {
        fields,
        data: this.stats.campaigns
      };
    },
    topWebsites() {
      let fields = [
        { key: "name", label: this.$tc("general.website", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "ctr_calc", label: "CTR", class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right" }
      ];

      return {
        fields,
        data: this.stats.websites
      };
    },
    websitesFull() {
      let fields = [
        { key: "name", label: this.$tc("general.website", 1), sortable: true },
        {
          key: "impressions",
          label: this.$tc("general.view", 2),
          class: "text-right",
          sortable: true
        },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right", sortable: true },
        { key: "ctr_calc", label: "CTR", class: "text-right", sortable: true },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right" }
      ];

      return {
        fields: fields,
        data: this.websites
      };
    },
    templateCampaigns() {
      let allCampaigns = [];
      for (let t = 0; t < this.stats.length; t++) {
        for (let c = 0; c < this.stats[t].campaigns.length; c++) {
          let tCampaign = this.stats[t].campaigns[c];
          tCampaign.ctr = this.getCTR(
            tCampaign.impressions,
            tCampaign.clicks,
            2
          );
          allCampaigns.push(tCampaign);
        }
      }

      return allCampaigns.sort(function(a, b) {
        return b.ctr - a.ctr;
      });
    }
  },
  methods: {
    async switchView(newView) {
      //this.overview = false;

      if (this[newView]) {
        this[newView] = false;
      } else {
        this.templateOverview = false;
        this.campaignOverview = false;
        this.websiteOverview = false;

        if (newView == "templateOverview") {
          if (this.templates.length == 0) {
            this.loading = true;

            const result = await this.$parent.getDashboardStats(
              false,
              false,
              "templates"
            );
            if (result !== false) {
              this.templates = result;
            }

            this.loading = false;
          }
        } else if (newView == "campaignOverview") {
          if (this.campaigns.length == 0) {
            this.loading = true;

            const result = await this.$parent.getDashboardStats(
              false,
              false,
              "campaigns"
            );
            if (result !== false) {
              this.campaigns = result;
            }

            this.loading = false;
          }
        } else if (newView == "websiteOverview") {
          if (this.websites.length == 0) {
            this.loadingWebsites = true;

            this.websites = await this.$api.dashboard.getAnalytics({
              start: this.$parent.dateRange.startDate,
              end: this.$parent.dateRange.endDate,
              campaigns: this.$parent.selectedCampaigns,
              details: "websites"
            });
            this.loadingWebsites = false;
          }
        }

        this[newView] = true;
      }
    },
    getCTR(views, clicks, precision) {
      let ctr = 0;
      if (views > 0) {
        ctr = Number(((clicks * 100) / views).toFixed(precision));
      }

      return ctr;
    },
    getRandomInt(x, y) {
      return Math.floor(Math.random() * (y - x + 1)) + x;
    }
  },
  created() {
    if (
      this.topCampaigns.data.length > 0 ||
      this.topTemplates.data.length > 0 ||
      this.topWebsites.data.length > 0
    ) {
      this.overview = true;
    }
  },
  components: {
    DashboardAnalyticsTopChart,
    DashboardAnalyticsBox,
    DashboardAnalyticsBookingTemplateBox,
    DashboardAnalyticsBookingCampaignBox,
    NumberAbbreviated
  }
};
</script>

<style>
.dba .card-body.smallpadding {
  padding: 20px 15px 10px !important;
}
</style>