<template>
  <b-modal :id="modalId" :title="`Changes for ${name}`" size="lg" ok-only>
    <table class="table">
      <tr>
        <th>Key name</th>
        <th>Old value</th>
        <th>New value</th>
      </tr>
      <tr>
        <td>
          Name
          <span
            class="badge"
            :class="changes.name ? 'badge-danger' : 'badge-success'"
            >{{ changes.name ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.name ? '1' : '2'">
          {{ current.name }}
        </td>
        <td v-if="changes.name">
          {{ changes.name }}
        </td>
      </tr>
      <tr>
        <td>
          Campaign
          <span
            class="badge"
            :class="changes.campaign ? 'badge-danger' : 'badge-success'"
            >{{ changes.campaign ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.campaign ? '1' : '2'">
          {{ current.campaign._id }} ({{ current.campaign.name }})
        </td>
        <td v-if="changes.campaign">
          {{ changes.campaign._id }}<br />({{ changes.campaign.name }})
        </td>
      </tr>
      <tr>
        <td>
          Booking
          <span
            class="badge"
            :class="changes.booking ? 'badge-danger' : 'badge-success'"
            >{{ changes.booking ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.booking ? '1' : '2'">
          {{ current.booking._id }} ({{ current.booking.name }})
        </td>
        <td v-if="changes.booking">
          {{ changes.booking._id }}<br />({{ changes.booking.name }})
        </td>
      </tr>
      <tr>
        <td>
          Bid Type
          <span
            class="badge"
            :class="changes.bidType ? 'badge-danger' : 'badge-success'"
            >{{ changes.bidType ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.bidType ? '1' : '2'">{{ current.bidType }}</td>
        <td v-if="changes.bidType">{{ changes.bidType }}</td>
      </tr>
      <tr>
        <td>
          Bid Price
          <span
            class="badge"
            :class="changes.bidPrice ? 'badge-danger' : 'badge-success'"
            >{{ changes.bidPrice ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.bidPrice ? '1' : '2'">{{ current.bidPrice }}</td>
        <td v-if="changes.bidPrice">{{ changes.bidPrice }}</td>
      </tr>
      <tr>
        <td>
          Landing Domain
          <span
            class="badge"
            :class="changes.landingDomain ? 'badge-danger' : 'badge-success'"
            >{{ changes.landingDomain ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.landingDomain ? '1' : '2'">
          {{ current.landingDomain }}
        </td>
        <td v-if="changes.landingDomain">{{ changes.landingDomain }}</td>
      </tr>
      <tr>
        <td>
          Budget
          <span
            class="badge"
            :class="changes.budget ? 'badge-danger' : 'badge-success'"
            >{{ changes.budget ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.budget ? '1' : '2'">
          €&nbsp;{{ current.budget }}
        </td>
        <td v-if="changes.budget">€&nbsp;{{ changes.budget }}</td>
      </tr>
      <tr>
        <td>
          Autoapprove
          <span
            class="badge"
            :class="changes.autoapprove ? 'badge-danger' : 'badge-success'"
            >{{ changes.autoapprove ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.autoapprove ? '1' : '2'">
          {{ current.autoapprove }}
        </td>
        <td v-if="changes.autoapprove">{{ changes.autoapprove }}</td>
      </tr>
      <tr v-if="changes.specifics && changes.specifics.gads && changes.specifics.gads.keywords.length && current.specifics && current.specifics.gads && current.specifics.gads.keywords.length">
        <td>
          Keywords
          <span
            class="badge"
            :class="changes.specifics.gads.keywords ? 'badge-danger' : 'badge-success'"
            >{{ changes.specifics.gads.keywords ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.specifics.gads.keywords ? '1' : '2'">
          €&nbsp;{{ current.specifics.gads.keywords.join(", ") }}
        </td>
        <td v-if="changes.specifics.gads.keywords">{{ changes.specifics.gads.keywords.join(", ") }}</td>
      </tr>
      <tr>
        <td>
          Reviews
          <span
            class="badge"
            :class="changes.reviews ? 'badge-danger' : 'badge-success'"
            >{{ changes.reviews ? "Changed" : "Unchanged" }}</span
          >
        </td>
        <td :colspan="changes.reviews ? '1' : '2'">
          <div v-for="review in Object.keys(current.reviews)" :key="review">
            <span class="badge badge-primary">{{ review }}</span>
            <span
              class="badge"
              :class="
                current.reviews[review].status == 'not_reviewed'
                  ? 'badge-warning'
                  : current.reviews[review].status == 'approved'
                  ? 'badge-success'
                  : 'badge-danger'
              "
              >{{
                current.reviews[review].status == 'not_reviewed'
                  ? "Not Reviewed"
                  : current.reviews[review].status == 'approved'
                  ? "approved"
                  : "Rejected"
              }}</span
            >
          </div>
        </td>
        <td v-if="changes.reviews">
          <div v-for="review in Object.keys(changes.reviews)" :key="review">
            <span class="badge badge-primary">{{ review }}</span>
            <span
              class="badge"
              :class="
                current.reviews[review].status == 'not_reviewed'
                  ? 'badge-warning'
                  : current.reviews[review].status == 'approved'
                  ? 'badge-success'
                  : 'badge-danger'
              "
              >{{
                current.reviews[review].status == 'not_reviewed'
                  ? "Not Reviewed"
                  : current.reviews[review].status == 'approved'
                  ? "approved"
                  : "Rejected"
              }}</span
            >
          </div>
        </td>
      </tr>
    </table>
  </b-modal>
</template>

<script>
export default {
  name: "ApprovalLineItemChanges",
  props: {
    modalId: {
      type: String,
      default: "",
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
    changes: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    name() {
      if (
        this.current.name != this.changes.name &&
        this.changes.name !== undefined
      )
        return this.changes.name;
      return this.current.name;
    },
    keys() {
      return Object.keys(this.current).filter((e) => e != "changes");
    },
  },
};
</script>

<style>
</style>