<template>
  <div class="col-md-12 stretch-card grid-margin dba-template">
    <div class="card">
      <div class="card-body pb-2">
        <h4 class="card-title">{{template.name}}</h4>
        <div class="row mb-5">
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-active">
                  <span>
                    <i class="fa fa-play" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span
                  v-if="template.campaigns.active > 1000"
                  :title="template.campaigns.pending"
                  class="title"
                >{{ template.campaigns.active | numeral('0.00a') }}</span>
                <span v-else class="title">{{ template.campaigns.active}}</span>
                <span class="subtitle">active bookings</span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-pending">
                  <span>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span
                  v-if="template.campaigns.pending > 1000"
                  :title="template.campaigns.pending"
                  class="title"
                >{{ template.campaigns.pending | numeral('0.00a') }}</span>
                <span v-else class="title">{{ template.campaigns.pending}}</span>
                <span class="subtitle">pending bookings</span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-totalbudget">
                  <span>
                    <i class="fa fa-money" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span
                  v-if="totalBudget > 1000"
                  :title="totalBudget"
                  class="title"
                >&euro; {{ totalBudget | numeral('0.00a') }}</span>
                <span v-else class="title">&euro; {{ totalBudget }}</span>
                <span class="subtitle">total budget</span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-avgbudget">
                  <span>
                    <i class="fa fa-eur" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span
                  v-if="template.budget.active > 1000"
                  :title="template.budget.active"
                  class="title"
                >&euro; {{ template.budget.active | numeral('0.00a') }}</span>
                <span v-else class="title">{{ template.budget.active}}</span>
                <span class="subtitle">budget in active bookings</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showDetails">
          <DashboardAnalyticsBox
            :title="`Top ${$tc('general.booking', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
            :footerText="((tableTitle == 'Bookings') ? $t('actions.hidebookingoverview') : $t('actions.showbookingoverview'))"
            @footerAction="loadCampaigns()"
            :tableData="topCampaignsShort.data"
            :tableFields="topCampaignsShort.fields"
            :size="6"
            class="pb-4"
          />

          <DashboardAnalyticsBox
            :title="`Top ${$tc('general.website', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
            :footerText="((tableTitle == 'Websites') ? $t('actions.hidewebsiteoverview') : $t('actions.showwebsiteoverview'))"
            @footerAction="loadWebsites()"
            :tableData="topWebsitesShort.data"
            :tableFields="topWebsitesShort.fields"
            :size="6"
            class="pb-4"
          />
        </div>
        <div class="row" v-if="loadingDetails">
          <div class="flip-square-loader"></div>
        </div>

        <div class="row mb-5" v-if="showDetails && tableData">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body smallpadding">
                <h4 class="card-title">
                  {{tableTitle}}
                  <small>({{tableData.data.length}})</small>
                </h4>
                <b-pagination
                  class="float-right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="rowsPerPage"
                  aria-controls="tbox-table"
                ></b-pagination>
                <div class="table-responsive fulltable">
                  <b-table
                    id="tbox-table"
                    striped
                    hover
                    :items="tableData.data"
                    :fields="tableData.fields"
                    :current-page="currentPage"
                    :per-page="rowsPerPage"
                  >
                    <template #cell(budget_calc)="data">
                      <b-progress v-if="data.item.budget > 0" :max="data.item.budget" class="progress-small">
                        <b-progress-bar v-if="data.item.budgetLeft < 10" variant="danger" :value="data.item.budgetLeft"></b-progress-bar>
                        <b-progress-bar v-else-if="data.item.budgetLeft < 35" variant="warning" :value="data.item.budgetLeft"></b-progress-bar>
                        <b-progress-bar v-else variant="success" :value="data.item.budgetLeft"></b-progress-bar>
                      </b-progress>
                      <span v-else>
                        <NumberAbbreviated
                          :value="data.item.dailyBudget"
                          :currency="true"
                        /> ({{$t("labels.daily")}})
                      </span>
                    </template>

                    <template #cell(impressions)="data">
                      <span v-if="data.value == '' && data.value !== 0">-</span>
                      <span v-else v-b-tooltip.hover :title="$n(data.value)">
                        <NumberAbbreviated :value="data.value" />
                      </span>
                    </template>

                    <template #cell(clicks)="data">
                      <span v-if="data.value == '' && data.value !== 0">-</span>
                      <span v-else v-b-tooltip.hover :title="$n(data.value)">
                        <NumberAbbreviated :value="data.value" />
                      </span>
                    </template>

                    <template #cell(ctr_calc)="data">
                      {{ getCTR(data.item.impressions, data.item.clicks, 2) }} %
                    </template>

                    <template #cell(conversions)="data">
                      <span v-if="data.item.pvConversions+data.item.pcConversions == '0'">0</span>
                      <span v-else :title="`PC: ${$n(data.item.pcConversions)} | PV: ${$n(data.item.pvConversions)}`" v-b-tooltip.hover
                        >{{ data.item.pvConversions+data.item.pcConversions }}
                      </span>
                    </template>

                    <template #cell(runtime)="data">
                      <small>
                        {{ new Date(data.item.start).formatDate() }} <br> {{ new Date(data.item.end).formatDate() }}
                      </small>
                    </template>

                    <template #cell(channels)="data">
                      <b-img
                        v-for="(channel, index) in data.value"
                        :key="index"
                        :src="channel.image"
                        :alt="channel.name"
                        :title="channel.name"
                        style="width: 20px; height: 20px;"
                        class="mr-1"
                      ></b-img>
                    </template>
                    
                    <template #cell(name)="data">
                      <span
                        v-if="data.item.status === 'active' || data.item.status === 'inactive'"
                        :class="data.item.status === 'active' ? 'text-success' : 'text-warning'"
                        :title="`${new Date(data.item.start).formatDate()} - ${new Date(data.item.end).formatDate()}`"
                      >&bull; </span>
                      <router-link v-if="data.item.link" :to="`${data.item.link}`" target="_blank">{{data.value}}</router-link>
                      <span v-else>
                        {{data.value}}
                      </span>
                    </template>
                    <template #cell()="data">{{ data.value }}</template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p v-if="(template.campaigns.active + template.campaigns.inactive) > 0" class="text-center mb-0">
          <span class="iconbox i-details" :class="showDetails ? 'active' : ''">
            <span v-if="!showDetails" @click="loadDetails()">
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
            <span v-if="showDetails" @click="hideDetails">
              <i class="fa fa-chevron-up" aria-hidden="true"></i>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardAnalyticsBox from "./DashboardAnalyticsBox";
import NumberAbbreviated from "@/components/NumberAbbreviated";
// import DashboardAnalyticsDoughnut from "./DashboardAnalyticsDoughnut";
import CHANNELS from "@/constants/channels.js";

export default {
  name: "DashboardAnalyticsBookingTemplateBox",
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDetails: false,
      loadingDetails: false,

      tableData: null,
      tableTitle: "",
      currentPage: 1,
      rowsPerPage: 10,

      details: false,
      campaignsFull: false,
      websitesFull: false
    };
  },
  computed: {
    totalBudget() {
      return (
        this.template.budget.active +
        this.template.budget.inactive +
        this.template.budget.pending
      );
    },
    totalRows() {
      return this.tableData.data.length;
    },
    topCampaignsShort() {
      let fields = [
        { key: "name", label: this.$tc("general.booking", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right" }

      ];

      for(let c = 0; c < this.details.campaigns.length; c++){
        let cObj = this.details.campaigns[c];
        cObj.link = `/campaign/stats/${cObj.id}`;
      }

      return {
        fields,
        data: this.details.campaigns
      };
    },
    topWebsitesShort() {
      let fields = [
        { key: "name", label: this.$tc("general.website", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right" }
      ];

      return {
        fields,
        data: this.details.websites
      };
    },
  },
  methods: {
    async loadDetails() {
      // load TOP5 bookings and websites
      if (this.details === false) {
        this.loadingDetails = true;

        const result = await this.$parent.$parent.getDashboardStats(
          this.template.id,
          false,
          false
        );
        if (result !== false) {
          this.details = result;
        }

        this.loadingDetails = false;
      }
      this.showDetails = true;
    },
    async loadCampaigns() {
      if (this.tableTitle == "Bookings") {
        this.hideTable();
        return;
      } else if (this.campaignsFull === false) {
        this.hideTable();
        this.loadingDetails = true;

        const campaignsFull = await this.$parent.$parent.getDashboardStats(
          this.template.id,
          false,
          "campaigns"
        );
        if (campaignsFull !== false) {
          let tableData = {
            fields: [
              { key: "name", label: this.$tc("general.booking", 1), sortable: true },
              { key: "runtime", label: this.$t("general.runtime"), class: "text-left"},
              { key: "budget_calc", label: this.$t("general.budget") },
              { key: "channels", label: this.$tc("general.channel", 2), class: "text-center" },
              {
                key: "impressions",
                label: this.$tc("general.view", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "clicks",
                label: this.$tc("general.click", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "conversions",
                label: this.$tc("general.conversion", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "ctr_calc",
                label: "CTR",
                class: "text-right",
                sortable: true
              }
            ],
            data: []
          };

          for (let c = 0; c < campaignsFull.length; c++) {
            let tCampaign = campaignsFull[c];

            let leftBudgetPercent = Math.round(
              (tCampaign.budgetLeft * 100) / tCampaign.budget
            );

            let color = "success";
            if (leftBudgetPercent <= 10) color = "danger";
            else if (leftBudgetPercent <= 35) color = "warning";

            tCampaign.budgetLeftData = {
              value: leftBudgetPercent,
              color: color
            };

            for(let ch = 0; ch < tCampaign.channels.length; ch++){
              let channelData = this.getChannelData(tCampaign.channels[ch].name);
                tCampaign.channels[ch].image = channelData.image;
                tCampaign.channels[ch].key = channelData.key;
                tCampaign.channels[ch].name = channelData.name;
            }

            tCampaign.link = `/campaign/stats/${tCampaign.id}`;

            tableData.data.push(tCampaign);
          }

          this.campaignsFull = tableData;
        }

        this.loadingDetails = false;
      }

      this.tableTitle = "Bookings";
      this.tableData = this.campaignsFull;
    },
    async loadWebsites() {
      if (this.tableTitle == "Websites") {
        this.hideTable();
        return;
      } else if (this.websitesFull === false) {
        this.hideTable();
        this.loadingDetails = true;

        const websitesFull = await this.$parent.$parent.getDashboardStats(
          this.template.id,
          false,
          "websites"
        );
        if (websitesFull !== false) {
          let tableData = {
            fields: [
              { key: "name", label: this.$tc("general.website", 1), sortable: true },
              {
                key: "impressions",
                label: this.$tc("general.impression", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "clicks",
                label: this.$tc("general.click", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "conversions",
                label: this.$tc("general.conversion", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "ctr_calc",
                label: "CTR",
                class: "text-right",
                sortable: true
              }
            ],
            data: websitesFull
          };

          this.websitesFull = tableData;
        }

        this.loadingDetails = false;
      }

      this.tableTitle = "Websites";
      this.tableData = this.websitesFull;
    },
    getChannelData(key) {
      for (let ch = 0; ch < CHANNELS.length; ch++) {
        if (key == CHANNELS[ch].key) {
          return CHANNELS[ch];
        }
      }
    },
    hideTable() {
      this.tableData = null;
      this.tableTitle = "";
    },
    hideDetails() {
      this.showDetails = false;
      this.hideTable();
    },
    getCTR(views, clicks, precision) {
      let ctr = 0;
      if (views > 0) {
        ctr = Number(((clicks * 100) / views).toFixed(precision));
      }

      return ctr;
    },
  },
  components: {
    DashboardAnalyticsBox,
    NumberAbbreviated
    // DashboardAnalyticsDoughnut
  }
};
</script>

<style>
.dba-template .progress.progress-small {
  height: 1rem;
}

.dba-template .progress {
  height: 2rem;
  border-radius: 0px;
}

.dba-template span.iconbox {
  width: 48px;
  height: 48px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  position: relative;
}

.dba-template span.iconbox span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dba-template span.title,
.dba-template span.subtitle {
  display: block;
}

.dba-template span.title {
  font-size: 18px;
  font-weight: bold;
}

.dba-template span.subtitle {
  font-size: 14px;
  color: #bbbbbb;
}

.dba-template .icon {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.dba-template .i-active {
  color: #7367f0;
  background-color: rgba(115, 103, 240, 0.12);
}

.dba-template span.badge-pending {
  color: #00cfe8;
  background-color: rgba(0, 207, 232, 0.12);
  border: 1px solid #00cfe8;
}

.dba-template .i-pending {
  color: #00cfe8;
  background-color: rgba(0, 207, 232, 0.12);
}
.dba-template .i-totalbudget {
  color: #ea5455;
  background-color: rgba(234, 84, 85, 0.12);
}
.dba-template .i-avgbudget {
  color: #28c76f;
  background-color: rgba(40, 199, 111, 0.12);
}
.dba-template .i-details {
  color: #cccccc;
  background-color: rgba(204, 204, 204, 0.12);
  cursor: pointer;
}
.dba-template .i-details:hover {
  color: #aaaaaa;
  background-color: rgba(170, 170, 170, 0.12);
}
.dba-template .i-details.active {
  color: #333333;
  background-color: rgba(33, 33, 33, 0.12);
}
.dba-template .card-body {
  padding: 30px 30px;
}

.dba-template .card-body.smallpadding {
  padding: 20px 15px 10px !important;
}

.dba-template .fulltable td,
.dba-template .fulltable th {
  padding: 16px 10px;
}
</style>