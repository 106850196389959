<template>
  <b-modal :id="modalId" :title="`Changes for ${name}`" size="lg">
      <div class="row">
          <div class="col">
            <prism-editor
              v-model="adCode"
              :highlight="highlighter"
              class="my-editor"
              :autoStyleLineNumbers=false
              line-numbers
              :readonly=true>
            </prism-editor>
          </div>
      </div>
      <div class="row">
          <div class="col text-center">
            <a :href="'https://ai.trk42.net/banner?id=' + bannerId + '&c=&desc=systempreview'" target="_blank">Open in new Tab</a>
          </div>
      </div>
  </b-modal>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';

export default {
    name: "ApprovalAdCodePreview",
    components: {
        PrismEditor
    },
    props: {
        modalId: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: "N/A"
        },
        adCode: {
            type: String,
            default: "Empty"
        },
        bannerId: {
            type: String,
            default: ""
        }
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.js); //returns html
        },
    }
}
</script>

<style scoped>
/* required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #f5f6f7;
    color: rgb(211, 136, 51);
 
    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    height: 150px;
  }
 
  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>