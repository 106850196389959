var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:{ h4: !_vm.small }},[_vm._v(" "+_vm._s(_vm.tree.name)+" "),_c('b-badge',{attrs:{"variant":"inverse-dark"}},[_vm._v(_vm._s(_vm.tree._id))]),_c('span',{staticClass:"badge ml-1 font-weight-bold badge-primary",attrs:{"title":'The type of this job is ' + _vm.tree.type}},[_vm._v(" "+_vm._s(_vm.tree.type == "lineitem" ? _vm.$tc('general.lineitem', 1) : _vm.tree.type == "campaign" ? _vm.$tc('general.campaign', 1) : _vm.tree.type)+" ")]),_c('span',{staticClass:"badge font-weight-bold ml-1",class:{
      'badge-success': _vm.shownStatus == _vm.$t('syncqueue.status.successful'),
      'badge-warning': _vm.shownStatus == _vm.$t('syncqueue.status.partial'),
      'badge-danger': _vm.shownStatus == _vm.$t('syncqueue.status.failed'),
      'badge-dark': _vm.shownStatus == _vm.$t('syncqueue.status.not-run'),
    },attrs:{"title":_vm.shownStatusTitle}},[_vm._v(_vm._s(_vm.shownStatus))]),_c('span',{staticClass:"badge ml-1",class:{
      'badge-danger': _vm.tree.runCount >= 10,
      'badge-warning': _vm.tree.runCount < 10 && _vm.tree.runCount > 1,
      'badge-success': _vm.tree.runCount <= 1,
    },attrs:{"title":_vm.$t('syncqueue.title.run-count', { count: _vm.tree.runCount })}},[_vm._v(_vm._s(_vm.tree.runCount))]),_c('span',{staticClass:"badge ml-1",class:{ 'badge-success': _vm.tree.notified, 'badge-danger': !_vm.tree.notified },attrs:{"title":_vm.$tc('syncqueue.title.notified', _vm.tree.notified ? 1 : 0)}},[_c('i',{staticClass:"fa",class:{ 'fa-check': _vm.tree.notified, 'fa-times': !_vm.tree.notified },staticStyle:{"height":"10px"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }