<template>
    <date-range-picker
    class="daterange-chooser"
    ref="picker"
    :opens="opens"
    :locale-data="{
        firstDay: 1,
        format: 'DD-MM-YYYY HH:mm:ss',
    }"
    :maxDate="new Date()"
    :minDate="minDate"
    :showWeekNumbers="true"
    :autoApply="true"
    slots="icon-calendar"
    v-model="selectedDaterange"
    >
    <template v-slot:input="picker" style="min-width: 350px">{{pickerDate(picker)}}</template>
    </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DateRangePickerCustom",
  components: {
    DateRangePicker
  },
  props: {
    minDate: {
      type: Date,
      required: false,
      default: () => new Date(0),
    },
    opens: {
        type: String,
        required: false,
        default: 'center'
    },
  },
  data() {
    return {
      selectedDaterange: null,
    };
  },
  created() {
    if(!this.dateRange.startDate || !this.dateRange.endDate){
        this.dateRange.startDate = new Date(new Date().setDate(new Date().getDate()-1)).getUTC(true);
        this.dateRange.endDate = new Date(new Date().setDate(new Date().getDate()-1)).getUTC(false);
    }

    let startD = new Date(this.dateRange.startDate);
    let endD = new Date(this.dateRange.endDate);
    this.selectedDaterange = {
        startDate: new Date(startD.getUTCFullYear(), startD.getUTCMonth(), startD.getUTCDate()),
        endDate: new Date(endD.getUTCFullYear(), endD.getUTCMonth(), endD.getUTCDate())
    };
  },
  methods:{
    pickerDate(picker){
      let str = picker.startDate ? picker.startDate.formatDate(true) : null;
      const endStr = picker.endDate ? picker.endDate.formatDate(true) : null;

      if(str !== endStr){
        str += ' - '+endStr;
      }

      return str;
    },
  },
  computed: {
    dateRange: {
      get() {
          return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      }
    },
  },
  watch: {
    selectedDaterange(dateRange, oldDateRange) {

        const newDateRange = {
          startDate: new Date(dateRange.startDate).getUTC(true),
          endDate: new Date(dateRange.endDate).getUTC(false)
        };

        if(oldDateRange && (newDateRange.startDate !== oldDateRange.startDate ||
          newDateRange.endDate !== oldDateRange.endDate
        )){
          this.dateRange = newDateRange;
          this.$emit('update');
        }
    },
  },
}
</script>
<style scoped>
.daterange-chooser {
    /* border: none; */
    display: block;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
</style>