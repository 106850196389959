<template>
    <div class="row bg-light pt-2">
        <div class="col-4 form-group">
            <label for="advertiser" class="mb-1">{{ $tc('general.advertiser', 2) }}</label>
            <v-select
                id="advertisers"
                v-model="advertiser"
                @search="onSearchAdvertisers"
                label="name"
                class="form-control"
                :filterable="false"
                :reduce="(advertiser) => advertiser._id"
                :options="allAdvertisers"
                @input="reportInput('advertiser')"
            >
                <template slot="no-options">
                {{ $t("messages.typetosearch", {name: $tc("general.advertiser", 2)}) }}
                </template>
            </v-select>
        </div>
    </div>
</template>
  
<script>
  import _debounce from "lodash.debounce";

  export default {
    name: "EntityFilterBookingtemplate",
    props: {
    },
    created(){

    },
    data() {
      return {
        allAdvertisers: [],
        advertiser: null,
      };
    },
    methods: {
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
        onSearchAdvertisers(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _debounce((loading, search, vm) => {
        vm.$api.advertiser
            .search(search, { enableLoading: false })
                .then((advertisers) => {
                vm.allAdvertisers = advertisers.items;
                loading(false);
            });
        }, 350),
    },
  };
</script>
