<template>
    <div class="row bg-light pt-2">
        <div class="col-6 form-group">
            <label for="searchfield_lineitem" class="mb-1">{{ $tc('general.lineitem', 2) }}</label>
            <!-- <SearchEntity class="py-2" entity="lineitem" :value="lineitem" multiple @selected="setSearchInput"/> -->
            <v-select
                id="searchfield_lineitem"
                entity="lineitem"
                multiple
                :close-on-select="false"
                v-model="lineitemIds"
                label="name"
                class="form-control py-2"
                :filterable="false"
                :reduce="(entities) => entities._id"
                :selectable="option => !lineitemIds.includes(option._id)"
                :options="lineitems"
                @input="reportInput('lineitemIds')"
            >
            </v-select>
        </div>
        <div class="col-6 form-group">
            <label for="searchfield_creative" class="mb-1">{{ $tc('general.creative', 2) }}</label>
            <!-- <SearchEntity class="py-2" entity="creative" :value="campaign" multiple @selected="setSearchInput"/> -->
            <v-select
                id="searchfield_creative"
                entity="creative"
                v-model="creativeIds"
                label="name"
                class="form-control py-2"
                filterable
                :reduce="(entities) => entities._id"
                :selectable="option => !creativeIds.includes(option._id)"
                :options="creatives"
                @input="reportInput('creativeIds')"
                multiple
                :close-on-select="false"
            >
            </v-select>
        </div>
        <div class="col-6 form-group">
            <label for="searchfield_daterange" class="mb-1">{{ $t('general.startdate') }} / {{ $t('general.enddate') }}</label>
            <DateRangePickerCustom class="p-0" opens="left" @update="setDates" />
        </div>
        <div class="col-6 form-group">
            <label for="searchfield_status" class="mb-1">Status</label>
            <!-- <SearchEntity class="py-2" entity="creative" :value="campaign" multiple @selected="setSearchInput"/> -->
            <v-select
                id="searchfield_status"
                entity="approvalStatus"
                v-model="approvalStatus"
                label="name"
                class="form-control py-2"
                filterable
                :reduce="(entities) => entities.value"
                :options="statuses"
                @input="reportInput('approvalStatus')"
            >
            </v-select>
        </div>
    </div>
</template>
  
<script>
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import { mapGetters } from 'vuex';

  export default {
    name: "EntityFilterConversions",
    components: {
        DateRangePickerCustom
    },
    props: {
        filter: {
            type: Object,
            required: false
        },
        lineitems: {
            type: Array,
            required: true
        },
        creatives: {
            type: Array,
            required: true
        }
    },
    data() {
      return {
        lineitemIds: [],
        creativeIds: [],
        approvalStatus: null,
        allLineitems: [],
        allCreatives: [],
        statuses: [
            {
                name: this.$t('review.pending'),
                value: 'NULL'
            },
            {
                name: this.$t('review.approved'),
                value: 'approved'
            },
            {
                name: this.$t('review.rejected'),
                value: 'rejected'
            }
        ]
      };
    },
    created(){
        if(this.filter){
            if(this.filter.lineitem)        this.lineitem = this.filter.lineitem;
            if(this.filter.creative)        this.creative = this.filter.creative;

            // preload line items and creatives
            this.allLineitems = this.lineitems;
            this.allCreatives = this.creatives;
        }
    },
    computed: {
        ...mapGetters(["dateRange"]),
        dateRange: {
            get() {
                return this.$store.state.store.dateRange;
            }
        },
    },
    methods: {
        setDates(){
            this.$emit("filter", 'start_date', new Date(this.dateRange.startDate));
            this.$emit("filter", 'end_date', new Date(this.dateRange.endDate));
        },
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
    },
    watch: {
        creatives: function(newCreatives){
            this.allCreatives = newCreatives;
        },
        lineitems: function(newLineitems){
            this.allLineitems = newLineitems;
        },
    },
  };
</script>
