// Lib imports
import axios from 'axios'
import router from '@/router'
import store from '@/store'

const request = axios.create({ 
  baseURL: process.env.VUE_APP_API, 
  timeout: 120000, 
  maxContentLength: Infinity,
  maxBodyLength: Infinity 
})

request.interceptors.request.use(config => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem(
      process.env.VUE_APP_JWT
    )}`,
    // Advertiser: JSON.parse(localStorage.getItem(process.env.VUE_APP_ADVERTISER)).manager
    Advertiser: localStorage.getItem(process.env.VUE_APP_ADVERTISER) && JSON.parse(localStorage.getItem(process.env.VUE_APP_ADVERTISER)) ? JSON.parse(localStorage.getItem(process.env.VUE_APP_ADVERTISER)) : null,
    Publisher: localStorage.getItem(process.env.VUE_APP_PUBLISHER) && JSON.parse(localStorage.getItem(process.env.VUE_APP_PUBLISHER)) ? JSON.parse(localStorage.getItem(process.env.VUE_APP_PUBLISHER)) : null,
    Language: localStorage.getItem(process.env.VUE_APP_LANGUAGE) ? localStorage.getItem(process.env.VUE_APP_LANGUAGE) : process.env.VUE_APP_LANGUAGE_DEFAULT
  };

  if(store.getters.archived){
    if(config.params) config.params.archived = true;
    else config.params = { archived: true}
  }

  // Check for enableLoading property and if set to false return without commiting loading
  if('enableLoading' in config && !config.enableLoading) {
    return config;
  }

  store.commit('LOADING_ACTIVE');
  return config;
}, (error) => {
  store.commit('LOADING_INACTIVE');
  // console.log(`%cError in Request Interceptor: %c${JSON.stringify(error)}`, "color: red;", "")
  store.dispatch("setError", error?.message);
  return Promise.reject(error);
});

request.interceptors.response.use((response) =>  {
    store.commit('LOADING_INACTIVE');
    return response
  }, error => {
    if (error?.response?.status === 401) {
      store.dispatch("signout");
      router.push({ name: `Login`, params: { unauthorized: true } });
      store.commit('LOADING_INACTIVE');
      return Promise.reject(new Error('Access Expired.'))
    } else if(error?.response?.status === 406){
      setUserAndAdvertiser();
      store.commit('LOADING_INACTIVE');
      store.dispatch("setError", error?.response?.data?.errors ? error?.response?.data?.errors[0]?.message : error?.response?.data?.message);
      return Promise.reject(new Error('Access changed.'))
    }
    store.commit('LOADING_INACTIVE');
    // console.log(`%cError in Response Interceptor: %c${error?.response?.data?.errors}`, "color: red;font-weight: bold;font-size: 1.1rem;", "font-size: 1.0rem;")
    // console.log(`%cError in Response Interceptor: %c${error?.response?.data?.message}`, "color: red;font-weight: bold;font-size: 1.1rem;", "font-size: 1.0rem;")
    if(error?.response?.data?.errors){
      store.dispatch("setError", error?.response?.data?.errors[0]?.message);
    } else if(error?.response?.data?.message){
      if(error?.response?.data?.message?.[0]?.constraints){
        let constraints = Object.values(error?.response?.data?.message?.[0]?.constraints).map(e=>{
          e = e.charAt(0).toUpperCase() + e.slice(1)
          if(!e.includes(".")) e = e + ".";
          return e;
        }).join(" ");
        store.dispatch("setError",constraints);
      }else {
        store.dispatch("setError", error?.response?.data?.message);
      }
    }
    return Promise.reject(error)
});

var setUserAndAdvertiser = (function() {
  var executed = false;
  return async function() {
      if (!executed) {
          executed = true;
          var user = await store.dispatch('getUser');
          if(user?.publisher){
            store.commit('SET_PUBLISHER', user.selectedAdvertiser);
            await store.dispatch('getPublishers');
          }
          else{
            store.commit('SET_ADVERTISER', user.selectedAdvertiser);
            await store.dispatch('getAdvertisers');
          }
          window.location.reload();
          executed = false;
      }
  };
})();

export default request