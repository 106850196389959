<template>
  <div class="row mt-3" v-if="getGroupedCreatives()">
    <div class="col-12">
      <h5 class="card-title">{{ $tc("general.creative", 2) }}</h5>
    </div>
    <div class="mb-3 col-12">
      <div class="row">
        <div
          class="col-6"
          v-for="(creative, index) in getGroupedCreatives()"
          :key="index"
        >
          <!-- <AdListCreative
              class="rounded border mx-0"
              :creative="creative"
              :remove="false"
              :select="false"
              v-bind:previewSize="60"
              :showRequirements="true"
          /> -->
          <CreativeCard :creative="creative" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CHANNELS from "@/constants/channels.js";
import CreativeCard from "@/components/cards/CreativeCard";

export default {
  name: "CreativeCardPanel",
  components: { CreativeCard },
  props: ["lineItems", "contracts", "banners"],
  methods: {
    getChannelData(key) {
      const data = CHANNELS.find((ch) => ch.key === key);
      return data;
    },
    getGroupedCreatives() {
      // group by channel
      let creatives = [];

      if (!this.lineItems?.length) {
        return [];
      }
      const lineItems = this.lineItems.filter((li) => li.creatives?.length > 0);

      for (let l = 0; l < lineItems.length; l++) {
        const li = lineItems[l];
        for (let cr = 0; cr < li.creatives.length; cr++) {
          const creativeId = li.creatives[cr];

          // check if already in array
          const idx = creatives.findIndex((cr) => cr._id == creativeId);
          const contract = this.contracts?.find((co) => co._id == li.contract);
          if (idx == -1) {
            // add full banner object
            const creative = this.banners?.find((b) => b._id == creativeId);

            if (creative && contract) {
              const channelData = this.getChannelData(contract.channel);
              creative.channels = [channelData];

              // add keywords, if it is a googleads line item
              if (li?.specifics?.gads?.keywords) {
                creative.keywords = li.specifics.gads.keywords;
              }

              creatives.push(creative);
            }
          } else if (contract) {
            // add channel
            const channelData = this.getChannelData(contract.channel);
            creatives[idx].channels.push(channelData);
          }
        }
      }

      return creatives;
    },
  },
};
</script>
