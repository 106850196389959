<template>
  <div>
    <div
      v-if="
        (isSysop || isAdmin || isAgency) && !campaignFinished
      "
    >
      <router-link
        :to="`/lineitem/update/${channel.lineitem}`"
        v-for="(channel, index) in channels"
        :key="index"
      >
        <b-img
          v-b-tooltip.hover
          :src="channel.image"
          :alt="channel.name"
          :title="`${channel.name} ${channel.statusText.length ? ' - '+channel.statusText : ''}`"
          style="width: 20px; height: 20px"
          class="mr-1"
          :class="channel.lineitemStatus ? '' : 'channel-inactive'"
        ></b-img>
      </router-link>
    </div>
    <div v-else>
      <b-img
        v-for="(channel, index) in channels"
        v-b-tooltip.hover
        :key="index"
        :src="channel.image"
        :alt="channel.name"
        :title="`${channel.name} ${channel.statusText.length ? ' - '+channel.statusText : ''}`"
        style="width: 20px; height: 20px"
        class="mr-1"
        :class="channel.lineitemStatus ? '' : 'channel-inactive'"
      ></b-img>
    </div>
  </div>
</template>

<script>
const jwtDecode = require("jwt-decode");
import CHANNELS from "@/constants/channels.js";

export default {
  name: "CampaignChannels",
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      channels: []
    };
  },
  mounted() {
    this.channels = this.getChannels();
    setInterval(() => {
      this.channels = this.getChannels();
    }, 5000)
  },
  methods: {
    getChannelDetails(key) {
      for (let i = 0; i < CHANNELS.length; i++) {
        if (CHANNELS[i].key == key) {
          return CHANNELS[i];
        }
      }
      return [];
    },
    getChannels() {
      let channels = [];
      
      if (
        this.campaign?.lookups?.lineitems?.length &&
        this.campaign?.lookups?.contracts?.length
      ) {
        for (let i = 0; i < this.campaign.lookups.lineitems.length; i++) {

          const lineItem = this.campaign.lookups.lineitems[i];

          const contract = this.campaign.lookups.contracts.find(
            (c) => c._id.toString() == lineItem.contract
          );

          if (contract) {
            const channelDetails = this.getChannelDetails(contract.channel);

            channelDetails.lineitem = lineItem._id;
            channelDetails.lineitemStatus =
            lineItem.active ||
              (!lineItem.active &&
              lineItem.autopaused &&
                !lineItem.userpause)
                ? true
                : false;

            channelDetails.statusText = lineItem.userpause ? this.$t("general.paused") : '';

            if(lineItem.active && this.campaign?.lookups?.banners.length){
              // check if banners were reviewed
              const allBanners = this.campaign.lookups.banners;
              const lineItemBanners = allBanners.filter(b => lineItem.creatives.includes(b._id.toString()));

              // console.log(lineItemBanners);

              let allRejected = true;

              if(lineItemBanners.length){
                for(const lBanner of lineItemBanners){

                  if(lBanner?.reviews && Object.keys(lBanner.reviews).length){
                    for(const channel in lBanner.reviews){

                      const reviewData = lBanner.reviews[channel];
                      if(reviewData.status !== "rejected" ){
                        allRejected = false;
                      }
                    }
                  }
                  else{
                    // no review property. maybe old banner, show as active
                    allRejected = false;
                  }
                }
              }

              if(allRejected){
                channelDetails.lineitemStatus = false;
                channelDetails.statusText = "no active banner";
              }

            }
            channels.push(Object.assign({}, channelDetails));
          }
        }
      }
      
      return channels;
    },
  },
  computed: {
    campaignFinished(){
      let campaignEnd = new Date(Date.now() + 315569520000).getTime();

      if(this.campaign?.lookups?.bookings[0]){
        const booking = this.campaign.lookups.bookings[0];
        if (booking.end) campaignEnd = new Date(booking.end).getTime();
      }

      return (this.campaign?.status && this.campaign.status == "interrupted") || campaignEnd <= new Date().getUTC();
    },
    isSysop: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
      "systemoperator"
        ? true
        : false,
    isAdmin: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
      "administrator"
        ? true
        : false,
    isAgency: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "agency"
        ? true
        : false,
  },
  components: {},
};
</script>

<style>
.dba-box .table td,
.dba-box .table th {
  padding: 16px 6px;
}
.dba-box .card-body.smallpadding {
  padding: 20px 15px 10px !important;
}

.dba-box a.border-top {
  border-color: #d5dcec;
}

.dba-box .card-title {
  text-transform: none;
}

.dba-box .table-responsive {
  margin-bottom: 0px;
}
.dba-box td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>