<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h3>
          <span class="handwriting mr-2">Instant Report</span>
          {{ campaignName }}
        </h3>
        <!-- <small
          class="pull-right d-block"
          v-if="campaignStats.queryTime && getRole == 'systemoperator'"
        >Query time: {{ (campaignStats.queryTime / 1000).toFixed(1) }}s</small> -->
      </div>
    </div>
    <div class="row" v-if="!error">

      <div class="col pull-right">
        <div class="reportings-box my-1 pull-right btn-group">
          <b-button class="" variant="outline-white" @click="openDownloadStatsModal()">{{ $t('actions.download-stats') }}<i class="fa fa-floppy-o ml-2" aria-hidden="true"></i></b-button>
        </div>
      </div>

      <div class="col-md-auto pull-right">
        <b-dropdown
          variant="outline-white"
          :html="'<i class=\'fa '+activeSubscriptionsIcon+'\' aria-hidden=\'true\'></i> '+$t('labels.subscrivestats')"
          class="reportings-box my-1 pull-right"
        >
          <b-dropdown-item @click.native.capture.stop v-for="(item, index) in statisticsSubscription" :key="index">
            <b-form-checkbox v-model="item.status" name="check-button">{{item.text}}</b-form-checkbox>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-text style="width: 240px;">{{ $t("messages.reportingsrecipient", { email: userEmail }) }}</b-dropdown-text>
        </b-dropdown>
      </div>
      <div class="col-md-4">
        <DateRangePickerCustom opens="left" @update="reloadStats" :minDate="minAllowedDate" />
      </div>
    </div>
    <hr />

    <div class="row" v-if="error && !loading">
      <b-alert class="col-md-12 text-center" show variant="danger">{{ error }}</b-alert>
    </div>
    <div class="row" v-if="loading && !error">
      <div class="col-md-12">
        <div class="flip-square-loader"></div>
      </div>
    </div>
    <div class="row" v-if="!loading && !error && emptyData">
      <b-alert class="col-md-12 text-center" show variant="info">
        {{ $t("messages.nostats") }}
      </b-alert>
    </div>
    <div class="row justify-content-center" v-if="!loading && !error && !emptyData">
      <div class="col-md-12" v-if="!loading && amountOfDays() < 3">
        <b-alert variant="info" show dismissible >{{ $t("messages.choosemoredays") }}</b-alert>
      </div>
      <div class="col-md-12 text-right">
        <a v-if="showConversionsApproval" :href="`/campaign/conversions/${id}`" class="btn btn-primary mr-2 mb-2 text-white">Conversion Approval</a>
        <a v-if="showClicksApproval" :href="`/campaign/clicks/${id}`" class="btn btn-primary mb-2 text-white">Clicks Approval</a>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              content-class="mt-3"
              align="center"
              active-nav-item-class="nav-item-stats-active"
              pills
              v-model="bTabsIndex">
              <b-tab :title="$tc('general.channel', 2)" active title-link-class="nav-item-stats">
                <div class="table-responsive">
                  <b-table hover :items="channelTableData" :fields="channelTableFields" foot-clone no-footer-sorting>
                    <template #cell(channel)="data">
                      <b-img
                        :src="data.value.image"
                        :alt="data.value.name"
                        style="width: 20px; height: 20px;"
                        class="mr-2"
                      ></b-img>
                      <strike v-if="!data.item.isActive">{{ data.value.name }}</strike>
                      <span v-else>{{ data.value.name }}</span>
                    </template>
                    <template #cell(impressions)="data">
                      <span>{{ $n(data.value) }}</span>
                    </template>
                    <template #cell(clicks)="data">
                      <span>{{ $n(data.value) }}</span>
                    </template>
                    <template #cell(cost)="data">
                      <span><NumberAbbreviated
                          :value="data.value"
                          :currency="true"
                        /></span>
                    </template>
                    <template #cell(ctr)="data">
                      <span>{{ data.item.ctr + ' %' }}</span>
                    </template>
                    <template #cell(cr)="data">
                      <span>{{ data.item.cr + ' %' }}</span>
                    </template>

                    <!-- footer computed row -->
                    <template #foot(channel)>
                        <b-img
                          v-for="(channel, index) in allChannelsSelected" :key="index"
                          :src="channel.image"
                          :alt="channel.name"
                          :title="channel.name"
                          v-b-tooltip.hover
                          style="width: 20px; height: 20px;"
                          class="mr-2"
                        ></b-img>
                    </template>
                    <template #foot(impressions)>
                      <span>{{ $n(totalImpressions) }}</span>
                    </template>
                    <template #foot(clicks)>
                      <span>{{ $n(totalClicks) }}</span>
                    </template>
                    <template #foot(conversions)>
                      <span>{{ $n(totalConversions) }}</span>
                    </template>
                    <template #foot(cost)>
                      <span><NumberAbbreviated
                          :value="totalCost"
                          :currency="true"
                        /></span>
                    </template>
                    <template #foot(ctr)>
                      <span>{{ totalCtr + ' %' }}</span>
                    </template>
                    <template #foot(cr)>
                      <span>{{ totalCr + ' %' }}</span>
                    </template>

                  </b-table>
                </div>
              </b-tab>
              <b-tab :title="$t('general.date')" lazy title-link-class="nav-item-stats">
                <div class="table-responsive">
                  <p class="text-right">
                    <b-form-checkbox v-model="tableDetails">{{ $t("labels.showdetails") }}</b-form-checkbox>
                  </p>
                  <b-table hover :items="dateTableData" :fields="dateTableFields" sortBy="date" foot-clone no-footer-sorting>
                    <template #cell(impressions)="data">
                      <span class="clearfix">{{ $n(data.value) }}</span>
                      <table v-if="tableDetails && data.item.channels.length > 0" class="table-sm border-0 float-right mt-1">
                        <tr class="" v-for="(channel, index) in data.item.channels" :key="index">
                          <td class="text-right border-top-0 border-bottom py-1"><img width="10" :alt="channel.channelDetails.key" :title="channel.channelDetails.name" v-b-tooltip.hover :src="channel.channelDetails.image" style="width: 12px; height: auto;" /></td>
                          <td class="text-right border-top-0 border-bottom pr-0 py-1"><small>{{ $n(channel.impressions) }}</small></td>
                        </tr>
                      </table>
                    </template>
                    <template #cell(clicks)="data">
                      <span class="clearfix">{{ $n(data.value) }}</span>
                      <table v-if="tableDetails && data.item.channels.length > 0" class="table-sm border-0 float-right mt-1">
                        <tr class="" v-for="(channel, index) in data.item.channels" :key="index">
                          <td class="text-right border-top-0 border-bottom py-1"><img width="10" :alt="channel.channelDetails.key" :title="channel.channelDetails.name" v-b-tooltip.hover :src="channel.channelDetails.image" style="width: 12px; height: auto;" /></td>
                          <td class="text-right border-top-0 border-bottom pr-0 py-1"><small>{{ $n(channel.clicks) }}</small></td>
                        </tr>
                      </table>
                    </template>
                    <template #cell(conversions)="data">
                      <span class="clearfix">{{ $n(data.value) }}</span>
                      <table v-if="tableDetails && data.item.channels.length > 0" class="table-sm border-0 float-right mt-1">
                        <tr class="" v-for="(channel, index) in data.item.channels" :key="index">
                          <td class="text-right border-top-0 border-bottom py-1"><img width="10" :alt="channel.channelDetails.key" :title="channel.channelDetails.name" v-b-tooltip.hover :src="channel.channelDetails.image" style="width: 12px; height: auto;" /></td>
                          <td class="text-right border-top-0 border-bottom pr-0 py-1"><small>{{ $n(channel.conversions) }}</small></td>
                        </tr>
                      </table>
                    </template>
                    <template #cell(ctr)="data">
                      <span class="clearfix">{{ data.item.ctr + ' %' }}</span>
                      <table v-if="tableDetails && data.item.channels.length > 0" class="table-sm border-0 float-right mt-1">
                        <tr class="" v-for="(channel, index) in data.item.channels" :key="index">
                          <td class="text-right border-top-0 border-bottom py-1"><img width="10" :alt="channel.channelDetails.key" :title="channel.channelDetails.name" v-b-tooltip.hover :src="channel.channelDetails.image" style="width: 12px; height: auto;" /></td>
                          <td class="text-right border-top-0 border-bottom pr-0 py-1"><small>{{ channel.ctr +' %' }}</small></td>
                        </tr>
                      </table>
                    </template>
                    <template #cell(cr)="data">
                      <span class="clearfix">{{ data.item.cr + ' %' }}</span>
                      <table v-if="tableDetails && data.item.channels.length > 0" class="table-sm border-0 float-right mt-1">
                        <tr class="" v-for="(channel, index) in data.item.channels" :key="index">
                          <td class="text-right border-top-0 border-bottom py-1"><img width="10" :alt="channel.channelDetails.key" :title="channel.channelDetails.name" v-b-tooltip.hover :src="channel.channelDetails.image" style="width: 12px; height: auto;" /></td>
                          <td class="text-right border-top-0 border-bottom pr-0 py-1"><small>{{ channel.cr +' %' }}</small></td>
                        </tr>
                      </table>
                    </template>
                    <template #cell(cost)="data">
                      <span class="clearfix">{{ $n(data.value, 'currency') }}</span>
                      <table v-if="tableDetails && data.item.channels.length > 0" class="table-sm border-0 float-right mt-1">
                        <tr class="" v-for="(channel, index) in data.item.channels" :key="index">
                          <td class="text-right border-top-0 border-bottom py-1"><img width="10" :alt="channel.channelDetails.key" :title="channel.channelDetails.name" v-b-tooltip.hover :src="channel.channelDetails.image" style="width: 12px; height: auto;" /></td>
                          <td class="text-right border-top-0 border-bottom pr-0 py-1"><small><NumberAbbreviated
                          :value="channel.cost"
                          :currency="true"
                        /></small></td>
                        </tr>
                      </table>
                    </template>

                    <!-- footer computed row -->
                    <template #foot(date)>
                      <span>{{ totalDateRange }}</span>
                    </template>
                    <template #foot(impressions)>
                      <span>{{ $n(totalImpressions) }}</span>
                    </template>
                    <template #foot(clicks)>
                      <span>{{ $n(totalClicks) }}</span>
                    </template>
                    <template #foot(conversions)>
                      <span>{{ $n(totalConversions) }}</span>
                    </template>
                    <template #foot(cost)>
                      <span><NumberAbbreviated
                          :value="totalCost"
                          :currency="true"
                        /></span>
                    </template>
                    <template #foot(ctr)>
                      <span>{{ totalCtr + ' %' }}</span>
                    </template>
                    <template #foot(cr)>
                      <span>{{ totalCr + ' %' }}</span>
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab :title="$tc('general.creative',2)" lazy title-link-class="nav-item-stats">
                <div class="table-responsive">
                  <b-table hover :items="creativeTableData" :fields="creativeTableFields" foot-clone no-footer-sorting>
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <AdListPreview :creative="data.item.banner" v-if="data.item.banner._id" class="mr-3 "/>
                        <div class="mr-3">
                          <p class="mb-2">{{ data.item.banner.name }}</p>
                          <p class="mb-0 text-muted text-small" v-if="data.item.banner.bannerType != 'search_ad' && data.item.banner._id">
                            {{ data.item.banner.width }}x{{
                            data.item.banner.height
                            }}
                          </p>
                        </div>
                        <div class="ml-1" v-for="(channel, index) in data.item.channels" :key="index">
                          <b-img
                            :src="channel.image"
                            :alt="channel.name"
                            :title="channel.name"
                            style="width: 20px; height: 20px;"
                            class="mr-2"
                            v-b-tooltip.hover
                          ></b-img>
                        </div>
                      </div>
                    </template>
                    <template #cell(impressions)="data">
                      <span class="clearfix">{{ $n(data.value) }}</span>
                    </template>
                    <template #cell(clicks)="data">
                      <span class="clearfix">{{ $n(data.value) }}</span>
                    </template>
                    <template #cell(conversions)="data">
                      <span class="clearfix">{{ $n(data.value) }}</span>
                    </template>
                    <template #cell(ctr)="data">
                      <span class="clearfix">{{ data.item.ctr + ' %' }}</span>
                    </template>
                    <template #cell(cr)="data">
                      <span class="clearfix">{{ data.item.cr + ' %' }}</span>
                    </template>

                    <!-- footer computed row -->
                    <template #foot(name)>
                      <span >{{ $tc("general.creative", 2) }}</span>
                      <div class="d-flex mt-2">
                        <div v-for="(value, key, index) in getCreativeCountForEachChannel()" :key="index" class="mr-4 w-auto">
                          <b-img
                            :src="getChannelDetails(key).image"
                            :alt="getChannelDetails(key).name"
                            :title="getChannelDetails(key).name"
                            style="width: 20px; height: 20px;"
                            class="mr-2"
                            v-b-tooltip.hover
                          ></b-img>
                          <span>{{ value }}</span>
                        </div>
                      </div>
                    </template>
                    <template #foot(impressions)>
                      <span>{{ $n(totalImpressions) }}</span>
                    </template>
                    <template #foot(clicks)>
                      <span>{{ $n(totalClicks) }}</span>
                    </template>
                    <template #foot(conversions)>
                      <span>{{ $n(totalConversions) }}</span>
                    </template>
                    <template #foot(cost)>
                      <span><NumberAbbreviated
                          :value="totalCost"
                          :currency="true"
                        /></span>
                    </template>
                    <template #foot(ctr)>
                      <span>{{ totalCtr + ' %' }}</span>
                    </template>
                    <template #foot(cr)>
                      <span>{{ totalCr + ' %' }}</span>
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab :title="$tc('general.website',2)" lazy title-link-class="nav-item-stats">
                <div class="row d-flex align-items-center" v-if="websitePossibleChannels.length">
                  <div class="col text-right">
                    <div
                      class="mr-2 border px-2 py-1 d-inline-block"
                      style="cursor: pointer"
                      v-for="(channel, index) in websitePossibleChannels"
                      :key="index"
                      @click="toggleWebsiteSelectedChannels(channel.key)"
                      :title="channel.name" v-b-tooltip.hover
                      >
                      <img class="mr-2" style="width: 25px" :src="channel.image">
                      <i
                        class="text-primary"
                        :class="websiteSelectedChannels.includes(channel.key) ? 'fa fa-check-square' : 'fa fa-square'"
                        title="Select creative"
                      />
                    </div>
                    <b-btn size="sm" class="btn-outline-success" :disabled="websitesButtonDisabled" @click="updateWebsites()">
                      OK
                    </b-btn>
                  </div>
                </div>
                <div class="row" v-if="websitesLoading">
                  <div class="col-md-12">
                    <div class="flip-square-loader"></div>
                  </div>
                </div>
                <div class="row mt-3" v-else-if="!websiteTableData.length">
                  <b-alert class="col text-center" show variant="warning">
                    {{ $t("messages.notfoundx", { name: $tc("general.website", 0)})}}
                  </b-alert>
                </div>
                <div :class="websitesLoading || !websiteTableData.length ? 'd-none' : 'd-block'">
                  <div class="table-responsive" >
                    <b-table hover :items="websiteTableData" :fields="websiteTableFields" sortBy="clicks" sort-desc @sort-changed="sortWebsites" :perPage="perPageWebsites" :current-page="currentPageWebsites">
                      <template #cell(location)="data">
                        <span v-if="data.value.length == 0" class="font-italic">{{ $t("general.unknown") }}</span>
                          <span v-else>
                            <span v-if="data.item.app === 1">
                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                {{ ' ' }}
                              </span>
                            {{ data.value }} <!-- do da fancy thingy -->
                          </span>
                      </template>
                      <template #cell(impressions)="data">
                        <span>{{ $n(data.value) }}</span>
                      </template>
                      <template #cell(clicks)="data">
                        <span>{{ $n(data.value) }}</span>
                      </template>
                      <template #cell(conversions)="data">
                        <span>{{ $n(data.item.pcConversions+data.item.pvConversions) }}</span>
                      </template>
                      <template #cell(ctr)="data">
                        <span>{{ data.item.ctr + '%' }}</span>
                      </template>
                      <template #cell(cr)="data">
                        <span>{{ data.item.cr + '%' }}</span>
                      </template>
                      <template #cell(cost)="data">
                        <span><NumberAbbreviated
                                :value="data.value"
                                :currency="true"
                              /></span>
                      </template>
                      <template #cell(remove)="data">
                        <button v-b-tooltip.hover @click="confirmRemoveWebsite(data.value)" class="btn btn-sm btn-outline-danger p-2" :disabled="!data.value || hideDomainlistButton" :title="data.value ? $t('actions.remove-website', {name: data.value}) : ''"><i class="fa fa-remove mr-0" /></button>
                      </template>
                    </b-table>
                  </div>
                  <Pagination class="mt-4" :itemCount="websiteTableData.length" :currentPage="currentPageWebsites" :perPage="perPageWebsites" @changeQuantity="changeQuantityWebsites" @changePage="changePageWebsites"></Pagination>
                </div>
                
              </b-tab>
              <b-tab v-if="showConversions" :title="$tc('general.conversion',2)" lazy title-link-class="nav-item-stats">
                <div class="row" v-if="conversionsLoading">
                  <div class="col-md-12">
                    <div class="flip-square-loader"></div>
                  </div>
                </div>
                <div class="row" v-else-if="!conversionTableData.length">
                  <b-alert class="col text-center" show variant="warning">
                    {{ $t("messages.notfoundx", { name: $tc("general.conversion", 0)})}}
                  </b-alert>
                </div>
                <div :class="conversionsLoading || !conversionTableData.length ? 'd-none' : 'd-block'">
                  <div class="table-responsive">
                    <b-table hover :items="conversionTableData" :fields="conversionTableFields" sortBy="timestamp" sort-desc :perPage="pagination" :current-page="currentPageConversions">
                      <template #cell(approvalStatus)="data">
                        <i v-b-tooltip.hover :title="`${$t('review.approved-on')} ${new Date(data.item.approvalDate).formatDatetime()}`" v-if="data.value == 'approved'" class="fa fa-check-circle-o text-success"></i>
                        <i v-b-tooltip.hover :title="`${$t('review.rejected-on')}  ${new Date(data.item.approvalDate).formatDatetime()}`" v-else-if="data.value == 'rejected'" class="fa fa-times-circle-o text-danger"></i>
                        <i v-b-tooltip.hover :title="`${$t('review.pending')}`" v-else class="fa fa-clock-o text-warning"></i>
                      </template>
                      <template #cell(timestamp)="data">
                        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
                          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
                        </span>
                      </template>
                      <template #cell(timestampView)="data">
                        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
                          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
                        </span>
                      </template>
                      <template #cell(timestampClick)="data">
                        <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
                          {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
                        </span>
                      </template>
                      <template #cell(cartValue)="data">
                        <span v-if="data.item.basketValue && data.item.basketValue != data.value">
                          <span v-if="!isNaN(data.item.basketValue)">
                            <NumberAbbreviated
                              :value="new Number(data.item.basketValue)"
                              :currency="true"
                            /> <i class="fa fa-info-circle" v-b-tooltip.hover :title="`${$t('review.changed-cart-value', {'value': data.value})}`"></i>
                          </span>
                          <span v-else>-</span>
                        </span>
                        <NumberAbbreviated
                          v-else-if="!isNaN(data.value)"
                          :value="data.value"
                          :currency="true"
                        />
                        <span v-else>-</span>
                      </template>
                      <template #cell(cost)="data">
                        <span v-if="data.item.commission !== null">
                          <span v-if="!isNaN(data.item.commission)">
                            <NumberAbbreviated
                              :value="new Number(data.item.commission)"
                              :currency="true"
                            />
                          </span>
                          <span v-else>-</span>
                        </span>
                        <NumberAbbreviated
                          v-else-if="!isNaN(data.value)"
                          :value="data.value"
                          :currency="true"
                        />
                        <span v-else>-</span>
                      </template>
                      <template #cell(fee)="data">
                        <NumberAbbreviated
                          v-if="!isNaN(data.value)"
                            :value="data.value"
                            :currency="true"
                          />
                        <span v-else>-</span>
                      </template>
                      <template #cell(channel)="data">
                        <b-img
                          :src="data.value.image"
                          :alt="data.value.name"
                          :title="data.value.name"
                          style="width: 20px; height: 20px;"
                          v-b-tooltip.hover
                        ></b-img>
                      </template>
                      <template #cell(creative)="data">
                        <AdListPreview :creative="data.item.banner" :onlyButton="true" />
                      </template>
                    </b-table>
                  </div>
                  <Pagination class="mt-4" :itemCount="conversionTableData.length" :currentPage="currentPageConversions" :perPage="perPageConversions" @changeQuantity="changeQuantityConversions" @changePage="changePageConversions"></Pagination>
                </div>
              </b-tab>
              <b-tab :title="$tc('general.publisher',2)" lazy title-link-class="nav-item-stats" v-if="hasAffiliate">
                <b-carousel
                  id="carousel-1"
                  v-model="publisherSlide"
                  :interval="0"
                  background="#ffffff"
                  class="pslider"
                >
                  <b-carousel-slide img-blank>
                    <div class="table-responsive">
                      <b-table hover :items="publisherTableData" :fields="publisherTableFields" sortBy="date" foot-clone no-footer-sorting>
                        <template #cell(name)="data">
                            <span v-if="data.item.publisher.length == 0" class="font-italic">{{ $t("general.unknown") }}</span>
                              <span v-else>
                                {{ data.item.publisher.name }} <a :href="data.item.publisher.url" target="_blank"><i aria-hidden="true" class="fa fa-external-link"></i></a>
                              </span>
                          </template>
                        <template #cell(impressions)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(clicks)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(conversions)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(ctr)="data">
                          <span class="clearfix">{{ data.item.ctr + ' %' }}</span>
                        </template>
                        <template #cell(cr)="data">
                          <span class="clearfix">{{ data.item.cr + ' %' }}</span>
                        </template>
                        <template #cell(cost)="data">
                          <span class="clearfix">{{ $n(data.value, 'currency') }}</span>
                        </template>
                        <template #cell(actions)="data">
                          <button v-b-tooltip.hover @click="activePublisher = data.item.publisher._id" class="btn btn-sm btn-outline-primary p-2"><i class="fa fa-bar-chart mr-0" /></button>
                        </template>

                        <!-- footer computed row -->
                        <template #foot(date)>
                          <span>{{ totalDateRange }}</span>
                        </template>
                        <template #foot(impressions)>
                          <span>{{ $n(totalImpressions) }}</span>
                        </template>
                        <template #foot(clicks)>
                          <span>{{ $n(totalClicks) }}</span>
                        </template>
                        <template #foot(conversions)>
                          <span>{{ $n(totalConversions) }}</span>
                        </template>
                        <template #foot(cost)>
                          <span><NumberAbbreviated
                              :value="totalCost"
                              :currency="true"
                            /></span>
                        </template>
                        <template #foot(ctr)>
                          <span>{{ totalCtr + ' %' }}</span>
                        </template>
                        <template #foot(cr)>
                          <span>{{ totalCr + ' %' }}</span>
                        </template>
                      </b-table>
                    </div>
                  </b-carousel-slide>
                  <b-carousel-slide img-blank>
                    <div class="row">
                      <div class="col-auto">
                        <b-btn @click="activePublisher = null" variant="primary" class="btn-sm h-100"><i class="icon-arrow-left m-0"></i> {{ $t("general.overview") }}</b-btn>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col-auto">
                            <b-btn @click="publisherBack()" :disabled="publisherBackActive" variant="primary" class="btn-sm h-100"><i class="icon-arrow-left m-0"></i></b-btn>
                          </div>
                          <div class="col">
                            <v-select
                              class="form-control"
                              v-model="activePublisher"
                              :filterable="true"
                              :reduce="(publisher) => publisher._id"
                              :options="statsResponseOriginal.publishers"
                              :clearable="false"
                            >
                              <template slot="option" slot-scope="option">
                                {{ option.name }} ({{ option.url }})
                              </template>
    
                              <template slot="selected-option" slot-scope="option">
                                {{ option.name }} ({{ option.url }})
                              </template>
                            </v-select>
                          </div>
                          <div class="col-auto">
                            <b-btn @click="publisherNext()" :disabled="publisherNextActive" variant="primary" class="btn-sm h-100"><i class="icon-arrow-right m-0"></i></b-btn>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <b-button-group class="h-100">
                          <b-button v-b-tooltip.hover :class="publisherDetails == 'date' ? 'active' : ''" @click="publisherDetails = 'date'" :title="$t('general.date')"><i class="fa fa-calendar m-0" aria-hidden="true"></i></b-button>
                          <b-button v-b-tooltip.hover :class="publisherDetails == 'creative' ? 'active' : ''" @click="publisherDetails = 'creative'" :title="$tc('general.creative',2)"><i class="fa fa-picture-o m-0" aria-hidden="true"></i></b-button>
                          <b-button v-b-tooltip.hover :class="publisherDetails == 'website' ? 'active' : ''" @click="publisherDetails = 'website'" :title="$tc('general.website',2)"><i class="fa fa-globe m-0" aria-hidden="true"></i></b-button>
                          <b-button v-b-tooltip.hover :class="publisherDetails == 'conversion' ? 'active' : ''" @click="publisherDetails = 'conversion'" :title="$tc('general.conversion',2)"><i class="fa fa-shopping-cart m-0" aria-hidden="true"></i></b-button>
                        </b-button-group>
                      </div>
                    </div>

                    <div class="table-responsive mb-0 mt-3" v-if="publisherDetails == 'date'">
                      <b-table hover :items="publisherDateTableData" :fields="dateTableFields" sortBy="date" :busy="publisherBusy" foot-clone no-footer-sorting>
                        <template #cell(impressions)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(clicks)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(conversions)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(ctr)="data">
                          <span class="clearfix">{{ data.item.ctr + ' %' }}</span>
                        </template>
                        <template #cell(cr)="data">
                          <span class="clearfix">{{ data.item.cr + ' %' }}</span>
                        </template>
                        <template #cell(cost)="data">
                          <span class="clearfix">{{ $n(data.value, 'currency') }}</span>
                        </template>

                        <!-- footer computed row -->
                        <template #foot(date)>
                          <span>{{ totalDateRange }}</span>
                        </template>
                        <template #foot(impressions)>
                          <span>{{ $n(publisherTotalImpressions) }}</span>
                        </template>
                        <template #foot(clicks)>
                          <span>{{ $n(publisherTotalClicks) }}</span>
                        </template>
                        <template #foot(conversions)>
                          <span>{{ $n(publisherTotalConversions) }}</span>
                        </template>
                        <template #foot(cost)>
                          <span><NumberAbbreviated
                              :value="publisherTotalCost"
                              :currency="true"
                            /></span>
                        </template>
                        <template #foot(ctr)>
                          <span>{{ publisherTotalCtr + ' %' }}</span>
                        </template>
                        <template #foot(cr)>
                          <span>{{ publisherTotalCr + ' %' }}</span>
                        </template>
                      </b-table>
                    </div>

                    <div class="table-responsive mb-0 mt-3" v-if="publisherDetails == 'creative'">
                      <b-table hover :items="publisherCreativesTableData" :fields="creativeTableFields" :busy="publisherBusy" foot-clone no-footer-sorting>
                        <template #cell(name)="data">
                          <div class="d-flex align-items-center">
                            <AdListPreview :creative="data.item.banner" v-if="data.item.banner._id" class="mr-3 "/>
                            <div class="mr-3">
                              <p class="mb-2">{{ data.item.banner.name }}</p>
                              <p class="mb-0 text-muted text-small" v-if="data.item.banner.bannerType != 'search_ad' && data.item.banner._id">
                                {{ data.item.banner.width }}x{{
                                data.item.banner.height
                                }}
                              </p>
                            </div>
                          </div>
                        </template>
                        <template #cell(impressions)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(clicks)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(conversions)="data">
                          <span class="clearfix">{{ $n(data.value) }}</span>
                        </template>
                        <template #cell(ctr)="data">
                          <span class="clearfix">{{ data.item.ctr + ' %' }}</span>
                        </template>
                        <template #cell(cr)="data">
                          <span class="clearfix">{{ data.item.cr + ' %' }}</span>
                        </template>

                        <!-- footer computed row -->
                        <template #foot(name)>
                          <span >{{ $tc("general.creative", 2) }}</span>
                        </template>
                        <template #foot(impressions)>
                          <span>{{ $n(publisherTotalImpressions) }}</span>
                        </template>
                        <template #foot(clicks)>
                          <span>{{ $n(publisherTotalClicks) }}</span>
                        </template>
                        <template #foot(conversions)>
                          <span>{{ $n(publisherTotalConversions) }}</span>
                        </template>
                        <template #foot(cost)>
                          <span><NumberAbbreviated
                              :value="publisherTotalCost"
                              :currency="true"
                            /></span>
                        </template>
                        <template #foot(ctr)>
                          <span>{{ publisherTotalCtr + ' %' }}</span>
                        </template>
                        <template #foot(cr)>
                          <span>{{ publisherTotalCr + ' %' }}</span>
                        </template>
                      </b-table>
                    </div>
                    <div v-if="publisherDetails == 'website'">
                      <div class="table-responsive mb-0 mt-3">
                        <b-table hover :items="publisherWebsiteTableData" :fields="websiteTableFields.slice(0,-1)" sortBy="clicks" :busy="publisherBusy" sort-desc :perPage="perPagePublisherWebsites" :current-page="currentPagePublisherWebsites">
                          <template #cell(location)="data">
                            <span v-if="data.value.length == 0" class="font-italic">{{ $t("general.unknown") }}</span>
                              <span v-else>
                                <span v-if="data.item.app === 1">
                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                    {{ ' ' }}
                                  </span>
                                {{ data.value }} <!-- do da fancy thingy -->
                              </span>
                          </template>
                          <template #cell(impressions)="data">
                            <span>{{ $n(data.value) }}</span>
                          </template>
                          <template #cell(clicks)="data">
                            <span>{{ $n(data.value) }}</span>
                          </template>
                          <template #cell(conversions)="data">
                            <span>{{ $n(data.item.pcConversions+data.item.pvConversions) }}</span>
                          </template>
                          <template #cell(ctr)="data">
                            <span>{{ data.item.ctr + '%' }}</span>
                          </template>
                          <template #cell(cr)="data">
                            <span>{{ data.item.cr + '%' }}</span>
                          </template>
                          <template #cell(cost)="data">
                            <span><NumberAbbreviated
                                    :value="data.value"
                                    :currency="true"
                                  /></span>
                          </template>
                        </b-table>
                      </div>
                      <Pagination class="mt-4" :itemCount="publisherWebsiteTableData.length" :currentPage="currentPagePublisherWebsites" :perPage="perPagePublisherWebsites" @changeQuantity="changeQuantityPublisherWebsites" @changePage="changePagePublisherWebsites"></Pagination>
                    </div>
                    <div v-if="publisherDetails == 'conversion'">
                      <div class="row mx-0 mt-4" v-if="!publisherConversionTableData.length">
                        <b-alert class="col text-center" show variant="warning">
                          {{ $t("messages.notfoundx", { name: $tc("general.conversion", 0)})}}
                        </b-alert>
                      </div>
                      <div v-else class="table-responsive mb-0 mt-3">
                        <b-table hover :items="publisherConversionTableData" :fields="conversionTableFields" :busy="publisherBusy" sort-desc :perPage="perPagePublisherConversions" :current-page="currentPagePublisherConversions">
                          <template #cell(location)="data">
                            <span v-if="data.value.length == 0" class="font-italic">{{ $t("general.unknown") }}</span>
                              <span v-else>
                                <span v-if="data.item.app === 1">
                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                    {{ ' ' }}
                                  </span>
                                {{ data.value }} <!-- do da fancy thingy -->
                              </span>
                          </template>
                          <template #cell(approvalStatus)="data">
                            <i v-b-tooltip.hover :title="`${$t('review.approved-on')} ${new Date(data.item.approvalDate).formatDatetime()}`" v-if="data.value == 'approved'" class="fa fa-check-circle-o text-success"></i>
                            <i v-b-tooltip.hover :title="`${$t('review.rejected-on')}  ${new Date(data.item.approvalDate).formatDatetime()}`" v-else-if="data.value == 'rejected'" class="fa fa-times-circle-o text-danger"></i>
                            <i v-b-tooltip.hover :title="`${$t('review.pending')}`" v-else class="fa fa-clock-o text-warning"></i>
                          </template>
                          <template #cell(timestamp)="data">
                            <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
                              {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
                            </span>
                          </template>
                          <template #cell(timestampView)="data">
                            <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
                              {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
                            </span>
                          </template>
                          <template #cell(timestampClick)="data">
                            <span v-if="data.value.length" :title="new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatDate() : new Date(data.value).formatTime()" v-b-tooltip.hover>
                              {{ new Date().formatDate() == new Date(data.value).formatDate() ? new Date(data.value).formatTime() : new Date(data.value).formatDate() }}
                            </span>
                          </template>
                          <template #cell(cartValue)="data">
                            <span v-if="data.item.basketValue && data.item.basketValue != data.value">
                              <span v-if="!isNaN(data.item.basketValue)">
                                <NumberAbbreviated
                                  :value="new Number(data.item.basketValue)"
                                  :currency="true"
                                /> <i class="fa fa-info-circle" v-b-tooltip.hover :title="`${$t('review.changed-cart-value', {'value': data.value})}`"></i>
                              </span>
                              <span v-else>-</span>
                            </span>
                            <NumberAbbreviated
                              v-else-if="!isNaN(data.value)"
                              :value="data.value"
                              :currency="true"
                            />
                            <span v-else>-</span>
                          </template>
                          <template #cell(cost)="data">
                            <span v-if="data.item.commission !== null">
                              <span v-if="!isNaN(data.item.commission)">
                                <NumberAbbreviated
                                  :value="new Number(data.item.commission)"
                                  :currency="true"
                                />
                              </span>
                              <span v-else>-</span>
                            </span>
                            <NumberAbbreviated
                              v-else-if="!isNaN(data.value)"
                              :value="data.value"
                              :currency="true"
                            />
                            <span v-else>-</span>
                          </template>
                          <template #cell(fee)="data">
                            <NumberAbbreviated
                              v-if="!isNaN(data.value)"
                                :value="data.value"
                                :currency="true"
                              />
                            <span v-else>-</span>
                          </template>
                          <template #cell(channel)="data">
                            <b-img
                              :src="data.value.image"
                              :alt="data.value.name"
                              :title="data.value.name"
                              style="width: 20px; height: 20px;"
                              v-b-tooltip.hover
                            ></b-img>
                          </template>
                          <template #cell(creative)="data">
                            <AdListPreview :creative="data.item.banner" :onlyButton="true" />
                          </template>
                        </b-table>
                      </div>
                      <Pagination class="mt-4" :itemCount="publisherConversionTableData.length" :currentPage="currentPagePublisherConversions" :perPage="perPagePublisherConversions" @changeQuantity="changeQuantityPublisherConversions" @changePage="changePagePublisherConversions"></Pagination>
                    </div>
                  </b-carousel-slide>
                </b-carousel>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="!loading && !error && !emptyData">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              content-class="mt-3"
              align="center"
              active-nav-item-class="nav-item-stats-active"
              pills>
              <b-tab :title="$tc('general.click', 2)" active title-link-class="nav-item-stats">
                <line-chart :chart-data="clicksChart" :options="chartOptions" />
              </b-tab>
              <b-tab :title="$tc('general.impression', 2)" lazy title-link-class="nav-item-stats">
                <line-chart :chart-data="impressionsChart" :options="chartOptions" />
              </b-tab>
              <!--<b-tab :title="$tc('general.conversion_click', 2)" lazy title-link-class="nav-item-stats">
                <line-chart :chart-data="pcConversionsChart" :options="chartOptions" />
              </b-tab>
              <b-tab :title="$tc('general.conversion_view', 2)" lazy title-link-class="nav-item-stats">
                <line-chart :chart-data="pvConversionsChart" :options="chartOptions" />
              </b-tab>-->
              <b-tab :title="$tc('general.conversion', 2)" lazy title-link-class="nav-item-stats">
                <line-chart :chart-data="conversionsChart" :options="chartOptions" />
              </b-tab>
              <b-tab title="CTR" lazy title-link-class="nav-item-stats">
                <line-chart :chart-data="ctrChart" :options="chartOptions" />
              </b-tab>
              <b-tab title="CR" lazy title-link-class="nav-item-stats">
                <line-chart :chart-data="crChart" :options="chartOptions" />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    
    <ConfirmModal
      id="confirmModal"
      :titleText="confirmModal.title"
      :bodyText="confirmModal.body"
      :finishButtonText="confirmModal.finishButtonText"
      :cancelButtonText="confirmModal.cancelButtonText"
      finishButtonVariant="success"
      cancelButtonVariant="secondary"
      :finishEvent="confirmModal.finishFunction"
      @removeWebsite="removeWebsite"
      :cancelEvent="confirmModal.cancelRemoveWebsite"
      @cancelRemoveWebsite="confirmModal.cancelFunction"
    />
    <b-modal id="download-stats-modal" :title="$t('actions.download-stats')" :hide-footer="true" size="lg">
      <DownloadStatsModal :stats="stats" :statsRows="statsRows" @download-stats="downloadStats" />
    </b-modal>
  </div>
</template>

<script>
import AdListPreview from "@/views/ads/adlist/components/AdListPreview.vue";
import NumberAbbreviated from "@/components/NumberAbbreviated";
import { mapGetters  } from "vuex";
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import LineChart from "../../charts/Line";
import CHANNELS from "@/constants/channels.js";
import ConfirmModal from "@/components/modals/ConfirmModal";
import DownloadStatsModal from "@/components/modals/DownloadStatsModal";
import Pagination from "@/components/pages/assets/Pagination.vue";
const jwtDecode = require('jwt-decode');

export default {
  name: "CampaignStatsNew",
  data() {
    let locale = 'en';
    if(this.$i18n.locale) locale = this.$i18n.locale;
    return {
      minAllowedDate: new Date(),
      emptyData: false,
      hasAffiliate: false,
      showConversions: false,
      showConversionsApproval: false,
      showClicksApproval: false,
      activePublisher: null,
      statsResponseOriginal: null,
      conversionTableEntry: {},
      pagination: 10,
      currentPageWebsites: 1,
      currentPageConversions: 1,
      perPageConversions: 10,
      perPageWebsites: 10,
      bTabsIndex: 0,
      confirmModal: {
        title: '',
        body: '',
        finishButtonText: this.$t("actions.save"),
        cancelButtonText: this.$t("actions.cancel"),
        cancelFunction: "cancelRemoveWebsite",
        finishFunction: "removeWebsite",
        object: null,
      },
      locale: locale,
      tableDetails: false,
      campaignName: '',
      error: '',
      statisticsSubscription: [
        { text: "Daily", value: "daily", oldstatus: false, status: false },
        { text: "Weekly", value: "weekly", oldstatus: false,  status: false },
        { text: "Monthly", value: "monthly", oldstatus: false,  status: false }
      ],
      updatingSubscriptions: false,
      currentDaterange: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      channelTableData: [],
      channelTableFields: [
        {
          key: 'channel',
          label: this.$tc('general.channel', 1),
          class: "text-left",
        },
        {
          key: 'impressions',
          label: this.$tc('general.impression', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'clicks',
          label: this.$tc('general.click', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'conversions',
          label: this.$tc('general.conversion', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'ctr',
          label: 'CTR',
          class: "text-right",
          sortable: true
        },
        {
          key: 'cr',
          label: 'CR',
          class: "text-right",
          sortable: true
        },
        // {
        //   key: 'pcConversions',
        //   label: this.$tc('general.conversion_click', 2),
        //   class: "text-right",
        //   sortable: true
        // },
        // {
        //   key: 'pvConversions',
        //   label: this.$tc('general.conversion_view', 2),
        //   class: "text-right",
        //   sortable: true
        // },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
          sortable: true
        }
      ],
      dateTableData: [],
      dateTableFields: [
        {
          key: 'date',
          label: this.$t('general.date'),
          class: "text-left",
        },
        {
          key: 'impressions',
          label: this.$tc('general.impression', 2),
          class: "text-right",
        },
        {
          key: 'clicks',
          label: this.$tc('general.click', 2),
          class: "text-right",
        },
        {
          key: 'conversions',
          label: this.$tc('general.conversion', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'ctr',
          label: 'CTR',
          class: "text-right",
          sortable: true
        },
        {
          key: 'cr',
          label: 'CR',
          class: "text-right",
          sortable: true
        },
        // {
        //   key: 'pcConversions',
        //   label: this.$tc('general.conversion_click', 2),
        //   class: "text-right",
        //   sortable: true
        // },
        // {
        //   key: 'pvConversions',
        //   label: this.$tc('general.conversion_view', 2),
        //   class: "text-right",
        //   sortable: true
        // },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
        },
      ],
      websiteTableFields: [
        {
          key: 'location',
          class: "wrap-normal text-left",
          label: this.$tc('general.website', 1),
          sortable: true,
        },
        {
          key: 'impressions',
          sortable: true,
          label: this.$tc('general.impression', 2),
          class: "text-right",
        },
        {
          key: 'clicks',
          label: this.$tc('general.click', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'conversions',
          label: this.$tc('general.conversion', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'ctr',
          label: 'CTR',
          class: "text-right",
          sortable: false
        },
        {
          key: 'cr',
          label: 'CR',
          class: "text-right",
          sortable: false
        },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
          sortable: true
        },
        {
          key: "remove",
          label: this.$tc('general.action', 1),
          class: "text-right"
        }
        // {
        //   key: 'pcConversions',
        //   label: this.$tc('general.conversion_click', 2),
        //   class: "text-right",
        //   sortable: true
        // },
        // {
        //   key: 'pvConversions',
        //   label: this.$tc('general.conversion_view', 2),
        //   class: "text-right",
        //   sortable: true
        // },
      ],
      websiteTableData: [],
      websitesLoading: false,
      websitesButtonDisabled: true,
      websiteSelectedChannels: [],
      websitesSort: { sortBy: 'clicks', sortDesc: true },
      creativeTableData: [],
      creativeTableFields: [
        {
          key: 'name',
          label: this.$t('general.name'),
          class: "text-left",
        },
        {
          key: 'impressions',
          label: this.$tc('general.impression', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'clicks',
          label: this.$tc('general.click', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'conversions',
          label: this.$tc('general.conversion', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'ctr',
          label: 'CTR',
          class: "text-right",
          sortable: true
        },
        {
          key: 'cr',
          label: 'CR',
          class: "text-right",
          sortable: true
        },
        // {
        //   key: 'pcConversions',
        //   label: this.$tc('general.conversion_click', 2),
        //   class: "text-right",
        //   sortable: true
        // },
        // {
        //   key: 'pvConversions',
        //   label: this.$tc('general.conversion_view', 2),
        //   class: "text-right",
        //   sortable: true
        // },
      ],
      clicksChart: null,
      impressionsChart: null,
      // pcConversionsChart: null,
      // pvConversionsChart: null,
      conversionsChart: null,
      ctrChart: null,
      crChart: null,
      conversionsLoading: false,
      conversionTableData: [],
      conversionTableFields: [
        {
          key: 'timestamp',
          label: this.$t('general.date'),
          class: "text-left",
          sortable: true,
        },
        {
          key: 'orderId',
          label: `${this.$tc('general.conversion', 1)} ID`,
          class: "text-left",
          sortable: true
        },
        {
          key: 'type',
          label: this.$tc('general.order', 1)+" "+this.$tc('general.type', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'channel',
          label: this.$tc('general.channel', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'creative',
          label: this.$tc('general.creative', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'location',
          label: this.$tc('general.website', 1),
          class: "text-left",
          sortable: true
        },
        {
          key: 'approvalStatus',
          label: this.$tc('general.status', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'cartValue',
          label: this.$t('general.cart'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'fee',
          label: this.$t('labels.servicefee'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'timestampView',
          label: `${this.$tc('general.view',1)}`,
          class: "text-left",
          sortable: true
        },
        {
          key: 'timestampClick',
          label: `${this.$tc('general.click', 1)}`,
          class: "text-left",
          sortable: true
        },
        // {
        //   key: 'actions',
        //   label: 'Actions',
        //   class: 'text-right',
        //   sortable: false,
        // }
      ],

      // for affilaite 
      publisherTableData: [],
      publisherTableFields: [
        {
          key: 'name',
          label: this.$tc('general.publisher',1),
          class: "text-left",
        },
        {
          key: 'impressions',
          label: this.$tc('general.impression', 2),
          class: "text-right",
        },
        {
          key: 'clicks',
          label: this.$tc('general.click', 2),
          class: "text-right",
        },
        {
          key: 'conversions',
          label: this.$tc('general.conversion', 2),
          class: "text-right",
          sortable: true
        },
        {
          key: 'ctr',
          label: 'CTR',
          class: "text-right",
          sortable: true
        },
        {
          key: 'cr',
          label: 'CR',
          class: "text-right",
          sortable: true
        },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
        },
        {
          key: 'actions',
          label: '',
          class: "text-right",
        },
      ],

      publisherDateTableData: [],
      publisherCreativesTableData: [],
      publisherWebsiteTableData: [],
      publisherConversionTableData: [],

      currentPagePublisherWebsites: 1,
      perPagePublisherWebsites: 10,
      currentPagePublisherConversions: 1,
      perPagePublisherConversions: 10,

      publisherTotalImpressions: 0,
      publisherTotalClicks: 0,
      publisherTotalConversions: 0,
      publisherTotalCost: 0,
      publisherTotalCtr: 0,
      publisherTotalCr: 0,

      publisherDetails: '',
      publisherSlide: 0,
      publisherBusy: false,
    };
  },
  props: ["id"],
  mounted() {
    this.$root.$on('bv::dropdown::hide', () => {
      // check if changed
      let save = false;
      for(let s = 0; s < this.statisticsSubscription.length; s++){
        if(this.statisticsSubscription[s].status != this.statisticsSubscription[s].oldstatus){
          this.statisticsSubscription[s].oldstatus = this.statisticsSubscription[s].status;
          save = true;
          break;
        }
      }

      if(save){
        this.saveStatisticsSubscription();
      }
    })
  },
  async created() {
    this.minAllowedDate.setDate(this.minAllowedDate.getDate() - 1)
    this.minAllowedDate.setMonth(this.minAllowedDate.getMonth() - 6) // 6 Months max
    
    if(this.isViewer){
      this.$router.push('/403')
    }
    this.currentDaterange = this.dateRange;

    await this.reloadStats();

    this.activateAllChannelsForWebsites();

    const userSubscriptions = this.subscriptions;

    if(Array.isArray(userSubscriptions)){
      for(let s = 0; s < userSubscriptions.length; s++){
        if(userSubscriptions[s].campaign == this.id){
          if(userSubscriptions[s].daily){
            this.statisticsSubscription[0].status = true;
            this.statisticsSubscription[0].oldstatus = true;
          }
          if(userSubscriptions[s].weekly){
            this.statisticsSubscription[1].status = true;
            this.statisticsSubscription[1].oldstatus = true;
          }
          if(userSubscriptions[s].monthly){
            this.statisticsSubscription[2].status = true;
            this.statisticsSubscription[2].oldstatus = true;
          }

          break;
        }
      }
    }
  },
  methods: {
    publisherBack(){
      const publisherIndex = this.statsResponseOriginal.publishers.findIndex(publisher => publisher._id == this.activePublisher);

      if(publisherIndex == 0){
        // go to first slide, the overview
        this.publisherSlide = 0;
        this.activePublisher = null;
      }
      else{
        this.activePublisher = this.statsResponseOriginal.publishers[publisherIndex - 1]._id;
      }
    },
    publisherNext(){
      const publisherIndex = this.statsResponseOriginal.publishers.findIndex(publisher => publisher._id == this.activePublisher);

      if(publisherIndex == this.statsResponseOriginal.publishers.length - 1){
        // go to first slide, the overview
        this.publisherSlide = 0;
        this.activePublisher = null;
      }
      else{
        this.activePublisher = this.statsResponseOriginal.publishers[publisherIndex + 1]._id;
      }
    },
    showPublisherDetails(publisherId){
      this.publisherBusy = true;
      this.activePublisher = publisherId;
      this.publisherDateTableData = [];
      this.publisherCreativesTableData = [];
      this.publisherWebsiteTableData = [];
      this.publisherConversionTableData = [];

      this.publisherTotalImpressions = 0;
      this.publisherTotalClicks = 0;
      this.publisherTotalConversions = 0;
      this.publisherTotalCost = 0;
      this.publisherTotalCtr = 0;
      this.publisherTotalCr = 0;


      const publisherStats = this.statsResponseOriginal.stats.filter(stat => stat.publisher == publisherId);

      // dates stats for this publisher
      const dates = [...new Set(this.statsResponseOriginal.stats.map(item => item.date))].sort(function(a,b) {
        a = a.split('-').join('');
        b = b.split('-').join('');
        return a > b ? 1 : a < b ? -1 : 0;
      });

      for (const date of dates) {

        const datasDates = publisherStats.filter(stat => stat.date == date);

        const reportDates = {
          date,
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          ctr: 0,
          cr: 0,
        }

        for (const data of datasDates) {
          data.conversions = data.pvConversions+data.pcConversions;

          reportDates.impressions += data.impressions;
          reportDates.clicks += data.clicks;
          reportDates.cost += data.cost;
          reportDates.conversions += data.conversions;

          data.cost = Math.round((data.cost + Number.EPSILON) * 100) / 100;
        }

        reportDates.ctr = reportDates.impressions > 0 ? this.parseNumber(reportDates.clicks / reportDates.impressions) : 0;
        reportDates.cr = reportDates.clicks > 0 ? this.parseNumber(reportDates.conversions / reportDates.clicks) : 0;
        reportDates.cost = Math.round((reportDates.cost + Number.EPSILON) * 100) / 100;

        this.publisherDateTableData.push(reportDates);

        // totals
        this.publisherTotalImpressions += reportDates.impressions;
        this.publisherTotalClicks += reportDates.clicks;
        this.publisherTotalConversions += reportDates.conversions;
        this.publisherTotalCost += reportDates.cost;
      }

      this.publisherTotalCtr = this.publisherTotalImpressions > 0 ? this.parseNumber(this.publisherTotalClicks / this.publisherTotalImpressions) : 0;
      this.publisherTotalCr = this.publisherTotalClicks > 0 ? this.parseNumber(this.publisherTotalConversions / this.publisherTotalClicks) : 0;

      // creatives stats for this publisher

      const publisherBanners = [...new Set(publisherStats.map(item => item.banner))];

      for (const bannerId of publisherBanners) {

        const banner = this.statsResponseOriginal.banners.find(banner => banner._id == bannerId);

        const datas = publisherStats.filter(stat => stat?.banner == bannerId);

        const report = {
          banner: {...banner, show: false},
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          ctr: 0,
          cr: 0,
          channels: []
        }

        for (const data of datas) {
          data.conversions = data.pvConversions+data.pcConversions;

          report.impressions += data.impressions;
          report.clicks += data.clicks;
          report.cost += data.cost;
          report.conversions += data.conversions;
        }

        report.channels = report.channels.sort(function (a, b) { return b.key > a.key ? -1 : b.key < a.key ? 1 : 0 });
        report.ctr = report.impressions > 0 ? this.parseNumber(report.clicks / report.impressions) : 0;
        report.cr = report.clicks > 0 ? this.parseNumber(report.conversions / report.clicks) : 0;
        report.cost = Math.round((report.cost + Number.EPSILON) * 100) / 100;
        if(report.impressions + report.clicks + report.conversions + report.cost > 0){
          this.publisherCreativesTableData.push(report);
        }
      }

      // website stats for this publisher
      const publisherWebsiteStats = this.statsResponseOriginal.websites.filter(website => website.publisher == publisherId);

      if(publisherWebsiteStats.length){

        const distincsWebsites = [...new Set(publisherWebsiteStats.map(item => item.location))];

        for(const website of distincsWebsites){
          const websiteStats = publisherWebsiteStats.filter(w => w.location == website);

          const impressions = websiteStats.reduce((acc, cur) => {
            return acc + cur.impressions;
          }, 0);

          const clicks = websiteStats.reduce((acc, cur) => {
            return acc + cur.clicks;
          }, 0);

          const pcConversions = websiteStats.reduce((acc, cur) => {
            return acc + cur.pcConversions;
          }, 0);

          const pvConversions = websiteStats.reduce((acc, cur) => {
            return acc + cur.pvConversions;
          }, 0);

          const cost = websiteStats.reduce((acc, cur) => {
            return acc + cur.cost;
          }, 0);

          const ctr = impressions > 0 ? this.parseNumber(clicks / impressions) : 0;
          const cr = clicks > 0 ? this.parseNumber((pcConversions+pvConversions) / clicks) : 0;

          const websiteObj = {
            location: website,
            impressions,
            clicks,
            pcConversions,
            pvConversions,
            ctr,
            cr,
            cost,
          };

          this.publisherWebsiteTableData.push(websiteObj);
        }

        // conversios stats
        const publisherConversions = this.statsResponseOriginal.conversions.filter(conversion => conversion.publisher == publisherId);
        this.publisherConversionTableData = publisherConversions;
      }

      this.publisherBusy = false;
    },
    changePagePublisherWebsites(page){
      this.currentPagePublisherWebsites = page;
    },
    changePagePublisherConversions(page){
      this.currentPagePublisherConversions = page;
    },
    changeQuantityPublisherWebsites(quantity){
      this.perPagePublisherWebsites = quantity;
    },
    changeQuantityPublisherConversions(quantity){
      this.perPagePublisherConversions = quantity;
    },
    changePageWebsites(page){
      this.currentPageWebsites = page;
    },
    changeQuantityWebsites(quantity){
      this.perPageWebsites = quantity;
    },
    changePageConversions(page){
      this.currentPageConversions = page;
    },
    changeQuantityConversions(quantity){
      this.perPageConversions = quantity;
    },
    downloadStats(stats, statsRows, selectedSort){
      this.$bvModal.hide('download-stats-modal');
      //order rows, so they are always in the same order as the TableFields specified here
      let orderedStatsRows = Object.keys(statsRows).reduce((obj, key) => {
        obj[key] = this.statsRows[key].filter(field => statsRows[key].includes(field.key)).map(field => field.key);
        return obj;
      }, {});
      //now that the rows are ordered, we can create all the csv. Sorted
      for (const stat of stats) {
        let csv = `${orderedStatsRows[stat].join(',')}\r\n`;
        const sortBy = selectedSort[stat].sortBy;
        const sortDesc = selectedSort[stat].sortOrder == 'DESC';
        const sortedItems = this[`${stat}TableData`].slice().sort((a, b) => {

          let aa = a[sortBy];
          let bb = b[sortBy];

          if(stat == 'creative' && ['banner_id', 'bannerheight', 'bannerwidth'].includes(sortBy)){
            aa = a?.banner?.[sortBy.substring(6)];
            bb = b?.banner?.[sortBy.substring(6)];
          }

          aa = this.normalizeValue(aa)
          bb = this.normalizeValue(bb)

          if ((aa instanceof Date && bb instanceof Date) || (typeof aa === 'number' && typeof bb === 'number')) {
            let result = aa < bb ? -1 : aa > bb ? 1 : 0;
            return (result || 0) * (sortDesc ? -1 : 1)
          } else if (aa === '' && bb !== '') {
            let result = 1;
            return (result || 0) * (sortDesc ? -1 : 1)
          } else if (aa !== '' && bb === '') {
            let result = -1;
            return (result || 0) * (sortDesc ? -1 : 1)
          }

          // Do localized string comparison
          let result = this.stringifyObjectValues(aa).localeCompare(this.stringifyObjectValues(bb), undefined, { numeric: true, usage: 'sort' });
          return (result || 0) * (sortDesc ? -1 : 1);
        })
        
        for (const row of sortedItems) {
          csv += orderedStatsRows[stat].map(key => {
            //handle some edge cases, like banner name, unkown website etc.

            if(stat == 'creative'){
              //replace all , with ; to avoid csv errors
              if(key == 'name'){
                return row?.banner?.name?.replace(/,/g, ';');
              }
              else if(['banner_id', 'bannerheight', 'bannerwidth'].includes(key)){
                return row?.banner?.[key.substring(6)];
              }
            }
            else if(stat == 'channel'){
              if(key == 'channel'){
                return row[key]?.name ?? row[key];
              }
            }
            else if(stat == 'website'){
              if(key == 'location' && row[key] == null){
                return 'unknown';
              }
            }
            else if(stat == 'publisher'){
              if(key == 'name'){
                return row?.publisher?.name?.replace(/,/g, ';');
              }
              else if(key == 'url'){
                return row?.publisher?.url?.replace(/,/g, ';');
              }
              else if(key == 'publisher_id'){
                return row?.publisher?._id?.replace(/,/g, ';');
              }
            }
            else if(stat == 'conversion'){
              if(key == 'approvalStatus'){
                if(row[key] == 'approved'){
                  return this.$t('review.approved');
                }
                else if(row[key] == 'rejected'){
                  return this.$t('review.rejected');
                }
                else{
                  return this.$t('review.pending');
                }
              }
              if(key == 'cartValue'){
                if(isNaN(row[key])){
                  return '-';
                }
                else{
                  return row[key];
                }
              }
            }

            if(row[key]?.key) return row[key].key;
            if(row[key]?._id) return row[key]._id;
            return row[key]
          })
          .join(',') + '\r\n';
        }
        /* console.log(csv) */
        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        anchor.target = "_blank";
        anchor.download = `${this.campaignName}_${new Date(this.dateRange.startDate).toLocaleDateString().replace(/\//g, '-')}-${new Date(this.dateRange.endDate).toLocaleDateString().replace(/\//g, '-')}_${stat}.csv`;
        anchor.click();
      }
    },
    stringifyObjectValues(value){
      if (value === null || value === undefined) {
        return ''
      }
      if ((value !== null && typeof value === 'object') && !(value instanceof Date)) {
        return Object.keys(value)
          .sort() // Sort to prevent SSR issues on pre-rendered sorted tables
          .map(k => this.stringifyObjectValues(value[k]))
          .filter(v => !!v) // Ignore empty strings
          .join(' ')
      }
      return toString(value)
    },
    normalizeValue(value) {
      if (value === null || value === undefined) {
        return ''
      }
      if (this.isNumeric(value)) {
        return isNaN(parseFloat(value)) ? NaN : parseFloat(value)
      }
      return value
    },
    isNumeric(value){
      /^[0-9]*\.?[0-9]+$/.test(String(value))
    },
    openDownloadStatsModal(){
      this.$bvModal.show('download-stats-modal');
    },
    getCreativeCountForEachChannel(){
      let channels = this.channelTableData.reduce((acc, cur) => {
        acc[cur.channel.key] = 0;
        return acc;
      }, {});
      this.creativeTableData.forEach(creative => {
        creative.channels.forEach(channel => {
          channels[channel.key]++;
        });
      });
      return channels;
    },
    parseNumber(number) {
      return Math.round(parseFloat(number || 0).toFixed(4) * 100 * 100) / 100
    },
    amountOfDays() {
      let startD = new Date(this.dateRange.startDate);
      let endD = new Date(this.dateRange.endDate);

      let aod = parseInt(
        (new Date(endD.getUTCFullYear(), endD.getUTCMonth(), endD.getUTCDate()) -
          new Date(startD.getUTCFullYear(), startD.getUTCMonth(), startD.getUTCDate())) /
          (1000 * 3600 * 24) +
          1
      );
      return aod;
    },
    formatNumber(number) {
      let format = this.locale == 'de' ? "de-DE" : "en-EN";
      return new Intl.NumberFormat(format).format(number);
    },
    getChannelDetails(key) {
      for (let i = 0; i < CHANNELS.length; i++) {
        if (CHANNELS[i].key == key) {
          // console.log(CHANNELS[i]);
          return CHANNELS[i];
        }
      }
      return false;
    },
    pickerDate(picker){
      let str = picker.startDate ? picker.startDate.formatDate(true) : null;
      const endStr = picker.endDate ? picker.endDate.formatDate(true) : null;

      if(str !== endStr){
        str += ' - '+endStr;
      }

      return str;
    },
    async saveStatisticsSubscription(){
      this.updatingSubscriptions = true;

      let userSubscriptions = this.subscriptions;

      let append = true;
      if(Array.isArray(userSubscriptions)){

        for(let s = 0; s < userSubscriptions.length; s++){
          if(userSubscriptions[s].campaign == this.id){

            userSubscriptions[s].daily = this.statisticsSubscription[0].status,
            userSubscriptions[s].weekly = this.statisticsSubscription[1].status,
            userSubscriptions[s].monthly = this.statisticsSubscription[2].status

            append = false;
            break;
          }
        }
      }
      else{
        userSubscriptions = [];
      }

      if(append){
        userSubscriptions.push({
          campaign: this.id,
          daily: this.statisticsSubscription[0].status,
          weekly: this.statisticsSubscription[1].status,
          monthly: this.statisticsSubscription[2].status
        });
      }

      const payload = {
        email: this.userEmail,
        subscriptions: userSubscriptions,
        setResultAsCurrentUser: true,
      };

      await this.$store.dispatch("updateUser", payload);

      this.subscriptions = userSubscriptions;

      this.statisticsSubscription[0].oldstatus = this.statisticsSubscription[0].status;
      this.statisticsSubscription[1].oldstatus = this.statisticsSubscription[1].status;
      this.statisticsSubscription[2].oldstatus = this.statisticsSubscription[2].status;

      this.updatingSubscriptions = false;
    },
    async sortWebsites(sortSettings){
      this.websitesSort = sortSettings;
      await this.updateWebsites();
    },
    confirmRemoveWebsite(location){
      // generate channels string
      let channelStr = '';
      for(const channel of this.websiteSelectedChannels.filter(sc => this.bannerAdsChannelIds.includes(sc) )){
        const ch = this.getChannelDetails(channel);
        channelStr += `<img style="width: 25px; height: 25px;" class="mr-1" src="${ch.image}" title="${ch.name}" alt="${channel}" />`;
      }

      this.confirmModal.object = location;
      this.confirmModal.title = this.$t('actions.remove-website', {name: location}),
      this.confirmModal.body = this.$t('messages.remove-website', {name: location, channels: channelStr}),
      this.$bvModal.show('confirmModal');
    },
    cancelRemoveWebsite(){
      this.confirmModal.object = null;
      this.confirmModal.title = "",
      this.confirmModal.body = ""
    },
    async removeWebsite(location) {
      location = this.confirmModal.object;

      try {
        this.$api.campaign.updateDomainlist(this.id, {
          name: location, 
          channels: this.websiteSelectedChannels
        });
      } catch (e) {
        /* console.log(e) */
      }
    },
    generateWebsiteStats(websites){
      if(websites?.length){

        this.websiteTableData = [];

        const distincsWebsites = [...new Set(websites.map(item => item.location))];

        for(const website of distincsWebsites){
          const websiteStats = websites.filter(w => w.location == website);

          const impressions = websiteStats.reduce((acc, cur) => {
            return acc + cur.impressions;
          }, 0);

          const clicks = websiteStats.reduce((acc, cur) => {
            return acc + cur.clicks;
          }, 0);

          const pcConversions = websiteStats.reduce((acc, cur) => {
            return acc + cur.pcConversions;
          }, 0);

          const pvConversions = websiteStats.reduce((acc, cur) => {
            return acc + cur.pvConversions;
          }, 0);

          const cost = websiteStats.reduce((acc, cur) => {
            return acc + cur.cost;
          }, 0);

          const ctr = impressions > 0 ? this.parseNumber(clicks / impressions) : 0;
          const cr = clicks > 0 ? this.parseNumber((pcConversions+pvConversions) / clicks) : 0;

          const app = websiteStats?.[0]?.app ? true : false;

          const websiteObj = {
            location: website,
            impressions,
            clicks,
            pcConversions,
            pvConversions,
            ctr,
            cr,
            cost,
            app: app ? 1 : 0,
            remove: app ? "" : website
          };

          this.websiteTableData.push(websiteObj);
        }

        // calculate ctr and cr
        // websites.forEach(website => {
        //   website.ctr = website.impressions > 0 ? this.parseNumber(website.clicks / website.impressions) : 0;
        //   website.cr = website.clicks > 0 ? this.parseNumber(website.conversions / website.clicks) : 0;
        //   website.remove = website.location;
        // });
      }
      else{
        this.websiteTableData = [];
      }
      this.websitesLoading = false;
    },
    getLocaleFromCurrency(currency){
      switch(currency){
        case 'EUR':
          return 'de';
        case 'USD':
          return 'en';
        default:
          return 'en';
      }
    },
    getFullDateFromDate(date){
      // in format dd/mm/yyyy
      const d = new Date(date);
      return d.toLocaleDateString('de');
      // return `${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`;
    },
    getFullTimeFromDate(date){
      // in format hh:mm:ss (24h)
      const d = new Date(date);
      return d.toLocaleTimeString('de');
      // return `${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}:${('0' + d.getSeconds()).slice(-2)}`;
    },
    async reloadStats() {
      this.$store.commit('LOADING_ACTIVE')
      // clear current values
      this.error = '';
      this.channelTableData = [];
      this.dateTableData = [];
      this.creativeTableData = [];
      this.conversionTableData = [];
      this.websiteTableData = [];
      this.publisherTableData = [];
      this.publisherDateTableData = [];
      this.publisherCreativeTableData = [];
      this.publisherConversionTableData = [];

      this.clicksChart = null;
      this.impressionsChart = null;
      // this.pcConversionsChart = null;
      // this.pvConversionsChart = null;
      this.conversionsChart = null;
      this.emptyData = false;
      this.activePublisher = null;

      try {
        const response = await this.$api.campaign.getCampaignReport({ id: this.id, start: this.dateRange.startDate, end: this.dateRange.endDate }, { enableLoading: false });
        this.campaignName = response.campaignName;
        this.showConversionsApproval = response.conversionApproval;
        this.showClicksApproval = response.clickApproval;

        if(response){
          this.showConversions = response.conversions?.length > 0;
          
          this.statsResponseOriginal = response;
          const promises = [];

          promises.push(this.generateWebsiteStats(response.websites));
          promises.push(this.generateConversionsStats(response.conversions));

          if(response.stats.length) {

            const countAll = response.stats.reduce(
              (acc, current) => acc + current.impressions + current.clicks + current.conversions + current.costs,
              0
            );

            if(countAll == 0){
              /* console.log("EMPTY") */
              this.emptyData = true;
              return;
            }

            const dateSortedStats = response.stats.sort(function(a,b) {
              a = a.date.split('-').join('');
              b = b.date.split('-').join('');
              return a > b ? 1 : a < b ? -1 : 0;
            });

            promises.push(this.generateChannelStatsAndCharts(dateSortedStats));
            promises.push(this.generateDateStats(dateSortedStats));
            promises.push(this.generateCreativeStats(dateSortedStats, response.banners));

            if(response?.publishers?.length){
              this.hasAffiliate = true;
              promises.push(this.generatePublisherStats(dateSortedStats, response.publishers));
            }
          }
          else{
            this.emptyData = true;
          }

          if(promises.length){
            Promise.all(promises);
          }
        }
        else{
          this.emptyData = true;
        }
      } catch(error) {
        /* console.log(error) */
        this.error = "Could not get information about the selected campaign"
      }
      this.$store.commit('LOADING_INACTIVE')
    },
    toggleWebsiteSelectedChannels(channelKey){
      if(this.websiteSelectedChannels.includes(channelKey)){
        this.websiteSelectedChannels = this.websiteSelectedChannels.filter(function(e) { return e !== channelKey })

        if(this.websiteSelectedChannels == 0){
          this.activateAllChannelsForWebsites();
        }
      }
      else  this.websiteSelectedChannels.push(channelKey);

      this.websitesButtonDisabled = false;
    },
    activateAllChannelsForWebsites(){
      this.websiteSelectedChannels = this.websitePossibleChannels.map(c => c.key);
    },
    async updateWebsites(){
      this.websitesButtonDisabled = true;
      this.websitesLoading = true;
      const response = await this.$api.campaign.getCampaignReport({ sortBy: this.websitesSort.sortBy, sortDesc: this.websitesSort.sortDesc, onlyWebsites: true, channels: this.websiteSelectedChannels, id: this.id, start: this.dateRange.startDate, end: this.dateRange.endDate }, { enableLoading: false });
      if(response){
        this.generateWebsiteStats(response.websites);
      }
    },
    generateDateStats(stats){

      const dates = [...new Set(stats.map(item => item.date))];
      for (const date of dates) {
        const datasDates = stats.filter(stat => stat.date == date);

        const reportDates = {
          date,
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          ctr: 0,
          cr: 0,
          channels: []
        }

        for (const data of datasDates) {
          data.conversions = data.pvConversions+data.pcConversions;

          reportDates.impressions += data.impressions;
          reportDates.clicks += data.clicks;
          reportDates.cost += data.cost;
          reportDates.conversions += data.conversions;

          data.cost = Math.round((data.cost + Number.EPSILON) * 100) / 100;

          if(data.impressions + data.clicks + data.conversions + data.cost > 0){
            const channelDetails = this.getChannelDetails(data.channel);
            if(channelDetails){
              // check if channel already in array
              const channelIndex = reportDates.channels.findIndex(x => x.channelDetails.key == channelDetails.key);
              if(channelIndex == -1){
                data.channelDetails = channelDetails;

                reportDates.channels.push({
                  channel: channelDetails.key,
                  channelDetails: channelDetails,
                  impressions: data.impressions,
                  clicks: data.clicks,
                  cost: data.cost,
                  conversions: data.conversions,
                  ctr: data.impressions > 0 ? this.parseNumber(data.clicks / data.impressions) : 0,
                  cr: data.clicks > 0 ? this.parseNumber(data.conversions / data.clicks) : 0
                });
              } else {
                // update values
                reportDates.channels[channelIndex].impressions += data.impressions;
                reportDates.channels[channelIndex].clicks += data.clicks;
                reportDates.channels[channelIndex].conversions += data.conversions;
                reportDates.channels[channelIndex].cost = Math.round((reportDates.channels[channelIndex].cost + data.cost + Number.EPSILON) * 100) / 100;
                reportDates.channels[channelIndex].ctr = reportDates.channels[channelIndex].impressions > 0 ? this.parseNumber(reportDates.channels[channelIndex].clicks / reportDates.channels[channelIndex].impressions) : 0;
                reportDates.channels[channelIndex].cr = reportDates.channels[channelIndex].clicks > 0 ? this.parseNumber(reportDates.channels[channelIndex].conversions / reportDates.channels[channelIndex].clicks) : 0;
              }
            }
          }
        }

        reportDates.channels = reportDates.channels.sort(function (a, b) { return b.channelDetails.key > a.channelDetails.key ? -1 : b.channelDetails.key < a.channelDetails.key ? 1 : 0 });

        reportDates.ctr = reportDates.impressions > 0 ? this.parseNumber(reportDates.clicks / reportDates.impressions) : 0;
        reportDates.cr = reportDates.clicks > 0 ? this.parseNumber(reportDates.conversions / reportDates.clicks) : 0;
        reportDates.cost = Math.round((reportDates.cost + Number.EPSILON) * 100) / 100;

        this.dateTableData.push(reportDates);
      }
    },
    generateCreativeStats(stats, banners){

      for (const banner of banners) {

        const datas = stats.filter(stat => stat?.banner == banner._id);

        const report = {
          banner: {...banner, show: false},
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          ctr: 0,
          cr: 0,
          channels: []
        }

        for (const data of datas) {
          data.conversions = data.pvConversions+data.pcConversions;

          report.impressions += data.impressions;
          report.clicks += data.clicks;
          report.cost += data.cost;
          report.conversions += data.conversions;

          if(data.impressions + data.clicks + data.conversions + data.cost > 0){
            const channelDetails = this.getChannelDetails(data.channel);
            if(channelDetails){
              // check if channel already in array
              const channelIndex = report.channels.findIndex(x => x.key == channelDetails.key);
              if(channelIndex == -1){
                report.channels.push(channelDetails);
              }
            }
          }
        }

        report.channels = report.channels.sort(function (a, b) { return b.key > a.key ? -1 : b.key < a.key ? 1 : 0 });
        report.ctr = report.impressions > 0 ? this.parseNumber(report.clicks / report.impressions) : 0;
        report.cr = report.clicks > 0 ? this.parseNumber(report.conversions / report.clicks) : 0;
        report.cost = Math.round((report.cost + Number.EPSILON) * 100) / 100;
        if(report.impressions + report.clicks + report.conversions + report.cost > 0){
          this.creativeTableData.push(report);
        }
      }
    },
    generateConversionsStats(conversions){

      this.conversionTableData = conversions.map(conversion => {
        conversion.timestamp = conversion.timestamp+"";
        conversion.timestampClick = conversion.timestampClick ? conversion.timestampClick : '';
        conversion.timestampView = conversion.timestampView ? conversion.timestampView : '';
        conversion.cost = conversion.cost ? conversion.cost : 0;
        conversion.cartValue = conversion.cartValue ? parseFloat(conversion.cartValue) : 0;
        conversion.locale = this.getLocaleFromCurrency(conversion.currency);
        conversion.banner = this.statsResponseOriginal.banners.find(banner => banner._id.toString() == conversion.creative.toString());
        conversion.channel = this.getChannelDetails(conversion.channel);
        return conversion;
      });
    },
    generatePublisherStats(stats, publishers){

      for (const publisher of publishers) {

        const datas = stats.filter(stat => stat?.publisher == publisher._id);

        const report = {
          publisher,
          impressions: 0,
          clicks: 0,
          cost: 0,
          conversions: 0,
          ctr: 0,
          cr: 0,
        }

        for (const data of datas) {
          data.conversions = data.pvConversions+data.pcConversions;

          report.impressions += data.impressions;
          report.clicks += data.clicks;
          report.cost += data.cost;
          report.conversions += data.conversions;
        }

        report.ctr = report.impressions > 0 ? this.parseNumber(report.clicks / report.impressions) : 0;
        report.cr = report.clicks > 0 ? this.parseNumber(report.conversions / report.clicks) : 0;
        report.cost = Math.round((report.cost + Number.EPSILON) * 100) / 100;
        if(report.impressions + report.clicks + report.conversions + report.cost > 0){
          this.publisherTableData.push(report);
        }
      }
    },
    generateChannelStatsAndCharts(stats){
      const clickChartData = [];
      const impressionChartData = [];
      // const pcConversionsChartData = [];
      // const pvConversionsChartData = [];
      const conversionsChartData = [];
      const ctrChartData = [];
      const crChartData = [];

      const channels = [...new Set(stats.map(item => item.channel))];
      /* console.log(channels) */
      for (const channel of channels) {
        const datasChannel = stats.filter(stat => stat.channel == channel);
        
        // Create clickChartHolder
        const clickChartValues = [];
        const impressionChartValues = [];
        // const pcConversionsChartValues = [];
        // const pvConversionsChartValues = [];
        const conversionsChartValues = [];
        const ctrChartValues = [];
        const crChartValues = [];

        const channelDetails = this.getChannelDetails(channel);

        const color = channelDetails.color;
        const chartConfig = {
          label: channelDetails.name,
          fill: false,
          tension: 0.1,
          borderColor: color,
          backgroundColor: color,
          data: []
        }
        // Datatable data holder

        const reportChannel = {
          channel: channelDetails,
          isActive: false,
          impressions: 0,
          clicks: 0,
          cost: 0,
          pcConversions: 0,
          pvConversions: 0,
          conversions: 0,
          ctr: 0,
          cr: 0
        };

        // needed because there can be multiple datas for the same channel

        let channelIndex = {};
        for (const data of datasChannel) {
          // Sum data for chart
          // save the index of the date to make it possible to sum multiple lineitems/channels
          let idx = 0;
          if(channelIndex[data.date] !== undefined) idx = channelIndex[data.date];
          else{
            idx = Object.keys(channelIndex).length;
            channelIndex[data.date] = idx;
          }

          if(clickChartValues[idx])           clickChartValues[idx] += data.clicks;
          else                                clickChartValues[idx] = data.clicks;

          if(impressionChartValues[idx])      impressionChartValues[idx] += data.impressions;
          else                                impressionChartValues[idx] = data.impressions;

          // if(pcConversionsChartValues[idx])   pcConversionsChartValues[idx] += data.pcConversions;
          // else                                pcConversionsChartValues[idx] = data.pcConversions;

          // if(pvConversionsChartValues[idx])   pvConversionsChartValues[idx] += data.pvConversions;
          // else                                pvConversionsChartValues[idx] = data.pvConversions;

          if(conversionsChartValues[idx])     conversionsChartValues[idx] += (data.pcConversions+data.pvConversions);
          else                                conversionsChartValues[idx] = (data.pcConversions+data.pvConversions);

          ctrChartValues[idx] = impressionChartValues[idx] > 0 ? this.parseNumber(clickChartValues[idx] / impressionChartValues[idx]) : 0;
          crChartValues[idx] = clickChartValues[idx] > 0 ? this.parseNumber(conversionsChartValues[idx] / clickChartValues[idx]) : 0;

          // Calculate sum for datatable
          reportChannel.impressions += data.impressions
          reportChannel.clicks += data.clicks,
          reportChannel.cost += Math.round((data.cost + Number.EPSILON) * 100) / 100,
          // report.pcConversions += data.pcConversions,
          // report.pvConversions += data.pvConversions,
          reportChannel.conversions += (data.pcConversions + data.pvConversions);
          reportChannel.isActive = data.isActive;
        }

        reportChannel.ctr = reportChannel.impressions > 0 ? this.parseNumber(reportChannel.clicks / reportChannel.impressions) : 0;
        reportChannel.cr = reportChannel.clicks > 0 ? this.parseNumber(reportChannel.conversions / reportChannel.clicks) : 0;


        // Push report table data
        this.channelTableData.push(reportChannel);
        // Push chart data
        clickChartData.push({ ...chartConfig, ...{ data: clickChartValues }});
        impressionChartData.push({ ...chartConfig, ...{ data: impressionChartValues }});
        // pcConversionsChartData.push({ ...chartConfig, ...{ data: pcConversionsChartValues }});
        // pvConversionsChartData.push({ ...chartConfig, ...{ data: pvConversionsChartValues }});
        conversionsChartData.push({ ...chartConfig, ...{ data: conversionsChartValues }});
        ctrChartData.push({ ...chartConfig, ...{ data: ctrChartValues }});
        crChartData.push({ ...chartConfig, ...{ data: crChartValues }});

        const dates = [...new Set(stats.map(item => item.date))];

        this.clicksChart = {
          labels: dates,
          datasets: clickChartData
        };

        this.impressionsChart = {
          labels: dates,
          datasets: impressionChartData
        };

        this.ctrChart = {
          labels: dates,
          datasets: ctrChartData
        };

        this.crChart = {
          labels: dates,
          datasets: crChartData
        };

        // this.pcConversionsChart = {
        //   labels: dates,
        //   datasets: pcConversionsChartData
        // };

        // this.pvConversionsChart = {
        //   labels: dates,
        //   datasets: pvConversionsChartData
        // };

        this.conversionsChart = {
          labels: dates,
          datasets: conversionsChartData
        };
      }
    }
  },
  computed: {
    ...mapGetters([
      "loading",
      "success",
      "warning"
    ]),
    publisherNextActive(){
      const publisherIndex = this.statsResponseOriginal.publishers.findIndex(publisher => publisher._id == this.activePublisher);
      return (publisherIndex == this.statsResponseOriginal.publishers.length - 1);
    },
    publisherBackActive(){
      const publisherIndex = this.statsResponseOriginal.publishers.findIndex(publisher => publisher._id == this.activePublisher);
      return (publisherIndex == 0);
    },
    stats(){
      const allStats = Object.keys(this).filter(key => key.includes('TableFields')).map(key => key.replace('TableFields', ''))
      if(!this.hasAffiliate) return allStats.filter(x => x != 'publisher');
      else  return allStats;
    },
    statsRows(){
      return this.stats.reduce((acc, cur) => {
        if(!this.hasAffiliate && cur == 'publisher') return acc;
        acc[cur] = this[`${cur}TableFields`].filter(field => field.key != 'remove' && field.key != 'actions');
        if(cur == 'creative'){
          acc[cur].push({ key: 'banner_id', label: `${this.$tc('general.banner', 1)} ID`}, { key: 'bannerheight', label: `${this.$tc('general.banner', 1)} ${this.$t('general.height')}`}, { key: 'bannerwidth', label: `${this.$tc('general.banner', 1)} ${this.$t('general.width')}`});
        }
        else if(cur == 'publisher'){
          acc[cur].push({ key: 'url', label: 'URL'}, { key: 'publisher_id', label: `${this.$tc('general.publisher', 1)} ID`});
        }
        return acc;
      }, {});
    },
    totalDateRange(){
      const firstDate = this.dateTableData[0]?.date;
      const lastDate = this.dateTableData[this.dateTableData.length-1]?.date;
      return firstDate + " - " + lastDate;
    },
    selectedTableData(){
      if(this.bTabsIndex == 0) return this.channelTableData;
      else if(this.bTabsIndex == 1) return this.dateTableData;
      else if(this.bTabsIndex == 2) return this.creativeTableData;
      else if(this.bTabsIndex == 5) return this.publisherTableData;
      return [];
    },
    totalCr(){
      const clicks = this.totalClicks;
      const conversions = this.totalConversions;

      if(clicks && conversions){
        const totalCr = conversions / clicks * 100;
        return Math.round((totalCr + Number.EPSILON) * 100) / 100;
      }
      else return 0;
    },
    totalCtr(){
      const clicks = this.totalClicks;
      const impressions = this.totalImpressions;

      if(clicks && impressions){
        const totalCtr = clicks / impressions * 100;
        return Math.round((totalCtr + Number.EPSILON) * 100) / 100;
      }
      else return 0;
    },
    totalConversions(){
      return this.selectedTableData.reduce((a, b) => a + b.conversions, 0);
    },
    totalCost(){
      return this.selectedTableData.reduce((a, b) => a + b.cost, 0);
    },
    totalClicks(){
      return this.selectedTableData.reduce((a, b) => a + b.clicks, 0);
    },
    totalImpressions(){
      return this.selectedTableData.reduce((a, b) => a + b.impressions, 0);
    },
    allChannelsSelected(){
      return this.channelTableData.map(row => row.channel);
    },
    bannerAdsChannelIds: () => CHANNELS.filter(c => c.category == 'Banner Ads').map(c => c.key),
    hideDomainlistButton(){
      for(const selChannel of this.websiteSelectedChannels){
        if(this.bannerAdsChannelIds.includes(selChannel))  return false;
      }

      return true;
    },
    websitePossibleChannels(){
      const allChannels = this.channelTableData.map(report => report.channel);
      const toSelect = [];
      for(let channel of allChannels){
        if(channel.category == 'Banner Ads' || ['outbrain', 'affiliate', 'affiliate_cpc'].includes(channel.key))
          // if(!this.websiteSelectedChannels.includes(channel.key))
            toSelect.push(channel);
      }

      return toSelect;
    },
    activeSubscriptionsIcon(){
      if(this.updatingSubscriptions){
        return 'fa-spinner fa-spin';
      }
      else{
        for(let s = 0; s < this.statisticsSubscription.length; s++){
          if(this.statisticsSubscription[s].oldstatus === true){
            return 'fa-bell';
          }
        }
        return 'fa-bell-o';
      }
    },
    userEmail() {
      return localStorage.getItem(process.env.VUE_APP_EMAIL);
    },
    adserver() {
      return process.env.VUE_APP_ADSERVER;
    },
    getStartTS() {
      return new Date(this.dateRange.startDate).getUTC(true);
    },
    getEndTS() {
      return new Date(this.dateRange.endDate).getUTC(false);
    },
    dateRange: {
      get() {
        return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      }
    },
    subscriptions: {
      get() {
        return this.$store.state.store.subscriptions;
      },
      set(value) {
        this.$store.dispatch("setSubscriptions", value);
      }
    },
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    isViewer: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "viewer" ? true : false,
    firstCellContent() {
      return this.$store.state.store.firstCellContent;
    },
  },
  watch: {
    activePublisher(newPublisher, oldPublisher) {
      /* console.log(newPublisher, oldPublisher) */
      if(newPublisher !== null){
        this.showPublisherDetails(newPublisher);
        if(oldPublisher == null){
          this.publisherSlide = 1;
          this.publisherDetails = 'date';
        }
      }
      else{
        this.publisherSlide = 0;
      }
    },
    bTabsIndex(newTabIndex){
      if(newTabIndex == 5){
        this.activePublisher = null;
        // this.publisherSlide = 0;
      }
    }
  },
  components: {
    DateRangePickerCustom,
    LineChart,
    NumberAbbreviated,
    ConfirmModal,
    AdListPreview,
    DownloadStatsModal,
    Pagination
  }
}
</script>

<style>
  .nav-item-stats-active {
    background-color: #bdd0f7 !important;
  }
  .nav-item-stats {
    padding: 1.2rem 1.6rem !important;
    border-color: #b2c7f5 !important;
    background-color: transparent;
  }
  .alert{
    border-radius: 0px;
  }
  
  .wrap-normal{
    white-space: normal !important;
  }

  .pslider .carousel-caption{
    position: relative;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    color: unset;
  }

  .pslider .img-fluid.w-100, .pslider .img-fluid.w-100.d-block{
    display: none !important;
    
  }
</style>
