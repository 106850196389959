<template>
  <form class="forms-sample">
    <div v-if="loading" class="flip-square-loader"></div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="row justify-content-center">
          <div v-for="(change, index) in versions" :key="index" class="col-md-11">
            <div class="p-3 border" :class="'border-' + getBorderClass(change.message)">
              <div v-b-popover.hover.bottomleft.html="loginDetails(change.login)" class="w-25">
                <b-icon icon="person-lines-fill" aria-hidden="true" class="mr-2" ></b-icon>{{ getLoginName(change.login)}}
              </div>
              <p class="p-0 m-0">{{change.message}}</p>
              <p class="p-0 m-0 mb-2">{{getFormattedDate(change.createdOn)}}</p>
              <div class="col-md-12">
                <div class="d-inline-block m-1" v-for="(prop, pIndex) in change.changes" :key="pIndex" v-b-popover.hover.bottomleft.html="getOldValue(prop.path, index)">
                  <span class="badge" style="white-space: normal;" :class="'badge-' + getBadgeClass(prop.op)">{{prop.path.join(" => ")}}</span>
                  <span class="badge badge-dark text-left" style="white-space: normal;"><strong>{{getFormatString(prop.val)}}</strong></span>
                  <!-- <span class="badge badge-light text-left" style="white-space: normal;"><i class="text-primary fa fa-copy" title="Copy value to clipboard" style="cursor: pointer;" @click="copyToClipboard(prop.val)"></i></span> -->
                  <span v-if="prop.fullVal" class="badge badge-light text-left" style="white-space: normal;"><i class="text-primary fa fa-eye" title="View full value" style="cursor: pointer;" @click="showMiniModal(prop.fullVal)"></i></span>
                </div>
              </div>
            </div>
            <div v-if="index < versions.length-1" class="d-flex justify-content-center my-3">
              <b-icon icon="chevron-up" scale="2" aria-hidden="true" class="" ></b-icon>
            </div>
          </div>
          <b-modal id="mini-compare" ok-only>
            <div class="d-inline-block m-1" v-for="(prop, pIndex) in fullVal" :key="pIndex">
              <span class="badge" style="white-space: normal;" :class="'badge-' + getBadgeClass(prop.op)">{{prop.path.join(" => ")}}</span>
              <span class="badge badge-dark text-left" style="white-space: normal;"><strong>{{getFormatString(prop.val)}}</strong></span>
              <!-- <span class="badge badge-light text-left" style="white-space: normal;"><i class="text-primary fa fa-copy" title="Copy value to clipboard" style="cursor: pointer;" @click="copyToClipboard(prop.val)"></i></span> -->
            </div>
          </b-modal>
        </div>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="submit"
          class="btn mr-2 pull-right mt-2"
        >
          Close
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
const rdiff = require('recursive-diff');
export default {
  name: "ComparisonModal",
  props: {
    entityId: {
      type: String,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    loginData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      error: "",
      loading: false,
      versions: [],
      fullVal: {}
    };
  },
  methods: {
    showMiniModal(val){
      if(Array.isArray(val)) this.fullVal = rdiff.getDiff({}, {...val});
      else this.fullVal = rdiff.getDiff({}, val);
      this.$bvModal.show("mini-compare");
    },
    getFormattedDate(date){
      return new Date(date).formatDate();
    },
    isObject(val){
      if(!val.val) return false;
      if(Object.prototype.toString.call(val) === "[object Object]"){
        return true;
      }
      return false;
    },
    getFormatString(val){
      // if(Object.prototype.toString.call(val) === "[object Object]"){
      //   console.log("object");
      //   return JSON.stringify(val).replace(/,/g, ", ");
      // }
      if(typeof val === "string") return `"${val}"`;
      return val;
    },
    getBadgeClass(type){
      switch(type){
        case 'add': return 'success'
        case 'update': return 'warning'
        case 'delete': return 'danger'
      }
      return 'info'
    },
    getChanges(){
      for (let i = 0; i < this.versions.length; i++) {
        // Object.values(this.versions[i].version).forEach(val => {
        //   if(Object.prototype.toString.call(val) === "[object Object]"){
        //     val = rdiff.getDiff({}, val);
        //   }
        // });
        this.versions[i].changes = rdiff.getDiff(this.getPrevious(i), this.versions[i].version);
      }
      for (let i = 0; i < this.versions.length; i++) {
        for (let j = 0; j < this.versions[i].changes.length; j++) {
          if(this.versions[i].changes[j]?.val?.['_id']){
            this.versions[i].changes[j].fullVal = this.versions[i].changes[j].val;
            this.versions[i].changes[j].val = this.versions[i].changes[j].val['_id'];
          }

          if(Array.isArray(this.versions[i].changes[j].val) && this.versions[i].changes[j].val?.[0]?.['_id']){
            this.versions[i].changes[j].fullVal = this.versions[i].changes[j].val;
            this.versions[i].changes[j].val = this.versions[i].changes[j].val.map(e=>e._id);
          }

          if(Array.isArray(this.versions[i].changes[j].val) && this.versions[i].changes[j].val?.[0]?.properties?.currentPlace?.formatted_address){
            this.versions[i].changes[j].fullVal = this.versions[i].changes[j].val;
            this.versions[i].changes[j].val = this.versions[i].changes[j].val.map(e=>e?.properties?.currentPlace?.formatted_address);
          }
        }
      }
    },
    getOldValue(path, versionIndex){
      if(versionIndex == (this.versions.length-1)){
        let html = `<strong>No previous value</strong>`;
        return html;
      }
      let val = undefined;
      for (let i = versionIndex+1; i < this.versions.length; i++) {
        val = this.versions[i]?.changes?.find?.(e=> e?.path?.join?.(' ') == path?.join?.(' '))?.val;
        if(val) break;
      }
      if(!val){
        let html = `<strong>No previous value found</strong>`;
        return html;
      }
      let html = `<strong>Old Value: </strong> ${typeof val === "string" ? `"${val}"` : val.toString()}<br>`;
      return html;
    },
    getPrevious(i) {
      if(i == this.versions.length-1){
        return {};
      }
      return this.versions?.[i+1]?.version;
    },
    async submit() {
      try {
        this.$bvModal.hide("compare-modal");
      } catch (err) {
        this.error = err.message;
      }
    },
    copyToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str.toString();
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    getLoginName(id){
      let first = this.loginData?.[id]?.name ? this.loginData?.[id]?.name : 'unkown'
      let last = this.loginData?.[id]?.surname ? this.loginData?.[id]?.surname : 'unkown'
      return `${first} ${last}`;
    },
    loginDetails(id){
        let html = `<strong>${this.getLoginName(id)}</strong><br>`;
        html +=`<strong>ID</strong>: ${this.loginData?.[id]?._id ? this.loginData?.[id]?._id : 'unkown'}<br>`
        html +=`<strong>E-Mail</strong>: ${this.loginData?.[id]?.email ? this.loginData?.[id]?.email : 'unkown'}<br>`
        html +=`<strong>Role</strong>: ${this.loginData?.[id]?.role ? this.loginData?.[id]?.role : 'unkown'}<br>`
        html +=`<strong>Last Login</strong>: ${this.loginData?.[id]?.lastLogin ? this.loginData?.[id]?.lastLogin : 'unkown'}<br>`
        html +=`<strong>Archived</strong>: ${this.loginData?.[id]?.archived ? this.loginData?.[id]?.archived : 'unkown'}<br>`
        return html;
    },
    getBorderClass(message){
      if(message.includes('Add') || message.includes('Created')){
          return 'success'
      } else if(message.includes('Edit')){
          return 'warning'
      } else if(message.includes('Delete')){
          return 'danger'
      } else{
          return 'info'
      }
    },
  },
  async created(){
    this.loading = true;
    this.versions = await this.$api.campaignlog.versions({
        _id: this.entityId,
        entityType: this.entityType,
    });
    for (let i = 0; i < this.versions.length; i++) {
      this.versions[i].version = JSON.parse(this.versions[i].version);
    }
    this.getChanges()
    this.loading = false;
  }
};
</script>
