import request from '@/services/axios';
import api from '@/services/api';

import router from '../router';

const state = {
  loading: false,
  pagination: 10,
  paginationSelectOptions: [{
      value: 10,
      text: 10
    },
    {
      value: 20,
      text: 20
    },
    {
      value: 50,
      text: 50
    },
    {
      value: 100,
      text: 100
    },
  ],
  impressionsChart: null,
  clicksChart: null,
  oprChart: null,
  dwellChart: null,
  productsRangeChart: null,
  impressionsRangeChart: null,
  clicksRangeChart: null,
  oprRangeChart: null,
  orderRangeChart: null,
  conversionRangeChart: null,
  revenueRangeChart: null,
  registered: false,
  error: null,
  warning: null,
  success: null,
  logins: [],
  accessLogsChart: [],
  campaignStats: {},
  campaignName: '',
  budget: 0,
  dateStart: new Date(),
  dateEnd: new Date(Date.now() + 2592000000),
  targeting: [],
  channels: [],
  goals: [],
  creative: [],
  campaign: null,
  applicationTime: null,
  applicationTimeout: null,
  expiration: process.env.VUE_APP_EXPIRATION,
  advertisers: [],
  advertiser: JSON.parse(localStorage.getItem(process.env.VUE_APP_ADVERTISER)),
  publishers: [],
  publisher: JSON.parse(localStorage.getItem(process.env.VUE_APP_PUBLISHER)),
  user: null,
  dateRange: JSON.parse(localStorage.getItem(process.env.VUE_APP_DATERANGE)),
  templates: [],
  subscriptions: JSON.parse(localStorage.getItem(process.env.VUE_APP_SUBSCRIPTIONS)),
  archived: false
};

const mutations = {
  SET_ARCHIVED(state, payload) {
    state.archived = payload;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_WARNING(state, payload) {
    state.warning = payload;
  },
  SET_SUCCESS(state, payload) {
    state.success = payload;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  SET_LOGINS(state, payload) {
    state.logins = payload;
  },
  LOADING_ACTIVE(state) {
    state.loading = true;
  },
  LOADING_INACTIVE(state) {
    state.loading = false;
  },
  REGISTER_SUCCESS(state) {
    state.registered = true;
  },
  SET_IMPRESSIONS_CHART(state, payload) {
    state.impressionsChart = payload;
  },
  SET_CLICKS_CHART(state, payload) {
    state.clicksChart = payload;
  },
  SET_OPR_CHART(state, payload) {
    state.oprChart = payload;
  },
  SET_DWELL_CHART(state, payload) {
    state.dwellChart = payload;
  },
  SET_PRODUCTS_RANGE_CHART(state, payload) {
    state.productsRangeChart = payload;
  },
  SET_IMPRESSIONS_RANGE_CHART(state, payload) {
    state.impressionsRangeChart = payload;
  },
  SET_CLICKS_RANGE_CHART(state, payload) {
    state.clicksRangeChart = payload;
  },
  SET_OPR_RANGE_CHART(state, payload) {
    state.oprRangeChart = payload;
  },
  SET_ORDER_RANGE_CHART(state, payload) {
    state.orderRangeChart = payload;
  },
  SET_CONVERSION_RANGE_CHART(state, payload) {
    state.conversionRangeChart = payload;
  },
  SET_REVENUE_RANGE_CHART(state, payload) {
    state.revenueRangeChart = payload;
  },
  SET_ACCESS_LOGS_CHART(state, payload) {
    state.accessLogsChart = payload;
  },
  SET_CAMPAIGN_STATS(state, payload) {
    state.campaignStats = payload;
  },
  SET_ADVERTISERS(state, payload) {
    state.advertisers = payload;
  },
  SET_ADVERTISER(state, payload) {
    state.advertiser = payload;
    state.publisher = null;
    localStorage.setItem(process.env.VUE_APP_ADVERTISER, JSON.stringify(payload))
  },
  SET_PUBLISHERS(state, payload) {
    state.publishers = payload;
  },
  SET_PUBLISHER(state, payload) {
    state.publisher = payload;
    state.advertiser = null;
    localStorage.setItem(process.env.VUE_APP_PUBLISHER, JSON.stringify(payload))
  },
  SET_CAMPAIGN(state, payload) {
    state.campaign = payload;
  },
  SET_APP_TIME(state, payload) {
    state.applicationTime = payload;
  },
  SET_APP_TIMEOUT(state, payload) {
    state.applicationTimeout = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_DATERANGE(state, payload){
    state.dateRange = payload;
    localStorage.setItem(process.env.VUE_APP_DATERANGE, JSON.stringify(payload))
  },
  SET_STAT_PREFERENCE(state, payload){
    state.user.statPreferences = payload;
  },
  ADD_TEMPLATE(state, template) {
    const index = state.templates.findIndex(temp => temp.name === template.name);
    if(index === -1) {
      state.templates.push(template)
    } else {
      state.templates[index] = template
    }
  },
  SET_SUBSCRIPTIONS(state, payload) {
    state.subscriptions = payload;
    localStorage.setItem(process.env.VUE_APP_SUBSCRIPTIONS, JSON.stringify(payload))
  }
};

const actions = {
  async getUser({
    commit
  }) {
    const user = await api.user.get();
    commit('SET_USER', user);
    return user;
  },

  async updateUser({
    commit
  }, payload) {
    commit('LOADING_ACTIVE');
    commit('SET_ERROR',null);

    try{
      const user = await api.user.update(payload);

      if(typeof payload.setResultAsCurrentUser == 'undefined' || payload.setResultAsCurrentUser === true){
        commit('SET_USER', user);
      }
      
      commit('LOADING_INACTIVE');
      return true;
    }
    catch(err){
      let errorMessage = '';
      if (err.response.data.message && typeof err.response.data.message !== 'string') {
        if (typeof err.response.data.message[0].constraints !== 'undefined') {
          for (var property in err.response.data.message[0].constraints) {
            errorMessage += err.response.data.message[0].constraints[property] + ' ';
          }
        }
      } else {
        errorMessage = err.response.data.message;
      }
      commit('SET_ERROR', errorMessage);
      commit('LOADING_INACTIVE');
    }
  },
 
  /*async updateUser({
    commit
  }, payload) {
    const user = await api.user.update(payload);
    commit('SET_USER', user);
  },*/
  setAppTime({
    commit
  }) {
    commit('SET_APP_TIME', new Date().formatDatetime());
  },
  setAppTimeout({
    commit
  }) {
    const now = Date.now();
    const active = new Date(localStorage.getItem(process.env.VUE_APP_ACTIVE)).getTime();
    commit('SET_APP_TIMEOUT', ((state.expiration - (now - active)) / 1000).toFixed());
  },
  async getAdvertisers({
    commit
  }) {
    const advertisers = (await api.advertiser.getAll()).items
    commit('SET_ADVERTISERS', advertisers)
  },
  async getPublishers({
    commit
  }) {
    const publishers = (await api.publisher.getAll()).items
    commit('SET_PUBLISHERS', publishers)
  },
  getLogins({
    commit
  }, payload) {
    commit('LOADING_ACTIVE');
    let query = '';
    if (payload !== null) {
      const payloadString = payload.join();
      query = `/${payloadString}`;
    }
    return request
      .get(`${process.env.VUE_APP_API}/auth/logins${query}`)
      .then((response) => {
        commit('LOADING_INACTIVE');
        commit('SET_LOGINS', response.data);
      });
  },
  /*updateLogin({
    commit
  }, payload) {
    const jwtToken = localStorage.getItem(process.env.VUE_APP_JWT);
    return request
      .patch(`${process.env.VUE_APP_API}/auth/login`, payload)
      .catch(function (error) {
        console.log(error);
        if (error.response.status == 401) {
          actions.signout();
          router.push('/login');
        } else {
          commit('SET_ERROR', JSON.stringify(error));
        }
      });
  },*/
  register({
    commit
  }, payload) {
    commit('LOADING_ACTIVE');
    return request
      .post(`${process.env.VUE_APP_API}/auth/signup`, payload)
      .then(() => {
        commit('LOADING_INACTIVE');
        commit('REGISTER_SUCCESS');
        return true;
      })
  },
  signin({
    commit
  }, payload) {
    commit('LOADING_ACTIVE');
    return request
      .post(`${process.env.VUE_APP_API}/auth/signin`, payload)
      .then((response) => {
        commit('LOADING_INACTIVE');
        if (response.data.accessToken) {
          localStorage.setItem(process.env.VUE_APP_JWT, response.data.accessToken);
          localStorage.setItem(process.env.VUE_APP_ACTIVE, new Date());
          localStorage.setItem(process.env.VUE_APP_NAME, response.data.name);
          localStorage.setItem(process.env.VUE_APP_ROLE, response.data.role);
          localStorage.setItem(process.env.VUE_APP_SURNAME, response.data.surname);
          localStorage.setItem(process.env.VUE_APP_EMAIL, response.data.email);
          localStorage.setItem(process.env.VUE_APP_SUBSCRIPTIONS, JSON.stringify(response.data.subscriptions));
          localStorage.setItem(process.env.VUE_APP_LANGUAGE, response.data.language ? response.data.language : process.env.VUE_APP_LANGUAGE_DEFAULT);

          if(response.data.selectedType == 'publisher' && response.data?.publisher?._id){
            localStorage.setItem(process.env.VUE_APP_PUBLISHER, JSON.stringify(response.data.publisher._id));
            localStorage.setItem(process.env.VUE_APP_ADVERTISER, null);
          }
          else{
            localStorage.setItem(process.env.VUE_APP_ADVERTISER, JSON.stringify(response.data.advertiser._id));
            localStorage.setItem(process.env.VUE_APP_PUBLISHER, null);
          }

          let defaultDateRange = {
            startDate: new Date(new Date().setDate(new Date().getDate())).getUTC(true),
            endDate: new Date(new Date().setDate(new Date().getDate())).getUTC(false)
          };
          commit('SET_DATERANGE', defaultDateRange);

          return 'success';
        } else {
          commit('SET_ERROR', 'Login failed, please try again.');
          return null;
        }
      });
  },
  newpassword({
    commit
  }, payload) {
    commit('LOADING_ACTIVE');
    return request
      .post(`${process.env.VUE_APP_API}/auth/newpassword`, payload)
      .then(() => {
        commit('LOADING_INACTIVE');
        return true;
      });
  },
  resetpassword({
    commit
  }, payload) {
    commit('SET_ERROR',null);
    commit('LOADING_ACTIVE');
    return request
      .post(`${process.env.VUE_APP_API}/auth/reset`, payload)
      .then((response) => {
        commit('LOADING_INACTIVE');
        if (response.data.token && response.data.token == 'ok') {
          return true;
        } else {
          commit('SET_ERROR', 'Are you sure this is your email address?');
          return null;
        }
      });
  },
  sendverification({
    commit
  }, payload) {
    commit('SET_ERROR',null);
    commit('LOADING_ACTIVE');
    return request
      .post(`${process.env.VUE_APP_API}/auth/sendverification`, payload)
      .then((response) => {
        commit('LOADING_INACTIVE');
        if (response.data.result && response.data.result == 'ok') {
          return true;
        } else {
          commit('SET_ERROR', 'Failed sending request?');
          return null;
        }
      });
  },
  signout() {
    return new Promise((resolve) => {
      localStorage.removeItem(process.env.VUE_APP_JWT);
      localStorage.removeItem(process.env.VUE_APP_ACTIVE);
      localStorage.removeItem(process.env.VUE_APP_NAME);
      localStorage.removeItem(process.env.VUE_APP_SURNAME);
      localStorage.removeItem(process.env.VUE_APP_EMAIL);
      localStorage.removeItem(process.env.VUE_APP_ADVERTISER);
      localStorage.removeItem(process.env.VUE_APP_ROLE);
      localStorage.removeItem(process.env.VUE_APP_DATERANGE);
      localStorage.removeItem(process.env.VUE_APP_SUBSCRIPTIONS);
      resolve();
    });
  },
  setError({
    commit
  }, payload) {
    commit('SET_ERROR', payload);
  },
  setWarning({
    commit
  }, payload) {
    commit('SET_WARNING', payload);
  },
  setSuccess({
    commit
  }, payload) {
    commit('SET_SUCCESS', payload);
  },
  setLoadingActive({
    commit
  }) {
    commit('LOADING_ACTIVE');
  },
  setLoadingInactive({
    commit
  }) {
    commit('LOADING_INACTIVE');
  },
  // getDashboardCampaigns({ commit }) {
  //   commit('LOADING_ACTIVE');
  //   const jwtToken = localStorage.getItem(process.env.VUE_APP_JWT);
  //   return request
  //     .get(`${process.env.VUE_APP_API}/dashboard`, {
  //       headers: { Authorization: `Bearer ${jwtToken}` }
  //     })
  //     .then((response) => {
  //       commit('LOADING_INACTIVE');
  //       commit('SET_DASHBOARD_CAMPAIGNS', response.data);
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //       if (error.response.status == 401) {
  //         actions.signout();
  //         router.push('/login');
  //       } else {
  //         console.error(error);
  //       }
  //     });
  // },
  getCampaignStats({
    commit
  }, payload) {
    commit('LOADING_ACTIVE');
    return request
      .get(`${process.env.VUE_APP_API}/campaign/${payload.id}/stats?start=${payload.dateRange.startDate}&end=${payload.dateRange.endDate}`)
      .then((response) => {
        commit('LOADING_INACTIVE');
        commit('SET_CAMPAIGN_STATS', response.data);
        commit('SET_PRODUCTS_RANGE_CHART', {
          labels: response.data.productsRangeChart.labels,
          datasets: [{
            label: "Product views",
            data: response.data.productsRangeChart.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255,99,132,1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_IMPRESSIONS_RANGE_CHART', {
          labels: response.data.impressionsRangeChart.labels,
          datasets: [{
            label: "Impressions",
            data: response.data.impressionsRangeChart.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255,99,132,1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_CLICKS_RANGE_CHART', {
          labels: response.data.clicksRangeChart.labels,
          datasets: [{
            label: "Clicks",
            data: response.data.clicksRangeChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_OPR_RANGE_CHART', {
          labels: response.data.oprRangeChart.labels,
          datasets: [{
            label: "OPR",
            data: response.data.oprRangeChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_ORDER_RANGE_CHART', {
          labels: response.data.orderRangeChart.labels,
          datasets: [{
            label: "Orders",
            data: response.data.orderRangeChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_CONVERSION_RANGE_CHART', {
          labels: response.data.conversionRangeChart.labels,
          datasets: [{
            label: "Orders",
            data: response.data.conversionRangeChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }, {
            label: "Network",
            data: response.data.nwConversionRangeChart.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255,99,132,1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_REVENUE_RANGE_CHART', {
          labels: response.data.revenueRangeChart.labels,
          datasets: [{
            label: "Revenue",
            data: response.data.revenueRangeChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_IMPRESSIONS_CHART', {
          labels: response.data.impressionsChart.labels,
          datasets: [{
            label: "Impressions",
            data: response.data.impressionsChart.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255,99,132,1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_CLICKS_CHART', {

          labels: response.data.clicksChart.labels,
          datasets: [{
            label: "Clicks",
            data: response.data.clicksChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }]
        })
        commit('SET_OPR_CHART', {

          labels: response.data.oprChart.labels,
          datasets: [{
            label: "Actual",
            data: response.data.oprChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }, {
            label: "Goal",
            data: response.data.oprGoalChart.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255,99,132,1)',
            ],
            borderWidth: 1
          }]
        }),
        commit('SET_DWELL_CHART', {

          labels: response.data.dwellChart.labels,
          datasets: [{
            label: "Amount per duration in sec.",
            data: response.data.dwellChart.data,
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
          }]
        })
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          actions.signout();
          router.push('/login');
        } else {
          commit('SET_ERROR', 'Could not get information about the selected campaign');
        }
      });
  },
  setBudget({
    commit
  }, payload) {
    commit('SET_BUDGET', payload);
  },
  setPagination({
    commit
  }, payload) {
    commit('SET_PAGINATION', payload);
  },
  setArchived({
    commit
  }, payload) {
    commit('SET_ARCHIVED', payload);
  },
  setDateStart({
    commit
  }, payload) {
    commit('SET_DATE_START', payload);
  },
  setDateEnd({
    commit
  }, payload) {
    commit('SET_DATE_END', payload);
  },
  setTargeting({
    commit
  }, payload) {
    commit('SET_TARGETING', payload);
  },
  setChannels({
    commit
  }, payload) {
    commit('SET_CHANNELS', payload);
  },
  removeChannels({
    commit
  }, payload) {
    commit('REMOVE_CHANNELS', payload);
  },
  setGoals({
    commit
  }, payload) {
    commit('SET_GOALS', payload);
  },
  removeGoals({
    commit
  }, payload) {
    commit('REMOVE_GOALS', payload);
  },
  setDateRange({
    commit
  }, payload) {
    commit('SET_DATERANGE', payload);
  },
  setStatPreference({
    commit
  }, payload) {
    commit('SET_STAT_PREFERENCE', payload);
  },
  setSubscriptions({
    commit
  }, payload) {
    commit('SET_SUBSCRIPTIONS', payload);
  },
  setCreative({
    commit
  }, payload) {
    const advertiser = localStorage.getItem(process.env.VUE_APP_ADVERTISER);

    commit('SET_CREATIVE', {
      name: payload.name,
      downloadLink: payload.xhr.response,
      advertiser: JSON.parse(advertiser),
      mime: payload.type,
      width: payload.width,
      height: payload.height,
      size: payload.size,
    });
  }
};

const getters = {
  archived(state){
    return state.archived;
  },
  getAppTime(state) {
    return state.applicationTime;
  },
  getAdvertisers(state) {
    return state.advertisers;
  },
  getAdvertiser(state) {
    return state.advertiser;
  },
  getPublishers(state) {
    return state.publishers;
  },
  getPublisher(state) {
    return state.publisher;
  },
  getAppTimeout(state) {
    return state.applicationTimeout;
  },
  loading(state) {
    return state.loading;
  },
  registered(state) {
    return state.registered;
  },
  error(state) {
    return state.error;
  },
  success(state) {
    return state.success;
  },
  warning(state) {
    return state.warning;
  },
  logins(state) {
    return state.logins;
  },
  impressionsChart(state) {
    return state.impressionsChart;
  },
  clicksChart(state) {
    return state.clicksChart;
  },
  oprChart(state) {
    return state.oprChart;
  },
  dwellChart(state) {
    return state.dwellChart;
  },
  productsRangeChart(state) {
    return state.productsRangeChart;
  },
  impressionsRangeChart(state) {
    return state.impressionsRangeChart;
  },
  clicksRangeChart(state) {
    return state.clicksRangeChart;
  },
  oprRangeChart(state) {
    return state.oprRangeChart;
  },
  orderRangeChart(state) {
    return state.orderRangeChart;
  },
  conversionRangeChart(state) {
    return state.conversionRangeChart;
  },
  revenueRangeChart(state) {
    return state.revenueRangeChart;
  },
  accessLogsChart(state) {
    return state.accessLogsChart;
  },
  campaignStats(state) {
    return state.campaignStats;
  },
  campaigns(state) {
    return state.campaigns;
  },
  campaignCount(state) {
    return state.campaignCount;
  },
  pagination() {
    return state.pagination;
  },
  paginationSelectOptions() {
    return state.paginationSelectOptions;
  },
  user() {
    return state.user;
  }
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;