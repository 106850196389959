<template>
  <div class="row dba-selects pb-4">
    <div v-if="!activeAnalytics" class="col-md-8" @click="$emit('activateAnalytics')">
      <span class="activate-box">
        <span>{{ $t("actions.showstats") }}</span>
      </span>
    </div>
    <div v-if="activeAnalytics" class="col-md-4">
      <div class="form-group">
        <v-select
          class="templates-chooser"
          v-model="selectedTemplates"
          :closeOnSelect="false"
          multiple
          :placeholder="`${$t('general.all')} ${$tc('general.template', 2)} (${getTemplates.length})`"
          @option:deselected="checkForUpdateTemplate"
          @input="makeTemplateDirty"
          :filterable="true"
          @close="checkForUpdateTemplate"
          :reduce="(template) => template.code"
          :options="getTemplates"
        ></v-select>
      </div>
    </div>
    <div v-if="activeAnalytics" class="col-md-4">
      <div class="form-group">
        <v-select
          class="campaign-chooser"
          v-model="selectedCampaigns"
          :closeOnSelect="false"
          multiple
          @option:deselected="checkForUpdateBooking"
          @input="makeCampaignDirty"
          @close="checkForUpdateBooking"
          :placeholder="`${$t('general.all')} ${$tc('general.booking', 2)} (${getCampaigns.length})`"
          :filterable="true"
          :reduce="(campaign) => campaign.code"
          :options="getCampaigns"
        ></v-select>
      </div>
    </div>
    <div class="col-md-4">
      <DateRangePickerCustom opens="left" @update="updateDashboard" />
    </div>
    <div v-if="warning.length > 0" class="col-md-12 pb-4">
      <div class="card">
        <div class="card-body p-3">
          <em>{{warning}}</em>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";

export default {
  name: "DashboardAnalyticsSelects",
  props: {
    templates: {
      type: Array,
      required: false,
      default: () => []
    },
    campaigns: {
      type: Array,
      required: false,
      default: () => []
    },
    activeAnalytics: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      hasCampaignChanged: false,
      hasTemplateChanged: false,
      selectedTemplates: [],
      selectedCampaigns: [],
      warning: ''
    };
  },
  async created() {
  },
  computed: {
    getTemplates() {
      let templates = [];

      templates.push({
        label: 'no template',
        code: 0
      });

      for (let t = 0; t < this.templates.length; t++) {
        templates.push({
          label: this.templates[t].name,
          code: this.templates[t]._id
        });
      }
      return templates;
    },
    getCampaigns() {
      let campaigns = [];
      for (let c = 0; c < this.campaigns.length; c++) {
        let templateName = "no template";
        let templateId = 0;
        if (
          this.campaigns[c].lookups.bookings &&
          this.campaigns[c].lookups.bookings.length > 0 &&
          this.campaigns[c].lookups.bookings[0].bookingtemplate
        ) {
          templateId = this.campaigns[c].lookups.bookings[0].bookingtemplate;
          templateName = "no template name";

          let template = this.getBookingTemplateById(
            this.campaigns[c].lookups.bookings[0].bookingtemplate
          );

          if (template !== false) {
            templateName = template.name;
          }
        }

        if (this.selectedTemplates.length == 0 || this.selectedTemplates.includes(templateId)) {
          campaigns.push({
            label: `${this.campaigns[c].name} (${templateName})`,
            code: this.campaigns[c]._id
          });
        }
      }
      return campaigns;
    }
  },
  methods: {
    pickerDate(picker){
      let str = picker.startDate ? picker.startDate.formatDate(true) : null;
      const endStr = picker.endDate ? picker.endDate.formatDate(true) : null;

      if(str !== endStr){
        str += ' - '+endStr;
      }

      return str;
    },
    makeTemplateDirty() {
      this.hasTemplateChanged = true;
    },
    makeCampaignDirty() {
      this.hasCampaignChanged = true;
    },
    checkForUpdateTemplate() {
      if(this.hasTemplateChanged) {
        this.updateDashboard();
      }
    },
    checkForUpdateBooking() {
      if(this.hasCampaignChanged) {
        this.updateDashboard();
      }
    },
    getBookingTemplateById(tplId) {
      for (let t = 0; t < this.templates.length; t++) {
        if (tplId == this.templates[t]._id) {
          return this.templates[t];
        }
      }
      return false;
    },
    updateDashboard() {
      this.warning = "";

      if (this.selectedCampaigns.length > 0) {
        this.$emit("updateDashboard", this.selectedCampaigns);
      } else if (this.selectedTemplates.length > 0) {
        let campaignsForStats = this.getCampaigns;

        let campaignIdsForStats = [];
        for (let c = 0; c < campaignsForStats.length; c++) {
          campaignIdsForStats.push(campaignsForStats[c].code);
        }

        if(campaignIdsForStats.length == 0){
          this.warning = 'Please select at least one booking or a template with bookings included';
        }
        else{
          this.$emit("updateDashboard", campaignIdsForStats);
        }
      } else {
        this.$emit("updateDashboard", []);
      }
      this.hasTemplateChanged = false;
      this.hasCampaignChanged = false;
    }
  },
  watch: {
    selectedTemplates: function() {
      this.selectedCampaigns = [];
    },
  },
  components: {
    DateRangePickerCustom
  }
};
</script>

<style>
.dba-selects .col-md-4 {
  font-size: 0.8rem;
}

.dba-selects .vs__selected-options {
  padding-bottom: 4px;
}

.dba-selects .templates-chooser .vs__dropdown-toggle,
.dba-selects .campaign-chooser .vs__dropdown-toggle {
  border: 1px solid #ccc !important;
}

.dba-selects .templates-chooser .vs__search::placeholder,
.dba-selects .templates-chooser .vs__dropdown-toggle,
.dba-selects .templates-chooser .vs__dropdown-menu,
.dba-selects .campaign-chooser .vs__search::placeholder,
.dba-selects .campaign-chooser .vs__dropdown-toggle,
.dba-selects .campaign-chooser .vs__dropdown-menu {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0px;
}

.dba-selects .daterange-chooser {
  /* border: none; */
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.dba-selects .daterange-chooser .reportrange-text {
  background-color: #fff;
  /* color: #ffffff; */
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400;
  padding: 13px 17px;
  /* border: none; */
  border-radius: 0px;
}

.dba-selects .daterange-chooser .daterangepicker {
  top: 100%;
}

.dba-selects .activate-box{
  padding: 4px 0px;
  display: block;
  cursor: pointer;
}

.dba-selects .activate-box span{
  border: 1px solid #cccccc;
  background-color: #ececec;
  padding: 12px 17px;
  display: block;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
}
</style>