const revenueSources = [
    {
      code: "belboon",
      label: "Belboon",
    },
    {
      code: "webgains",
      label: "Webgains",
    },
    {
      code: "tradedoubler",
      label: "Tradedoubler",
    },
    {
      code: "cj",
      label: "CJ",
    },
    {
      code: "effiliation",
      label: "Effiliation",
    },
    {
      code: "kwanko",
      label: "Kwanko",
    },
    {
      code: "afilio",
      label: "Afilio",
    },
    {
      code: "shareasale",
      label: "ShareASale",
    },
    {
      code: "adcell",
      label: "Adcell",
    },
    {
      code: "vitrado",
      label: "Vitrado",
    },
    {
      code: "uppr",
      label: "Uppr",
    },
    {
      code: "leadalliance",
      label: "Lead Alliance",
    },
    {
      code: "awin",
      label: "Awin",
    },
    {
      code: "pepperjam",
      label: "Pepperjam",
    },
    {
      code: "tradetracker",
      label: "Tradetracker",
    },
    {
      code: "impact",
      label: "Impact",
    },
    {
      code: "linkshare",
      label: "Linkshare",
    },
    {
      code: "indoleads",
      label: "Indoleads",
    },
    {
      code: "vcommission",
      label: "VCommission",
    },
    {
      code: "linkbux",
      label: "Linkbux",
    },
    {
      code: "gekko",
      label: "Gekko",
    },
    {
      code: "yieldkit",
      label: "Yieldkit",
    },
    {
      code: "flexoffers",
      label: "FlexOffers",
    },
    {
      code: "sovrn",
      label: "Sovrn",
    },
    {
      code: "retailads",
      label: "RetailAds",
    },
    {
      code: "communicationads",
      label: "CommunicationAds",
    },
    {
      code: "financeads",
      label: "FinanceAds",
    },
    {
      code: "connects",
      label: "Connects",
    },
    {
      code: "daisycon",
      label: "Daisycon",
    },
    {
      code: "adtraction",
      label: "Adtraction",
    },
    {
      code: "admitad",
      label: "Admitad",
    },
    {
      code: "adgoal",
      label: "AdGoal",
    },
    {
      code: "direct",
      label: "Direct",
    },
  ];

module.exports = revenueSources.sort((a, b) => (a.label < b.label) ? -1 : 1);