<template>
  <form class="forms-sample">
    <div v-if="loading" class="flip-square-loader"></div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group  d-flex justify-content-center">
                    <b-button
                        @click="changeCode"
                        type="button"
                        :disabled="loading"
                        class="btn mr-4"
                        variant="outline-primary"
                        :pressed="universal"
                        >
                        Universal
                    </b-button>
                    <b-button
                        type="button"
                        @click="changeCode"
                        :disabled="loading"
                        class="btn ml-4"
                        variant="outline-primary"
                        :pressed="individual"
                        >
                        Individual
                    </b-button>
            </div>
            <div class="form-group" v-if="individual">
              <div
                style="white-space: pre-wrap; word-break: break-all; background-color: #ddd"
                class="rounded p-3"
              >
                <p class="h6">Individual Code</p>
                <p>For special shops and individual websites</p>
                <br />
                <p class="text-monospace">
                  &lt;script type=&quot;text/javascript&quot;&gt;<br />
                  //Please insert string to identify network<br />
                  window.fortytwo_network = &quot;direct&quot;;<br />
                  //If applicable, insert 1 or TCF consent id if consent is
                  given or 0 if no consent is given.<br />
                  window.fortytwo_euconsent = &quot;CONSENT&quot;;<br />
                  //Insert internal program id<br />
                  window.fortytwo_program = &quot;{{programid}}&quot;;<br />
                  //Insert internal page identifier<br />
                  window.fortytwo_pagetype = &quot;START|PRODUCT|...&quot;;<br />
                  //Insert product number on product pages<br />
                  window.fortytwo_product = &quot;PRODUCT_NUMBER&quot;;<br />
                  //Insert id of purchase on transaction (order) pages<br />
                  window.fortytwo_txnid = &quot;ORDERID&quot;;<br />
                  //Insert value of transaction on order pages<br />
                  window.fortytwo_txnvalue = &quot;ORDERVALUE&quot;;<br />
                  //Insert an array, object or list of products that were
                  purchased on order pages<br />
                  window.fortytwo_txnproducts = &quot;PRODUCT_NUMBERS&quot;;<br />
                  //String, array or object with custom data<br />
                  window.fortytwo_custom = &quot;CUSTOM&quot;;<br />
                  &lt;/script&gt;
                </p>

                <p class="text-monospace">&lt;script type=&quot;text/javascript&quot;&gt;function
                  docReady(e){&quot;complete&quot;===document.readyState||
                  &quot;interactive&quot;===document.readyState?setTimeout(e,1):document.addEventListener(&quot;DOMContentLoaded&quot;,e)}
                  docReady(function(){var
                  e=document.createElement(&quot;script&quot;);e.async=!0,e.type=&quot;text/javascript&quot;,e.src=
                  &quot;https://{{ getAdserver }}/&quot;;var
                  t=document.getElementsByTagName(&quot;body&quot;);t&amp;&amp;t[0]&amp;&amp;t[0].appendChild(e)});
                  &lt;/script&gt;
                </p>
              </div>
            </div>
            <div class="form-group" v-if="universal">
              <div
                style="white-space: pre-wrap; word-break: break-all; background-color: #ddd"
                class="rounded p-3"
              >
                <p class="h6">Universal-Code</p>
                <p>Shop systems like Magentoo, Shopware, Shopify, etc.</p>
                <br />
                <p class="text-monospace">&lt;script type=&quot;text/javascript&quot;&gt;function
                  docReady(e){&quot;complete&quot;===document.readyState||
                  &quot;interactive&quot;===document.readyState?setTimeout(e,1):document.addEventListener(&quot;DOMContentLoaded&quot;,e)}
                  docReady(function(){var
                  e=document.createElement(&quot;script&quot;);e.async=!0,e.type=&quot;text/javascript&quot;,e.src=
                  &quot;https://{{ getAdserver }}/&quot;;var
                  t=document.getElementsByTagName(&quot;body&quot;);t&amp;&amp;t[0]&amp;&amp;t[0].appendChild(e)});
                  &lt;/script&gt;
                </p>
              </div>
            </div>
          </div>
        </div>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="submit"
          class="btn mr-2 pull-right"
        >
          Close
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "TrackingCodeModal",
  props: ["programid"],
  data() {
    return {
      error: "",
      loading: false,
      getAdserver: process.env.VUE_APP_ADSERVER,
      universal: true,
      individual: false
    };
  },
  methods: {
    async submit() {
      try {
        this.$bvModal.hide("tracking-code-modal");
      } catch (err) {
        this.error = err.message;
      }
    },
    changeCode(){
        this.universal = !this.universal;
        this.individual = !this.individual;
    }
  },
};
</script>
