<template>
  <i18n-n :value="formatValue" :format="currency ? 'currency' : null" :locale="localee">
    <template v-slot:currency="{ currency }">{{ currency }}</template>
    <template v-slot:fraction="{ fraction }">{{
      `${fraction}${abbreviation}`
    }}</template>
  </i18n-n>
</template>

<script>
import { formatPrefix, precisionPrefix } from "d3-format";

export default {
  data() {
    let locale = 'en';
    if(this.$i18n.locale) locale = this.$i18n.locale;
    return {
      defaultLocale: locale,
    };
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    currency: {
      type: Boolean,
      required: false,
      default: false,
    },
    locale: {
      type: String,
      required: false,
    },
  },
  computed: {
    localee() {
      return this.locale || this.defaultLocale;
    },
    formatValue() {
      const val = this.value / Math.pow(10, precisionPrefix(1, this.value));
      if (this.value < 1000) return Number(this.value.toFixed(2));
      return val;
    },
    abbreviation() {
      const abbreviation = formatPrefix(
        ".0s",
        this.value
      )(this.value).replace(/\d/g, "");

      return this.value > 1 ? abbreviation : "";
    },
  },
};
</script>
