<template>
  <b-modal :id="modalId" @ok="execOk" size="xl" title="Give a reason for the Rejection">
    <b-table :fields="fields" :items="items">
      <template #cell(channel)="data">
        <img
          class="p-1"
          :src="`/images/channels/${data.value == 'gads' ? 'googleads' : data.value}.png`"
          style="background-color: white"
        />
      </template>
      <template #cell(review)="data">
        <b-textarea rows="5" v-model="reasons[data.value.channel]"/>
      </template>
    </b-table>
    <hr/>
    <h4 class="mb-3">Comment (Optional)</h4>
    <b-textarea rows="5" v-model="comment"/>
  </b-modal>
</template>

<script>
export default {
  name: "ApprovalModal",
  props: {
    reviews: {
      required: true,
      type: Object,
      default: () => {}
    },
    target: {
      required: true,
      type: String,
      default: ""
    },
    modalId: {
      required: true,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fields: [
        {
          label: "Channel",
          key: "channel"
        },
        {
          label: "Reason",
          key: "review"
        }
      ],
      reasons: {},
      comment: ""
    }
  },
  computed: {
    items() {
      let returnValue = [];

      for (const channel in this.reviews) {
        const review = this.reviews[channel];
        let item = {};
        if (review?.status == 'rejected') {
          item.channel = channel;
          item.review = {
            channel: channel,
            reason: ""
          };
          if (this.reasons[channel]) item.review.reason = this.reasons[channel];
          returnValue.push(item);
        } 
      }

      return returnValue;
    }
  },
  methods: {
    execOk(bvModalEvent) {
      this.items.forEach((/* item */) => { /* console.log(item.review.reason) */ });
      if (this.items.some((item) => typeof item.review.reason == "undefined" || item.review.reason?.length == 0)) {
        bvModalEvent.preventDefault();
        return;
      }
      this.$emit('reasoned', this.target, this.reasons, this.comment);
    }
  }
}
</script>

<style>

</style>