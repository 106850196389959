<template>
    <div class="row bg-light pt-3">
        <div class="col-4 form-group">
            <label for="status" class="mb-1">{{ $t('general.status')}}</label>
            <v-select
                id="status"
                multiple
                :close-on-select="false"
                v-model="status"
                label="name"
                class="form-control"
                :filterable="false"
                :reduce="(type) => type.value"
                :selectable="option => !status.includes(option.value)"
                :options="statuses"
                @input="reportInput('status')"
            >
            </v-select>
        </div>
        <div class="col-4 form-group">
            <label for="type" class="mb-1">{{ $t('syncqueue.table.type')}}</label>
            <v-select
                id="type"
                v-model="type"
                label="name"
                class="form-control"
                :filterable="false"
                :reduce="(type) => type.value"
                :options="types"
                @input="reportInput('type')"
            >
            </v-select>
        </div>
        <div v-if="type == 'lineitem'" class="col-4 form-group">
            <label for="searchfield_lineitem" class="mb-1">{{ $tc('general.lineitem', 2) }}</label>
            <SearchEntity entity="lineitem" :multiple="false" @selected="setSearchInput"/>
        </div>
        <div v-else-if="type == 'campaign'" class="col-4 form-group">
            <label for="searchfield_campaign" class="mb-1">{{ $tc('general.campaign', 2) }}</label>
            <SearchEntity entity="campaign" :multiple="false" @selected="setSearchInput"/>
        </div>
        <div v-else-if="type == 'banner'" class="col-4 form-group">
            <label for="searchfield_banner" class="mb-1">{{ $tc('general.creative', 2) }}</label>
            <SearchEntity entity="banner" :multiple="false" @selected="setSearchInput"/>
        </div>
    </div>
</template>
  
<script>
  import SearchEntity from "./SearchEntity";

  export default {
    name: "EntityFilterSyncQueue",
    components: {
        SearchEntity,
    },
    props: {
        filter: {
            type: Object,
            required: false
        }
    },
    created(){
        if(this?.filter?.status)
            this.status = this.filter.status;
    },
    data() {
      return {
        types: [
            { name : this.$tc('general.lineitem', 1), value: 'lineitem' },
            { name : this.$tc('general.campaign', 1), value: 'campaign' },
            { name : this.$tc('general.creative', 1), value: 'banner' },
        ],
        statuses: [
            { name : 'not run', value: 'not run' },
            { name : 'failed', value: 'failed' },
            { name : 'partial', value: 'partial' },
            { name : 'successful', value: 'successful' },
        ],
        type: null,
        status: [],
        foreignId: null
      };
    },
    methods: {
        reportInput(field){
            this.$emit("filter", field, this[field]);
            if(field == 'type' && this[field] == null){
                this.foreignId = null;
                this.reportInput('foreignId');
            }
        },
        setSearchInput(data){
            this.foreignId = data.value;
            this.reportInput('foreignId');
        },
    },
  };
</script>
