<template>
    <div class="row">
        <div class="col-4 form-group">
            <label for="optimizer_campaign" class="mb-1">{{ $tc('general.campaign', 2) }} (Keep empty to fetch all)</label>
            <v-select
                id="optimizer_campaign"
                name="optimizer_campaign"
                v-model="campaign"
                label="name"
                class="form-control"
                :filterable="true"
                :reduce="(entities) => entities._id"
                :options="allCampaigns"
                @input="reportInput('campaign')"
                :multiple="true"
                :close-on-select="false"
            >
                <template slot="no-options">
                {{ $t("messages.typetosearch", {name: getSearchString()} ) }}
                </template>
            </v-select>
        </div>

        <div class="col-2 form-group">
            <label for="optimizer_revenuesource" class="mb-1">Revenue Source</label>
            <v-select
                id="optimizer_revenuesource"
                name="optimizer_revenuesource"
                class="form-control"
                v-model="revenueSource"
                required
                :reduce="source => source.code"
                :options="getRevenueSources"
                @input="reportInput('revenueSource')"
            />
            </div>

        <div class="col-2 form-group text-center">
            <label for="searchfield_ignoresticky" class="mb-1">Ignore Sticky</label>
            <b-form-checkbox
                class="mt-2"
                id="searchfield_ignoresticky"
                v-model="ignoresticky"
                name="searchfield_ignoresticky"
                :value="true"
                :unchecked-value="false"
                @change="reportInput('ignoresticky')"
            >
            </b-form-checkbox>
        </div>
        <div class="col-4 form-group">
            <label for="searchfield_daterange" class="mb-1">{{ $t('general.startdate') }} / {{ $t('general.enddate') }}</label>
            <DateRangePickerCustom opens="right" @update="setDates" />
        </div>
    </div>
</template>
  
<script>
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
  import { mapGetters } from 'vuex';
  import revenueSources from "@/constants/revenueSources.js";

  export default {
    name: "EntityFilterOptimizer",
    components: {
        DateRangePickerCustom
    },
    props: {
        filter: {
            type: Object,
            required: false
        },
    },
    async created(){

        const campaigns = await this.$api.optimizer.getCampaigns();

        for(const c of campaigns){
            if(c.sticky) c.name += " (sticky)";
            this.allCampaigns.push(c);
        }

        if(this.filter){
            if(this.filter.campaign)        this.campaign = this.filter.campaign;
            if(this.filter.ignoresticky)    this.ignoresticky = this.filter.ignoresticky;
        }
    },
    data() {
      return {
        campaign: [],
        revenueSource: '',
        allCampaigns: [],
        ignoresticky: false,
      };
    },
    computed: {
        ...mapGetters(["dateRange"]),
        dateRange: {
            get() {
                return this.$store.state.store.dateRange;
            }
        },
        getRevenueSources(){
            return revenueSources;
        },
    },
    methods: {
        getSearchString(){
            return this.$te(`general.${this.entity}`) ? this.$tc(`general.${this.entity}`,2) : this.entity;
        },
        setDates(){
            this.$emit("filter", 'start', this.dateRange.startDate);
            this.$emit("filter", 'end', this.dateRange.endDate);
        },
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
    },
  };
</script>
