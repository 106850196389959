<template>
  <div>
    <div v-for="(subentities, topIndex) in entities" :key="topIndex">
      <table class="table table-striped" v-if="subentities.length">
        <thead>
          <tr>
            <!-- <th
              v-for="(field, index) in fields"
              :key="'fieldtitle'+index"
            >{{field == "name" ? topIndex.toUpperCase() : field.toUpperCase()}}</th>
            <th
              v-for="(populate, index) in populates"
              :key="'poptitle'+index"
            >{{populate.path.toUpperCase()}}</th> -->
            <th colspan="3">{{ topIndex.toUpperCase() }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entity in subentities" :key="entity._id">
            <td
              :style="
                'width: ' +
                (100 /
                  Math.ceil(
                    (fields ? fields.length : 0) +
                      (populates ? populates.length : 0)
                  ) -
                  10) +
                '%'
              "
              v-for="(field, index) in fields"
              :key="'fieldcontent' + index"
              v-html="getEntityFieldValues(entity, field, topIndex)"
            ></td>
            <td
              :style="
                'width: ' +
                (100 /
                  Math.ceil(
                    (fields ? fields.length : 0) +
                      (populates ? populates.length : 0)
                  ) -
                  10) +
                '%'
              "
              v-for="(populate, index) in populates"
              :key="'popcontent' + index"
            >
              {{ getEntityPopulation(entity, populate.ref, populate.select) }}
            </td>
            <td
              :style="
                'width: ' +
                (100 -
                  (100 /
                    Math.ceil(
                      (fields ? fields.length : 0) +
                        (populates ? populates.length : 0)
                    ) -
                    10)) +
                '%'
              "
            >
              
              <i
                class="fa fa-copy"
                :title="'Copy ID to clipboard: ' + entity._id"
                style="cursor: pointer"
                @click="copyIdToClipboard(entity._id)"
              ></i>
              <router-link
                class="ml-2"
                :to="`/${topIndex.substring(0, topIndex.length - 1)}/edit/${entity._id}`"
                style="cursor: pointer"
                ><i class="fa fa-edit" @click="log(topIndex)"></i
              ></router-link>

              <router-link
              v-if="topIndex == 'campaigns'"
                class="ml-2"
                :to="`/campaign/${entity._id}`"
                style="cursor: pointer"
                ><i class="fa fa-bar-chart"></i
              ></router-link>
            </td>
          </tr>
        </tbody>
        <input type="hidden" id="clipboard" value />
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchResultTable",
  props: ["entities", "type", "populates", "fields"],
  methods: {
    // eslint-disable-next-line
    log(entities) {
      /* console.log(JSON.stringify(entities) */
    },
    getEntityPopulation(entity, ref, select) {
      if (typeof entity[ref] !== "undefined" && entity[ref] !== null) {
        if (typeof entity[ref][select] !== "undefined")
          return entity[ref][select];
        if (entity[ref].length) {
          let joinArray = [];
          for (var s in entity[ref]) {
            joinArray.push(entity[ref][s][select]);
          }
          return joinArray.join(", ");
        }
      } else {
        return "";
      }
    },
    getEntityFieldValues(entity, field, topIndex) {
      let returnString = "";
      if(topIndex == "hosts" && field == "name") {
        field = 'host';
      }
      else if(topIndex == "campaigns" && field == 'name' && typeof entity.externalIds !== "undefined") {
        let externalIds = Object.keys(entity.externalIds);
        if(externalIds.length) {
          for(var e in externalIds) {
            returnString += `<img class="mr-2" style="width: 20px; height: 20px;" src="/images/tools/${externalIds[e]}.png">`;
          }
        }
      }
      if (typeof entity[field] !== "undefined" && entity[field] !== null) {
        if(field == "created") {
          returnString += new Date(entity[field]).formatDatetime();
        }
        else {
          returnString += entity[field];
        }
        
        
        // if (
        //   entity[field].endsWith("jpg") ||
        //   entity[field].endsWith("gif") ||
        //   entity[field].endsWith("png") ||
        //   entity[field].endsWith("jpeg")
        // ) {
        //   return `<img src="${entity[field]}">`;
        // } else {
        //   return entity[field];
        // }
      } else {
        returnString += "";
      }
      return returnString;
    },
    copyIdToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
};
</script>

<style scoped>
</style>