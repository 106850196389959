<template>
  <div class="row dba-bookings">
    <div class="col-md-12 pb-4">
      <div class="card">
        <div class="card-body pb-2">
          <div v-if="campaigns.length == 0" class="row">
            <div class="col">
              <h2 class="h3 mb-4">
                {{ $tc("general.booking", 2) }}
              </h2>
              <b-alert
                show
                variant="warning"
                v-html="$t('messages.nobookings', { link: '/booking/create' })"
              >
              </b-alert>
            </div>
          </div>
          <div v-else>
            <div class="row align-items-center h-100 mb-5">
              <div class="col-3">
                <h2 class="m-0 h3">
                  {{ $tc("general.booking", 2) }}
                </h2>
              </div>
              <div class="col text-right">
                <span
                  v-for="(campaigns, type) in sortedCampaigns"
                  :key="type"
                  class="ml-1 mb-1 badge py-2 px-3 cursor-pointer"
                  @click="toggleVisibleCampaigns(type)"
                  :class="
                    visibleCampaigns[type].display === true
                      ? 'btn-inverse-success'
                      : 'btn-inverse-dark'
                  "
                >
                  <i
                    class="fa"
                    :class="visibleCampaigns[type].icon"
                    aria-hidden="true"
                  >
                  </i>
                  {{ visibleCampaigns[type].text }}
                  <b-badge variant="light" class="text-dark">{{
                    $n(campaigns.length)
                  }}</b-badge>
                </span>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-lg-3 col-sm-6 mb-3">
                <div class="row">
                  <div class="col-auto">
                    <span class="iconbox i-purple">
                      <span>
                        <i class="fa fa-play" aria-hidden="true"></i>
                      </span>
                    </span>
                  </div>
                  <div class="col">
                    <span class="title">{{ bookingsAmount }}</span>
                    <span class="subtitle">{{
                      $tc("general.booking", bookingsAmount)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <div class="row">
                  <div class="col-auto">
                    <span class="iconbox i-yellow">
                      <span>
                        <i class="fa fa-money" aria-hidden="true"></i>
                      </span>
                    </span>
                  </div>
                  <div
                    class="col"
                    @mouseover="showBudget = true"
                    @mouseout="showBudget = false"
                  >
                    <div v-if="showBudget">
                      <span
                        class="title"
                        :title="$n(totalBudget.amount, 'currency')"
                        v-b-tooltip.hover
                      >
                        <NumberAbbreviated
                          :value="totalBudget.amount"
                          :currency="true"
                        />
                      </span>
                      <span class="subtitle">{{ totalBudget.subtitle }}</span>
                    </div>
                    <div v-else>
                      <span class="title"
                        :title="$n(totalCosts.amount, 'currency')"
                        v-b-tooltip.hover>
                        <NumberAbbreviated
                          :value="totalCosts.amount"
                          :currency="true"
                        />
                      </span>
                      <span class="subtitle">{{ totalCosts.subtitle }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <div class="row">
                  <div class="col-auto">
                    <span class="iconbox i-blue">
                      <span>
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </span>
                    </span>
                  </div>
                  <div class="col">
                    <span class="title" :title="$n(totalImpressions)" v-b-tooltip.hover>
                      <NumberAbbreviated :value="totalImpressions" />
                    </span>
                    <span class="subtitle">{{
                      $tc("general.impression", 2)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <div class="row">
                  <div class="col-auto">
                    <span class="iconbox i-green">
                      <span>
                        <i class="fa fa-mouse-pointer" aria-hidden="true"></i>
                      </span>
                    </span>
                  </div>
                  <div class="col">
                    <span class="title" :title="$n(totalClicks)" v-b-tooltip.hover>
                      <NumberAbbreviated :value="totalClicks"
                    /></span>
                    <span class="subtitle">{{ $tc("general.click", 2) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="bookingsAmount == 0">
              <b-alert show variant="warning">
                {{ $t("messages.selecttype") }}
              </b-alert>
            </div>
            <div v-else class="table-responsive fulltable">
              <b-table
                striped
                hover
                :fields="tableFields"
                :items="campaignTable"
                ref="campaignTable"
              >
                <template #head(cost)>
                  {{ $t("general.costs") }}<br />{{ $t("general.budget") }}
                </template>
                <template #cell(icon)="data">
                  <span
                    v-if="data.item.sortType == 'active'"
                    @click="pauseCampaign(data.item._id)"
                    class="iconbox"
                    :class="data.item.statusTooltip.iconClass"
                    :title="data.item.statusTooltip.iconTitle"
                    v-b-tooltip.hover
                  >
                    <span>
                      <i
                        v-if="changeStatusCampaign == data.item._id"
                        class="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-else
                        :class="data.item.statusTooltip.icon"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </span>
                  <span
                    v-else-if="data.item.sortType == 'paused'"
                    @click="resumeCampaign(data.item._id)"
                    class="iconbox"
                    :class="data.item.statusTooltip.iconClass"
                    :title="data.item.statusTooltip.iconTitle"
                    v-b-tooltip.hover
                  >
                    <span>
                      <i
                        v-if="changeStatusCampaign == data.item._id"
                        class="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-else
                        :class="data.item.statusTooltip.icon"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </span>
                  <span
                    v-else
                    class="iconbox"
                    :class="data.item.statusTooltip.iconClass"
                    :title="data.item.statusTooltip.iconTitle"
                    v-b-tooltip.hover
                  >
                    <span>
                      <i
                        :class="data.item.statusTooltip.icon"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </span>
                </template>
                <template #cell(name)="data">
                  {{ data.value }}<br />
                  <small class="mt-1 pt-1 d-block"
                    ><i class="fa fa-arrow-right"></i>
                    {{ data.item.runtime }}</small
                  >
                </template>
                <template #cell(status)="data">
                  <i
                    v-if="bookingPublishing == data.item.booking"
                    class="fa fa-spinner fa-spin"
                  ></i>
                  <div v-else>
                    <b-badge
                      v-if="data.value == 'pending' && !isViewer"
                      class="cursor-pointer"
                      variant="success"
                      href="#"
                      @click="publishBooking(data.item.booking)"
                    >
                      <i class="fa fa-check"></i>
                      {{ $t("actions.clicktopublish") }}
                    </b-badge>

                    <span v-else>{{ data.item.statusTooltip.title }}</span>
                    <i
                      :id="data.index + '-' + data.item.statusTooltip.id"
                      class="ml-1 icon-question"
                    ></i>
                    <b-popover
                      custom-class="text-center"
                      triggers="hover focus"
                      :title="data.item.statusTooltip.title"
                      :target="data.index + '-' + data.item.statusTooltip.id"
                    >
                      <span v-if="data.item.statusTooltip.info"
                        ><em>{{ data.item.statusTooltip.info }}</em></span
                      >
                      <br
                        v-if="
                          data.item.statusTooltip.info &&
                            data.item.statusTooltip.todo
                        "
                      />
                      <span
                        class="d-block mt-2"
                        v-if="data.item.statusTooltip.todo"
                        ><strong>{{ $t("status.whatdonow") }}</strong
                        ><br /><em>{{ data.item.statusTooltip.todo }}</em></span
                      >
                    </b-popover>
                  </div>
                </template>
                <template #cell(channels)="data">
                  <CampaignChannels :campaign="data.item" />
                </template>

                <template #cell(impressions)="data">
                  <span v-if="data.value == '' && data.value !== 0">-</span>
                  <span v-else v-b-tooltip.hover :title="$n(data.value)">
                    <NumberAbbreviated :value="data.value" />
                  </span>
                </template>
                <template #cell(clicks)="data">
                  <span v-if="data.value == '' && data.value !== 0">-</span>
                  <span v-else v-b-tooltip.hover :title="$n(data.value)"
                    ><NumberAbbreviated :value="data.value"
                  /></span>
                </template>
                <template #cell(conversions)="data">
                  <span v-if="!data.item.pvConversions && !data.item.pcConversions">-</span>
                  <span v-else :title="`PC: ${$n(data.item.pcConversions)} | PV: ${$n(data.item.pvConversions)}`" v-b-tooltip.hover
                    >{{ data.item.pvConversions+data.item.pcConversions }}</span>
                </template>
                <template #cell(cost)="data">
                  <span v-if="data.value == '' && data.value !== 0">-</span>
                  <span :title="`${data.value} €`" v-b-tooltip.hover v-else
                    ><NumberAbbreviated
                      :value="data.value"
                      :currency="true"/></span
                  ><br />
                  <small class="mt-1 pt-1 d-block">
                    <span
                      v-if="
                        (!data.item.budget || data.item.budget == 0) &&
                          data.item.dailyBudget
                      "
                      ><NumberAbbreviated
                        :value="data.item.dailyBudget"
                        :currency="true"
                      />
                      /
                      {{ $tc("general.day",1) }}
                    </span>
                    <span :title="data.item.budget" v-else>
                      <NumberAbbreviated
                        :value="data.item.budget"
                        :currency="true"
                      />
                    </span>
                  </small>
                </template>
                <template #cell(health)="data">
                  <label
                    class="badge"
                    :class="data.value.badgeColor"
                    :title="data.value.message"
                  >
                    <i class="fa" :class="data.value.icon"></i>
                  </label>
                </template>
                <template #cell(actions)="data">
                  <div v-if="bookingPublishing !== data.item.booking">
                    <b-badge
                      class="cursor-pointer mr-1"
                      variant="primary"
                      href="#"
                      :title="$t('labels.viewbooking')"
                      v-b-tooltip.hover
                      @click="bookingDetails(data.item._id)"
                    >
                      <i class="fa fa-eye"></i>
                    </b-badge>

                    <b-badge
                      :title="$t('labels.editbooking')"
                      v-b-tooltip.hover
                      class="cursor-pointer mr-1"
                      :href="`/booking/update/${data.item.booking}`"
                      variant="warning"
                      v-if="
                        data.item.booking &&
                          data.item.sortType !== 'finished' &&
                          getRole != 'viewer'
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </b-badge>

                    <b-badge
                      v-if="getRole == 'systemoperator'"
                      title="Update Campaign in Campaign-Form"
                      v-b-tooltip.hover
                      class="cursor-pointer mr-1"
                      :href="`/campaign/edit/${data.item._id}`"
                      variant="warning"
                    >
                      <i class="fa fa-pencil-square"></i>
                    </b-badge>

                    <b-badge
                      title="Instant Reporting"
                      v-b-tooltip.hover
                      class="cursor-pointer mr-1"
                      :href="`/campaign/stats/${data.item._id}`"
                      variant="info"
                    >
                      <i class="fa fa-bar-chart"></i>
                    </b-badge>

                    <b-badge
                      v-if="getRole === 'systemoperator' || getRole === 'admin'"
                      title="Expert Analytics"
                      v-b-tooltip.hover
                      class="cursor-pointer mr-1"
                      :href="`/campaign/${data.item._id}`"
                      variant="dark"
                    >
                      <i class="fa fa-bar-chart"></i>
                    </b-badge>

                    <b-badge
                      v-if="
                        data.item.sortType !== 'finished' && getRole != 'viewer'
                      "
                      :title="$t('labels.interruptbooking')"
                      v-b-tooltip.hover
                      class="cursor-pointer mr-1"
                      variant="danger"
                      href="#"
                      @click="cancelCampaignConfirmModal(data.item._id)"
                    >
                      <i
                        v-if="cancelingCampaign == data.item._id"
                        class="fa fa-spinner fa-spin"
                      ></i>
                      <i v-else class="fa fa-remove"></i>
                    </b-badge>
                  </div>
                </template>
              </b-table>
            </div>
            <p v-if="bookingsAmount > 0" class="text-right mb-2">
              <b-button
                @click="csv"
                class="btn btn-outline-primary btn-sm"
                variant="secondary"
                ><i class="fa fa-floppy-o m-0" aria-hidden="true"></i
              ></b-button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="bookingModal"
      id="bookingModal"
      size="xl"
      :title="$t('headers.bookingmodal')"
      :hide-footer="true"
    >
      <PendingBookingModal :booking="modalBooking" />
    </b-modal>
    <ConfirmModal
      :id="'confirmModalActive'"
      :titleText="confirmModalTitleText"
      :bodyText="confirmModalBodyText"
      :finishButtonText="confirmModalFinishButtonText"
      :cancelButtonText="confirmModalCancelButtonText"
      :finishButtonVariant="confirmModalFinishButtonVariant"
      :cancelButtonVariant="confirmModalCancelButtonVariant"
      :finishEvent="confirmModalFinishEvent"
      :cancelEvent="confirmModalCancelEvent"
      @cancelCampaign="cancelCampaign"
      @abortCancelCampaign="abortCancelCampaign"
    />
  </div>
</template>

<script>
import NumberAbbreviated from "@/components/NumberAbbreviated";
import PendingBookingModal from "@/components/modals/PendingBookingModal";
import CHANNELS from "@/constants/channels.js";
const jwtDecode = require("jwt-decode");
import ConfirmModal from "@/components/modals/ConfirmModal";
import _cloneDeep from "lodash.clonedeep";
import CampaignChannels from "./CampaignChannels";

export default {
  name: "DashboardAnalyticsBookings",
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    tiny: {
      type: Boolean,
      required: false,
      default: false,
    },
    open: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      interval: null,
      showBudget: false,
      visibleCampaigns: {
        // will be overtritten on created hook if no campaigns/bookings are present in the type of campaigns
        active: {
          text: this.$t("general.active"),
          icon: "fa-play",
          display: true,
        },
        paused: {
          text: this.$t("general.paused"),
          icon: "fa-pause",
          display: true,
        },
        upcoming: {
          text: this.$t("general.upcoming"),
          icon: "fa-calendar-check-o",
          display: true,
        },
        pending: {
          text: this.$t("general.pending"),
          icon: "fa-hourglass-half",
          display: true,
        },
        rejected: {
          text: this.$t("general.rejected"),
          icon: "fa-ban",
          display: false,
        },
        finished: {
          text: this.$t("general.finished"),
          icon: "fa-check",
          display: true,
        },
      },
      localCampaigns: [],
      campaignTable: [],
      sortedCampaigns: {
        active: [],
        paused: [],
        pending: [],
        upcoming: [],
        rejected: [],
        finished: [],
      },
      tableFieldsDefault: [
        { key: "icon", label: "", class: "pr-0" },
        {
          key: "name",
          label: this.$t("general.name"),
          class: "text-left align-middle wrap-normal",
        },
        // "budget",
        {
          key: "status",
          label: this.$t("general.status"),
          class: "text-center align-middle",
          sortable: true,
        },
        {
          key: "channels",
          label: this.$tc("general.channel", 2),
          class: "text-center align-middle",
        },
        {
          key: "impressions",
          label: this.$tc("general.view", 2),
          class: "text-right align-middle",
          sortable: true,
        },
        {
          key: "clicks",
          label: this.$tc("general.click", 2),
          class: "text-right align-middle",
          sortable: true,
        },
        {
          key: "cost",
          label: `${this.$t("general.costs")} / ${this.$t("general.budget")}`,
          class: "text-right align-middle",
          sortable: true,
        },
      ],
      updatingStatus: "0",
      modalBooking: null,

      confirmModalBodyText: "",
      confirmModalTitleText: "",
      confirmModalFinishButtonText: "",
      confirmModalCancelButtonText: this.$t("actions.cancel"),
      confirmModalFinishButtonVariant: "",
      confirmModalCancelButtonVariant: "secondary",
      confirmModalFinishEvent: "",
      confirmModalCancelEvent: "",
      cancelingCampaign: 0,
      bookingPublishing: 0,
      changeStatusCampaign: 0,
      statusTooltips: {
        "waiting for approval": {
          title: this.$t("status.booking-waitingapproval-title"),
          info: this.$t("status.booking-waitingapproval-info"),
          icon: "fa fa-hourglass-half",
          iconTitle: this.$t("status.booking-waitingapproval-title"),
          iconClass: "i-yellow",
          id: "waiting_for_approval",
        },
        "generating creatives": {
          title: this.$t("status.booking-generatingcreatives-title"),
          info: this.$t("status.booking-generatingcreatives-info"),
          icon: "fa fa-hourglass-start",
          iconTitle: this.$t("status.booking-generatingcreatives-title"),
          iconClass: "i-yellow",
          id: "generating_creatives",
        },
        rejected: {
          title: this.$t("status.booking-rejected-title"),
          info: this.$t("status.booking-rejected-info"),
          todo: this.$t("status.booking-rejected-todo"),
          icon: "fa fa-ban",
          iconTitle: this.$t("status.booking-rejected-title"),
          iconClass: "i-red",
          id: "rejected",
        },
        pending: {
          title: this.$t("status.booking-pending-title"),
          info: this.$t("status.booking-pending-info"),
          todo: this.$t("status.booking-pending-todo"),
          icon: "fa fa-bullseye",
          iconTitle: this.$t("status.booking-pending-title"),
          iconClass: "i-green",
          id: "pending",
        },
        published: {
          title: this.$t("status.booking-active-title"),
          info: this.$t("status.booking-active-info"),
          todo: this.$t("status.booking-active-todo"),
          icon: "fa fa-play",
          iconTitle: this.$t("status.booking-active"),
          iconClass: "i-purple cursor-pointer",
          id: "published",
        },
        finished: {
          title: this.$t("status.booking-finished-title"),
          info: this.$t("status.booking-finished-info"),
          todo: this.$t("status.booking-finished-todo"),
          icon: "fa fa-check",
          iconTitle: this.$t("status.booking-finished-title"),
          iconClass: "i-green",
          id: "finished",
        },
        interrupted: {
          title: this.$t("status.booking-interrupted-title"),
          info: this.$t("status.booking-interrupted-info"),
          icon: "fa fa-stop",
          iconTitle: this.$t("status.booking-interrupted-title"),
          iconClass: "i-yellow",
          id: "interrupted",
        },
        paused: {
          title: this.$t("status.booking-paused-title"),
          info: this.$t("status.booking-paused-info"),
          todo: this.$t("status.booking-paused-todo"),
          icon: "fa fa-pause",
          iconTitle: this.$t("status.booking-paused-info"),
          iconClass: "i-yellow cursor-pointer",
          id: "paused",
        },
        upcoming: {
          title: this.$t("status.booking-upcoming-title"),
          info: this.$t("status.booking-upcoming-info"),
          icon: "fa fa-calendar-check-o",
          iconTitle: this.$t("status.booking-upcoming-icon"),
          iconClass: "i-blue",
          id: "upcoming",
        },
      },
    };
  },
  computed: {
    dateStringLong() {
      let str = new Date(this.dateRange.startDate).formatDate();
      const endStr = new Date(this.dateRange.endDate).formatDate();

      if (str !== endStr) {
        str = "between " + str + " and " + endStr;
      } else str = "on " + str;

      return str;
    },
    dateString() {
      let str = new Date(this.dateRange.startDate).formatDate();
      const endStr = new Date(this.dateRange.endDate).formatDate();

      if (str !== endStr) {
        str += " - " + endStr;
      }

      return str;
    },
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    tableFields() {
      let fields = this.tableFieldsDefault;

      if (
        this.$store.state.store.advertiser == "5f552fa515909b001cf407a7" &&
        (this.isSysop || this.isAdmin)
      ) {
        fields.push({
          key: "health",
          label: this.$t("general.health"),
          class: "text-center",
        });
      }

      // if(this.showConversions){
        // add as item before the last one
        fields.splice(fields.length - 1, 0, {
          key: "conversions",
          label: this.$tc("general.conversion",2),
          class: "text-right align-middle",
          sortable: true,
        });
      // }

      fields.push({
        key: "actions",
        label: this.$tc("general.action", 2),
        class: "text-right",
      });

      return fields;
    },
    bookingsAmount() {
      return this.campaignTable.length;
    },
    totalBudget() {
      let budget = 0;
      let dailyBudget = 0;

      for (let campaign of this.campaignTable) {
        if (campaign?.budget) budget += campaign.budget;
        if (campaign?.dailyBudget) dailyBudget += campaign.dailyBudget;
      }

      return {
        amount: budget > 0 ? budget : dailyBudget,
        subtitle:
          budget > 0
            ? this.$t("general.budget")
            : this.$t("general.dailybudget"),
      };
    },
    totalCosts() {
      const costs = this.campaignTable.reduce(
        (acc, campaign) => (campaign.cost ? acc + campaign.cost : acc),
        0
      );

      return { amount: costs, subtitle: this.$t("general.totalcosts") };
    },
    totalImpressions() {
      return this.campaignTable.reduce(
        (acc, campaign) =>
          campaign.impressions ? acc + campaign.impressions : acc,
        0
      );
    },
    totalClicks() {
      return this.campaignTable.reduce(
        (acc, campaign) => (campaign.clicks ? acc + campaign.clicks : acc),
        0
      );
    },
    isSysop: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
      "systemoperator"
        ? true
        : false,
    isViewer: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
      "viewer"
        ? true
        : false,
    isAdmin: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
      "administrator"
        ? true
        : false,
    isAgency: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "agency"
        ? true
        : false,
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    toggleVisibleCampaigns(type) {
      if (this.visibleCampaigns[type].display === true)
        this.visibleCampaigns[type].display = false;
      else this.visibleCampaigns[type].display = true;
      this.updateCampaignTable();
    },
    updateCampaignTable() {
      let campaignTable = [];

      for (let type in this.visibleCampaigns) {
        if (
          this.visibleCampaigns[type].display &&
          this.sortedCampaigns[type].length > 0
        ) {
          campaignTable = campaignTable.concat(this.sortedCampaigns[type]);
        }
      }
      this.campaignTable = campaignTable;
      // for (let campaign of this.campaignTable)
      //   console.log(
      //     "in table",
      //     campaign.name,
      //     campaign.status,
      //     campaign.sortType
      //   );
    },

    sortCampaigns() {
      const log = false;

      const startTimestamp = this.dateRange.startDate;
      const endTimestamp = this.dateRange.endDate;

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const tomorrowTimestamp = tomorrow.getUTC();

      const now = new Date().getUTC();

      // console.log("Reporting", startTimestamp, endTimestamp);
      // console.log("NOW", now);

      const sortedCampaigns = {
        active: [],
        paused: [],
        pending: [],
        upcoming: [],
        rejected: [],
        finished: [],
      };

      for (let campaign of this.localCampaigns) {
        const booking = campaign.lookups.bookings[0];
        campaign.booking = booking._id;
        const campaignStart = campaign.start;
        let campaignEnd = campaign.end;

        if(log) { /* console.log('Booking: '+booking._id) */ }

        if (campaign?.status && campaign.status == "interrupted") {
          // booking was interrupted or is finished
          campaign.sortType = "finished";
          campaign = this.fillCampaign(campaign);
          sortedCampaigns.finished.push(campaign);
          if (log) { /* console.log(booking.name, "interrupted") */ }
        } else if (campaignEnd <= now) {
          campaign.sortType = "finished";
          campaign.status = "finished";
          campaign = this.fillCampaign(campaign);
          sortedCampaigns.finished.push(campaign);
          if (log) { /* console.log(booking.name, "finished") */ }
        } else {
          campaign.status = booking.status;
          if (booking.status == "published") {
            // if booking is published and start and end are in the given daterange
            if (
              (campaignStart >= startTimestamp &&
                campaignStart < endTimestamp) ||
              (campaignEnd >= startTimestamp && campaignEnd < endTimestamp) ||
              (campaignStart <= startTimestamp && campaignEnd >= endTimestamp)
            ) {
              const activeLineItems = campaign.lookups.lineitems.find(
                (x) => x.active
              );

              let pauseType = "";
              let pauseDate = "";

              // if no active line items, check pause type
              if (!activeLineItems) {
                if(log) { /* console.log("no line items") */ }
                if(log) { /* console.log(campaign.lookups.lineitems) */ }
                for (let lineitem of campaign.lookups.lineitems) {
                  if (log) { /* console.log(lineitem.name) */ }
                  if (!lineitem.active) {
                    if (lineitem.autopaused) {
                      pauseType = "auto";
                      pauseDate = lineitem.autopaused;
                      if (log) { /* console.log("autopaused") */ }
                      break;
                    }
                    else{
                      pauseType = "user";
                      if (lineitem.userpause && lineitem.userpaused) {
                        pauseDate = lineitem.userpaused;
                        if (log) { /* console.log("userpaused") */ }
                      } else{
                        if (log) { /* console.log("not paused, but inactive") */ }
                      }
                    }
                  }
                }

                if (pauseType == "user") {
                  campaign.sortType = "paused";
                  campaign = this.fillCampaign(campaign);
                  sortedCampaigns.paused.push(campaign);
                  if (log) { /* console.log(booking.name, "userpaused") */ }
                } else if (pauseType == "auto") {
                  campaign.sortType = "active";
                  campaign = this.fillCampaign(campaign);
                  sortedCampaigns.active.push(campaign);
                  if (log) { /* console.log(booking.name, "active, but autopaused") */ }
                }
              }
              else{
                // at least one line item is active, so the campaign is active
                campaign.sortType = "active";
                campaign = this.fillCampaign(campaign);
                sortedCampaigns.active.push(campaign);
                if (log) { /* console.log(booking.name, "active") */ }
              }

              campaign.pauseType = pauseType;
              campaign.pauseDate = pauseDate;

            } else if (campaignStart >= tomorrowTimestamp) {
              campaign.sortType = "upcoming";
              campaign = this.fillCampaign(campaign);
              sortedCampaigns.upcoming.push(campaign);
              if (log) { /* console.log(booking.name, "upcoming") */ }
            }
          } else if (
            [
              "generating creatives",
              "waiting for approval",
              "pending",
            ].includes(booking.status)
          ) {
            campaign.sortType = "pending";
            campaign = this.fillCampaign(campaign);
            sortedCampaigns.pending.push(campaign);
            if (log) { /* console.log(booking.name, booking.status) */ }
          } else if (booking.status == "rejected") {
            campaign.sortType = "rejected";
            campaign = this.fillCampaign(campaign);
            sortedCampaigns.rejected.push(campaign);
            if (log) { /* console.log(booking.name, "rejected") */ }
          }
        }
        // console.log(
        //   booking.name,
        //   campaign.status,
        //   booking.status,
        //   campaignStart,
        //   campaignEnd
        // );
      }

      this.sortedCampaigns = sortedCampaigns;

      this.updateCampaignTable();
    },
    fillCampaign(campaign) {
      const booking = campaign.lookups.bookings[0];

      let runtime = new Date(campaign.start).formatDate();
      runtime += booking.continously
        ? " - " + this.$t("labels.openend")
        : " - " + new Date(campaign.end).formatDate();

      campaign.runtime = runtime;

      let channels = [];
      if (
        campaign?.lookups?.lineitems?.length &&
        campaign?.lookups?.contracts?.length
      ) {
        for (let i = 0; i < campaign.lookups.lineitems.length; i++) {
          const contract = campaign.lookups.contracts.find(
            (c) => c._id.toString() == campaign.lookups.lineitems[i].contract
          );

          if (contract) {
            const details = this.getChannelDetails(contract.channel);
            details.lineitem = campaign.lookups.lineitems[i]._id;
            details.lineitemStatus =
              campaign.lookups.lineitems[i].active ||
              (!campaign.lookups.lineitems[i].active &&
                campaign.lookups.lineitems[i].autopaused &&
                !campaign.lookups.lineitems[i].userpause)
                ? true
                : false;
            channels.push(Object.assign({}, details));
          }
        }
      }

      campaign.channels = channels;

      if (campaign.sortType == "upcoming")
        campaign.statusTooltip = this.statusTooltips["upcoming"];
      else if (campaign.sortType == "paused")
        campaign.statusTooltip = this.statusTooltips["paused"];
      else campaign.statusTooltip = this.statusTooltips[campaign.status];

      campaign.budget = booking?.budget ? booking.budget : 0;
      campaign.dailyBudget = booking?.dailyBudget ? booking.dailyBudget : 0;

      if (
        booking &&
        ["active", "finished", "paused"].includes(campaign.sortType) &&
        this.$store.state.store.advertiser == "5f552fa515909b001cf407a7" &&
        (this.isSysop || this.isAdmin)
      )
        campaign = this.addHealth(campaign, booking);

      return campaign;
    },
    addHealth(campaign, booking) {
      const consoleOutput = false;

      if(!campaign.clicks)  campaign.clicks = 0;

      let health = {};

      switch (booking.goal[0]) {
        case "clicks":
          var startBooking = new Date(booking.start).getUTC(true);
          var startSelected = this.dateRange.startDate;

          var relevantStart =
            startBooking <= startSelected ? startSelected : startBooking;

          var endSelected = this.dateRange.endDate;
          var relevantEnd = endSelected;

          var dailyRequiredClicks = 0;
          if (consoleOutput) { /* console.log("--------------" +campaign.name+ "--------------") */ }
          if (booking.end) {
            var endBooking = new Date(booking.end).getUTC(false);
            relevantEnd = endBooking <= endSelected ? endBooking : endSelected;

            if (
              this.$store.state.store.advertiser == "5f552fa515909b001cf407a7"
            ) {
              dailyRequiredClicks = Math.round(
                booking.budget /
                  2.5 /
                  ((new Date(booking.end).getTime() -
                    new Date(booking.start).getTime()) /
                    1000 /
                    60 /
                    60 /
                    24)
              );
              if (consoleOutput) {
                /* console.log("dailyRequiredClicks", dailyRequiredClicks) */
              }
            } else if (booking.dailyBudget) {
              // simple wizard
              dailyRequiredClicks = booking.dailyBudget / booking.goalValue;
              if (consoleOutput) {
                /*
                console.log(
                  "SIMPLE",
                  booking.name,
                  "budget: " + booking.dailyBudget + " daily",
                  "goal: " + booking.goalValue
                );
                */
              }
              if (consoleOutput) {
                /* console.log("daily clicks " + dailyRequiredClicks) */
              }
            } else {
              // extended wizard
              var bookingDuration = this.amountOfDays(
                booking.start,
                booking.end
              );
              var totalRequiredClicks = booking.budget / booking.goalValue;
              dailyRequiredClicks = totalRequiredClicks / bookingDuration;
              if (consoleOutput) {
                /*
                console.log(
                  "EXTENDED",
                  booking.name,
                  "budget: " + booking.budget + " overall",
                  "goal: " + booking.goalValue
                );
                */
              }
              if (consoleOutput) {
                /*
                console.log(
                  "runtime " + bookingDuration + " days",
                  "total " + totalRequiredClicks + " clicks",
                  "daily: " + dailyRequiredClicks
                );
                */
              }
            }
          } else {
            // simple wizard
            if (
              this.$store.state.store.advertiser == "5f552fa515909b001cf407a7"
            ) {
              dailyRequiredClicks = Math.round(
                booking.budget /
                  2.5 /
                  ((new Date(booking.end).getTime() -
                    new Date(booking.start).getTime()) /
                    1000 /
                    60 /
                    60 /
                    24)
              );
              /* console.log("dailyRequiredClicks", dailyRequiredClicks) */
            } else if (booking.dailyBudget) {
              dailyRequiredClicks = booking.dailyBudget / booking.goalValue;
              if (consoleOutput) {
                /*
                console.log(
                  "SIMPLE NO ENDDATE",
                  booking.name,
                  "budget: " + booking.dailyBudget + " daily",
                  "goal: " + booking.goalValue
                );
                */
              }
              if (consoleOutput) {
                /* console.log("daily clicks " + dailyRequiredClicks) */
              }
            } else {
              // no end date and no daiulyBudget is not possible
            }
          }

          var amountOfDaysInSelectedTimerange = this.amountOfDays(
            relevantStart,
            relevantEnd
          );
          if (amountOfDaysInSelectedTimerange == 0)
            amountOfDaysInSelectedTimerange = 1; // when the campaign started on selectedStartdate
          var requiredClicksInSelectedTimerange = Math.round(
            amountOfDaysInSelectedTimerange * dailyRequiredClicks
          );
          if (consoleOutput) {
            /*
            console.log(
              amountOfDaysInSelectedTimerange + " days",
              relevantStart + " - " + relevantEnd,
              campaign.clicks + " clicks reached",
              requiredClicksInSelectedTimerange + " clicks required"
            );
            */
            }

          health = {
            badgeColor:
              requiredClicksInSelectedTimerange > campaign.clicks
                ? "badge-warning"
                : "badge-success",
            message:
              requiredClicksInSelectedTimerange > campaign.clicks
                ? `Increase clicks (${campaign.clicks}/${requiredClicksInSelectedTimerange})`
                : `Good job (${campaign.clicks}/${requiredClicksInSelectedTimerange})`,
            icon:
              requiredClicksInSelectedTimerange > campaign.clicks
                ? "fa-hand-o-left"
                : "fa-check",
          };
          break;
        default:
          health = {
            badgeColor: "badge-primary",
            message: `No goal detected`,
            icon: "fa-question",
          };
          break;
      }

      campaign.health = health;

      return campaign;
    },
    makeToast(title, text, variant) {
      this.$emit("toast", title, text, variant);
    },
    abortCancelCampaign() {
      this.cancelingCampaign = 0;
    },
    async cancelCampaign() {
      if (!this.cancelingCampaign) return;

      await this.$api.campaign.cancel(this.cancelingCampaign);

      this.makeToast(
        this.$t("toasts.canceled-campaign-title"),
        this.$t("toasts.canceled-campaign"),
        "success"
      );
      this.cancelingCampaign = 0;
      this.$emit("updateDashboard");
    },
    async publishBooking(bookingId) {
      if (this.bookingPublishing) return; // do not publish multiple times
      this.bookingPublishing = bookingId;

      try {
        await this.$api.booking.changeStatus(bookingId, "published");

        this.makeToast(
          this.$t("messages.bookingpublished-title"),
          this.$t("messages.bookingpublished"),
          "success"
        );
      } catch (e) {
        this.makeToast(
          "Error while publishing",
          "Unkown Error. If the error continuous, please contact us.",
          "danger"
        );
      }

      this.bookingPublishing = 0;
      this.$emit("updateDashboard");
    },
    async pauseCampaign(campaignId) {
      if (this.changeStatusCampaign) return;
      this.changeStatusCampaign = campaignId;
      await this.$api.campaign.pause(campaignId);

      this.makeToast(
        this.$t("toasts.paused-campaign.title"),
        this.$t("toasts.paused-campaign.message"),
        "success"
      );
      this.changeStatusCampaign = 0;
      this.$emit("updateDashboard");
    },
    async resumeCampaign(campaignId) {
      if (this.changeStatusCampaign) return;
      this.changeStatusCampaign = campaignId;
      await this.$api.campaign.resume(campaignId);

      this.makeToast(
        this.$t("toasts.resumed-campaign.title"),
        this.$t("toasts.resumed-campaign.message"),
        "success"
      );
      this.changeStatusCampaign = 0;
      this.$emit("updateDashboard");
    },
    cancelCampaignConfirmModal(campaignid) {
      if (this.cancelingCampaign) return;
      this.cancelingCampaign = campaignid;
      this.confirmModalTitleText = this.$t("actions.cancel-campaign");
      this.confirmModalBodyText = this.$t("messages.cancel-campaign");
      this.confirmModalFinishButtonText = this.$t("actions.cancel-campaign");
      this.confirmModalFinishButtonVariant = "danger";
      this.confirmModalFinishEvent = "cancelCampaign";
      this.confirmModalCancelEvent = "abortCancelCampaign";
      this.$bvModal.show("confirmModalActive");
    },
    async updateCampaignStatus(campaignId, status) {
      if (this.updatingStatus == "0") {
        let campaignIndex = this.campaigns.findIndex(
          (c) => c._id == campaignId
        );
        if (campaignIndex !== -1) {
          let campaignData = this.campaigns[campaignIndex];
          if (campaignData) {
            this.updatingStatus = campaignId;
            if (status) {
              await this.$api.campaign.pause(campaignId);
              this.campaigns[campaignIndex].status = false;
            } else {
              await this.$api.campaign.resume(campaignId);
              this.campaigns[campaignIndex].status = true;
            }

            this.updatingStatus = "0";
          }
          this.$emit("updateDashboard");
        }
      }
    },
    amountOfDays(start, end) {
      let startD = new Date(start);
      let endD = new Date(end);

      let aod = parseInt(
        (new Date(
          endD.getUTCFullYear(),
          endD.getUTCMonth(),
          endD.getUTCDate()
        ) -
          new Date(
            startD.getUTCFullYear(),
            startD.getUTCMonth(),
            startD.getUTCDate()
          )) /
          (1000 * 3600 * 24) +
          1
      );
      return aod;
    },
    csv() {
      let csv = `Name,Status,Budget,Channels,Impressions,Clicks,Costs,Start,End,Location,Target,Gender,Age\r\n`;

      for (let type in this.sortedCampaigns) {
        for (let campaign of this.sortedCampaigns[type]) {
          const budget =
            (!campaign.budget || campaign.budget == 0) && campaign.dailyBudget
              ? `${campaign.dailyBudget} / day`
              : campaign.budget;
          const booking = campaign.lookups?.bookings[0];

          let start = new Date(campaign.start).formatDate();
          let end = booking.continously
            ? "-"
            : new Date(campaign.end).formatDate();

          let markers = booking?.markers?.reduce?.((acc, cur) => {
            if (cur?.properties?.currentPlace?.formatted_address) {
              acc.push(
                cur.properties.currentPlace.formatted_address.replace(/,/g, "")
              );
              return acc;
            }
            else  return acc;
          }, []);

          let gender = booking?.gender ? booking.gender.join(",") : "";
          let age = booking?.age ? booking.age.join(",") : "";

          let channels = campaign.channels.reduce?.((acc, cur) => {
            acc.push(cur.key);
            return acc;
          }, []);

          csv += `"${campaign.name}",`;
          csv += `"${campaign.sortType}",`;
          csv += `"${budget}",`;
          csv += `"${channels.join(",")}",`;
          csv += `${campaign.impressions ? campaign.impressions : 0},`;
          csv += `${campaign.clicks ? campaign.clicks : 0},`;
          csv += `${campaign.cost ? Math.round(campaign.cost, 2) : 0},`;
          csv += `"${start}",`;
          csv += `"${end}",`;
          csv += `"${markers.join(",")}",`;
          csv += `"${booking?.target}",`;
          csv += `"${gender}",`;
          csv += `"${age}"`;
          csv += `\r\n`;
        }
      }

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = `bookings.csv`;
      anchor.click();
    },
    getChannelDetails(key) {
      for (let i = 0; i < CHANNELS.length; i++) {
        if (CHANNELS[i].key == key) {
          return CHANNELS[i];
        }
      }
      return [];
    },
    bookingDetails(campaignId) {
      for (let c = 0; c < this.campaigns.length; c++) {
        if (this.campaigns[c]._id == campaignId) {
          this.modalBooking = this.campaigns[c].lookups.bookings[0];
          this.modalBooking["lookups"] = {
            lineitems: this.campaigns[c]?.lookups?.lineitems
              ? this.campaigns[c].lookups.lineitems
              : [],
            banners: this.campaigns[c]?.lookups?.banners
              ? this.campaigns[c].lookups.banners
              : [],
            contracts: this.campaigns[c]?.lookups?.contracts
              ? this.campaigns[c].lookups.contracts
              : [],
          };
          /* console.log("open", this.modalBooking) */
          this.$refs["bookingModal"].show();
          return;
        }
      }
    },
    async updateBookingStatuses() {
      if (this.sortedCampaigns.pending.length == 0) return;

      const campaignsToUpdate = this.sortedCampaigns.pending.reduce?.(
        (acc, cur) => {
          if (
            ["generating creatives", "waiting for approval"].includes(
              cur.lookups.bookings[0].status
            )
          )
            acc.push(cur._id);

          return acc;
        },
        []
      );

      try {
        const campaigns = await this.$api.dashboard.getDashboard({
          start: this.dateRange.startDate,
          end: this.dateRange.endDate,
          campaigns: campaignsToUpdate,
        });

        let reSort = false;
        for (let campaign of campaigns) {
          const campaignIdx = this.localCampaigns.findIndex(
            (c) => c._id == campaign._id
          );

          if (campaignIdx !== -1) {
            this.localCampaigns[campaignIdx] = campaign;
            reSort = true;
          }
        }

        if (reSort) {
          this.sortCampaigns();
        }
      } catch (e) {
        if (this.interval) clearInterval(this.interval);
      }
    },
  },
  created() {
    this.localCampaigns = _cloneDeep(this.campaigns);
    this.sortCampaigns();

    for (let type in this.sortedCampaigns) {
      if (this.sortedCampaigns[type].length == 0)
        this.visibleCampaigns[type].display = false;
    }

    const campaignsToUpdate = this.sortedCampaigns.pending.reduce?.(
      (acc, cur) => {
        if (
          ["generating creatives", "waiting for approval"].includes(
            cur.lookups.bookings[0].status
          )
        )
          acc.push(cur._id);

        return acc;
      },
      []
    );

    if (campaignsToUpdate.length > 0) {
      this.interval = setInterval(() => {
        this.updateBookingStatuses();
      }, 5000);
    }
  },
  components: {
    PendingBookingModal,
    ConfirmModal,
    NumberAbbreviated,
    CampaignChannels
  },
};
</script>

<style>
.wrap-normal {
  white-space: normal !important;
}

.dba-bookings span.iconbox {
  width: 48px;
  height: 48px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  position: relative;
}

.dba-bookings span.iconbox span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dba-bookings span.title,
.dba-bookings span.subtitle {
  display: block;
}

.dba-bookings span.title {
  font-size: 18px;
  font-weight: bold;
}

.dba-bookings span.subtitle {
  font-size: 14px;
  color: #bbbbbb;
}

.dba-bookings .i-purple {
  color: #7367f0;
  background-color: rgba(115, 103, 240, 0.12);
}

.dba-bookings .i-blue {
  color: #00cfe8;
  background-color: rgba(0, 207, 232, 0.12);
}

.dba-bookings .i-yellow {
  color: #ffb027;
  background-color: rgba(255, 196, 0, 0.12);
}

.dba-bookings .i-red {
  color: #ea5455;
  background-color: rgba(234, 84, 85, 0.12);
}

.dba-bookings .i-green {
  color: #28c76f;
  background-color: rgba(40, 199, 111, 0.12);
}

.dba-bookings .i-details {
  color: #cccccc;
  background-color: rgba(204, 204, 204, 0.12);
  cursor: pointer;
}

.dba-bookings .i-details:hover {
  color: #aaaaaa;
  background-color: rgba(170, 170, 170, 0.12);
}

.dba-bookings .i-details.active {
  color: #333333;
  background-color: rgba(33, 33, 33, 0.12);
}

.dba-bookings .cursor-pointer {
  cursor: pointer;
}

.dba-bookings .cursor-pointer:hover {
  color: white;
}

.i-purple.cursor-pointer:hover {
  color: #6258ca;
}

.i-yellow.cursor-pointer:hover {
  color: rgb(199, 152, 0);
}

.channel-inactive {
  filter: grayscale(1) !important;
}
</style>
