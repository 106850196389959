<template>
  <div class="col-md-12 stretch-card grid-margin dba-campaign">
    <div class="card">
      <div class="card-body pb-2">
        <h4 class="card-title">{{campaign.name}}</h4>
        <div class="row mb-5">
          <div class="col-lg-3 col-sm-6 mb-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-creatives">
                  <span>
                    <i class="fa fa fa-picture-o" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span class="title">{{campaign.creatives}}</span>
                <span class="subtitle">{{ $tc("general.creative", 2)}}</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6 mb-3">
            <div class="row">
              <div class="col-auto">
                <span class="iconbox i-yellow">
                  <span>
                    <i class="fa fa-money" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div
                class="col"
              >
                <div>
                  <span class="title">
                    <NumberAbbreviated
                      :value="campaign.cost"
                      :currency="true"
                    />
                  </span>
                  <span class="subtitle">{{ $t("general.totalcosts") }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6 mb-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-budget">
                  <span>
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span class="title" :title="$n(campaign.impressions)">
                  <NumberAbbreviated :value="campaign.impressions" />
                </span>
                <span class="subtitle">{{ $tc("general.impression", 2)}}</span>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-lg-3 col-sm-6 mb-3">
            <div class="row">
              <div class="col-md-auto">
                <span class="iconbox i-ctr">
                  <span>
                    <i class="fa fa-mouse-pointer" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
              <div class="col">
                <span class="title" :title="$n(campaign.clicks)">
                  <NumberAbbreviated :value="campaign.clicks"/>
                 </span>
                <span class="subtitle">{{ $tc("general.click", 2)}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-5" v-if="showDetails">
          <!--<DashboardAnalyticsLinechart
            title="Clicks"
            :chartData="clickData"
          />-->
          <DashboardAnalyticsBox
            :title="`Top ${$tc('general.creative', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
            :footerText="((tableTitle == 'Creatives') ? $t('actions.hidecreativeoverview') : $t('actions.showcreativeoverview'))"
            @footerAction="loadCreatives()"
            :tableData="topCreativesShort.data"
            :tableFields="topCreativesShort.fields"
            :size="6"
            class="pb-4"
          />

          <DashboardAnalyticsBox
            :title="`Top ${$tc('general.website', 2)} <small>${$t('labels.byclicks', 2)}</small>`"
            :footerText="((tableTitle == 'Websites') ? $t('actions.hidewebsiteoverview') : $t('actions.showwebsiteoverview'))"
            @footerAction="loadWebsites()"
            :tableData="topWebsitesShort.data"
            :tableFields="topWebsitesShort.fields"
            :size="6"
            class="pb-4"
          />

          <div class="col-md-12 mt-4" v-if="campaign.budget > 0">
            <h4>{{ $t("general.budget") }}</h4>
            <b-progress
              :max="campaign.budget"
              show-value
              show-progress
              :striped="budgetColor !== 'success'"
            >
              <b-progress-bar :variant="budgetColor" :value="campaign.budgetLeft">
                <strong>{{Math.round(campaign.budgetLeft*100/campaign.budget)}}%</strong>
              </b-progress-bar>
            </b-progress>
          </div>
          <div class="col-md-12 mt-4">
            <h4>{{ $tc("general.channel", 2) }}</h4>
            <b-progress :max="campaignChannels.total" show-value show-progress>
              <b-progress-bar
                v-for="(channel, index) in campaignChannels.channels"
                :key="index"
                :style="'background-color: '+channel.color"
                :value="channel.amount"
              >
                <span>
                  <strong>{{channel.name}}:</strong>
                  {{channel.amount}}%
                </span>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>

        <div class="row" v-if="loadingDetails">
          <div class="flip-square-loader"></div>
        </div>

        <div class="row mb-5" v-if="showDetails && tableData">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body smallpadding">
                <h4 class="card-title">
                  {{tableTitle}}
                  <small>({{tableData.data.length}})</small>
                </h4>
                <b-pagination
                  class="float-right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="rowsPerPage"
                  aria-controls="cbox-table"
                ></b-pagination>
                <div class="table-responsive fulltable">
                  <b-table
                    striped
                    hover
                    id="cbox-table"
                    :items="tableData.data"
                    :fields="tableData.fields"
                    :current-page="currentPage"
                    :per-page="rowsPerPage"
                  >
                    <template #cell(budget)="data">
                      <b-progress max="100" show-value show-progress>
                        <b-progress-bar :variant="data.value.color" :value="data.value.value"></b-progress-bar>
                      </b-progress>
                    </template>

                    <template #cell(impressions)="data">
                      <span v-if="data.value == '' && data.value !== 0">-</span>
                      <span v-else v-b-tooltip.hover :title="$n(data.value)">
                        <NumberAbbreviated :value="data.value" />
                      </span>
                    </template>

                    <template #cell(clicks)="data">
                      <span v-if="data.value == '' && data.value !== 0">-</span>
                      <span v-else v-b-tooltip.hover :title="$n(data.value)">
                        <NumberAbbreviated :value="data.value" />
                      </span>
                    </template>
                    <template #cell(ctr_calc)="data">
                      {{ getCTR(data.item.impressions, data.item.clicks, 2) }} %
                    </template>

                    <template #cell(conversions)="data">
                      <span v-if="data.item.pvConversions+data.item.pcConversions == '0'">0</span>
                      <span v-else :title="`PC: ${$n(data.item.pcConversions)} | PV: ${$n(data.item.pvConversions)}`" v-b-tooltip.hover
                        >{{ data.item.pvConversions+data.item.pcConversions }}
                      </span>
                    </template>

                    <template #cell(name)="data">
                      <span v-if="data.item.isapp === true">
                        <i class="fa fa-mobile" aria-hidden="true"></i>
                          {{ ' ' }}
                      </span>
                      {{ data.value }}
                    </template>

                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p v-if="campaign.creatives >= 0" class="text-center mb-0">
          <span class="iconbox i-details" :class="showDetails ? 'active' : ''">
            <span v-if="!showDetails" @click="loadDetails()">
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
            <span v-if="showDetails" @click="hideDetails">
              <i class="fa fa-chevron-up" aria-hidden="true"></i>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardAnalyticsBox from "./DashboardAnalyticsBox";
import NumberAbbreviated from "@/components/NumberAbbreviated";
//import DashboardAnalyticsDoughnut from "./DashboardAnalyticsDoughnut";
// import DashboardAnalyticsLinechart from "./DashboardAnalyticsLinechart";
// import _cloneDeep from "lodash.clonedeep";
import CHANNELS from "@/constants/channels.js";

export default {
  name: "DashboardAnalyticsBookingCampaignBox",
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDetails: false,
      loadingDetails: false,

      tableData: null,
      tableTitle: "",
      currentPage: 1,
      rowsPerPage: 10,

      details: false,
      creativesFull: false,
      websitesFull: false
    };
  },
  computed: {
    totalRows() {
      return this.tableData.data.length;
    },
    topCreativesShort() {
      let fields = [
        { key: "name", label: this.$tc("general.creative", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right"},
      ];

      return {
        fields,
        data: this.details.creatives
      };
    },
    topWebsitesShort() {
      let fields = [
        { key: "name", label: this.$tc("general.website", 1) },
        { key: "impressions", label: this.$tc("general.view", 2), class: "text-right" },
        { key: "clicks", label: this.$tc("general.click", 2), class: "text-right" },
        { key: "conversions", label: this.$tc("general.conversion", 2), class: "text-right"},
      ];

      return {
        fields,
        data: this.details.websites
      };
    },
    campaignChannels() {
      let total = 0;
      const channels = [];

      for (let ch = 0; ch < this.details.channels.length; ch++) {
        let channelData = this.getChannelData(this.details.channels[ch].name);
        total += this.details.channels[ch].amount;
        channels.push({
          name: channelData.shortName,
          color: channelData.color,
          amount: this.details.channels[ch].amount
        });
      }

      return {
        total,
        channels
      };
    },
    // channelsData() {  // for doughnut
    //   const data = {
    //     labels: [],
    //     datasets: [
    //       {
    //         backgroundColor: [],
    //         data: []
    //       }
    //     ]
    //   };

    //   for(let ch = 0; ch < this.campaign.channels.length; ch++) {
    //     let chn = this.campaign.channels[ch];
    //       data.labels.push(chn.channel);
    //       data.datasets[0].backgroundColor.push(chn.color);
    //       data.datasets[0].data.push(chn.amount);
    //   }

    //   return data;
    // },
    clickData() {
      const data = {
        labels: [],
        datasets: [
          {
            fill: true,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 3,
            data: [],
            lineTension: 0
          }
        ]
      };

      let entryData = [];
      let labelData = [];

      for (let cr = 0; cr < this.campaign.creatives.length; cr++) {
        for (let w = 0; w < this.campaign.creatives[cr].websites.length; w++) {
          let statEntries = this.campaign.creatives[cr].websites[w].stats
            .length;
          let addToIndex = 0;

          let entries = 7;
          if (statEntries - entries < 0) {
            entries = statEntries;
          }

          for (let s = statEntries - entries; s < statEntries; s++) {
            let statEntriesValues = this.campaign.creatives[cr].websites[w]
              .stats[s];

            if (entryData[addToIndex]) {
              entryData[addToIndex] += statEntriesValues.clicks;
            } else {
              labelData[addToIndex] = statEntriesValues.day;
              entryData[addToIndex] = statEntriesValues.clicks;
            }

            addToIndex++;
          }
        }
      }

      data.labels = labelData;
      data.datasets[0].data = entryData;

      return data;
    },
    budgetColor() {
      let leftBudgetPercent = Math.round(
        (this.campaign.budgetLeft * 100) / this.campaign.budget
      );
      let color = "success";
      if (leftBudgetPercent <= 10) color = "danger";
      else if (leftBudgetPercent <= 35) color = "warning";
      return color;
    }
  },
  methods: {
    async loadDetails() {
      // load TOP5 bookings, websites and channels
      if (this.details === false) {
        this.loadingDetails = true;

        const result = await this.$parent.$parent.getDashboardStats(
          false,
          this.campaign.id,
          false
        );
        if (result !== false) {
          this.details = result;
        }

        this.loadingDetails = false;
      }
      this.showDetails = true;
    },
    async loadCreatives() {
      if (this.tableTitle == "Creatives") {
        this.hideTable();
        return;
      } else if (this.creativesFull === false) {
        this.hideTable();
        this.loadingDetails = true;

        const creativesFull = await this.$parent.$parent.getDashboardStats(
          false,
          this.campaign.id,
          "creatives"
        );
        if (creativesFull !== false) {
          let tableData = {
            fields: [
              { key: "name", label: this.$tc("general.creative", 1), sortable: true },
              { key: "size", label: this.$t("general.size"), sortable: true },
              {
                key: "impressions",
                label: this.$tc("general.view", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "clicks",
                label: this.$tc("general.click", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "conversions",
                label: this.$tc("general.conversion", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "ctr_calc",
                label: "CTR",
                class: "text-right",
                sortable: true
              }
            ],
            data: []
          };

          for (let cr = 0; cr < creativesFull.length; cr++) {
            let creativeObj = creativesFull[cr];
            creativeObj.size = `${creativeObj.width} x ${creativeObj.height}`;
            tableData.data.push(creativeObj);
          }

          this.creativesFull = tableData;
        }

        this.loadingDetails = false;
      }

      this.tableTitle = "Creatives";
      this.tableData = this.creativesFull;
    },
    async loadWebsites() {
      if (this.tableTitle == "Websites") {
        this.hideTable();
        return;
      } else if (this.websitesFull === false) {
        this.hideTable();
        this.loadingDetails = true;

        const websitesFull = await this.$parent.$parent.getDashboardStats(
          false,
          this.campaign.id,
          "websites"
        );
        if (websitesFull !== false) {
          let tableData = {
            fields: [
              { key: "name", label: this.$tc("general.website", 1), sortable: true },
              {
                key: "impressions",
                label: this.$tc("general.view", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "clicks",
                label: this.$tc("general.click", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "conversions",
                label: this.$tc("general.conversion", 2),
                class: "text-right",
                sortable: true
              },
              {
                key: "ctr_calc",
                label: "CTR",
                class: "text-right",
                sortable: true
              }
            ],
            data: websitesFull
          };

          this.websitesFull = tableData;
        }
        this.loadingDetails = false;
      }

      this.tableTitle = "Websites";
      this.tableData = this.websitesFull;
    },
    getChannelData(key) {
      for (let ch = 0; ch < CHANNELS.length; ch++) {
        if (key == CHANNELS[ch].key) {
          return CHANNELS[ch];
        }
      }
    },
    showTable(title, data) {
      if (title == this.tableTitle) {
        this.hideTable();
      } else {
        this.tableTitle = title;
        this.tableData = data;
        this.currentPage = 1;
      }
    },
    hideTable() {
      this.tableData = null;
      this.tableTitle = "";
    },
    hideDetails() {
      this.showDetails = false;
      this.hideTable();
    },
    getCTR(views, clicks, precision) {
      let ctr = 0;
      if (views > 0) {
        ctr = Number(((clicks * 100) / views).toFixed(precision));
      }

      return ctr;
    }
  },
  components: {
    DashboardAnalyticsBox,
    NumberAbbreviated
    //DashboardAnalyticsDoughnut,
    // DashboardAnalyticsLinechart
  }
};
</script>

<style>
.dba-campaign .progress {
  height: 2rem;
  border-radius: 0px;
}
.dba-campaign .progress .progress-bar{
  border-radius: 0px;
}

.dba-campaign span.iconbox {
  width: 48px;
  height: 48px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  position: relative;
}

.dba-campaign span.iconbox span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dba-campaign span.title,
.dba-campaign span.subtitle {
  display: block;
}

.dba-campaign span.title {
  font-size: 18px;
  font-weight: bold;
}

.dba-campaign span.subtitle {
  font-size: 14px;
  color: #bbbbbb;
}

.dba-campaign .icon {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.dba-campaign .i-creatives {
  color: #7367f0;
  background-color: rgba(115, 103, 240, 0.12);
}

.dba-campaign .i-budget {
  color: #00cfe8;
  background-color: rgba(0, 207, 232, 0.12);
}

.dba-campaign .i-yellow {
  color: #ffb027;
  background-color: rgba(255, 196, 0, 0.12);
}

.dba-campaign .i-ctr {
  color: #28c76f;
  background-color: rgba(40, 199, 111, 0.12);
}
.dba-campaign .i-details {
  color: #cccccc;
  background-color: rgba(204, 204, 204, 0.12);
  cursor: pointer;
}
.dba-campaign .i-details:hover {
  color: #aaaaaa;
  background-color: rgba(170, 170, 170, 0.12);
}
.dba-campaign .i-details.active {
  color: #333333;
  background-color: rgba(33, 33, 33, 0.12);
}
.dba-campaign .card-body {
  padding: 30px 30px;
}
.dba-campaign .card-body.smallpadding {
  padding: 20px 15px 10px !important;
}

.dba-campaign .fulltable td,
.dba-campaign .fulltable th {
  padding: 16px 10px;
}
</style>