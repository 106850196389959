<template>
  <div class="col">
    <div class="row my-2">
      <div class="col col-lg-3 col-md-6 col-sm-12">
        <label for="type">Type</label>
        <v-select id="type" class="form-control" :options="types" v-model="type" :clearable="false" />
      </div>
      <div class="col col-lg-3 col-md-6 col-sm-12">
        <label for="advertiser">{{ $tc('general.advertiser', 1) }}</label>
        <SearchEntity entity="advertiser" :value="advertiser" @selected="setSearchInput"/>
      </div>
      <div class="col col-lg-3 col-md-6 col-sm-12">
        <label for="campaign">{{ $tc('general.campaign', 1) }}</label>
        <SearchEntity entity="campaign" :value="campaign" @selected="setSearchInput"/>
      </div>
      <div class="col col-lg-3 col-md-6 col-sm-12">
        <label for="booking">{{ $tc('general.booking', 1) }}</label>
        <SearchEntity entity="booking" :value="booking" @selected="setSearchInput"/>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from "lodash.debounce";
import SearchEntity from "../../assets/SearchEntity";
export default {
  name: "ApprovalSearch",
    components: {
        SearchEntity,
    },
  data() {
    return {
      type: {
        label: "Banner",
        value: "banner"
      },
      advertiser: null,
      booking: null,
      campaign: null,
      advertisers: [],
      bookings: [],
      campaigns: [],
      allAdvertisers: [],
      types: [
        {
          label: "Banner",
          value: "banner"
        },
        {
          label: "LineItem",
          value: "lineitem"
        }
      ]
    }
  },
  methods: {
    onSearchAdvertisers(search, loading) {
        if (search.length > 2) {
            loading(true);
            this.search(loading, search, this);
        }
    },
    search: _debounce((loading, search, vm) => {
    vm.$api.advertiser
        .search(search, { enableLoading: false })
            .then((advertisers) => {
            vm.allAdvertisers = advertisers.items;
            loading(false);
        });
    }, 350),
    setSearchInput(data){
      if(data.entity == "campaign"){
        this.campaign = data.value;
        this.advertiser = null;
        this.booking = null;
        this.$emit('campaignSelected', data.value)
      }
      else if(data.entity == "advertiser"){
        this.advertiser = data.value;
        this.campaign = null;
        this.booking = null;
        this.$emit('advertiserSelected', data.value)
      }
      else if(data.entity == "booking"){
        this.booking = data.value;
        this.advertiser = null;
        this.campaign = null;
        this.$emit('bookingSelected', data.value)
      }

      /* console.log(data) */
        // this[data.entity] = data.value;
        // this.reportInput(data.entity);
    },
  },
  watch: {
    type() {
      this.$emit('typeSelected', this.type.value);
    },
  }
};
</script>

<style>
</style>