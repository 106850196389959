import { render, staticRenderFns } from "./ClickPage.vue?vue&type=template&id=0304b8ca&scoped=true"
import script from "./ClickPage.vue?vue&type=script&lang=js"
export * from "./ClickPage.vue?vue&type=script&lang=js"
import style0 from "./ClickPage.vue?vue&type=style&index=0&id=0304b8ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0304b8ca",
  null
  
)

export default component.exports