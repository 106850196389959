const businessRoles = [
  { value: null, text: "Bitte wählen Sie Ihre Rolle aus", disabled: true},
  { value: 	9	, text: 'Inhaber' },
  { value: 	10	, text: 'Geschäftsführer (CEO)' },
  { value: 	20	, text: 'Operativer Geschäftsführer (COO)' },
  { value: 	30	, text: 'Finanzvorstand (CFO)' },
  { value: 	40	, text: 'Marketingchef (CMO)' },
  { value: 	50	, text: 'Technischer Direktor (CTO)' },
  { value: 	60	, text: 'Vertriebsleiter' },
  { value: 	70	, text: 'Produkmanager' },
  { value: 	80	, text: 'Projektmanager' },
  { value: 	90	, text: 'Finanzmanager' },
  { value: 	100	, text: 'Personalmanager' },
  { value: 	110	, text: 'Marketingfachmann' },
  { value: 	120	, text: 'Wirtschaftsanalytiker' },
  { value: 	130	, text: 'Personalwesen' },
  { value: 	140	, text: 'Buchhalter' },
  { value: 	150	, text: 'Vertriebsmitarbeiter' },
  { value: 	160	, text: 'Kundenbetreuer' },
  { value: 	170	, text: 'Verwaltungsassistent' },
]

module.exports = businessRoles;