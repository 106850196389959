<template>
  <b-modal :id="modalId" :title="`Changes for ${name}`" size="lg">
      <div class="row">
          <div class="col">
              <h5>Headlines</h5>
          </div>
          <div class="col">
              <h5>Descriptions</h5>
          </div>
      </div>
      <div class="row">
          <div class="col-6">
              <b-list-group>
                  <b-list-group-item v-for="description in descriptions" :key="description">{{description}}</b-list-group-item>
              </b-list-group>
          </div>
          <div class="col-6">
              <b-list-group>
                  <b-list-group-item v-for="headline in headlines" :key="headline">{{headline}}</b-list-group-item>
              </b-list-group>
          </div>
      </div>
  </b-modal>
</template>

<script>
export default {
    name: "ApprovalSearchAdPreview",
    props: {
        modalId: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: "N/A"
        },
        headlines: {
            type: Array,
            default: () => {
                return ["None"]
            }
        },
        descriptions: {
            type: Array,
            default: () => {
                return ["None"]
            }
        }
    }
}
</script>

<style>

</style>