<template>
  <form>
    <div v-if="loading" class="flip-square-loader"></div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
      </div>
      <div class="col-12">
        <h6>{{ $t("messages.select-stats-download") }}:</h6>
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="selectedStats"
          name="stats"
          class="mt-2"
        >
          <b-form-checkbox
            :value="stat"
            v-for="(stat, index) in stats"
            :key="index"
          >
            {{ $tc(`general.${stat}`, 1) }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
      <div class="col-12 mt-5" v-if="hasSelectedStats">
        <div>
          <h6>{{ $t("messages.select-desired-rows") }}:</h6>
        </div>
        <div class="row mb-4" v-for="(stat, index) in selectedStats" :key="index">
          <div class="col-auto">
            <b-button variant="primary" class="h-100" disabled>{{ $tc(`general.${stat}`, 1) }}</b-button>
          </div>
          <div class="col-3 px-1">
            <v-select
              v-model="selectedSort[stat].sortBy"
              class="form-control"
              :filterable="false"
              :clearable="false"
              :reduce="(option) => option.key"
              :options="statsRows[stat]"
              :placeholder="$t('messages.select-sortBy')"
            >
            </v-select>
          </div>
          <div class="col-2">
            <v-select
              v-model="selectedSort[stat].sortOrder"
              class="form-control"
              :placeholder="$t('messages.select-sortOrder')"
              label="text"
              :reduce="(option) => option.value"
              :filterable="false"
              :clearable="false"
              :options="[
                { text: 'ASC', value: 'ASC' },
                { text: 'DESC', value: 'DESC' },
              ]"
            >
            </v-select>
          </div>
          <div class="col-auto">
            <b-button variant="success" @click="toggleAllRows(stat)" class="h-100">Select all rows</b-button>
          </div>
          <div class="col-12 mt-2">
            <div
              class="border d-inline-flex mb-2 mr-2 p-1 pr-2"
              v-for="(row, rIndex) in statsRows[stat]"
              :key="rIndex"
            >
              <b-form-checkbox v-model="selectedRows[stat]" type="checkbox" :value="row.key" class="ml-1" > {{ row.label }}   </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <b-button
        :disabled="!hasSelectedStats || !hasSelectedRows"
        squared
        variant="success"
        @click="downloadStats"
        class="d-flex"
        >{{ $t("actions.download-stats") }}</b-button>
      <b-checkbox v-model="savePreferences" class="pull-right">{{ $t("actions.savex", {name: $t("general.preferences")}) }}</b-checkbox>
    </div>
  </form>
</template>

<script>
import { mapGetters  } from "vuex";
export default {
  name: "DownloadStatsModal",
  props: {
    stats: {
      type: Array,
      default: () => [],
    },
    statsRows: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      savePreferences: false,
      selectedStats: [],
      selectedRows: {},
      selectedSort: {},
      hasSelectedRows: true,
    };
  },
  computed: {
    ...mapGetters([
      "loading",
      "success",
      "warning",
      "error",
    ]),
    hasSelectedStats() {
      return this.selectedStats.length > 0;
    },
    statPreferences: {
      get() {
        return this.$store.getters.user.statPreferences;
      },
      set(value) {
        this.$store.dispatch("setStatPreference", value);
      }
    },
    preselectedRows(){
      const defaultPreselect = {
        "publisher": ["name","impressions","clicks","conversions"],
        "conversion": ["conversionId","timestampClick","cartValue","timestampView","timestamp"],
        "creative": ["name","impressions","conversions","clicks"],
        "website": ["conversions","impressions","clicks","location"],
        "date": ["date","impressions","clicks","conversions"],
        "channel": ["conversions","clicks","impressions","channel"]
      };
      const preferencePreselect = this.statPreferences.length ? this.statPreferences : null;
      return {
        "publisher": preferencePreselect && preferencePreselect.find(stat => stat.statType === 'publisher') ? preferencePreselect.find(stat => stat.statType === 'publisher')?.statColumns : defaultPreselect.publisher,
        "conversion": preferencePreselect && preferencePreselect.find(stat => stat.statType === 'conversion') ? preferencePreselect.find(stat => stat.statType === 'conversion')?.statColumns : defaultPreselect.conversion,
        "creative": preferencePreselect && preferencePreselect.find(stat => stat.statType === 'creative') ? preferencePreselect.find(stat => stat.statType === 'creative')?.statColumns : defaultPreselect.creative,
        "website": preferencePreselect && preferencePreselect.find(stat => stat.statType === 'website') ? preferencePreselect.find(stat => stat.statType === 'website')?.statColumns : defaultPreselect.website,
        "date": preferencePreselect && preferencePreselect.find(stat => stat.statType === 'date') ? preferencePreselect.find(stat => stat.statType === 'date')?.statColumns : defaultPreselect.date,
        "channel": preferencePreselect && preferencePreselect.find(stat => stat.statType === 'channel') ? preferencePreselect.find(stat => stat.statType === 'channel')?.statColumns : defaultPreselect.channel
      };
    },
    preselectedSort(){
      const defaultPreselect = {
        "publisher": {sortBy: "clicks", sortOrder: "DESC"},
        "conversion": {sortBy: "conversionId", sortOrder: "ASC"},
        "creative": {sortBy: "name", sortOrder: "ASC"},
        "website": {sortBy: "conversions", sortOrder: "ASC"},
        "date": {sortBy: "date", sortOrder: "ASC"},
        "channel": {sortBy: "conversions", sortOrder: "ASC"}
      };
      const preferencePreselect = this.statPreferences.length ? this.statPreferences : null;
      return {
        "publisher": {
          sortBy: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'publisher') ? preferencePreselect.find(stat => stat.statType === 'publisher')?.statSortBy : defaultPreselect.publisher.sortBy,
          sortOrder: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'publisher') ? preferencePreselect.find(stat => stat.statType === 'publisher')?.statSortOrder : defaultPreselect.publisher.sortOrder
        },
        "conversion": {
          sortBy: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'conversion') ? preferencePreselect.find(stat => stat.statType === 'conversion')?.statSortBy : defaultPreselect.conversion.sortBy,
          sortOrder: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'conversion') ? preferencePreselect.find(stat => stat.statType === 'conversion')?.statSortOrder : defaultPreselect.conversion.sortOrder
        },
        "creative": {
          sortBy: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'creative') ? preferencePreselect.find(stat => stat.statType === 'creative')?.statSortBy : defaultPreselect.creative.sortBy,
          sortOrder: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'creative') ? preferencePreselect.find(stat => stat.statType === 'creative')?.statSortOrder : defaultPreselect.creative.sortOrder
        },
        "website": {
          sortBy: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'website') ? preferencePreselect.find(stat => stat.statType === 'website')?.statSortBy : defaultPreselect.website.sortBy,
          sortOrder: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'website') ? preferencePreselect.find(stat => stat.statType === 'website')?.statSortOrder : defaultPreselect.website.sortOrder
        },
        "date": {
          sortBy: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'date') ? preferencePreselect.find(stat => stat.statType === 'date')?.statSortBy : defaultPreselect.date.sortBy,
          sortOrder: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'date') ? preferencePreselect.find(stat => stat.statType === 'date')?.statSortOrder : defaultPreselect.date.sortOrder
        },
        "channel": {
          sortBy: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'channel') ? preferencePreselect.find(stat => stat.statType === 'channel')?.statSortBy : defaultPreselect.channel.sortBy,
          sortOrder: preferencePreselect && preferencePreselect.find(stat => stat.statType === 'channel') ? preferencePreselect.find(stat => stat.statType === 'channel')?.statSortOrder : defaultPreselect.channel.sortOrder
        }
      };
    }
  },
  methods: {
    async downloadStats() {
      if(this.savePreferences) {
        const statPreferences = this.selectedStats.map(stat => {
          return {
            statType: stat,
            statColumns: this.selectedRows[stat],
            statSortBy: this.selectedSort[stat].sortBy,
            statSortOrder: this.selectedSort[stat].sortOrder,
          }
        });
        await this.$api.user.update({
          email: localStorage.getItem(process.env.VUE_APP_EMAIL),
          statPreferences
        });
        this.statPreferences = statPreferences;
      }
      this.$emit(
        "download-stats",
        this.selectedStats,
        this.selectedRows,
        this.selectedSort
      );
    },
    toggleAllRows(stat) {
      if (this.selectedRows[stat]?.length < this.statsRows[stat].length) {
        this.selectedRows[stat] = this.statsRows[stat].map((row) => row.key);
      } else {
        this.selectedRows[stat] = [];
      }
      this.hasSelectedRows = true;
      this.selectedStats.forEach((stat) => {
        if (!this.selectedRows[stat]?.length) {
          this.hasSelectedRows = false;
        }
      });
      this.$forceUpdate();
    },
    isSelected(stat, row) {
      return this.selectedRows[stat].find((r) => r === row.key)
        ? "secondary"
        : "outline-secondary";
    },
    isSelectedSort(stat, row, sort) {
      return this.selectedSort[stat]?.[row.key] === sort
        ? "secondary"
        : "outline-secondary";
    },
  },
  watch: {
    selectedStats: {
      handler: function (val) {
        const newSelectedRows = {};
        const newSelectedSort = {};
        val.forEach((stat) => {
          newSelectedRows[stat] = this.selectedRows[stat] ? this.selectedRows[stat] : [...this.preselectedRows[stat]];
          newSelectedSort[stat] = this.selectedSort[stat] ? this.selectedSort[stat] : { sortBy: this.preselectedSort[stat].sortBy, sortOrder: this.preselectedSort[stat].sortOrder };
        });
        this.selectedRows = {...newSelectedRows};
        this.selectedSort = {...newSelectedSort};
        /* console.log(this.selectedSort) */
        this.hasSelectedRows = true;
        this.selectedStats.forEach((stat) => {
          if (!this.selectedRows[stat]?.length) {
            this.hasSelectedRows = false;
          }
        });
        this.$forceUpdate();
      },
      deep: true,
    },
    selectedRows: {
      handler: function () {
        this.hasSelectedRows = true;
        this.selectedStats.forEach((stat) => {
          if (!this.selectedRows[stat]?.length) {
            this.hasSelectedRows = false;
          }
        });
        this.$forceUpdate();
      },
      deep: true,
    },
  },
};
</script>