<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{ routeName }}</h4>
          <br />

          <div class="alert alert-danger" v-if="error">{{ error }}</div>
          <div class="alert alert-warning" v-if="warning">{{ warning }}</div>
          <div class="alert alert-success" v-if="success">{{ success }}</div>

          <div class="row">
            <div class="col-9">
              <input
                type="text"
                class="form-control h-100"
                :placeholder="`Search ${routeName}`"
                v-model="subsearch"
                @keydown.enter="startSubsearch"
              />
            </div>
            <div class="col-3">
              <DateRangePickerCustom opens="left" @update="changePage" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-3">
              <div class="form-group">
                <label for="" class="">Login</label>
                <v-select
                  v-model="selectedLogins"
                  label="email"
                  class="form-control"
                  :options="allLogins"
                  :reduce="(login) => login._id"
                  @input="changePage"
                ></v-select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="" class="">Campaign</label>
                <v-select
                  class="form-control"
                  v-model="selectedCampaigns"
                  @search="onSearch"
                  label="name"
                  :filterable="false"
                  :reduce="(campaign) => campaign._id"
                  :options="allCampaigns"
                >
                  <template slot="no-options">
                    {{
                      $t("messages.typetosearch", {
                        name: $tc("general.campaign", 2),
                      })
                    }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="" class="">Log Level</label>
                <v-select
                  v-model="selectedLogLevels"
                  class="form-control"
                  multiple
                  :options="logLevels"
                  @input="changePage"
                ></v-select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="" class="">Log Category</label>
                <v-select
                  v-model="selectedLogCategorys"
                  class="form-control"
                  multiple
                  :options="logCategorys"
                  @input="changePage"
                ></v-select>
              </div>
            </div>
          </div>

          <div v-if="entityCount && entities.length">
            <Pagination class="my-4" :itemCount="entityCount" :currentPage="currentPage" :perPage="pagination" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
            <div class="table-responsive">
                <b-table
                :busy="loading"
                :fields="fields"
                :items="entities"
                striped
                responsive
                no-provider-filtering
                no-provider-sorting
                >
                <template #cell(type)="data">
                    <div class="text-center my-2">
                    <strong
                        ><b-icon
                        :icon="getIcon(data.value)"
                        aria-hidden="true"
                        scale="1.5"
                        ></b-icon
                    ></strong>
                    </div>
                </template>

                <template #cell(createdOn)="data">
                    <div class="text-center my-2">
                    <div class="pb-1">
                        <b-icon
                        icon="calendar-event"
                        aria-hidden="true"
                        class="mr-2"
                        ></b-icon
                        >{{ parseDate(data.value) }}
                    </div>
                    <div>
                        <b-icon
                        icon="clock"
                        aria-hidden="true"
                        class="mr-2"
                        ></b-icon
                        >{{ parseTime(data.value) }}
                    </div>
                    </div>
                </template>

                <template #cell(campaign)="data">
                    <div
                    v-b-popover.hover.bottomleft.html="
                        campaignDetails(data.value)
                    "
                    >
                    <b-icon
                        icon="info-circle"
                        aria-hidden="true"
                        class="mr-2"
                    ></b-icon>
                    {{ getCampaignName(data.value) }}
                    </div>
                </template>

                <template #cell(login)="data">
                    <div
                    v-b-popover.hover.bottomleft.html="loginDetails(data.value)"
                    >
                    <b-icon
                        icon="person-lines-fill"
                        aria-hidden="true"
                        class="mr-2"
                    ></b-icon
                    >{{ getLoginName(data.value) }}
                    </div>
                </template>

                <template #table-busy>
                    <div class="text-center my-2">
                    <div class="align-middle flip-square-loader"></div>
                    <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(actions)="data">
                    <i
                    v-if="
                        (data.item.version !== null) &
                        (data.item.entityType !== null)
                    "
                    class="text-primary icon-magnifier"
                    title="Compare"
                    style="cursor: pointer"
                    @click="compare(data.item)"
                    ></i>
                    <i
                    v-if="data.item.entityId"
                    class="ml-2 text-primary fa fa-copy"
                    :title="'Copy ID to clipboard: ' + data.item.entityId"
                    style="cursor: pointer"
                    @click="copyIdToClipboard(data.item.entityId)"
                    ></i>
                </template>
              </b-table>
            </div>
            <Pagination class="my-4" :itemCount="entityCount" :currentPage="currentPage" :perPage="pagination" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
          </div>
          <div v-if="!loading && !entityCount">
            <em>Unfortunately there is no {{ entityType }} available.</em>
          </div>
        </div>
        <b-modal
          size="lg"
          id="compare-modal"
          title="Comparison"
          :hide-footer="true"
        >
          <ComparisonModal
            :entityId="selectedEntityId"
            :entityType="selectedEntityType"
            :loginData="loginData"
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
const jwtDecode = require("jwt-decode");
import ComparisonModal from "@/components/modals/ComparisonModal";
import _debounce from "lodash.debounce";
import Pagination from "@/components/pages/assets/Pagination.vue";

export default {
  name: "Entities",
  components: {
    DateRangePickerCustom,
    ComparisonModal,
    Pagination
  },
  data() {
    return {
      selectedDaterange: null,
      currentPage: 1,
      subsearch: null,
      entities: [],
      entityCount: 0,
      localArchive: false,
      campaignData: {},
      allCampaigns: [],
      allLogins: [],
      logLevels: ["administrator", "system", "advertiser"],
      logCategorys: ["Campaign Management", "Optimizer", "Subscription"],
      selectedLogLevels: [],
      selectedLogCategorys: [],
      selectedLogins: [],
      selectedCampaigns: [],
      loginData: {},
      selectedEntityId: null,
      selectedEntityType: null,
      fields: [
        {
          key: "type",
          label: "EntityAction",
          sortable: true,
        },
        {
          key: "createdOn",
          label: "Timestamp",
          sortable: true,
        },
        {
          key: "entityType",
          label: "EntityType",
          sortable: true,
          formatter: "upperCaseFirstLetter",
        },
        {
          key: "campaign",
          label: "Campaign",
          sortable: true,
        },
        {
          key: "login",
          label: "Login",
          sortable: true,
        },
        {
          key: "message",
          label: "Message",
          sortable: true,
        },
        {
          key: "category",
          label: "Category",
          sortable: true,
        },
        {
          key: "level",
          label: "Level",
          sortable: true,
          formatter: "upperCaseFirstLetter",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    localArchive: function () {
      this.$store.dispatch("setArchived", this.localArchive);
      this.$store.dispatch("setLoadingActive");
      this.changePage();
      this.$store.dispatch("setLoadingInactive");
    },
  },
  computed: {
    ...mapGetters([
      "pagination",
      "paginationSelectOptions",
      "loading",
      "error",
      "success",
      "warning",
      "archived",
      "dateRange",
    ]),
    dateRange: {
      get() {
        return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      },
    },
    isSysOp: () =>
      jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role ==
      "systemoperator"
        ? true
        : false,
    selectedPagination: {
      get() {
        return this.$store.state.store.pagination;
      },
      set(value) {
        this.$store.dispatch("setPagination", value);
      },
    },
    numberOfPages() {
      return Math.ceil(this.entityCount / this.pagination);
    },
    offset() {
      return (this.currentPage - 1) * this.pagination;
    },
    routeName() {
      return this.$router.history.current.meta.title;
    },
    entityType() {
      return this.$router.history.current.meta.entity;
    },
    currentPath() {
      return this.$router.history.current.path;
    },
  },
  async created() {
    let startD = new Date(this.dateRange.startDate);
    let endD = new Date(this.dateRange.endDate);
    this.selectedDaterange = {
      startDate: new Date(
        startD.getUTCFullYear(),
        startD.getUTCMonth(),
        startD.getUTCDate()
      ),
      endDate: new Date(
        endD.getUTCFullYear(),
        endD.getUTCMonth(),
        endD.getUTCDate()
      ),
    };
    this.localArchive = this.$store.getters.archived;
    this.itemProvider();
    let result = (await this.$api.campaign.getAll()).items;
    result.forEach((e) => (this.campaignData[e._id] = e));
    this.allLogins = await this.$api.user.getAll();
    this.allLogins.forEach((e) => (this.loginData[e._id] = e));
  },
  methods: {
    copyIdToClipboard(entityId) {
      const el = document.createElement("textarea");
      el.value = entityId;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    upperCaseFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _debounce((loading, search, vm) => {
      vm.$api.campaign
        .search(search, { enableLoading: false })
        .then((campaigns) => {
          vm.allCampaigns = campaigns.items;
          loading(false);
        });
    }, 350),
    compare(entity) {
      if (entity.entityId) {
        this.selectedEntityId = entity.entityId;
        this.selectedEntityType = entity.entityType;
        this.$bvModal.show("compare-modal");
        return;
      }
      try {
        this.selectedEntityId = JSON.parse(entity.version)._id;
        this.selectedEntityType = entity.entityType;
      } catch (error) {
        /* console.log(error) */
      }
      this.$bvModal.show("compare-modal");
    },
    pickerDate(picker) {
      let str = picker.startDate ? picker.startDate.formatDate(true) : null;
      const endStr = picker.endDate ? picker.endDate.formatDate(true) : null;

      if (str !== endStr) {
        str += " - " + endStr;
      }

      return str;
    },
    async itemProvider() {
      const response = await this.$api.campaignlog.getAll({
        offset: this.offset,
        search: this.subsearch,
        qty: this.pagination,
        login: this.selectedLogins,
        campaign: this.selectedCampaigns,
        level: this.selectedLogLevels,
        category: this.selectedLogCategorys,
        start: new Date(this.selectedDaterange.startDate).getUTC(true),
        end: new Date(this.selectedDaterange.endDate).getUTC(false),
        changes: true,
        returnCount: true,
      });
      this.entityCount = response.count;
      this.entities = response.items;
      this.entities.map((item) => {
        //old version
        if (item.message.includes("Add") || item.message.includes("Created")) {
          item.type = "add";
          item._cellVariants = { type: "success" };
        } else if (item.message.includes("Edit")) {
          item.type = "edit";
          item._cellVariants = { type: "warning" };
        } else if (item.message.includes("Delete")) {
          item.type = "delete";
          item._cellVariants = { type: "danger" };
        }

        if (item?.entityAction == "create") {
          item.type = "create";
          item._cellVariants = { type: "success" };
        } else if (item?.entityAction == "update") {
          item.type = "edit";
          item._cellVariants = { type: "warning" };
        } else if (item?.entityAction == "delete") {
          item.type = "delete";
          item._cellVariants = { type: "danger" };
        } else {
          item.type = "info";
          item._cellVariants = { type: "info" };
        }
      });
      return this.entities;
    },
    changeQuantity(quantity){
        this.$store.dispatch("setPagination", quantity);
      this.changePage(1);
    },
    // async changePagination(pagination) {
    //   this.$store.dispatch("setPagination", pagination);
    //   this.currentPage = 1;
    //   this.itemProvider();
    // },
    changePage(page){
      this.currentPage = page;
      this.itemProvider();
    },
    // async changePage() {
    //   console.log("changePage");
    //   this.itemProvider();
    // },
    async startSubsearch() {
      this.itemProvider();
    },
    parseDate(value) {
      let date = new Date(value);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString(undefined, options);
    },
    parseTime(value) {
      let date = new Date(value);
      const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
      return date.toLocaleTimeString(undefined, options);
    },
    getIcon(action) {
      if (action == "add") return "plus-circle";
      if (action == "edit") return "tools";
      if (action == "info") return "info-circle";
      if (action == "delete") return "trash";
    },
    getCampaignName(id) {
      return this.campaignData?.[id]?.name
        ? this.campaignData?.[id]?.name
        : "unkown";
    },
    getLoginName(id) {
      let first = this.loginData?.[id]?.name
        ? this.loginData?.[id]?.name
        : "unkown";
      let last = this.loginData?.[id]?.surname
        ? this.loginData?.[id]?.surname
        : "unkown";
      return `${first} ${last}`;
    },
    campaignDetails(id) {
      let html = `<strong>${
        this.campaignData?.[id]?.name ? this.campaignData?.[id]?.name : "unkown"
      }</strong><br>`;
      html += `<strong>ID</strong>: ${
        this.campaignData?.[id]?._id ? this.campaignData?.[id]?._id : "unkown"
      }<br>`;
      html += `<strong>Manager</strong>: ${
        this.campaignData?.[id]?.manager
          ? this.campaignData?.[id]?.manager
          : "unkown"
      }<br>`;
      html += `<strong>Advertiser</strong>: ${
        this.campaignData?.[id]?.advertiser
          ? this.campaignData?.[id]?.advertiser
          : "unkown"
      }<br>`;
      html += `<strong>Archived</strong>: ${
        this.campaignData?.[id]?.archived
          ? this.campaignData?.[id]?.archived
          : "unkown"
      }<br>`;
      return html;
    },
    loginDetails(id) {
      let html = `<strong>${this.getLoginName(id)}</strong><br>`;
      html += `<strong>ID</strong>: ${
        this.loginData?.[id]?._id ? this.loginData?.[id]?._id : "unkown"
      }<br>`;
      html += `<strong>E-Mail</strong>: ${
        this.loginData?.[id]?.email ? this.loginData?.[id]?.email : "unkown"
      }<br>`;
      html += `<strong>Role</strong>: ${
        this.loginData?.[id]?.role ? this.loginData?.[id]?.role : "unkown"
      }<br>`;
      html += `<strong>Last Login</strong>: ${
        this.loginData?.[id]?.lastLogin
          ? this.loginData?.[id]?.lastLogin
          : "unkown"
      }<br>`;
      html += `<strong>Archived</strong>: ${
        this.loginData?.[id]?.archived
          ? this.loginData?.[id]?.archived
          : "unkown"
      }<br>`;
      return html;
    },
  },
};
</script>