<template>
  <nav class="navbar horizontal-layout col-lg-12 col-12 p-0">
    <div class="nav-top flex-grow-1">
      <div class="container d-flex flex-row h-100">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top">
          <router-link class="navbar-brand brand-logo" to="/">
            <img :src="headerlogo" id="logo" alt="logo" />
          </router-link>
          <router-link class="navbar-brand brand-logo-mini" to="/">
            <img :src="headerlogo" alt="logo" />
          </router-link>
        </div>
        <div
          class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
        >
          <v-select
            v-if="elements.length > 1"
            class="form-control nav-select mr-3 mobile-not"
            placeholder="Please select"
            :value="element"
            label="name"
            :reduce="(element) => element._id"
            :clearable="false"
            @input="changeAdvPub"
            :options="elements"
          >
            <template #selected-option="{ name }">
              <span class="text-white truncate">{{
                name.substring(0, 20)
              }}</span>
            </template>
            <template #option="{ name }">
              <span style="font-size: 0.75rem" class="truncate">{{
                name
              }}</span>
            </template>
            <template #open-indicator="{attributes}">
              <span v-bind="attributes" class="text-white">
                <i class="fa fa-chevron-down"></i>
              </span>
            </template>
          </v-select>
          <!-- Advertiser select mobile version -->

          <v-select
            v-if="elements.length > 1"
            class="form-control nav-select mr-3 mobile-only"
            placeholder="Please select"
            :value="element"
            label="name"
            :clearable="false"
            @input="changeAdvPub"
            :reduce="(element) => element._id"
            :options="elements"
          >
            <template #selected-option="{ name }">
              <span class="text-white truncate">{{
                name.substring(0, 7)
              }}</span>
            </template>
            <template #option="{ name }">
              <span style="font-size: 0.75rem" class="truncate">{{
                name
              }}</span>
            </template>
            <template #open-indicator>
              <span></span>
            </template>
          </v-select>
          <form class="search-field" action="#" v-if="isSysOp">
            <div class="form-group mb-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="searchterm"
                  v-model="searchterm"
                  placeholder="Search"
                  @keydown.enter="search"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i
                      class="icon-magnifier"
                      style="cursor: pointer;"
                      @click="search"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <ul class="navbar-nav navbar-nav-right mr-0">
            <li class="nav-item dropdown mobile-only" v-if="isSysOp">
              <a
                class="nav-link dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i class="icon-magnifier"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="notificationDropdown"
                id="notificationDropdown2"
              >
                <input
                  type="text"
                  style="border-color: transparent; font-size: 0.9rem"
                  class="form-control"
                  v-model="searchterm"
                  placeholder="Search"
                  @keydown.enter="search"
                />
              </div>
            </li>
            <li
              class="nav-item nav-profile nav-code"
              :title="$t('navbar.profile')"
            >
              <router-link to="/profile" class="nav-link">
                <span class="nav-profile-text"
                  >{{ $t("general.hello") }} {{ getName }}</span
                >
                <span
                  class="profileimgnav"
                  :style="
                    'background-image: url(\'' +
                      ((user && user.avatar) || '/images/avatar.jpg') +
                      '\');'
                  "
                  >&nbsp;</span
                >
              </router-link>
            </li>

            <li
              class="nav-item"
              v-if="(isAdvertiser || isAgency || isSysOp || isCoWorker) && advertiserSelected"
              :title="$t('navbar.container-code')"
            >
              <a
                v-b-modal.containerCode
                class="nav-link nav-code"
                style="cursor: pointer;"
              >
                <i class="fa fa-code"></i>
              </a>
              <b-modal
                id="containerCode"
                title="Container Code"
                :hide-footer="true"
              >
                <p v-html="$t('descriptions.containercode')"></p>
                <pre
                  style="white-space: pre-wrap; word-break: break-all; background-color: #ddd"
                >
&lt;script type=&quot;text/javascript&quot;&gt;function docReady(e){&quot;complete&quot;===document.readyState||&quot;interactive&quot;===document.readyState?setTimeout(e,1):document.addEventListener(&quot;DOMContentLoaded&quot;,e)}docReady(function(){var e=document.createElement(&quot;script&quot;);e.async=!0,e.type=&quot;text/javascript&quot;,e.src=&quot;https://{{
                    getAdserver
                  }}/&quot;;var t=document.getElementsByTagName(&quot;body&quot;);t&amp;&amp;t[0]&amp;&amp;t[0].appendChild(e)});&lt;/script&gt;</pre
                >
              </b-modal>
            </li>
            <li
              v-if="!isViewer"
              class="nav-item"
              data-v-step="5"
              :title="$t('navbar.finance-area')"
            >
              <router-link
                class="nav-link"
                :to="advertiserSelected ? '/advertiser/finances/' : '/p/finances/'"
                style="cursor: pointer"
              >
                <i class="fa fa-euro"></i>
              </router-link>
            </li>
            <!-- <li
              class="nav-item"
              v-if="isAdvertiser || isSysOp"
              :title="$t('navbar.tour')"
            >
              <a class="nav-link" @click="startTour" style="cursor: pointer">
                <i class="fa fa-compass"></i>
              </a>
            </li> -->
            <li class="nav-item" :title="$t('actions.logout')">
              <a
                class="nav-link"
                href="javascript:void(0)"
                @click="signout()"
                style="cursor: pointer;"
              >
                <i class="icon-logout"></i>
              </a>
            </li>
          </ul>
          <button
            class="navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            @click="setMenu"
          >
            <span class="icon-menu text-white"></span>
          </button>
        </div>
      </div>
    </div>
    <div
      :class="['nav-bottom', showMenu ? 'header-toggled' : '']"
      data-v-step="1"
    >
      <div class="container">
        <ul
          class="nav page-navigation"
          style="justify-content: left"
        >
          <li
            class="nav-item"
            id="nav-dashboard"
            v-if="!isAdmin && !isAccounting"
            data-v-step="2"
          >
            <router-link :to="isPublisher ? '/p' : '/'" class="nav-link">
              <i class="link-icon icon-screen-desktop"></i>
              <span class="menu-title">{{ $t("general.dashboard") }}</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isPublisher">
            <router-link v-bind:to="'/p/campaigns'" class="nav-link">
              <i class="link-icon icon-basket"></i>
              <span class="menu-title">{{ $tc("general.campaign", 2) }}</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isPublisher">
            <router-link v-bind:to="'/publishers'" class="nav-link">
              <i class="link-icon icon-picture"></i>
              <span class="menu-title">{{ $tc("general.website", 2) }}</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-advertiser" v-if="isAccounting">
            <router-link v-bind:to="'/advertisers'" class="nav-link">
              <i class="link-icon icon-flag"></i>
              <span class="menu-title">{{ $tc("general.advertiser", 2) }}</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAccounting">
            <router-link v-bind:to="'/campaigns'" class="nav-link">
              <i class="link-icon icon-frame"></i>
              <span class="menu-title">{{ $tc("general.campaign", 2) }}</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-accounting" v-if="isAccounting">
            <router-link v-bind:to="'/accounting'" class="nav-link">
              <i class="link-icon  icon-folder-alt"></i>
              <span class="menu-title">{{ $t("general.accounting") }}</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-wizard" v-if="isAdmin">
            <router-link to="/bookingtemplate" class="nav-link">
              <i
                class="link-icon icon-folder"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">{{ $tc("general.template", 2) }}</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-wizard" v-if="isAdmin">
            <router-link to="/bookingtemplate/create" class="nav-link">
              <i
                class="link-icon icon-plus"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">New Booking Template</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            id="nav-wizard"
            v-if="isDealer || isAdmin || isAdvertiser || isAgency || isViewer || isCoWorker"
            data-v-step="4"
          >
            <router-link to="/bookings" class="nav-link">
              <i
                class="link-icon icon-basket"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">{{ $tc("general.booking", 2) }}</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            id="nav-wizard"
            v-if="isAdmin || isAdvertiser || isAgency || isCoWorker"
            data-v-step="6"
          >
            <router-link to="/booking/create" class="nav-link">
              <i
                class="link-icon icon-plus"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">{{ $t("general.new-booking") }}</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            id="nav-wizard"
            v-if="isWizard || isSysOp || isAffiliateManager"
          >
            <router-link to="/wizards" class="nav-link">
              <i
                class="link-icon icon-handbag"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Affiliate-Wizard</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-wizard" v-if="isAffiliateManager">
            <router-link to="/affiliate" class="nav-link">
              <i
                class="link-icon icon-info"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Affiliate-Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-vertical" v-if="isAdmin || isSysOp">
            <router-link to="/approvals" class="nav-link">
              <i
                class="link-icon icon-like"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Approvals</span>
            </router-link>
          </li> 
          <li class="nav-item" id="nav-vertical" v-if="isSysOp">
            <router-link to="/syncqueue" class="nav-link">
              <i
                class="link-icon icon-layers"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Sync-Queue</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            id="nav-advertiser"
            v-if="
              isWizard || isAdmin || isSysOp || isAffiliateManager || isAgency || isCoWorker
            "
          >
            <router-link to="/advertisers" class="nav-link">
              <i
                class="link-icon icon-flag"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">{{ $tc("general.advertiser", 2) }}</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-analytics" v-if="isSysOp">
            <router-link to="/logins" class="nav-link">
              <i
                class="link-icon icon-people"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">{{ $t("general.user") }}</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            id="nav-apps"
            v-if="isWizard || isAffiliateManager"
          >
            <router-link to="/banners" class="nav-link">
              <i
                class="link-icon icon-picture"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Creatives</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-docs" v-if="isAdmin">
            <router-link to="/lineitems" class="nav-link">
              <i
                class="link-icon icon-layers"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Line Items</span>
            </router-link>
          </li>
          <li class="nav-item" id="nav-docs" v-if="false">
            <router-link to="/contracts" class="nav-link">
              <i
                class="link-icon icon-docs"
                style="color: rgba(0,0,0,0.5);"
              ></i>
              <span class="menu-title">Contracts</span>
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              'mega-menu',
              'pull-right',
              showSubMenu ? 'show-submenu' : '',
            ]"
            v-if="isSysOp || isAgency || isCoWorker"
            @click="setSubMenu"
          >
            <a href="#" class="nav-link">
              <i class="link-icon icon-calculator"></i>
              <span class="menu-title grayed-out">{{
                $t("navbar.panel.label")
              }}</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="submenu" id="submenu-container">
              <div class="col-group-wrapper row">
                <template v-for="nav in menu">
                  <div
                    class="col-group col-md-3"
                    :nav="nav"
                    :key="nav.section"
                    v-if="nav.auth.includes(getRole)"
                  >
                    <p class="category-heading">
                      {{ $te(nav.section) ? $t(nav.section) : nav.section }}
                    </p>
                    <ul class="submenu-item">
                      <li
                        class="nav-item"
                        v-for="(item, index) in nav.items"
                        :data-v-step="item.tourStep || 0"
                        :key="index"
                      >
                        <router-link
                          v-if="item.auth.includes(getRole)"
                          class="nav-link"
                          :to="item.route"
                          >{{
                            $te(item.label) ? $tc(item.label, 2) : item.label
                          }}</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="tourCallbacks"
      :options="tourOptions"
    ></v-tour> -->
  </nav>
</template>

<script>
const jwtDecode = require("jwt-decode");
import menu from "@/data/menu";
import { mapGetters } from "vuex";

export default {
  name: "PageNavbar",
  data() {
    return {
      searchterm: null,
      menu,
      showMenu: false,
      showSubMenu: false,
      getAdserver: process.env.VUE_APP_ADSERVER,
      tourOptions: {
        highlight: true,
      },
      steps: [],
      tourCallbacks: {
        onSkip: this.hideTour,
        onFinish: this.hideTour,
      },
      element: null,
      advertiserSelected: false,
    };
  },
  async created() {
    const user = await this.$store.dispatch("getUser");
    try{
      await this.$store.dispatch("getAdvertisers");
      await this.$store.dispatch("getPublishers");
    }
    // eslint-disable-next-line no-empty
    catch(err){
      
    }

    this.$store.commit("SET_PUBLISHER", null);
    this.$store.commit("SET_ADVERTISER", null);

    if(user?.selectedType == 'publisher' && user.selectedPublisher){
      this.$store.commit("SET_PUBLISHER", user.selectedPublisher);
      this.element = user.selectedPublisher;
      this.advertiserSelected = false;
    }
    else{
      this.$store.commit("SET_ADVERTISER", user.selectedAdvertiser);
      this.element = user.selectedAdvertiser;
      this.advertiserSelected = true;
    }

    // this.steps = this.getTourSteps();
    // if (
    //   !localStorage.getItem("42DoTOUR") &&
    //   (this.isSysOp || this.isAdvertiser)
    // ) {
    //   this.$tours["myTour"].start();
    // }
  },
  computed: {
    branding: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT))?.branding ?? false,
    getName: () => localStorage.getItem(process.env.VUE_APP_NAME),
    isSysOp() {
      return this.user?.role === "systemoperator" || false;
    },
    isWizard() {
      return this.user?.role === "wizard" || false;
    },
    isAffiliateManager() {
      return this.user?.role === "affiliatemanager" || false;
    },
    isAdmin() {
      return this.user?.role == "administrator" || false;
    },
    isAccounting() {
      return this.user?.role === "accounting" || false;
    },
    isAdvertiser() {
      return this.user?.role === "advertiser" || false;
    },
    isPublisher() {
      return this.user?.role === "publisher" || false;
    },
    isAgency() {
      return this.user?.role === "agency" || false;
    },
    isViewer() {
      return this.user?.role === "viewer" || false;
    },
    isCoWorker() {
      return this.user?.role === "co_worker" || false;
    },
    isDealer() {
      return this.user?.role === "dealer" || false;
    },
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    headerlogo() {
      if(this.branding) return `/branding/${this.branding}/logo.png`;
      else              return "/wl/" + process.env.VUE_APP_NAME + "/logo.png";
    },
    ...mapGetters({
      user: "user",
      advertisers: "getAdvertisers",
      advertiser: "getAdvertiser",
      publishers: "getPublishers",
      publisher: "getPublisher",
    }),
    elements(){
      const elements = [];
      let pre = false;
      if(this.advertisers.length > 0 && this.publishers.length > 0){
        pre = true;
      }

      if(this.advertisers.length > 0){
        let preValue = pre ? "(A) " : "";

        for(const advertiser of this.advertisers){
          elements.push({
            name: `${preValue}${advertiser.name}`,
            _id: advertiser._id,
            type: "advertiser"
          });
        }
      }

      if(this.publishers.length > 0){
        let preValue = pre ? "(P) " : "";

        for(const publisher of this.publishers){
          elements.push({
            name: `${preValue}${publisher.name}`,
            _id: publisher._id,
            type: "publisher"
          });
        }
      }

      return elements;
    },
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
  methods: {
    // getTourSteps() {
    //   if (this.isSysOp) {
    //     return [
    //       {
    //         target: '[data-v-step="1"]',
    //         header: {
    //           title: "Discover our platform",
    //         },
    //         content: `Follow the tour to quickly get to know some of our best features`,
    //       },
    //       {
    //         target: '[data-v-step="2"]',
    //         header: {
    //           title: "Dashboard",
    //         },
    //         content: `Here is your dashboard. You can quickly view all relevant data in one single place.`,
    //         before: async () => {
    //           const menu = document.getElementById("submenu-container");
    //           if (menu) {
    //             menu.classList.add("manually-show-menu");
    //           }
    //         },
    //       },
    //       {
    //         target: '[data-v-step="3"]',
    //         header: {
    //           title: "Booking Template",
    //         },
    //         content:
    //           "Here you can create booking templates that contains all booking configurations. Templates can be reused and act as default settings and restrictors when creating booking based on them.",

    //         params: {
    //           placement: "left", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    //         },
    //       },
    //       {
    //         target: '[data-v-step="4"]',
    //         header: {
    //           title: "Bookings",
    //         },
    //         content:
    //           "Here you will find all your bookings. You can edit them and create new ones with our magical booking wizard",
    //         params: {
    //           placement: "left",
    //         },
    //       },
    //       {
    //         target: '[data-v-step="5"]',
    //         header: {
    //           title: "Finance Area",
    //         },
    //         content:
    //           "Here is your current financial status. You can check your current budget and add funds for your campaigns.",
    //         before: async () => {
    //           document
    //             .getElementById("submenu-container")
    //             .classList.remove("manually-show-menu");
    //         },
    //         params: {
    //           placement: "bottom",
    //         },
    //       },
    //     ];
    //   }
    //   return [
    //     {
    //       target: '[data-v-step="1"]',
    //       header: {
    //         title: "Discover our platform",
    //       },
    //       content: `Follow the tour to quickly get to know some of our best features`,
    //     },
    //     {
    //       target: '[data-v-step="2"]',
    //       header: {
    //         title: "Dashboard",
    //       },
    //       content: `Here is your dashboard. You can quickly view all relevant data in one single place.`,
    //     },
    //     {
    //       target: '[data-v-step="4"]',
    //       header: {
    //         title: "Bookings",
    //       },
    //       content:
    //         "Here you will find all your bookings. You can check their status and edit them.",
    //     },
    //     {
    //       target: '[data-v-step="6"]',
    //       header: {
    //         title: "Bookings",
    //       },
    //       content:
    //         "Here you can create new bookings using our magical booking wizard",
    //     },
    //     {
    //       target: '[data-v-step="5"]',
    //       header: {
    //         title: "Finance Area",
    //       },
    //       content:
    //         "Here is your current financial status. You can check your current budget and add funds for your campaigns.",
    //     },
    //   ];
    // },
    async changeAdvPub(elementId) {
      const element = this.elements.find(element => element._id === elementId);
      if(element.type === "advertiser"){
        this.$store.commit("SET_ADVERTISER", elementId);
        await this.$api.user.update({
          selectedAdvertiser: elementId,
          email: localStorage.getItem(process.env.VUE_APP_EMAIL),
        });
        window.location = "/";
      }else{
        this.$store.commit("SET_PUBLISHER", elementId);
        await this.$api.user.update({
          selectedPublisher: elementId,
          email: localStorage.getItem(process.env.VUE_APP_EMAIL),
        });
        window.location = "/p/";
      }
    },
    signout() {
      this.$store.dispatch("signout");
      this.$router.push("/login");
    },
    search(e) {
      if (e) e.preventDefault();
      document.getElementById("notificationDropdown2").classList.remove("show");
      this.$router.push(`/search/${this.searchterm}`);
      this.searchterm = "";
    },
    hideTour() {
      const menu = document.getElementById("submenu-container");
      if (menu) {
        menu.classList.remove("manually-show-menu");
      }
      localStorage.setItem("42DoTOUR", "hide");
    },
    // startTour() {
    //   this.$tours["myTour"].start();
    // },
    setMenu() {
      this.showMenu = !this.showMenu;
    },
    setSubMenu() {
      this.showSubMenu = !this.showSubMenu;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style>
.nav-select .vs__dropdown-toggle{
  background: none;
}
</style>

<style scoped>
.manually-show-menu {
  display: block !important;
}

#logo {
  height: unset;
  max-height: 43px;
  margin-top: 5px;
}
.grayed-out {
  color: rgba(0, 0, 0, 0.3);
}
#searchterm {
  color: #fff;
}
.nav-select {
  background: transparent;
  max-width: 13rem;
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 20px;
  min-width: 110px;
}

span.profileimgnav {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  margin-left: 0.75rem;
}
</style>
<style>
@media (max-width: 1026px) {
  span.profileimgnav {
    display: none;
  }
  .nav-code {
    display: none;
  }
}

.nav-select .vs__selected-options {
  padding: 0px !important;
}
</style>
