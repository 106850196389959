<template>
  <div class="col mt-3">
    <div class="row">
      <div class="col">
        <h5>{{$tc('syncqueue.tree.depends-on')}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col" v-if="!hasNext">
        {{$tc('syncqueue.tree.no-more-dependencies')}}
      </div>
      <div class="col" v-else>
        <b-list-group>
          <b-list-group-item style="cursor: pointer;" @click="$emit('setActive', tree, true)" v-for="tree in next" :key="tree._id">
            <status :small="true" :tree="tree"/>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import Status from './Status';
export default {
  components: { Status },
  name: "Dependencies",
  props: ["next"],
  computed: {
    hasNext() {
      return !!this.next?.length;
    }
  }
}
</script>

<style>

</style>