<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <form action="#">
            <div class="form-group d-flex">
              <input
                type="text"
                class="form-control"
                v-model="newsearch"
                :placeholder="currentsearch"
                @keydown.enter="search"
              />
              <button
                type="submit"
                class="btn btn-primary ml-3"
                @click="search"
              >
                Search
              </button>
            </div>
          </form>

          <h2>
            Search Result For
            <u class="ml-2">"{{ currentsearch }}"</u>
          </h2>
          <p class="text-muted">
            Results: {{ itemCount }}, Duration: {{ searchDuration }} ms
          </p>

          <Pagination class="my-4" :itemCount="largestitemCount" :currentPage="settings.page" :perPage="filter.qty" @changeQuantity="changeQuantity" @changePage="changePage" :showResults="false"></Pagination>

          <div v-if="loading" class="flip-square-loader"></div>
          <div class="table-responsive" v-if="!loading && itemCount">
            <SearchResultTable
              :entities="searchResults"
              :fields="entityFields"
            />
          </div>
          <div v-if="!loading && !itemCount">
            <em>Unfortunately there are no results available.</em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResultTable from "./SearchResultTable.vue";
import Pagination from "@/components/pages/assets/Pagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "SearchResults",
  data() {
    return {
      newsearch: null,
      currentPage: 1,
      searchResults: [],
      searchDuration: 0,
      itemCount: 0,
      largestitemCount: 0,
      settings: {
        isBusy: false,
        page: 1,
      },
      filter: {
        qty: 10,
        offset: 0,
        search: this.term,
        returnCount: true,
      },
    };
  },
  props: ["term"],
  computed: {
    ...mapGetters([
      "loading",
      "error",
      "success",
      "warning",
    ]),
    currentsearch() {
      return this.term;
    },
    entityPopulate() {
      return this.$router.history.current.meta.populate;
    },
    entityFields() {
      return this.$router.history.current.meta.fields;
    },
  },
  async created() {
    this.fetchData();
    this.newsearch = this.currentsearch;
  },
  methods: {
    async fetchData(){
      if(this.settings.isBusy)  return;

      this.settings.isBusy = true;
      this.filter.offset = (this.settings.page-1)*this.filter.qty;

      this.filter.populate = JSON.stringify(this.entityPopulate);

      const response = await this.$api.search.get(this.filter)

      this.searchResults = response;
      this.itemCount = response.stats.qty;
      this.largestitemCount = response.stats.largest;
      this.searchDuration = response.stats.ms;

      this.settings.isBusy = false;
    },
    search() {
      this.$router.push(`/search/${this.newsearch}`);
    },
    changePage(page){
      this.settings.page = page;
      this.fetchData();
    },
    changeQuantity(quantity){
      this.filter.qty = quantity;
      this.changePage(1);
    },
  },
  components: {
    SearchResultTable,
    Pagination
  },
};
</script>

<style scoped>
</style>