const faq = [
  {
    icon: "icon-user",
    title: "Registration & Login",
    contents: [
      {
        title: "How can I register?",
        text: `<ol><li>Visit our website <a href="https://www.42ads.io">www.42ads.io</a>.</li>
        <li>Then click on the <strong>login</strong> button in the right top corner of the menu bar</li>
        <li>On the login page that appears, you can click on <strong>register</strong> in the corner on the right top. Now you
        will be redirected to the registration page.</li>
        <li>Now you can enter your data and select your industry.</li>
        <li>Read and confirm the privacy policy and the general terms and conditions.</li>
        <li>Finally, confirm the completion of the registration by clicking on <strong>register</strong>.</li></ol>
        <p>You will now receive an email confirming your registration.<br>
        Click on the button <strong>COnfirm email address</strong> to verify your email address.</p>`
      },
      {
        title: "How long does it take for my account to be activated after the registration?",
        text: `If you have registered as a <strong>company</strong>, your account will be activated immediately after confirming your e-mail address.<br><br>
        If you have requested an <strong>agency</strong> account, your registration will be manually checked and activated by us. We may contact you beforehand.`
      },
      {
        title: "In which languages is the 42AdSuite available?",
        text: `The 42AdSuite is currently available in <strong>German</strong> and the world language <strong>English</strong>.<br>You can change the language on your profile page.`
      },
      {
        title: "Does the access to 42AdSuite cost me anything?",
        text: `No, the access to the 42AdSuite is <strong>free of charge</strong>. Only the running campaigns are chargeable.`
      },
      {
        title: "How can I log in?",
        text: `<ol><li>Visit our website <a href="https://www.42ads.io">www.42ads.io</a>.</li>
        <li>Click on the login button in the menu bar.</li>
        <li>Now you will get to the login page and you can log in with the data you have set.</li></ol>`
      },
      {
        title: "I forgot my password, how can I reset it?",
        text: `<p>In the <strong>login</strong> area next to the login button you will find the point <strong>Reset password</strong>.</p>
        <ol><li>Click on the <strong>login</strong> button and then enter your <strong>e-mail address</strong>.</li>
        <li>Confirm the reset with a click on <strong>Reset password</strong>.</li>
        <li>Now you will receive an email with further instructions to the email address you have entered.</li></ol>`
      }
    ]
  },
  {
    icon: "icon-basket",
    title: "About your campaigns",
    contents: [
      {
        title: "How can I create a campaign?",
        text: `<p>You can create a campaign by clicking on the <strong>Launch Now</strong> button or by clicking on <strong>New Booking</strong>. You can create a campaign in the following steps:</p>
        <ol><li>Define the general conditions like channels or budget.</li>
        <li>Determine your target group and define what you want to advertise.</li>
        <li>Upload your creatives or use the creative service.</li>
        <li>Check your details and click on <strong>Create booking</strong></li></ol>
        <p>If you selected the creative service, we will generate suitable and optimized creatives for your booking. You can review and change, delete or replace this creatives. If you are satisfied with the selection, then you can <strong>publish</strong> the booking.</p>
        <p>If you have uploaded your own creatives, your campaign will start automatically as soon as we have checked your creatives.</p>`
      },
      {
        title: "On which channels can I create my campaigns?",
        text: `<p>We have categorized the available advertising channels:</p>
        <strong>Native Ads</strong>
          <ul><li>Facebook</li><li>Instagram</li></ul>
        
        <strong>Social Media Video</strong>
          <ul><li>TikTok</li></ul>
        
        <strong>Banner Ads</strong>
        <ul><li>Google Display & Video</li><li>Platform 161</li></ul>
        
        <strong>Digital Out-of-home</strong>
          <ul><li>UZE Ads</li><li>SSP1</li></ul>
        
        <p>It is possible that not all advertising channels are visible after registration. In this case, please contact us.</p>
        `
      },
      {
        title: "Can I advertise all channels in one campaign at the same time?",
        text: `Yes, with the 42AdSuite you can advertise all channels simultaneously in one campaign.`
      },
      {
        title: "Is there a minimum budget for the different channels?",
        text: `For all channels, excluded TikTok, 1,00 € daily budget per channel is enough. For TikTok is the minimum daily budget 20,00 € per day.`
      },
      {
        title: "Can I determine how long my campaign will run?",
        text: `Yes, you can set the start and end date flexibly when you create the campaign.`
      },
      {
        title: "Can I define my target group?",
        text: `Yes, you can define the target group when creating the campaign.<br>
        Specify the location where you want to advertise and define the gender and age of your target group.<br>
        You can always edit the data about your target group afterwards.`
      },
      {
        title: "Can I store my own banners/advertising material?",
        text: `During the creation of a new booking you can decide if you want to use the <strong>creative service</strong> or upload your own creatives. If you choose to upload your own creatives, you can upload seperate creatives for every channel`
      },
      {
        title: "What happens after creating a campaign?",
        text: `<ol><li>After creating a campaign, you will receive an email with the general conditions you set.</li>
        <li>If you choose to use the creative service, we will created the creatives in the background. When they have been created, you will receive an email.</li>
        <li>Your campaign will be checked in the background. When your campaign is ready to be published, you will receive an email notification.</li>
        <li>After receiving the email, you can publish your campaign.</li></ol>`
      },
      {
        title: "How can I publish my campaign?",
        text: `<p>Once you have received the email that your campaign is ready for publishing, you can now publish it from your dashboard.</p>
        <ol><li>Go to the <strong>Pending Bookings</strong> section on your dashboard.</li>
        <li>Click on the <strong>Publish</strong> point in the campaign status.</li></ol>
        Now your campaign will be published, this can take up to two minutes. Once the publishing is complete, you will receive an email.`
      },
      {
        title: "Is it possible to pause a started campaign and start it again at a later time?",
        text: `Your campaign can be paused and restarted at any time.`
      },
      {
        title: "Can an expiring campaign be extended?",
        text: `Yes, you can edit the duration as soon the campaign is running. Click in the <strong>Update Booking</strong> button and change the end date in the <strong>General</strong> section`
      },
      {
        title: "Can channels be added or removed from active campaigns?",
        text: `Yes, channels can be added and removed at any time.`
      },
      {
        title: "How can I view the success of my campaigns?",
        text: `<p>You can view the success of your campaigns in two ways.</p>
        <ol><li>A simple and short overview, you can find on your dashboard under the point <strong>Active Bookings</strong>.</li>
        <li>Under the point <strong>Active Bookings</strong>, you have the possibility to select <strong>Instant Reporting</strong> in the actions. Here you will find a simple but detailed overview of your respective campaign. You can easily have these reports sent to the email address you have stored. Specify this on the report page in the upper area under <strong>Subscribe to Statistics</strong>.`
      }
    ]
  },
  {
    icon: "fa fa-photo",
    title: "Creative service",
    contents: [
      {
        title: "What is the creative service?",
        text: `The creative service generates the appropriate and optimized creatives for your booking for each advertising channel that you have selected.<br>You can then check the creatives. You can delete creatives you do not like and also upload your own advertising material.`
      },
      {
        title: "What can I advertise?",
        text: `You can advertise your <strong>company</strong> or individual <strong>products</strong>.`
      },
      {
        title: "Can I also advertise an event?",
        text: `Yes, create your event as <strong>product</strong> in out creative service or upload your own creatives`
      },
      {
        title: "How and in which file formats can I upload my logo and/or product image?",
        text: `You can deposit your logo and product image as URL if you have already uploaded it to your website.
        You can also upload the logo and product image via drag & drop or select from your hard drive.
        The file must be a PNG or a JPG file.`
      },
      {
        title: "Can I insert my slogan for the advertising material?",
        text: `Yes, if you want to promote your company, you can insert your slogan when creating the campaign under the point <strong>Slogan / Call to action</strong>.`
      },
      {
        title: "Can I make a final check of the specified and selected data before I finally create the campaign?",
        text: `Yes, the last step to create a campaign, includes an overview with the data you specified and selected.`
      },
    ]
  },
  {
    icon: "fa fa-euro",
    title: "Payment & Invoices",
    contents: [
      {
        title: "How can I charge my credit and what payment options are available?",
        text: `<p>You can charge your credit via the point <strong>Finances</strong>. You can reach this via the menu item <strong>€</strong>.</p>
        <ol><li>Here you can enter the desired amount in the <strong>Add Funds</strong> section and click on the <strong>Add Funds</strong> button.</li>
        <li>If you didn't enter your invoice destails in the past, you will be asked to do it now.</li>
        <li>In the window that opens, enter your data and click on <strong>Pay now</strong>.</li>
        <li>The entered amount will be credited to your account and you will receive a confirmation by e-mail.</li>
        <li>An invoice will be generated and is ready to download.</li></ol>`
      },
      {
        title: "Where can I view my credit?",
        text: `Under the point <strong>Finances</strong>, which you can reach via the menu point <strong>€</strong>, you have the possibility to view your current balance at any time.`
      },
      {
        title: "Will I receive a notification when my budget is used up?",
        text: `Yes, we will notify you by email and you will also receive a notification on the dashboard.`
      }
    ]
  },
]

module.exports = faq;
