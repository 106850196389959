<template>
  <div>
    <div class="row">
      <div class="col-md-12 mt-3">
        <h3>
          <span class="handwriting mr-2">{{ $tc("general.conversion",2) }}</span>
          {{ campaignName }}
        </h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <EntityFilterConversions class="pt-3" :filter="filter" @filter="addFilter" :lineitems="lineItems" :creatives="creatives" />
        <div class="row bg-light pt-3">
          <div class="col-12 form-group">
            <label for="search" class="mb-1">{{ $t("general.searchfor")}}</label>
            <input
              id="search"
              class="form-control"
              v-model="filter.q"
              @keydown.enter="changePage(1)"
              :disabled="isBusy"
            />
          </div>
        </div>
        <div class="row bg-light py-3">
          <!-- <div class="col">
            <input class="mt-1" type="file" name="bulkimport" id="bulkimport">
          </div>
          <div class="col">
            <input class="btn btn-success ml-2" type="button" value="Import" @click="bulkImport()">
          </div> -->
          <div class="col text-right">
            <b-button variant="success" :disabled="isBusy" @click="changePage(1)">{{ $t("general.search") }}</b-button>
          </div>
        </div>
        <b-row v-if="!isBusy && total == 0" class="mt-3">
          <b-alert class="col-md-12 text-center" show variant="info">
            {{ $t("messages.nostats") }}
          </b-alert>
        </b-row>
        <b-row>
          <b-col>
            <div
              class="table-responsive mb-0"
              :class="!conversionTableData.length ? 'invisible' : 'visible'"
            >
              <b-table
                hover
                :items="conversionTableData"
                :fields="conversionTableFields"
                sortBy="timestamp"
                sort-desc
                :busy.sync="isBusy"
              >
                <template #cell(timestamp)="data">
                  <span class="d-block">
                    <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                    {{ getFullDateFromDate(data.value) }}
                  </span>
                  <span class="d-block mt-1">
                    <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                    {{ getFullTimeFromDate(data.value) }}
                  </span>
                </template>
                <template #cell(timestampClick)="data">
                  <span class="d-block">
                    <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                    {{ getFullDateFromDate(data.value) }}
                  </span>
                  <span class="d-block mt-1">
                    <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                    {{ getFullTimeFromDate(data.value) }}
                  </span>
                </template>
                <template #cell(timestampView)="data">
                  <span class="d-block">
                    <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                    {{ getFullDateFromDate(data.value) }}
                  </span>
                  <span class="d-block mt-1">
                    <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                    {{ getFullTimeFromDate(data.value) }}
                  </span>
                </template>
                <template #cell(approvalStatus)="data">
                  <i v-b-tooltip.hover :title="`${$t('review.approved-on')} ${new Date(data.item.approvalDate).formatDatetime()}`" v-if="data.value == 'approved'" class="fa fa-check-circle-o text-success"></i>
                  <i v-b-tooltip.hover :title="`${$t('review.rejected-on')}  ${new Date(data.item.approvalDate).formatDatetime()}`" v-else-if="data.value == 'rejected'" class="fa fa-times-circle-o text-danger"></i>
                  <i v-b-tooltip.hover :title="`${$t('review.pending')}`" v-else class="fa fa-clock-o text-warning"></i>
                </template>
                <!-- <template #cell(timeelapsed)="data">
                  <span>{{ data.value / 1000 }} s</span>
                </template> -->
                <template #cell(cartValue)="data">
                  <span v-if="data.item.basketValue && data.item.basketValue != data.value">
                    <span v-if="!isNaN(data.item.basketValue)">
                      <NumberAbbreviated
                        :value="new Number(data.item.basketValue)"
                        :currency="true"
                      /> <i class="fa fa-info-circle" v-b-tooltip.hover :title="`${$t('review.changed-cart-value', {'value': data.value})}`"></i>
                    </span>
                    <span v-else>-</span>
                  </span>
                  <NumberAbbreviated
                    v-else-if="!isNaN(data.value)"
                    :value="data.value"
                    :currency="true"
                  />
                  <span v-else>-</span>
                </template>
                <template #cell(cost)="data">
                  <span v-if="data.item.commission !== null">
                    <span v-if="!isNaN(data.item.commission)">
                      <NumberAbbreviated
                        :value="new Number(data.item.commission)"
                        :currency="true"
                      />
                    </span>
                    <span v-else>-</span>
                  </span>
                  <NumberAbbreviated
                    v-else-if="!isNaN(data.value)"
                    :value="data.value"
                    :currency="true"
                  />
                  <span v-else>-</span>
                </template>
                <template #cell(fee)="data">
                  <NumberAbbreviated
                    v-if="!isNaN(data.value)"
                      :value="data.value"
                      :currency="true"
                    />
                  <span v-else>-</span>
                </template>
                <template #cell(bannerObj)="data">
                  <AdListPreview :creative="data.value" :onlyButton="true" />
                </template>
                <template #cell(actions)="data">
                  <div>
                    <span v-if="data.value.readonly">{{data.value.status}}</span>
                    <b-dropdown :disabled="approvedIds.includes(data.value._id)" split id="action-dropdown" text="Approve" variant="success" @click="approveSale(data.value._id)">
                      <b-dropdown-item :disabled="approvedIds.includes(data.value._id)" @click="rejectSale(data.value._id)">Reject</b-dropdown-item>
                      <b-dropdown-item :disabled="approvedIds.includes(data.value._id)" @click="showApprovalModal(data.value._id)" >Update</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <Pagination class="my-4" :itemCount="total" :currentPage="filter.page" :perPage="filter.size" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
              <p v-if="conversionTableData.length" class="text-right mb-2">
                <b-button
                  @click="dlStats()"
                  class="btn btn-outline-primary btn-sm"
                  variant="secondary"
                  ><i class="fa fa-floppy-o m-0" aria-hidden="true"></i
                ></b-button>
              </p>
            </div>
          </b-col>
        </b-row>
        <b-modal
          id="sale-approval-modal"
          title="Sale Approval"
          size="md"
          @ok="saveApprovalModal(true)"
          @cancel="saveApprovalModal(false)"
          :ok-title="$t('conversion-modal.approve')"
          :cancel-title="$t('conversion-modal.reject')"
          cancel-variant="danger"
          ok-variant="success"
        >
          <b-row>
            <b-col>
              <h5 class="text-center mb-4">
                {{ $t('conversion-modal.title') }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="table">
                <tr>
                  <td>{{ $t('conversion-modal.website') }}</td>
                  <td class="text-right">{{ conversionTableEntry.location }}</td>
                </tr>
                <tr>
                  <td>{{ $tc('general.view',1) }}</td>
                  <td class="text-right">
                    <span v-if="conversionTableEntry.timestampView">
                      {{ new Date(conversionTableEntry.timestampView).formatDatetime() }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('general.click', 1) }}</td>
                  <td class="text-right">
                    <span v-if="conversionTableEntry.timestampClick">
                      {{ new Date(conversionTableEntry.timestampClick).formatDatetime() }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('conversion-modal.cart-value') }}</td>
                  <td class="text-right">
                    <b-input-group class="w-75 ml-auto">
                      <b-input
                        v-model="conversionTableEntry.adjustedCartValue"
                        type="number"
                        step="0.01"
                      />
                      <b-input-group-append>
                        <b-input-group-text>€</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('conversion-modal.provision') }}</td>
                  <td class="text-right" v-if="conversionTableEntry.commissionPercent > 0">
                    <b-input-group class="w-75 ml-auto">
                      <b-input
                        type="number"
                        step="1"
                        v-model="conversionTableEntry.provision"
                      />
                      <b-input-group-append>
                        <b-input-group-text>%</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <p class="mt-2">Final {{ $t('conversion-modal.provision') }}: {{ (conversionTableEntry.adjustedCartValue/100*conversionTableEntry.provision).toFixed(2) }} €</p>
                  </td>
                  <td class="text-right" v-else>
                    <b-input-group class="w-75 ml-auto">
                      <b-input
                        type="number"
                        step="1"
                        v-model="conversionTableEntry.provision"
                      />
                      <b-input-group-append>
                        <b-input-group-text>€</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import AdListPreview from '../../views/ads/adlist/components/AdListPreview.vue'
import NumberAbbreviated from "../NumberAbbreviated.vue";

// import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import EntityFilterConversions from '@/components/admin/assets/EntityFilterConversions.vue';
import Pagination from "@/components/pages/assets/Pagination.vue";
// import { mapGetters } from 'vuex';

export default {
  components: {
    NumberAbbreviated,
    EntityFilterConversions,
    Pagination,
    AdListPreview
  },
  name: "ConversionPage",
  data() {
    return {
      conversionTableEntry: {
        conversionId: "",
        adjustedCartValue: 0,
        commissionPercent: 0,
        commissionFixed: 0,
        provision: 0,
        approved: false,
        timestampView: null,
        timestampClick: null,
        location: "",
      },
      pagination: 10,
      total: 0,
      conversionTableData: [],
      conversionTableFields: [
        {
          key: 'timestamp',
          label: this.$t('general.date'),
          class: "text-left",
          sortable: true,
        },
        {
          key: 'orderId',
          label: `${this.$tc('general.conversion', 1)} ID`,
          class: "text-left",
          sortable: true
        },
        {
          key: 'approvalStatus',
          label: 'Status',
          class: 'text-center',
          sortable: true
        },
        {
          key: 'type',
          label: this.$tc('general.order', 1)+" "+this.$tc('general.type', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'bannerObj',
          label: this.$tc('general.creative', 1),
          class: "text-center",
          sortable: true
        },
        {
          key: 'location',
          label: this.$tc('general.website', 1),
          class: "text-left",
          sortable: true
        },
        {
          key: 'cartValue',
          label: this.$t('general.cart'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'cost',
          label: this.$t('general.costs'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'fee',
          label: this.$t('labels.servicefee'),
          class: "text-right",
          sortable: true
        },
        {
          key: 'timestampView',
          label: `${this.$tc('general.view',1)}`,
          class: "text-left",
          sortable: true
        },
        {
          key: 'timestampClick',
          label: `${this.$tc('general.click', 1)}`,
          class: "text-left",
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
          sortable: false,
        }
      ],
      rawLineItems: [],
      lineItems: [],
      lineItem: null,
      creatives: [],
      creative: null,
      search: "",
      isBusy: false,
      approvedIds: [],
      campaignName: "",
      filter: {
        size: 10,
        page: 1,
        sort: 'createdOn',
        direction: 'DESC',
        q: null,
        campaign: null,
        lineitemIds: null,
        creativeIds: null,
        start_date: null,
        end_date: null,
      },
    };
  },
  props: ["id"],
  created() {
    // this.dateRange.startDate.setHours(0, 0, 0, 0);
    // this.dateRange.startDate.setDate(this.dateRange.startDate.getDate() - 1);
    // this.dateRange.endDate.setHours(23, 59, 59, 999);
    this.filter.campaign = this.id;
    this.filter.start_date = new Date(this.dateRange.startDate);
    this.filter.end_date = new Date(this.dateRange.endDate);

    this.fetchCreatives();
    this.fetchLineItems();
  },
  mounted() {
    this.fetchStats();
  },
  computed: {
    dateRange: {
      get() {
          return this.$store.state.store.dateRange;
      }
    },
  },
  watch: {
  },
  methods: {
    bulkImport() {
      const elem = document.querySelector('#bulkimport');
      if (!elem || !elem.files.length) return; // TODO: error
      const file = elem.files[0];
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const content = fileReader.result;
        const values = content.split("\n").map(line => line.trim().split(","));
        const reqArr = [];
        for (const v of values.slice(1)) {
          const reqObj = {};
          for (const k in values[0]) {
            reqObj[values[0][k]] = v[k];
          }
          reqArr.push(reqObj)
        }
        this.$api.campaign.bulkSaveConversionChange(reqArr);
      }; 
      fileReader.readAsBinaryString(file.slice(0, file.size + 1));
    },
    changePage(page){
      this.filter.page = page;
      this.fetchStats();
    },
    changeQuantity(quantity){
      this.filter.size = quantity;
      this.changePage(1);
    },
    addFilter(field, value){
      /* console.log(field, value) */
      this.filter[field] = value;
    },
    presetConversionData(conversionId){
      const conversionData = this.conversionTableData.find((t) => t.conversionId == conversionId);

      this.conversionTableEntry.conversionId = conversionData.conversionId;
      this.conversionTableEntry.timestamp = conversionData.timestamp;
      this.conversionTableEntry.creative = conversionData.creative;
      this.conversionTableEntry.cartValue = conversionData.cartValue;
      this.conversionTableEntry.adjustedCartValue = conversionData.cartValue;
      this.conversionTableEntry.publisher = conversionData.publisher;

      this.conversionTableEntry.commissionPercent = conversionData.commissionPercent;
      this.conversionTableEntry.commissionFixed = conversionData.commissionFixed;

      this.conversionTableEntry.timestampView = conversionData.timestampView;
      this.conversionTableEntry.timestampClick = conversionData.timestampClick;

      this.conversionTableEntry.location = conversionData.location;

      if(this.conversionTableEntry.commissionPercent > 0)
        this.conversionTableEntry.provision = conversionData.commissionPercent;
      else
        this.conversionTableEntry.provision = conversionData.commissionFixed;

      this.conversionTableEntry.approved = false;
    },
    showApprovalModal(conversionId) {
      this.presetConversionData(conversionId);
      this.$bvModal.show("sale-approval-modal");
    },
    approveSale(conversionId) {
      this.presetConversionData(conversionId);
      this.conversionTableEntry.provision = this.conversionTableEntry.adjustedCartValue/100*this.conversionTableEntry.provision;
      this.conversionTableEntry.approved = true;
      this.$api.campaign.saveConversionChange(
        this.id,
        this.conversionTableEntry
      );
      this.approvedIds.push(this.conversionTableEntry.conversionId);
    },
    rejectSale(conversionId) {
      this.presetConversionData(conversionId);
      this.conversionTableEntry.provision = 0;
      this.conversionTableEntry.approved = false;
      this.$api.campaign.saveConversionChange(
        this.id,
        this.conversionTableEntry
      );
      this.approvedIds.push(this.conversionTableEntry.conversionId);
    },
    saveApprovalModal(approved) {

      this.conversionTableEntry.approved = approved;
      this.conversionTableEntry.adjustedCartValue = +this.conversionTableEntry.adjustedCartValue;
      this.conversionTableEntry.provision = this.conversionTableEntry.adjustedCartValue/100*this.conversionTableEntry.provision;

      this.$api.campaign.saveConversionChange(
        this.id,
        this.conversionTableEntry
      );
      this.approvedIds.push(this.conversionTableEntry.conversionId);
    },
    fetchCreatives() {
      this.$api.banner.getAll({
        campaign: [ this.id ]
      }).then((response) => {
        this.creatives = response.items.map((e) => {
          return {
            _id: e._id,
            name: e.name,
          }
        })
        // if (this.creatives.length) this.creative = this.creatives[0];
      });
    },
    fetchLineItems() {
      this.$api.lineitem.getAll().then((response) => {
        this.lineItems = response.items.filter(li => li.campaign[0]._id == this.id).map((e) => {
          return {
            _id: e._id,
            name: e.name
          }
        })
      });
    },
    close() {
      this.updateStartAndEndHours();
      // this.fetchStats();
    },
    dlStats() {
      const filter = Object.assign({}, this.filter);
      filter.size = 500;
      filter.page = 1;
      this.$api.campaign
        .getConversions(
          filter,
          {
            enableLoading: false,
          }
        )
        .then((response) => {
          if (response) {
            const conversionTableData = response.items.map((conversion) => {
              conversion.timestamp = new Date(conversion.timestamp);
              conversion.timestampClick = conversion.timestampClick
                ? new Date(conversion.timestampClick)
                : "";
              conversion.timestampView = conversion.timestampView
                ? new Date(conversion.timestampView)
                : "";
              conversion.cost = conversion.cost ? +conversion.cost : 0;
              conversion.cartValue = conversion.cartValue
                ? parseFloat(conversion.cartValue)
                : 0;
              delete conversion.currency;

              const banner = this.creatives.find(x => x._id.toString() == conversion.creative.toString());
              conversion.bannerObj = banner ? banner : { _id: conversion.creative, name: "" };

              conversion.actions = { _id: conversion.conversionId };
              conversion.approvalDate = conversion?.approvalDate ? new Date(conversion.approvalDate) : null;

              if (conversion.approvalStatus == 'approved' || conversion.approvalStatus == 'rejected') {
                this.approvedIds.push(conversion.conversionId);
              }
              return conversion;
            });
            const columns = Object.values(this.conversionTableFields.map((c) => c.key).filter((e) => e !== 'actions'));
            let csvStr = `${columns.join(',')}\r\n`;

            for (let tableData of conversionTableData) {
              const tableColumns = [];
              for (let column of columns) {
                let tmp = tableData[column];
                if (tmp instanceof Date) tmp = tmp.toISOString();
                else if (column === 'approvalStatus' && !tmp) tmp = 'not_reviewed';
                else if (column === 'bannerObj'){
                  tmp = tmp.name;
                }
                tableColumns.push(tmp);
              }
              csvStr += `"${tableColumns.join('","')}"\r\n`;
            }

            const blob = new Blob([csvStr], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const linkElem = document.createElement('a');
            linkElem.href = url;
            linkElem.download = 'conversions.csv';
            linkElem.click();
          }
        });
    },
    fetchStats() {
      this.isBusy = true;
      this.$api.campaign
        .getConversions(
          this.filter,
          {
            enableLoading: false,
          }
        )
        .then((response) => {
          if (response) {
            this.campaignName = response.campaignName;
            this.total = response.count;
            this.conversionTableData = response.items.map((conversion) => {
              conversion.timestamp = new Date(conversion.timestamp);
              conversion.timestampClick = conversion.timestampClick
                ? new Date(conversion.timestampClick)
                : "";
              conversion.timestampView = conversion.timestampView
                ? new Date(conversion.timestampView)
                : "";
              conversion.cost = conversion.cost ? +conversion.cost : 0;
              conversion.cartValue = conversion.cartValue
                ? parseFloat(conversion.cartValue)
                : 0;
              delete conversion.currency;

              const banner = this.creatives.find(x => x._id.toString() == conversion.creative.toString());
              conversion.bannerObj = banner ? banner : { _id: conversion.creative, name: "" };

              conversion.actions = { _id: conversion.conversionId };
              conversion.approvalDate = conversion?.approvalDate ? new Date(conversion.approvalDate) : null;

              if (conversion.approvalStatus == 'approved' || conversion.approvalStatus == 'rejected') {
                this.approvedIds.push(conversion.conversionId);
              }
              return conversion;
            });
            this.isBusy = false;
          }
        });
    },
    getLocaleFromCurrency(currency) {
      switch (currency) {
        case "EUR":
          return "de";
        case "USD":
          return "en";
        default:
          return "en";
      }
    },
    getFullDateFromDate(date) {
      // in format dd/mm/yyyy
      const d = new Date(date);
      return d.toString() === 'Invalid Date' ? '-' : d.formatDate();
      // return `${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`;
    },
    getFullTimeFromDate(date) {
      // in format hh:mm:ss (24h)
      const d = new Date(date);
      return d.toString() === 'Invalid Date' ? '-' : d.formatTime();
      // return `${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}:${('0' + d.getSeconds()).slice(-2)}`;
    },
  },
};
</script>

<style scoped>
.daterange-chooser {
  /* border: none; */
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>
