<template>
  <div class="stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <ApprovalSearch 
            @typeSelected="updateType"
            @bookingSelected="updateBooking"
            @campaignSelected="updateCampaign"
            @advertiserSelected="updateAdvertiser"
          />
        </div>
        <div class="row">
          <ApprovalTable
            @pageChanged="updateItems"
            :propItems="items"
            :total="total"
            :loading="loading"
            :error="error"
            :type="type"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApprovalTable from "./components/ApprovalTable";
import ApprovalSearch from "./components/ApprovalSearch";

export default {
  name: "ApprovalPage",
  components: {
    ApprovalTable,
    ApprovalSearch,
  },
  data() {
    return {
      page: 1,
      total: 0,
      items: [],
      loading: false,
      error: "",
      type: "banner",
      advertiser: null,
      booking: null,
      campaign: null,
    }
  },
  mounted() {
    this.updateItems(this.page);
    this.updateCount();
  },
  methods: {
    async updateCount() {
        try {
          this.loading = true;
          this.total = 0;
          this.total = await this.$api.approval.count({
            type: this.type,
            advertiser: this.advertiser ? this.advertiser : undefined,
            campaign: this.campaign ? this.campaign : undefined,
            booking: this.booking ? this.booking : undefined
          });
          this.loading = false;
          this.error = "";
      } catch {
        this.error = "Error while updating count";
      }
    },
    async updateItems(page) {
      try {
        this.page = page;
        if (typeof this.page !== "number") this.page = 1;
        this.loading = true;
        this.items = [];
        this.items = await this.$api.approval.list(this.page-1, 10, {
          type: this.type,
          advertiser: this.advertiser ? this.advertiser : undefined,
          campaign: this.campaign ? this.campaign : undefined,
          booking: this.booking ? this.booking : undefined
        });
        this.loading = false;
        this.error = "";
      } catch {
        this.error = "Error while updating items";
      }
    },
    updateType(type) {
      this.type = type;
      this.updateItems(this.page);
      this.updateCount();
    },
    updateCampaign(campaign) {
      this.advertiser = null;
      this.booking = null;
      this.campaign = campaign;
      this.updateItems(this.page);
      this.updateCount();
    },
    updateBooking(booking) {
      this.campaign = null;
      this.advertiser = null;
      this.booking = booking;
      this.updateItems(this.page);
      this.updateCount();
    },
    updateAdvertiser(advertiser) {
      this.campaign = null;
      this.booking = null;
      this.advertiser = advertiser;
      this.updateItems(this.page);
      this.updateCount();
    }
  }
};
</script>

<style>
</style>