<template>
  <div>
    <div
      :title="creative.name"
      v-b-tooltip.hover
      ref="dialog"
      tabindex="-1"
      role="dialog"
      aria-modal="false"
      aria-labelledby="form-confirm-label"
      class="text-center"
      style="cursor: pointer"
      @click="previewImage()"
      v-if="onlyButton || isGadsBanner"
    >
      <i class="text-primary fa fa-eye mt-2 pt-1"></i>
    </div>
    <div
      :title="creative.name"
      v-b-tooltip.hover
      ref="dialog"
      tabindex="-1"
      role="dialog"
      aria-modal="false"
      aria-labelledby="form-confirm-label"
      class="text-center"
      style="cursor: pointer"
      @click="previewImage()"
      v-else-if="onlyButton || isAdCode"
    >
      <i class="text-primary fa fa-eye mt-2 pt-1"></i>
    </div>
    <i
      v-else-if="creative.bannerType == 'template_ad'"
      class="text-primary fa fa-caret-square-o-up mt-2 pt-1"
      aria-hidden="true"
      :title="creative.name"
    >
    </i>
    <div
      v-else
      @mouseover="creative.show = true"
      @mouseleave="creative.show = false"
      :style="`width: ${getWidth()}px; height: ${getHeight()}px`"
      class="text-center"
      :title="creative.name"
      v-b-tooltip.hover
    >
      <b-overlay
        no-fade
        :show="creative.show"
        variant="transparent"
        blur="1rem"
        opacity="0.8"
        :style="`width: ${getWidth()}px; height: ${getHeight()}px`"
        class="text-center"
      >
        <template #overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center"
            style="cursor: pointer"
            @click="previewImage()"
          >
            <i class="text-primary fa fa-eye"></i>
          </div>
        </template>
        <i
          v-if="isVideoWithoutThumbnail()"
          class="icon-camrecorder"
          aria-hidden="true"
          :title="creative.name"
        >
        </i>
        <b-img
          v-else-if="hasDownloadLink()"
          blank-color="black"
          :src="getSRC()"
          :title="creative.name"
          alt=" "
          :width="getWidth()"
          :height="getHeight()"
        />
        <i
          v-else-if="creative.bannerType == 'html_ad'"
          class="text-primary fa fa-eye mt-2 pt-1"
          aria-hidden="true"
          :title="creative.name"
          @click="previewImage()"
        >
        </i>
        <iframe
          v-else
          style="pointer-events: none"
          border="0"
          class="border-0"
          scrolling="no"
          :src="`https://${adserverUrl}/banner?id=${
            creative._id
          }&desc=bookingwizard&width=${getWidth()}&height=${getHeight()}`"
          :width="getWidth()"
          :height="getHeight()"
        >
        </iframe>
      </b-overlay>
    </div>
    <b-modal :id="'gads-preview-modal' + uid" title="Google Ads" :hide-footer="true" size="lg">
      <GadsBannerModal :creative="creative._id" :descriptions="creative.descriptions" :headlines="creative.headlines" />
    </b-modal>
    <b-modal :id="'adcode-preview-modal' + uid" title="AdCode" :hide-footer="true" size="lg">
      <div class="bg-secondary py-2 px-3">
        <code>{{ creative.adCode }}</code>
      </div>
    </b-modal>
  </div>
</template>

<script>
import GadsBannerModal from "@/components/modals/GadsBannerModal";
export default {
  name: "AdListPreview",
  components: {
    GadsBannerModal,
  },
  props: {
    creative: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    size: {
      type: Number,
      required: false,
      default: 60,
    },
    onlyButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      VideoFileTypes: [
        "video/mp4",
        "video/quicktime",
        "video/mpeg",
        "video/3gp",
        "video/3gpp",
        "video/x-msvideo",
        "video/msvideo",
        "video/avi",
      ],
      uid: crypto.randomUUID(),
    };
  },
  computed: {
    adserverUrl: () => process.env.VUE_APP_ADSERVER,
    getFolder: () => process.env.VUE_APP_MEDIA_FOLDER,
    getBucket: () => process.env.VUE_APP_MEDIABUCKET,
    isGadsBanner: function () {
      return this?.creative?.bannerType == "search_ad" || (this.creative?.headlines?.length && this.creative?.descriptions?.length);
    },
    isAdCode: function () {
      return this?.creative?.adCode;
    },
  },
  methods: {
    getWidth() {
      const creativeWidth = this.creative?.thumbnail?.width
        ? this.creative.thumbnail.width
        : this.creative?.width ? this.creative.width : this.size;

      const creativeHeight = this.creative?.thumbnail?.height
        ? this.creative.thumbnail.height
        : this.creative?.height ? this.creative.height : this.size;

      return (this.size * creativeWidth) / creativeHeight > this.size
        ? this.size
        : (this.size * creativeWidth) / creativeHeight;
    },
    getHeight() {
      const creativeWidth = this.creative?.thumbnail?.width
        ? this.creative.thumbnail.width
        : this.creative?.width ? this.creative.width : this.size;

      const creativeHeight = this.creative?.thumbnail?.height
        ? this.creative.thumbnail.height
        : this.creative?.height ? this.creative.height : this.size;

      return (this.size * creativeHeight) / creativeWidth > this.size
        ? this.size
        : (this.size * creativeHeight) / creativeWidth;
    },
    hasDownloadLink() {
      if (this.creative?.downloadLink) return true;
      if (this.creative?.thumbnail?.downloadLink) return true;
      return false;
    },
    isVideoWithoutThumbnail() {
      return (
        this.creative?.video?.downloadLink &&
        !this.creative?.thumbnail?.downloadLink
      );
    },
    getSRC(click = false) {
      if (this.creative?.downloadLink?.startsWith?.("https://storage.googleapis.com")){
        return this.creative.downloadLink;
      }

      if (this.creative?.downloadLink?.startsWith?.("https://images.bannerbear.com")){
        return this.creative.downloadLink;
      }
        
      if (this.creative?.downloadLink){
        return `https://storage.googleapis.com/${this.getBucket}/${this.getFolder}/${this.creative.downloadLink}`;
      }

      if (!click && this.creative?.thumbnail && this.creative?.thumbnail?.downloadLink?.startsWith?.("https://storage.googleapis.com")){
        return this.creative.thumbnail.downloadLink;
      }

      if (!click && this.creative?.thumbnail?.downloadLink){
        return `https://storage.googleapis.com/${this.getBucket}/${this.getFolder}/${this.creative.thumbnail.downloadLink}`;
      }

      if (this.creative?.video?.downloadLink?.startsWith?.("https://storage.googleapis.com")){
        return this.creative.video.downloadLink;
      }

      if (this.creative?.video?.downloadLink){
        return `https://storage.googleapis.com/${this.getBucket}/${this.getFolder}/${this.creative.video.downloadLink}`;
      }

      let adserverLink = `https://${this.adserverUrl}/banner?id=${this.creative._id}&desc=bookingwizard`;

      if(this.creative.bannerType == 'html_ad' && this.creativePlaceholder().length > 0){
        adserverLink += `&placeholder=${encodeURIComponent(JSON.stringify(this.creativePlaceholder()))}`;
      }

      return adserverLink;
    },
    creativePlaceholder(){
      const existingPh = this.creative?.placeholder?.length ? this.creative.placeholder : [];
      return existingPh;
    },
    previewImage() {
      if (this.isGadsBanner) {
        this.$root.$emit("bv::show::modal", "gads-preview-modal" + this.uid);
      } else if(this.isAdCode){
        this.$root.$emit("bv::show::modal", "adcode-preview-modal" + this.uid);
      }
      else {
        window.open(this.getSRC(true), "_blank");
      }
    },
  },
};
</script>
