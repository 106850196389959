<template>
    <form @submit.prevent="createFolder">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="folder-name">{{ $tc('general.folder', 1 )}} Name</label>
                    <input v-model="folderName" name="foldername" id="folder-name" class="form-control" placeholder="Example">
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <button class="btn btn-primary">{{ targetFolder ? $t('actions.updatex', { name: $tc('general.folder', 1)}) : $t('actions.createx', { name: $tc('general.folder', 1)}) }}</button>
            </div>
        </div>
    </form>
</template>
<script>
export default {
    name: 'CreateFolderModal',
    data() {
        return {
            folderName: ""
        }
    },
    props: {
        parentFolder: {
            type: String,
            required: false,
            default: null
        },
        targetFolder: {
            required: false,
            default: null
        }
    },
    created() {
        if (this.targetFolder) {
            this.folderName = this.targetFolder.name;
        }
    },
    methods: {
        async createFolder() {
            console.log(this.folderName, this.parentFolder);
            if (this.targetFolder) {
                const folder = await this.$api.graphic.updateFolder(this.targetFolder._id, this.folderName, this.parentFolder, { enableLoading: false });
                this.$emit('updated', folder);
            } else {
                const folder = await this.$api.graphic.createFolder(this.folderName, this.parentFolder, { enableLoading: false });
                this.$emit('created', folder);
            }
        }
    }
}
</script>