<template>
    <div class="row bg-light pt-2">
        <div class="col-4 form-group">
            <label for="searchfield_campaign" class="mb-1">{{ $tc('general.campaign', 2) }}</label>
            <SearchEntity entity="campaign" :value="campaign" multiple @selected="setSearchInput"/>
        </div>
    </div>
</template>
  
<script>
  import SearchEntity from "./SearchEntity";

  export default {
    name: "CampaignOnly",
    components: {
        SearchEntity,
    },
    props: {
        filter: {
            type: Object,
            required: false
        }
    },
    created(){
        if(this.filter){
            if(this.filter.campaign)        this.campaign = this.filter.campaign;
        }
    },
    data() {
      return {
        campaign: null
      };
    },
    methods: {
        setSearchInput(data){
            this[data.entity] = data.value;
            this.reportInput(data.entity);
        },
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
    },
  };
</script>
