<template>
  <div :class="'col-md-'+size" class="stretch-card dba-box">
    <div class="card">
      <div class="card-body smallpadding">
        <h4 v-if="title.length > 0" class="card-title" v-html="title"></h4>
        <div class="table-responsive">
          <b-table striped hover :items="tableData" :fields="tableFields">
            <template #cell(impressions)="data">
              <span v-if="data.value == '' && data.value !== 0">-</span>
              <span v-else v-b-tooltip.hover :title="$n(data.value)">
                <NumberAbbreviated :value="data.value" />
              </span>
            </template>
            <template #cell(clicks)="data">
              <span v-if="data.value == '' && data.value !== 0">-</span>
              <span v-else v-b-tooltip.hover :title="$n(data.value)">
                <NumberAbbreviated :value="data.value" />
              </span>
            </template>
            <template #cell(conversions)="data">
              <span v-if="data.item.pvConversions+data.item.pcConversions == '0'">0</span>
              <span v-else :title="`PC: ${$n(data.item.pcConversions)} | PV: ${$n(data.item.pvConversions)}`" v-b-tooltip.hover
                >{{ data.item.pvConversions+data.item.pcConversions }}
              </span>
            </template>
            <template
              #cell(ctr_calc)="data"
            >{{ getCTR(data.item.impressions, data.item.clicks, 2) }} %
            </template>

            <template #cell(name)="data">
              <span
                v-if="data.item.status === 'active' || data.item.status === 'inactive'"
                :class="data.item.status === 'active' ? 'text-success' : 'text-warning'"
                :title="`${new Date(data.item.start).formatDate()} - ${new Date(data.item.end).formatDate()}`">
                &bull; </span>
              <span v-if="data.item.isapp === true">
                <i class="fa fa-mobile" aria-hidden="true"></i>
                {{ ' ' }}
              </span>
              <router-link v-if="data.item.link" :to="`${data.item.link}`" target="_blank">{{data.value}}</router-link>
              <span v-else>
                {{data.value}}
              </span>
            </template>
          </b-table>
        </div>
        <a
          @click.stop.prevent="footerLinkClicked"
          class="text-center d-block border-top pt-2"
          href="#"
        >{{footerText}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import NumberAbbreviated from "@/components/NumberAbbreviated";

export default {
  name: "DashboardAnalyticsBox",
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    footerText: {
      type: String,
      default: null,
      required: false
    },
    tableData: {
      type: Array,
      required: true
    },
    tableFields: {
      type: Array,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 4
    }
  },
  data() {
    return {};
  },
  methods: {
    footerLinkClicked() {
      this.$emit("footerAction");
    },
    getCTR(views, clicks, precision) {
      let ctr = 0;
      if (views > 0) {
        ctr = Number(((clicks * 100) / views).toFixed(precision));
      }

      return ctr;
    },
  },
  computed: {
    maxNameLength() {
      return 50 * this.size;
    }
  },
  components: {
    NumberAbbreviated
  }
};
</script>

<style>
.dba-box .table td,
.dba-box .table th {
  padding: 16px 6px;
}
.dba-box .card-body.smallpadding {
  padding: 20px 15px 10px !important;
}

.dba-box a.border-top {
  border-color: #d5dcec;
}

.dba-box .card-title {
  text-transform: none;
}

.dba-box .table-responsive {
  margin-bottom: 0px;
}
.dba-box td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>