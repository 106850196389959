import { LoginRole } from '../constants/roles';

const availableRoles = [
    LoginRole.DEALER,
    LoginRole.CAMPAIGNMANAGER,
    LoginRole.ACCOUNTING,
    LoginRole.VIEWER,
    LoginRole.PUBLISHER,
    LoginRole.ADVERTISER, //client
    LoginRole.CO_WORKER,
    LoginRole.AGENCY,
    LoginRole.ADMIN,
    LoginRole.AFFILIATEMANAGER,
    LoginRole.SYSOP,
  ];

function specialPermission(requiredPermission, userRole) {
    if (availableRoles.includes(userRole)) return true;
    return requiredPermission.includes(userRole);
}

export function canActivate(requiredPermission) {
    const userRole = localStorage.getItem(process.env.VUE_APP_ROLE);

    if(!userRole) return false;

    if (!requiredPermission.some((value) => availableRoles.indexOf(value) >= 0))
    return specialPermission(requiredPermission, userRole);

    let permissionLevel = 0;

    let userLevel = 0;
    let requiredLevel = 0;

    Object.values(availableRoles).forEach((role) => {
      if (role === userRole) userLevel = permissionLevel;
      if (requiredPermission.includes(role)) requiredLevel = permissionLevel;
      permissionLevel++;
    });
    return userLevel >= requiredLevel;
}
