<template>
  <form class="forms-sample">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="mctc_salesShop">Sales Shop</label>
              <input id="mctc_salesShop" class="form-control" v-model="salesShop" required />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="mctc_salesSuite">Sales AdSuite</label>
              <input id="mctc_salesSuite" class="form-control" v-model="salesSuite" required />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="mctc_start">Start</label>
              <input id="mctc_start" class="form-control" v-model="start" required />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="mctc_end">End</label>
              <input id="mctc_end" class="form-control" v-model="end" required />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="mctc_mct">Current MCT</label>
              <input id="mctc_mct" class="form-control" v-model="mct" required />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="mctc_step">Calculation steps</label>
              <v-select
                  id="mctc_step"
                  v-model="step"
                  label="name"
                  class="form-control"
                  :filterable="true"
                  :reduce="(entities) => entities.value"
                  :options="steps"
                  :multiple="false"
                  :close-on-select="true"
              >
              </v-select>
            </div>
          </div>
          <div class="col-12">
            <b-button
              class="btn btn-primary btn-block"
              :disabled="loading"
              @click="submit"
            >
              Calculate
            </b-button>
          </div>
          <div class="col-12 mt-3">
            <div v-if="loading" class="flip-square-loader"></div>
            <b-alert v-if="showResult" variant="success" show dismissible @dismissed="showResult = false">
              <p>Set MCT to <strong>{{ calculatedMct }}</strong> to reach <strong>{{ calculatedSales }}</strong> sales.</p>
            </b-alert>
            <div class="row" v-if="showResult">
              <div class="col-6">
                <b-button @click="setAndRedo" class="btn btn-primary btn-block">Set and try again</b-button>
              </div>
              <div class="col-6">
                <b-button :disabled="updating" @click="updateMct" class="btn btn-primary btn-block">Save <strong>{{ calculatedMct }}</strong> in Optimizer <i v-if="updating" class="fa fa-spinner fa-spin" /><i v-if="updated" class="fa fa-check text-success" /></b-button>
              </div>
            </div>
            <b-alert v-if="showError" variant="danger" show dismissible @dismissed="showError = false">
              <p>An error occurred while calculating the MCT.</p>
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: "MctCalculatorModal",
  props: ["data"],
  data() {
    return {
      campaignId: null,
      optimizerId: null,
      salesSuite: null,
      salesShop: null,
      mct: null,
      start: null,
      end: null,
      loading: false,
      showResult: false,
      showError: false,
      updating: false,
      updated: false,
      step: 5,
      steps: [{
        name: "10 - extreme fine",
        value: 10
      },
      {
        name: "9",
        value: 9
      },
      {
        name: "8",
        value: 8
      },
      {
        name: "7",
        value: 7
      },
      {
        name: "6",
        value: 6
      },
      {
        name: "5 - medium",
        value: 5
      },
      {
        name: "4",
        value: 4
      },
      {
        name: "3",
        value: 3
      },
      {
        name: "2",
        value: 2
      },
      {
        name: "1 - not fine",
        value: 1
      }
    ],
    };
  },
  computed: {
  },
  created(){
    this.campaignId = this.data.campaignId ?? null;
    this.optimizerId = this.data.optimizerId ?? null;
    this.salesSuite = this.data.salesSuite ?? null;
    this.salesShop = this.data.salesShop ?? null;
    this.mct = this.data.mct ?? null;
    this.start = this.data.start ?? null;
    this.end = this.data.end ?? null;
  },
  methods: {
    async submit() {
      this.showError = false;
      this.showResult = false;
      this.loading = true;
      this.updating = false;
      this.updated = false;

      const mctResult = await this.$api.optimizer.calculateMct({
        campaignId: this.campaignId,
        salesSuite: this.salesSuite,
        salesShop: this.salesShop,
        mct: this.mct,
        start: this.start,
        end: this.end,
        step: this.step,
      });

      if(mctResult.mct && mctResult.sales){
        this.calculatedMct = mctResult.mct;
        this.calculatedSales = mctResult.sales;
        this.showResult = true;
      }
      else{
        this.showError = true;
      }
      this.loading = false;
    },
    async updateMct(){
      this.updating = true;
      this.updated = false;
      await this.$api.optimizer.updateMct(this.optimizerId, this.calculatedMct);
      this.updating = false;
      this.updated = true;
    },
    setAndRedo(){
      this.mct = this.calculatedMct;
      this.salesSuite = this.calculatedSales;
      this.showResult = false;
      this.submit();
    },
  },
};
</script>