<template>
  <b-modal :id="id" :hide-footer="true" size="md" :title="titleText" @hidden="cancelAction()">
    <form class="forms-sample">
      <div v-if="loading" class="flip-square-loader"></div>
      <div class="row" v-else>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" v-html="bodyText">
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button :variant="cancelButtonVariant" @click="cancelAction()">
                {{cancelButtonText}}
            </b-button>
            <b-button class="ml-2" :variant="finishButtonVariant" @click="finishAction()">
                {{finishButtonText}}
            </b-button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ConfirmModal",
  props: {
    id: {
      type: String,
      default: 'confirmModal'
    },
    titleText: {
      type: String,
      required: true
    },
    finishButtonText: {
      type: String,
      default: 'Confirm'
    },
    finishButtonVariant: {
      type: String,
      default: 'primary'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    cancelButtonVariant: {
      type: String,
      default: 'danger'
    },
    bodyText: {
      type: String,
      required: true
    },
    finishFunction: {
      type: Function,
      default: null
    },
    finishEvent: {
      type: String,
      default: 'confirmAction'
    },
    cancelFunction: {
      type: Function,
      default: null
    },
    cancelEvent: {
      type: String,
      default: 'cancelAction'
    }
  },
  computed: {
    ...mapGetters([
      "error",
      "loading"
    ]),
  },
  methods: {
    async finishAction() {
      //INFO check for function that needs to be executed OnFinish
      if(this.finishFunction){
        //INFO Execute function and check if it returns something
        let returnable = await this.finishFunction();
        if(returnable) this.$emit(this.finishEvent, returnable);
        else this.$emit(this.finishEvent);
      } else {
        this.$emit(this.finishEvent);
      }
      this.hide();
    },
    async cancelAction() {
      //INFO check for function that needs to be executed OnCancel
      if(this.cancelFunction){
        //INFO Execute function and check if it returns something
        let returnable = await this.cancelFunction();
        if(returnable) this.$emit(this.cancelEvent, returnable);
        else this.$emit(this.cancelEvent);
      } else {
        this.$emit(this.cancelEvent);
      }
      this.hide();
    },
    hide(){
      this.$bvModal.hide(this.id);
    }
  },
};
</script>
