const industries = [
  { value: 	100	, text: 'Administration/Verwaltung' },
  { value: 	200	, text: 'Luft- und Raumfahrt' },
  { value: 	300	, text: 'Alkoholische Getränke' },
  { value: 	400	, text: 'Kunst, Kultur & Unterhaltung' },
  { value: 	500	, text: 'Automobilindustrie' },
  { value: 	600	, text: 'Banken' },
  { value: 	700	, text: 'Bars & Restaurants, Gastronomie' },
  { value: 	800	, text: 'Beauty-, Kosmetika- und Frisörgewerbe' },
  { value: 	900	, text: 'Biotechnologie & Chemietechnik' },
  { value: 	1000	, text: 'Dienstleistung' },
  { value: 	1100	, text: 'Casinos & Glückspielindustrie' },
  { value: 	1200	, text: 'Vereine' },
  { value: 	1300	, text: 'Software' },
  { value: 	1400	, text: 'Baugewerbe, Bauwirtschaft & Architektur' },
  { value: 	1500	, text: 'Beratung / Consulting' },
  { value: 	1600	, text: 'Handwerk' },
  { value: 	1700	, text: 'Kreuzfahrtschiffe und -Linien' },
  { value: 	1800	, text: 'Zahnärzte' },
  { value: 	1900	, text: 'Ärzte und anderes Gesundheitspersonal' },
  { value: 	2000	, text: 'Bildung, Erziehung & Unterricht' },
  { value: 	2100	, text: 'Elektro / Elektronik' },
  { value: 	2200	, text: 'Energieversorgung & Energiewirtschaft' },
  { value: 	2300	, text: 'Umwelt' },
  { value: 	2400	, text: 'Land- und Forstwirtschaft, Fischerei' },
  { value: 	2500	, text: 'Finanzen' },
  { value: 	2600	, text: 'Lebensmittelindustrie' },
  { value: 	2700	, text: 'Lebensmittelgeschäfte' },
  { value: 	2800	, text: 'Bestattungsunternehmen' },
  { value: 	2900	, text: 'Abfallwirtschaft' },
  { value: 	3000	, text: 'Gesundheit / Soziales / Pflege' },
  { value: 	3100	, text: 'Hotel- und Gastgewerbe' },
  { value: 	3200	, text: 'Personalwesen & -beschaffung' },
  { value: 	3300	, text: 'Industrie' },
  { value: 	3400	, text: 'Versicherung' },
  { value: 	3500	, text: 'Internet / Informationstechnik' },
  { value: 	3600	, text: 'Recht' },
  { value: 	3700	, text: 'Marktforschung' },
  { value: 	3800	, text: 'Marketing / Werbung / PR' },
  { value: 	3900	, text: 'Maschinenbau' },
  { value: 	4000	, text: 'Medien' },
  { value: 	4100	, text: 'Medizintechnik' },
  { value: 	4200	, text: 'Medizin/Pharma' },
  { value: 	4300	, text: 'Montanindustrie & Bergbau' },
  { value: 	4400	, text: 'Sonstige Branchen' },
  { value: 	4500	, text: 'Pharmabranche' },
  { value: 	4600	, text: 'Druck / Verpackung' },
  { value: 	4700	, text: 'Öffentliche Verwaltung' },
  { value: 	4800	, text: 'Immobilien-, Grundstücks- und Wohnungswesen' },
  { value: 	4900	, text: 'Forschung / Entwicklung' },
  { value: 	5000	, text: 'Handel' },
  { value: 	5100	, text: 'Seminare / Messen' },
  { value: 	5200	, text: 'Schiffbau und Meerestechnik' },
  { value: 	5300	, text: 'Sport' },
  { value: 	5400	, text: 'Steuerberatung / Wirtschaftsprüfung' },
  { value: 	5500	, text: 'Telekommunikation' },
  { value: 	5600	, text: 'Textil- und Bekleidungsbranche' },
  { value: 	5700	, text: 'Tourismus' },
  { value: 	5800	, text: 'Spielzeugbranche' },
  { value: 	5900	, text: 'Transport / Verkehr / Logistik' },
  { value: 	6000	, text: 'Reise und Erholung' },
  { value: 	6100	, text: 'Gewerkschaften' },
]

module.exports = [ { value: null, text: "Bitte wählen Sie eine Industrie", disabled: true} ].concat(industries.sort((a, b) => a.text.localeCompare(b.text, "de-DE")));
