export const LoginRole = {
    PUBLISHER: 'publisher',
    VIEWER: 'viewer',
    CO_WORKER: 'co_worker',
    ADVERTISER: 'advertiser',
    CAMPAIGNMANAGER: 'campaignmanager',
    WIZARD: 'wizard',
    ANALYST: 'analyst',
    ADMIN: 'administrator',
    SYSOP: 'systemoperator',
    BILLING: 'billing',
    REGISTERED: 'registered',
    DEALER: 'dealer',
    AFFILIATEMANAGER: 'affiliatemanager',
    ACCOUNTING: 'accounting',
    AGENCY: 'agency',
}