<template>
  <b-modal id="coupon-history-modal" :title="localCoupon.name" ok-only @hidden="$emit('closeCouponHistory')">
    <b-list-group v-if="advertiserHistory.length && loaded">
      <b-list-group-item v-for="entry in advertiserHistory" :key="entry.date">
        <div class="row">
          <div class="col">
            <small>{{new Date(entry.date).toLocaleString()}}</small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <router-link :to="'/advertiser/edit/' + entry.advertiser"><span>{{entry.name}}</span></router-link>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <h4 v-else-if="loaded" class="text-center">No History</h4>
    <b-list-group v-else>
      <b-list-group-item><b-skeleton></b-skeleton></b-list-group-item>
      <b-list-group-item><b-skeleton></b-skeleton></b-list-group-item>
      <b-list-group-item><b-skeleton></b-skeleton></b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
export default {
  name: "CouponHistoryModal",
  props: ["coupon"],
  data() {
    return {
      localCoupon: {},
      loaded: false,
      advertiserHistory: []
    }
  },
  watch: {
    coupon: function () {
      if (!this.coupon) return; 
      this.loaded = false;
      this.localCoupon = this.coupon;
      this.$bvModal.show("coupon-history-modal");
      this.loadAdvertisers();
    }
  },
  methods: {
    async loadAdvertisers() {
      if (this.localCoupon?.used?.length) {
        this.advertiserHistory = await this.$api.coupon.advertisers(this.localCoupon._id, {enableLoading: false});
      }
      this.loaded = true;
    }
  }
}
</script>

<style>

</style>