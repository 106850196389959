<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <nav class="navbar horizontal-layout col-lg-12 col-12 p-0">
      <div class="nav-top flex-grow-1">
        <div class="container-fluid d-flex flex-row h-100">
          <div class="text-center navbar-brand-wrapper d-flex align-items-top">
            <router-link class="navbar-brand brand-logo" to="/">
              <img :src="headerlogo" id="logo" alt="logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img :src="headerlogo" alt="logo" />
            </router-link>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item dropdown mr-4" style="cursor: pointer;">
                <a class="nav-link dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                  <i class="flag-icon" :class="selectedLanguage === 'en' ? 'flag-icon-gb' : 'flag-icon-de'"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list p-0" style="top: calc(45px - 6px);" aria-labelledby="notificationDropdown">
                  <a class="dropdown-item preview-item" @click="changeLanguage('en')">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-light" :class="selectedLanguage === 'en' ? 'bg-background' : ''">
                        <i class="flag-icon flag-icon-gb mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">English</h6>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" @click="changeLanguage('de')">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-light" :class="selectedLanguage == 'de' ? 'bg-background' : ''">
                        <i class="flag-icon flag-icon-de mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">German</h6>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            
            <p class="m-0">{{ $t("messages.noaccount") }}</p>
            <router-link class="btn get-started-btn text-uppercase" to="/register">{{ $t("actions.register") }}</router-link>
          </div>
        </div>
      </div>
    </nav>
    <div class="content-wrapper auth p-0 theme-two">
      <div class="row d-flex align-items-stretch">
        <div
          class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center"
        >
          <div class="slide-content bg-1" :style="'background-image: url('+authimage+')'"></div>
        </div>
        <div class="col-12 col-md-8 h-100 bg-white">
          <div
            class="auto-form-wrapper d-flex align-items-center justify-content-center flex-column"
          >
            <form @submit.prevent="setNewPassword">
              <h3 class="mr-auto">{{ $t("headers.resetpw") }}</h3>
              <p class="mb-5 mr-auto">{{ $t("messages.enternewpassword") }}</p>

              <div class="alert alert-danger" v-if="error">{{error}}</div>
              <div class="alert alert-warning" v-if="warning">{{warning}}</div>
              <div class="alert alert-success" v-if="success">{{success}}</div>

              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    :placeholder="$t('forms.newpassword')"
                    autocomplete="new-password"
                    required
                    minlength="8"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="icon-lock"></i>
                    </span>
                  </div>
                  <input
                    v-model="password2"
                    type="password"
                    class="form-control"
                    :placeholder="$t('forms.passwordconfirm')"
                    autocomplete="new-password"
                    required
                    minlength="8"
                  />
                </div>
              </div>
              <div v-if="!loading" class="form-group">
                <button class="btn btn-success btn-block" type="submit">{{ $t("actions.save") }}</button>
              </div>
              <div v-if="loading" class="flip-square-loader"></div>
              <div class="wrapper mt-5 text-gray">
                <p
                  class="footer-text"
                >Copyright © {{currentYear}} {{companyName}}. All rights reserved.</p>
                <!-- <ul class="auth-footer text-gray">
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Cookie Policy</a>
                  </li>
                </ul>-->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    this.selectedLanguage = localStorage.getItem(process.env.VUE_APP_LANGUAGE) || process.env.VUE_APP_LANGUAGE_DEFAULT;
  },
  name: "Resetpassword",
  data() {
    return {
      password: "",
      password2: "",
    };
  },
  props: ["token"],
  computed: {
    ...mapGetters(["loading", "error", "success", "warning", "registered"]),
    currentYear: () => new Date().getFullYear(),
    companyName: () => process.env.VUE_APP_COMPANY_NAME,
    authimage: () => "/wl/" + process.env.VUE_APP_NAME + "/bg_auth.jpg",
    headerlogo: () => '/wl/'+process.env.VUE_APP_NAME+'/logo.png',
  },
  methods: {
    changeLanguage(ln) {
      this.selectedLanguage = ln
      localStorage.setItem(process.env.VUE_APP_LANGUAGE, ln)
      this.$root.$i18n.locale = ln;
    },
    async setNewPassword() {
        this.$store.dispatch("setError", null);
        this.$store.dispatch("setSuccess", null);

      if (this.password != this.password2 || this.password.length < 8) {
        this.$store.dispatch(
          "setError",
          this.$t("messages.passwordnotmatch")
        );
      } else {
        const payload = {
          token: this.token,
          password: this.password
        };
        const result = await this.$store.dispatch("newpassword", payload);

      /* console.log(result) */

        if (result) {        
          this.$router.push("/login");
          this.$store.dispatch(
          "setSuccess",
          this.$t("messages.passwordchanged")
        );
        
        }
      }
    }
  }
};
</script>

<style scoped>
  #logo {
      height: 43px;
      margin-top: 5px;
  }
  .bg-background {
    border-color: #00b297;
    border-style: solid;
    border-width: 2px;
  }

  .get-started-btn{
    border: 1px solid #fff;
      padding: 10px 20px;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      margin-left: 20px;
      border-radius: 50px;
  }
.auth.theme-two .banner-section .slide-content.bg-1 {
  background-repeat: no-repeat;
  background-position: center center;
}
</style>