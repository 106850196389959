<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <nav
      class="navbar horizontal-layout col-lg-12 col-12 p-0"
      style="position: relative"
    >
      <div class="nav-top flex-grow-1">
        <div class="container-fluid d-flex flex-row h-100">
          <div class="text-center navbar-brand-wrapper d-flex align-items-top">
            <router-link class="navbar-brand brand-logo" to="/">
              <img :src="headerlogo" id="logo" alt="logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img :src="headerlogo" alt="logo" />
            </router-link>
          </div>
          <div
            class="
              navbar-menu-wrapper
              d-flex
              align-items-center
              justify-content-end
            "
          >
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item dropdown mr-4" style="cursor: pointer">
                <a
                  class="nav-link dropdown-toggle"
                  id="notificationDropdown"
                  href="#"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    class="flag-icon"
                    :class="
                      selectedLanguage === 'en'
                        ? 'flag-icon-gb'
                        : 'flag-icon-de'
                    "
                  ></i>
                </a>
                <div
                  class="
                    dropdown-menu dropdown-menu-right
                    navbar-dropdown
                    preview-list
                    p-0
                  "
                  style="top: calc(45px - 6px)"
                  aria-labelledby="notificationDropdown"
                >
                  <a
                    class="dropdown-item preview-item"
                    @click="changeLanguage('en')"
                  >
                    <div class="preview-thumbnail">
                      <div
                        class="preview-icon bg-light"
                        :class="
                          selectedLanguage === 'en' ? 'bg-background' : ''
                        "
                      >
                        <i class="flag-icon flag-icon-gb mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6
                        class="
                          preview-subject
                          font-weight-normal
                          text-dark
                          mb-1
                        "
                      >
                        English
                      </h6>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item preview-item"
                    @click="changeLanguage('de')"
                  >
                    <div class="preview-thumbnail">
                      <div
                        class="preview-icon bg-light"
                        :class="selectedLanguage == 'de' ? 'bg-background' : ''"
                      >
                        <i class="flag-icon flag-icon-de mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6
                        class="
                          preview-subject
                          font-weight-normal
                          text-dark
                          mb-1
                        "
                      >
                        German
                      </h6>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            <router-link
              v-if="jwt"
              class="btn get-started-btn text-uppercase"
              to="/"
              >{{ $t("actions.backtomemberarea") }}
            </router-link>
            <div v-else>
              <router-link
                class="btn get-started-btn text-uppercase"
                to="/login"
                >{{ $t("actions.signin") }}
              </router-link>
              <router-link
                class="btn get-started-btn text-uppercase"
                to="/register"
                >{{ $t("actions.register") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- <div class="content-wrapper">  -->
    <div
      class="row"
      style="height: calc(100vh - 70px); overflow-y: auto"
      ref="containerScroll"
    >
      <div
        class="
          col-md-4
          banner-section
          d-none d-md-flex
          align-items-stretch
          justify-content-center
          px-0
        "
      >
        <div
          class="slide-content bg-2"
          :style="'background-image: url(' + authimage + ')'"
        ></div>
      </div>
      <div class="col-md-8 bg-white pt-5" style="overflow: auto">
        <div class="row">
          <div class="col-10 offset-1">
            <h3>{{ $t("faq.title") }}</h3>
            <ul
              class="nav nav-tabs tab-solid tab-solid-primary mt-5"
              role="tablist"
            >
              <li
                v-for="(tab, index) in faq"
                :key="index"
                class="nav-item mb-2"
              >
                <a
                  class="nav-link show"
                  :class="index == 0 ? 'active' : ''"
                  :id="`tab-${index}`"
                  data-toggle="tab"
                  :href="`#content-${index}`"
                  role="tab"
                  :aria-controls="`content-${index}`"
                  aria-selected="true"
                  ><i :class="tab.icon"></i>{{ tab.title }}</a
                >
              </li>
            </ul>
            <div class="tab-content tab-content-basic">
              <div
                v-for="(tab, index) in faq"
                :key="index"
                class="tab-pane fade"
                :class="index == 0 ? 'active show' : ''"
                :id="`content-${index}`"
                role="tabpanel"
                :aria-labelledby="`tab-${index}`"
              >
                <div
                  class="accordion"
                  :id="`accordion-${index}`"
                  role="tablist"
                >
                  <div
                    v-for="(content, cidx) in tab.contents"
                    :key="cidx"
                    class="card"
                  >
                    <div
                      class="card-header"
                      role="tab"
                      :id="`heading-${index}-${cidx}`"
                    >
                      <h6 class="mb-0">
                        <a
                          data-toggle="collapse"
                          :href="`#content-${index}-${cidx}`"
                          aria-expanded="false"
                          :aria-controls="`content-${index}-${cidx}`"
                          class=""
                        >
                          {{ content.title }}
                        </a>
                      </h6>
                    </div>
                    <div
                      :id="`content-${index}-${cidx}`"
                      class="collapse"
                      role="tabpanel"
                      :aria-labelledby="`heading-${index}-${cidx}`"
                      :data-parent="`#accordion-${index}`"
                      style=""
                    >
                      <div class="card-body" v-html="content.text"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<div class="card border-0">
  <div class="card-body">
    <h5 class="card-title" style="text-transform: none;">{{ $t("faq.noanswer") }}</h5>
    <p class="card-text" v-html="$t('faq.noanswertext', {email: 'support@42ads.io', phone: '004915164964237', phone1: '+49 151 / 649 642 37', 'url': '/contact'})"></p>
  </div>
</div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</template>

<script>
export default {
  name: "FAQ",
  created() {
    this.selectedLanguage = this.$root.$i18n.locale;
  },
  data() {
    let locale = "en";
    if (this.$i18n.locale) locale = this.$i18n.locale;

    return {
      selectedLanguage: "en",
      faq: require(`@/locales/faq.${locale}.js`),
    };
  },
  computed: {
    jwt: () => localStorage.getItem(process.env.VUE_APP_JWT),
    authimage: () => "/wl/" + process.env.VUE_APP_NAME + "/bg_auth.jpg",
    headerlogo: () => "/wl/" + process.env.VUE_APP_NAME + "/logo.png",
    getLanguage: () =>
      localStorage.getItem(process.env.VUE_APP_LANGUAGE)
        ? localStorage.getItem(process.env.VUE_APP_LANGUAGE)
        : process.env.VUE_APP_LANGUAGE_DEFAULT,
  },
  methods: {
    changeLanguage(ln) {
      this.selectedLanguage = ln;
      localStorage.setItem(process.env.VUE_APP_LANGUAGE, ln);
      this.$root.$i18n.locale = ln;
      this.faq = require(`@/locales/faq.${ln}.js`);
    },
  },
};
</script>

<style scoped>
#logo {
  height: 43px;
  margin-top: 5px;
}
.banner-section .slide-content.bg-2 {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.get-started-btn {
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin-left: 20px;
  border-radius: 50px;
}

.bg-background {
  border-color: #00b297;
  border-style: solid;
  border-width: 2px;
}
</style>
