<template>
  <div class="col">
    <div class="row">
      <div class="col">
        <status :tree="tree"/>
      </div>
    </div>
    <div class="row mt-2">
      <messages :messages="dateMessages"/>
    </div>
    <div class="row">
      <dependencies @setActive="setActive" :next="tree.next"/>
    </div>
  </div>
</template>

<script>
import Dependencies from './Dependencies';
import Messages from './Messages';
import Status from './Status';

export default {
  name: "TreeEntry",
  components: {
    Messages,
    Status,
    Dependencies
  },
  methods: {
    setActive(tree, forward) {
      this.$emit('setActive', tree, forward);
    }
  },
  computed: {
    dateMessages() {
      if (!this?.tree?.messages?.length) return [];
      return this.tree.messages.map((message) => {
        message.date = new Date(message.date).toLocaleString();
        return message;
      })
    }
  },
  props: ["tree"]
};
</script>

<style>
</style>