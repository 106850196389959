<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">

          <b-dropdown
            variant="outline-white"
            class="pull-right ml-1"
            no-caret
            offset="-100"
            v-if="isSysOp"
          >
              <template #button-content>
                <i class="fa fa-bars"></i>
              </template>
            <b-dropdown-item @click.native.capture.stop>
              <b-form-checkbox v-model="localArchive" name="check-button">{{ $t("labels.show-deleted") }}</b-form-checkbox>
            </b-dropdown-item>
          </b-dropdown>

          <button class="btn btn-success pull-right" @click="gotoNewEntity" v-if="!isAccounting">
            <i class="fa fa-plus"></i>{{ $t("actions.createx", { name: $tc("general.booking", 1) }) }}
          </button>
          <h4 class="card-title">{{ $tc("general.booking", 2) }}</h4>
          <br />

          <div class="alert alert-danger" v-if="error">{{ error }}</div>
          <div class="alert alert-warning" v-if="warning">{{ warning }}</div>
          <div class="alert alert-success" v-if="success">{{ success }}</div>

          <div v-if="firstLoading" class="flip-square-loader"></div>

          <div v-else>
            <div class="row bg-light pt-3">
              <div class="col-4 form-group" v-if="isSysOp">
                <label for="advertiser" class="mb-1">{{ $tc("general.advertiser", 1)}}</label>
                <v-select
                  id="accounting_advertisers"
                  v-model="filter.advertiser"
                  label="name"
                  class="form-control"
                  :filterable="true"
                  :reduce="(advertiser) => advertiser._id"
                  :options="allAdvertisers"
                >
                  <template slot="no-options">
                  {{ $t("messages.typetosearch", {name: $tc("general.advertiser", 2)}) }}
                  </template>
                </v-select>
              </div>
              <!-- <div class="col-4 form-group">
                <label for="accounting_status" class="mb-1">{{ $t("general.status")}}</label>
                <v-select
                  id="accounting_status"
                  multiple
                  :close-on-select="false"
                  v-model="filter.status"
                  label="name"
                  class="form-control"
                  :filterable="false"
                  :reduce="(status) => status.key"
                  :selectable="option => !filter.status.includes(option.key)"
                  :options="getStatus()"
                >
                </v-select>
              </div> -->
              </div>
              <div class="row bg-light pt-3">
                <div class="col-12 form-group">
                  <label for="accounting_search" class="mb-1">{{ $t("general.searchfor")}}</label>
                  <input
                    id="accounting_search"
                    class="form-control"
                    v-model="filter.search"
                    @keydown.enter="fetchData"
                  />
                </div>
            </div>
            <div class="row bg-light pb-3">
              <div class="col text-right">
                  <b-button variant="success" :disabled="settings.isBusy" @click="loadFirstPage">Search</b-button>
              </div>
            </div>
          </div>

          <div class="mt-4" v-if="!itemCount && !firstLoading">
            <div v-if="settings.isBusy" class="flip-square-loader"></div>
            <em v-else>{{ $t("messages.noitems", {name: $tc("general.booking", 1) }) }}</em>
          </div>
          <div v-else-if="!firstLoading">
            <Pagination class="my-4" :itemCount="itemCount" :currentPage="settings.page" :perPage="filter.qty" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
            <div class="table-responsive">
              <b-table id="itemtable" hover :items="tableData" :fields="dataTableFields" @sort-changed="sortData" :busy.sync="settings.isBusy" :no-local-sorting="true">
                <template #cell(name)="data">
                  <span v-if="data.item.name">
                  {{ data.value}}
                  </span>
                  <span v-else>N/A</span>
                </template>
                <template #cell(channels)="data">
                  <CampaignChannels :campaign="data.item.lookups.campaign[0]" v-if="showCampaignChannels(data.item)" />
                  <div v-else-if="data.item.channels.length">
                    <b-img
                      v-for="(channel, index) in data.item.channels"
                      v-b-tooltip.hover
                      :key="index"
                      :src="channel.image"
                      :alt="channel.name"
                      :title="`${channel.name}`"
                      style="width: 20px; height: 20px"
                      class="mr-1"
                      :class="channel.lineitemStatus ? '' : 'channel-inactive'"
                    ></b-img>
                  </div>
                  <span v-else>N/A</span>
                </template>
                <template #cell(advertiser)="data">
                  <span v-if="data.item.lookups.advertiser[0]">
                    {{ data.item.lookups.advertiser[0].name }} <a :href="`/advertiser/edit/${data.item.lookups.advertiser[0]._id}`"><i class="text-primary fa fa-edit"></i></a>
                  </span>
                  <span v-else>N/A</span>
                </template>
                <template #cell(budget)="data">
                  <span v-if="data.item.budget || data.item.dailyBudget">
                    {{ (!data.item.budget || data.item.budget == 0) && data.item.dailyBudget ? `&euro; ${formatNumber(data.item.dailyBudget, "en-EN")} / ${$tc('general.day',1)}` : `&euro; ${formatNumber(data.item.budget, "en-EN")}` }}
                  </span>
                  <span v-else>N/A</span>
                </template>
                <template #cell(start)="data">
                  <span v-if="data.item.start">
                    {{ new Date(data.value).formatDate() }}
                  </span>
                  <span v-else>N/A</span>
                </template>
                <template #cell(end)="data">
                  <span v-if="data.item.continously">
                    {{ $t("labels.openend") }}
                  </span>
                  <span v-else-if="data.item.end">
                    {{ new Date(data.value).formatDate() }}
                  </span>
                  <span v-else>N/A</span>
                </template>
                <template #cell(created)="data">
                  <span :title="new Date(data.value).formatTime()" v-b-tooltip.hover>
                    {{ new Date(data.value).formatDate() }}
                  </span>
                </template>
                <template #cell(status)="data">
                  <div v-if="bookingIsOver(data.item)">
                    {{ $t("messages.bookingcomplete") }} <i class="ml-1 fa fa-check"></i>
                  </div>
                  <div v-else-if="data.item.status === 'pending' && !isViewer">
                    <b-badge
                      class="cursor-pointer mr-2"
                      variant="success"
                      href="#"
                      @click="publishBooking(data.item._id)"
                    >
                      <i class="fa fa-check"></i> {{ $t("actions.clicktopublish") }}
                    </b-badge>
                    <i class="ml-1 icon-question" :id="data.item._id + '-' + statusTooltips[data.item.status].id"></i>
                  </div>
                  <div v-else-if="data.item.status">
                    {{ statusTooltips[data.item.status].title }} <i class="ml-1 icon-question" :id="data.item._id + '-' + statusTooltips[data.item.status].id"></i>
                  </div>
                  <span v-else>N/A</span>
                </template>
                <template #cell(actions)="data">
                  <i v-if="isSysOp" class="text-primary fa fa-copy" :title="'Copy ID to clipboard: ' + data.item._id" style="cursor: pointer;" @click="copyIdToClipboard(data.item._id)"></i>
                  <i class="text-primary fa fa-eye ml-2" title="View Booking Information" @click="openBookingModal(data.item)" style="cursor: pointer;"></i>
                  <i class="text-primary fa fa-code ml-2" v-if="data.item.status !== 'canceled'" :title="'Show Container Code'" @click="showTrackingCode(data.item.advertiser[0]._id)" style="cursor: pointer;"></i>
                  <i class="text-primary ml-2 fa fa-edit" v-if="data.item.status !== 'canceled' && (!data.item.dailyBudget || data.item.dailyBudget == 0) && !isViewer" title="Edit Booking" style="cursor: pointer" @click="$router.push(`/booking/edit/${data.item._id}`)"></i>
                  <i class="text-primary ml-2 fa fa-edit" v-if="data.item.status !== 'canceled' && !(!data.item.dailyBudget || data.item.dailyBudget == 0) && !isViewer" title="Edit Booking" style="cursor: pointer" @click="$router.push(`/booking/update/${data.item._id}`)"></i>
                  <i v-if="isSysOp" class="text-primary ml-2 fa fa-trash" :title="`${archived ? 'Recover' : 'Delete'} ${entityType.charAt(0).toUpperCase() + entityType.slice(1)}`" style="cursor: pointer" @click="confirmation(data.item)"></i>
                </template>
              </b-table>
            </div>
            <Pagination class="my-4" :itemCount="itemCount" :currentPage="settings.page" :perPage="filter.qty" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
          </div>
        </div>
        <div class="mypopovers" v-for="(entity, index) in tableData" :key="'popover' + index">
          <b-popover v-if="entity.status" custom-class="text-center" triggers="hover focus" :title="statusTooltips[entity.status].title" :target="entity._id + '-' + statusTooltips[entity.status].id">
            <span v-if="statusTooltips[entity.status].info"><em>{{statusTooltips[entity.status].info}}</em></span>
            <br v-if="statusTooltips[entity.status].info && statusTooltips[entity.status].todo" />
            <span class="d-block mt-2" v-if="statusTooltips[entity.status].todo"><strong>{{ $t('status.whatdonow') }}</strong><br/><em>{{statusTooltips[entity.status].todo}}</em></span>
          </b-popover>
        </div>

        <b-modal id="tracking-code-modal" size="lg" title="Container Code" :hide-footer="true" >
          <TrackingCodeModal :programid="selectedEntityId"/>
        </b-modal>
        <b-modal id="deleteModal" :hide-footer="true" size="sm" :title="`${archived ? 'Recover' : 'Delete'} ${entityType.charAt(0).toUpperCase() + entityType.slice(1)}`">
          <RecoverDeleteModal :type="entityType" @recdelEntity="recoverEnt()" />
        </b-modal>
        <b-modal
          ref="bookingModal"
          id="bookingModal"
          size="xl"
          :title="$t('headers.bookingmodal')"
          :hide-footer="true"
        >
          <PendingBookingModal :booking="modalBooking" />
        </b-modal>
        <input type="hidden" id="clipboard" value />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CHANNELS from "@/constants/channels.js";
import TrackingCodeModal from "@/components/modals/TrackingCodeModal";
import PendingBookingModal from "@/components/modals/PendingBookingModal";
import RecoverDeleteModal from "@/components/modals/RecoverDeleteModal";
import CampaignChannels from "@/components/dashboard/components/CampaignChannels";
import Pagination from "@/components/pages/assets/Pagination.vue";

const jwtDecode = require('jwt-decode');

export default {
  async created() {
    this.localArchive = this.$store.getters.archived;
    this.getAllAdvertiser();
    this.fetchData();
  },
  components: {
    TrackingCodeModal,
    PendingBookingModal,
    RecoverDeleteModal,
    CampaignChannels,
    Pagination
  },
  data() {
    return {
      firstLoading: true,
      settings: {
        isBusy: false,
        page: 1,
      },
      filter: {
        entity: this.entityType,
        qty: 10,
        offset: 0,
        sort: 'created',
        direction: 'DESC',
        search: null,
        advertiser: null
      },
      allAdvertisers: [],
      dataTableFields: [
        {
          key: 'name',
          label: this.$t('general.name'),
          sortable: true
        },
        {
          key: 'budget',
          label: this.$t('general.budget'),
          sortable: true
        },
        {
          key: 'created',
          label: this.$t("general.created"),
          sortable: true
        },
        {
          key: 'start',
          label: this.$t("general.startdate"),
          sortable: true
        },
        {
          key: 'end',
          label: this.$t("general.enddate"),
          sortable: true
        },
        {
          key: 'advertiser',
          label: this.$tc("general.advertiser", 1),
          sortable: true
        },
        {
          key: 'channels',
          label: this.$tc("general.channel", 2),
        },
        {
          key: 'status',
          label: this.$t('general.status'),
        },
        {
          key: 'actions',
          label: this.$tc('general.action', 2),
          class: "text-right",
        },
      ],
      tableData: [],
      selectedEntityId: null,
      itemCount: 0,
      localArchive: false,
      entityType: 'booking',
      fields: ['name', 'budget', 'start', 'end', 'advertiser'],
      statusTooltips: {
        'waiting for approval': {
          title: this.$t("status.booking-waitingapproval-title"),
          info: this.$t("status.booking-waitingapproval-info"),
          id: "waiting_for_approval"
        },
        'generating creatives': {
          title: this.$t("status.booking-generatingcreatives-title"),
          info: this.$t("status.booking-generatingcreatives-info"),
          id: "generating_creatives"
        },
        'rejected': {
          title: this.$t("status.booking-rejected-title"),
          info: this.$t("status.booking-rejected-info"),
          todo: this.$t("status.booking-rejected-todo"),
          id: "rejected"
        },
        'pending': {
          title: this.$t("status.booking-pending-title"),
          info: this.$t("status.booking-pending-info"),
          todo: this.$t("status.booking-pending-todo"),
          id: "pending"
        },
        'canceled': {
          title: this.$t("status.booking-canceled-title"),
          info: this.$t("status.booking-canceled-info"),
          todo: this.$t("status.booking-canceled-todo"),
          id: "canceled"
        },
        'cancelling': {
          title: this.$t("status.booking-cancelling-title"),
          info: this.$t("status.booking-cancelling-info"),
          todo: this.$t("status.booking-cancelling-todo"),
          id: "cancelling"
        },
        'published': {
          title: this.$t("status.booking-published-title"),
          info: this.$t("status.booking-published-info"),
          todo: this.$t("status.booking-published-todo")
        },
        'publishing': {
          title: this.$t("status.booking-publishing-title"),
          info: this.$t("status.booking-publishing-info"),
          todo: this.$t("status.booking-publishing-todo")
        }
      },
      modalBooking: null,
      deleteEntity: null,
      confirmModalBodyText: '',
      confirmModalTitleText: '',
      confirmModalFinishButtonText: '',
      confirmModalCancelButtonText: this.$t("actions.cancel"),
      confirmModalFinishButtonVariant: '',
      confirmModalCancelButtonVariant: 'secondary',
      confirmModalFinishEvent: '',
      publishing: false
    };
  },
  watch: {
    localArchive: function(){
      this.$store.dispatch("setArchived", this.localArchive);
      this.$store.dispatch("setLoadingActive");
      this.changePage(1);
      this.$store.dispatch("setLoadingInactive");
    }
  },
  computed: {
    ...mapGetters([
      "error",
      "success",
      "warning",
      "archived"
    ]),
    isAdmin: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "administrator" ? true : false,
    isSysOp: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "systemoperator" ? true : false,
    isViewer: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "viewer" ? true : false,
    isAccounting: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "accounting" ? true : false,
    isAgency: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "agency" ? true : false,
    cellAttributes(slot) {
      return ['#cell(' + slot + ')="data"'];
    }
  },
  methods: {
    async getAllAdvertiser(){
      const response = (await this.$api.advertiser.getAll()).items;
      for(const advertiser of response){
        this.allAdvertisers.push({ _id: advertiser._id, name: advertiser.name });
      }
    },
    showCampaignChannels(item){
      return item?.lookups?.campaign[0]?.lookups;
    },
    sortData(sort){
      if(sort.sortBy == '') return;
      this.settings.page = 1;
      this.filter.sort = sort.sortBy;
      this.filter.direction = sort.sortDesc === false ? 'ASC' : 'DESC';
      this.fetchData();
    },
    changePage(page){
      this.settings.page = page;
      this.fetchData();
    },
    loadFirstPage(){
      this.settings.page = 1;
      this.fetchData();
    },
    changeQuantity(quantity){
      this.filter.qty = quantity;
      this.fetchData();
    },
    async fetchData(){
      if(this.settings.isBusy)  return;

      this.settings.isBusy = true;
      this.filter.offset = (this.settings.page-1)*this.filter.qty;

      const response = await this.$api.entity.getEntities(this.entityType,this.filter)


      this.itemCount = response.count;
      this.tableData = this._mapBookings(response.items);

      this.settings.isBusy = false;
      this.firstLoading = false;
    },
    _mapBookings(bookings) {
      if (bookings.length) {
        bookings.forEach(entity => {
          let channels = [];
          if(entity?.lookups?.lineitems?.length && entity?.lookups?.contracts?.length && entity?.lookups?.campaign[0]) {
            entity.lookups.campaign[0].lookups = {
              lineitems: entity.lookups.lineitems,
              contracts: entity.lookups.contracts,
              banners: entity?.lookups?.banners?.length ? entity.lookups.banners : [],
              bookings: [ entity ]
            };
          } 
          else {
            entity.channels.forEach(key => {
              let channelData = this.getChannelDetails(key);

              if(!channelData){
                channelData = { name: key}
              }
              channels.push(Object.assign({}, channelData));
            })
          }
          // set entity channels
          entity.channels = channels;
        });
        return bookings;
      }
      return [];
    },
    gotoNewEntity() {
      this.$router.push(`${this.entityType}/create`);
    },
    getChannelDetails(key) {
      for (let i = 0; i < CHANNELS.length; i++) {
        if (CHANNELS[i].key == key) {
          return CHANNELS[i];
        }
      }
      return false;
    },
    getLabel(field){
      switch(field){
        case 'advertiser':    return this.$tc("general.advertiser", 1).toUpperCase();
        case 'channels':      return this.$tc("general.channel", 2).toUpperCase();
        case 'start':         return this.$t("general.startdate").toUpperCase();
        case 'end':           return this.$t("general.enddate").toUpperCase();
      }
      return field.toUpperCase();
    },
    getEntityFieldValues(entity, field) {
      let returnString = "";

      if(field == 'channels') {
          entity.channels.forEach(channel => {
            let string = ''
            if((this.isSysOp || this.isAdmin || this.isAgency) && channel.lineitem && channel.image) {
              string = `<a href="/lineitem/update/${channel.lineitem}"><img
                  src="${channel.image}"
                  alt="${channel.name}"
                  title="${channel.name}"
                  style="width: 20px; height: 20px;"
                  class="mr-1 ${channel.lineitemStatus ? '' : 'channel-inactive'}"
                /></a> `
            } else {
              if(channel.image) {
                string = `<img class="mr-2" style="width: 20px; height: 20px;" title="${channel.name}" alt="${channel}" src="${channel.image}">`
              } else {
                string = `${channel.name}`
              }
              
            }
            returnString += string;
          })
      }
      return returnString;
    },
    bookingIsOver(booking){
      if(!booking.end) return false;
      if(new Date(booking.end) < new Date()) return true;
      return false;
    },
    formatNumber(number, format) {
      return new Intl.NumberFormat(format).format(number);
    },
    showTrackingCode(advertiserID){
      this.selectedEntityId = advertiserID;
      this.$bvModal.show('tracking-code-modal');
    },
    openBookingModal(entity) {
      this.modalBooking = entity;
      this.$refs["bookingModal"].show();
    },
    copyIdToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    confirmation(entity) {
      this.deleteEntity = entity;
      this.$bvModal.show("deleteModal");
    },
    async recoverEnt(){
      this.$store.dispatch("setLoadingActive");
        try {
          if(this.archived){
            let entity = await this.$api.get(this.deleteEntity['_id'] ? this.deleteEntity['_id'] : this.deleteEntity);
            entity.archived = new Date(0);
            await this.$api[this.entityType].update(this.deleteEntity);
          } else {
            await this.$api[this.entityType].delete(this.deleteEntity['_id'] ? this.deleteEntity['_id'] : this.deleteEntity);
          }
          this.changePage(1);
          // this.$emit('reloadEntities');
          this.$store.dispatch("setLoadingInactive");
          this.$store.dispatch("setError", "");
          this.$store.dispatch("setSuccess", `${this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1)} ${this.archived ? 'recovered' : 'deleted'}.`);
        } catch (err) {
          this.$store.dispatch("setError", err);
          this.$store.dispatch("setLoadingInactive");
        }
    },
    async publishBooking(bookingId) {
      this.changeLocalStatus(bookingId, "publishing");
      try {
        let response = await this.$api.booking.changeStatus(bookingId, "published");
        if (response.failedChannels.length > 0) {
          let vNodeMSG = this.$createElement('p', {}, [`Your Booking was published, but we could determine error with `, this.$createElement('b', {}, `${response.failedChannels.join(', ')}.`), this.$createElement('br'), `Our technicians were informed and will start `, this.$createElement('b', {}, response.failedChannels.join(', ')), ` as soon as possible.`]);
          this.$bvToast.toast(
            [vNodeMSG],
            {
              title: 'Booking was published with errors',
              noAutoHide: true,
              variant: 'warning'
            });
        } else {
          this.$bvToast.toast(
            this.$t("messages.bookingpublished"),
            {
              title: this.$t("messages.bookingpublished-title"),
              noAutoHide: true,
              variant: 'success'
            });
        }
        this.changeLocalStatus(bookingId, "published");
      } catch (e) {
        this.changeLocalStatus(bookingId, "pending");
        this.$bvToast.toast(
          `${e?.response?.data?.message ? e?.response?.data?.message : 'Unkown Error. If the error continuous, please contact us.'}`,
          {
            title: 'Booking was not published',
            noAutoHide: true,
            variant: 'danger'
          });
      }
      this.publishing = false;
    },
    changeLocalStatus(bookingId, newStatus) {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i]._id == bookingId) {
          this.tableData[i].status = newStatus;
        }
      }
    },
  }
}
</script>

<style>

</style>