<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper auth p-0 theme-two">
      <nav class="navbar horizontal-layout col-lg-12 col-12 p-0" style="position: relative">
      <div class="nav-top flex-grow-1">
        <div class="container-fluid d-flex flex-row h-100">
          <div class="text-center navbar-brand-wrapper d-flex align-items-top">
            <router-link class="navbar-brand brand-logo" to="/">
              <img :src="headerlogo" id="logo" alt="logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img :src="headerlogo" alt="logo" />
            </router-link>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item dropdown mr-4" style="cursor: pointer;">
                <a class="nav-link dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                  <i class="flag-icon" :class="selectedLanguage === 'en' ? 'flag-icon-gb' : 'flag-icon-de'"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list p-0" style="top: calc(45px - 6px);" aria-labelledby="notificationDropdown">
                  <a class="dropdown-item preview-item" @click="changeLanguage('en')">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-light" :class="selectedLanguage === 'en' ? 'bg-background' : ''">
                        <i class="flag-icon flag-icon-gb mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">English</h6>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" @click="changeLanguage('de')">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-light" :class="selectedLanguage == 'de' ? 'bg-background' : ''">
                        <i class="flag-icon flag-icon-de mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal text-dark mb-1">German</h6>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            
            <p class="my-0 ml-3">{{ $t("messages.noaccount") }}</p>
            <router-link class="btn get-started-btn text-uppercase" to="/register">{{ $t("actions.register") }}</router-link>
          </div>
        </div>
      </div>
    </nav>
      <div class="row align-items-stretch">
        <div class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center">
          <div class="slide-content bg-1" :style="'background: url(' + authimage + ')'"></div>
        </div>
        <div class="col-12 col-md-8 h-100 bg-white">
          <div
            class="auto-form-wrapper d-flex align-items-center flex-column"
          >
            <div class="nav-get-started">
              <button class="btn get-started-btn" @click="$router.go(-1)"
                >BACK</button>
            </div>
            
            <div style="overflow: scroll" class="hsb">
              <h1>Privacy policy</h1>
              <p><em>October 12th, 2019</em></p>
              <p>
                We at {{ companyName }} know that the careful handling of
                your personal information is important to you. For this
                reason, the protection of all data is of the utmost importance
                to {{ companyName }} and all its services, including the
                advertising services operated under the 42 Performance Ads
                and 42 Video Ads brands. Our principles include unrestricted
                respect for the right to informational self-determination.
                {{ companyName }} is fully committed to the data protection laws of Germany
                and Europe. In addition, {{ companyName }} strictly adheres to the
                principles of the EDAA. This is why we would like to inform
                you here about the handling of data at {{ companyName }}.
              </p>
              <p>
                The processing of personal data, such as the name, address,
                e-mail address or telephone number of a person concerned,
                is always carried out in accordance with the Basic Data Protection
                Ordinance (DSGVO) and in accordance with the country-specific
                data protection regulations applicable to {{ companyName }}; in
                particular in accordance with the statutory conditions of
                permission pursuant to Art. 6 Para. 1 lit. a (consent), b
                (fulfilment of contract) and f (legitimate interest) DSGVO.
                By means of this data protection declaration, we would like to
                inform you about the type, scope and purpose of the
                personal data collected, used and processed by us. Furthermore,
                this data protection declaration will inform the persons
                concerned about their rights.
              </p>
              <p>
                {{ companyName }}, as the data controller, has implemented numerous
                technical and organisational measures to ensure the most
                complete possible protection of the personal data processed
                via this website. Nevertheless, Internet-based data
                transmissions can in principle have security gaps, so that
                absolute protection cannot be guaranteed. For this reason, every
                person concerned is free to transmit personal data to us by
                alternative means, such as telephone.
              </p>
              <p class="pl40">1. Definitions</p>
              <p class="pl80 text-left">
                {{ companyName }}'s data protection declaration is based on the terms
                used by the European Directive and Ordinance Giver in the
                enactment of the Basic Data Protection Regulation (DSGVO).
              </p>
              <p class="pl40 text-left">
                2. The name and address of the controller
              </p>
              <p class="pl80 text-left">
                The person responsible within the meaning of the Basic Data
                Protection Regulation, other data protection laws
                applicable in the Member States of the European Union and other
                provisions of a data protection nature is:
              </p>
              <p class="pl80 text-left" v-html="`${companyName}<br> ${companyAddress}`"></p>
              <p class="pl40 text-left">
                3. Data protection officer
              </p>
              <p class="pl80 text-left">
                The data protection officer of the controller shall be reached
                directly as follows:
              </p>
              <p class="pl80 text-left" v-html="protectionOfficer"></p>
              <p class="pl80 text-left">
                Any person concerned can contact our data protection officer
                directly at any time with any questions or suggestions
                regarding data protection.
              </p>
              <p class="pl40 text-left">
                4. Cookies &amp; Pixel
              </p>
              <p class="pl80 text-left">
                We store so-called "cookies" in order to offer you an extensive
                range of functions and to make the use of our website more
                convenient. "Cookies" are small files that are stored on your
                computer with the help of your Internet browser. If you do not
                wish to use "cookies", you can prevent "cookies" from being
                saved on your computer by making the appropriate settings
                in your Internet browser. Please note that this may restrict the
                functionality and scope of our services.
              </p>
              <p class="pl80 text-left">
                In addition, we use so-called tracking pixels occasionally and,
                if necessary, by third parties. Tracking pixels are small
                scripts on web pages that enable log file recording and log
                file analysis and are frequently used for statistical
                evaluations. So-called Z&auml;hlpixel are integrated on our
                pages. When you visit our pages, the pixel-code can be used
                to establish a direct connection between your browser and a
                server belonging to us or the third party. We or the third
                party receive information from your browser, among other things,
                that our website has been called up by your terminal device. If
                you are a registered user of the third party, the third
                party can assign the visit to our pages to your user
                account. We would like to point out that, as the provider of the
                website, we are not aware of the content of the transmitted
                data or its use by the third party provider. We can only
                select in which segments (such as age, interests) our
                advertising should be displayed. By calling the pixel from
                your browser, we or the third party can recognize whether
                an advertisement was successful. This enables us to record the
                effectiveness of the advertisements for statistical and
                market research purposes.
              </p>
              <p class="pl40 text-left">
                5. Log data / collection of general data and information
              </p>
              <p class="pl80 text-left">
                The website of {{ companyName }} collects a series of general data and
                information each time the website is accessed by a person
                concerned or an automated system. These general data and
                information are stored in the log files of the server. The
                following can be recorded:
              </p>
              <ul class="pl80">
                <li style="list-style-type: none;">
                  <ul>
                    <li style="list-style-type: none;">
                      <ul style="list-style-type: circle;">
                        <li>browser types and versions used</li>
                        <li>
                          the operating system used by the accessing system
                        </li>
                        <li>
                          the Internet page from which an accessing system
                          accesses our Internet page (so-called referrer)
                        </li>
                        <li>
                          the subwebsites, which are accessed via an accessing
                          system on our website/li&gt;
                        </li>
                        <li>the date and time of access to the website</li>
                        <li>an Internet protocol address (IP address)</li>
                        <li>
                          the Internet service provider of the accessing system
                        </li>
                        <li>
                          other similar data and information used to avert
                          dangers in the event of attacks on our
                          information technology systems.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <p class="pl40" style="display: inline">
                6. 
              </p> <p class="pl80"> When using this general data and information, {{ companyName }}
                does not draw any conclusions about the person concerned.
                This information is required to (1) correctly deliver the
                content of our website, (2) optimize the content and advertising
                of our website, (3) ensure the long-term functionality of
                our information technology systems and the technology of
                our website, and (4) provide law enforcement authorities with
                the information necessary for law enforcement in the event
                of a cyber attack. This anonymously collected data and
                information is therefore evaluated by {{ companyName }} both statistically
                and with the aim of increasing data protection and data security
                in our company in order to ultimately ensure an optimum
                level of protection for the personal data processed by us.
                The legal basis for the temporary storage of data is Art. 6
                para. 1 lit. f DSGVO. These purposes also include our
                legitimate interest in data processing pursuant to Art. 6
                para. 1 lit. f DSGVO. The data contained in log files will be
                deleted after seven days at the latest. The anonymous data
                of the server log files are stored separately from all
                personal data provided by the person concerned.</p>
              <p class="pl40">
                7. Possibility to contact us via the website
              </p>
              <p class="pl80">
                Due to legal regulations, the website of {{ companyName }} contains
                information that enables quick electronic contact with our
                company and direct communication with us, which also includes
                a general address of the so-called electronic mail (e-mail
                address). If a data subject contacts the data controller by
                e-mail or via a contact form, the personal data transmitted
                by the data subject is automatically stored. Such personal data
                transmitted voluntarily by a data subject to the data
                controller will be stored for the purposes of processing or
                contacting the data subject. This personal data will not be
                passed on to third parties.
              </p>
              <p class="pl40">
                8. Routine deletion and blocking of personal data
              </p>
              <p class="pl80">
                &nbsp;{{ companyName }} processes and stores the personal data of the
                person concerned only for the period of time necessary to
                achieve the storage purpose or insofar as this has been provided
                for by the European Directive and Regulation Giver or another
                legislator in laws or regulations to which {{ companyName }} is
                subject. If the storage purpose no longer applies or if a
                storage period prescribed by the European directive and
                regulation giver or another competent legislator expires, the personal
                data shall be blocked or deleted routinely and in accordance
                with the statutory provisions.
              </p>
              <p class="pl40">
                9. Privacy policy for the use of Google Analytics (with
                anonymization function)
              </p>
              <p class="pl80">
                {{ companyName }} has integrated the component Google Analytics (with
                anonymization function) on this website. Google Analytics
                is a web analysis service. Web analysis is the collection,
                collection and evaluation of data about the behaviour of
                visitors to Internet pages. A web analysis service
                collects, among other things, data on which Internet page a
                person concerned came from (so-called referrers), which subpages
                of the Internet page were accessed or how often and for how
                long a subpage was viewed. A web analysis is mainly used to
                optimise a website and to analyse the costs and benefits of
                Internet advertising.
              </p>
              <p class="pl80">
                The operating company of the Google Analytics component is:<br /><br />
                Google Inc, <br />1600 Amphitheatre Pkwy, <br />Mountain View, <br />CA
                94043-1351, <br />USA.
              </p>
              <p class="pl80">
                The purpose of the Google Analytics component is to analyse the
                flow of visitors to our website. Google uses the data and
                information obtained, among other things, to evaluate the
                use of our website, to compile online reports for us showing the
                activities on our website and to provide other services in
                connection with the use of our website.
              </p>
              <p class="pl80">
                Google Analytics places a cookie on the information technology
                system of the person concerned. What cookies are has
                already been explained above. By setting the cookie, Google
                is able to analyse the use of our website. Each time you access
                one of the individual pages of this website, which is
                operated by the data controller and on which a Google
                Analytics component has been integrated, the Internet browser on
                the information technology system of the person concerned
                is automatically prompted by the respective Google
                Analytics component to transmit data to Google for the purpose
                of online analysis. As part of this technical process,
                Google obtains knowledge of personal data, such as the IP
                address of the person concerned.
              </p>
              <p class="pl80">
                The cookie is used to store personal information, such as the
                access time, the location from which an access originated
                and the frequency of visits to our website by the person concerned.
                Each time you visit our website, this personal data, including
                the IP address of the Internet connection used by the
                person concerned, is transmitted to Google in the United
                States of America. This personal data is stored by Google in the
                United States of America. Google may share this personal
                data collected through the technical process with third
                parties.
              </p>
              <p class="pl80">
                The person concerned can at any time prevent the setting of
                cookies by our website, as described above, by means of an
                appropriate setting of the Internet browser used and thus
                permanently object to the setting of cookies. Such a setting of
                the Internet browser used would also prevent Google from
                setting a cookie on the information technology system of
                the person concerned. In addition, a cookie already set by
                Google Analytics can be deleted at any time via the
                Internet browser.
              </p>
              <p class="pl80">
                Furthermore, it is possible for the person concerned to object
                to and prevent the collection of data generated by Google
                Analytics and relating to the use of this website and the
                processing of this data by Google. For this purpose, the person
                concerned must download and install a browser add-on under
                the link. This browser add-on informs Google Analytics via
                JavaScript that no data and information on visits to Internet
                pages may be transmitted to Google Analytics. The
                installation of the browser add-on is considered a
                contradiction by Google.
              </p>
              <p class="pl80">
                If the data subject's information technology system is later
                deleted, formatted or reinstalled, the data subject must
                reinstall the browser add-on in order to deactivate Google
                Analytics. If the browser add-on is uninstalled or deactivated
                by the data subject or by another person within his/her
                control, the browser add-on may be reinstalled or reactivated.
                You can download this browser add-on here: <a
                  href="http://tools.google.com/dlpage/gaoptout?hl=en"
                  >http://tools.google.com/dlpage/gaoptout?hl=en</a
                >
              </p>
              <p class="pl80">
                Opt-out cookies prevent the future collection of your data when
                you visit this website. To prevent Universal Analytics from
                collecting your data across multiple devices, you must opt-out
                on all systems you use.
              </p>
              <p class="pl80">
                Further information and Google's applicable privacy policy can
                be found at https://www.google.de/intl/de/policies/privacy/
                and http://www.google.com/analytics/terms/de.html Google
                Analytics is explained in more detail under this link
                <a href="https://www.google.com/intl/de_en/analytics/"
                  >https://www.google.com/intl/de_en/analytics/</a
                >.
              </p>
              <p class="pl80">
                The use of Google Analytics is based on our legitimate interest
                in the appropriate design, statistical analysis and
                efficient use of our website and the fact that your
                legitimate interests do not prevail, Art. 6 para. 1 DSGVO
              </p>
              <p class="pl40">10. Google Fonts</p>
              <p class="pl80">
                We integrate the fonts ("Google Fonts") of the provider:<br /> <br />Google
                LLC, <br />1600 Amphitheatre Parkway, <br />Mountain View, <br />CA 94043,<br />
                USA.<br /><br /> This website incorporates external fonts from Google
                Fonts. Google Fonts is a service provided by Google Inc. (USA).
                The fonts are integrated by a server call at Google in the
                USA, whereby it can be assumed that requests are stored by
                Google. If your browser does not support this function, a
                standard font will be used by your computer for display.
              </p>
              <p class="pl80">
                Further information on Google Webfonts can be found at <a
                  href="https://developers.google.com/fonts/faq?hl=de-DE&amp;csw=1"
                  >https://developers.google.com/fonts/faq?hl=de-DE&amp;csw=1</a
                >
              </p>
              <p class="pl80">
                Privacy Policy:
                <a href="https://www.google.com/policies/privacy/"
                  >https://www.google.com/policies/privacy/</a
                > Opt-Out:
                <a href="https://adssettings.google.com/authenticated"
                  >https://adssettings.google.com/authenticated</a
                >
              </p>
              <p class="pl40">
                11. Duration for which the personal data will be stored
              </p>
              <p class="pl80">
                The criterion for the duration of the storage of personal data
                is the respective legal retention period. After this period
                has expired, the corresponding data will be routinely deleted
                unless they are no longer required for the fulfilment or
                initiation of the contract.
              </p>
              <p class="pl40">
                12. Legal or contractual provisions governing the provision of
                personal data; necessity for the conclusion of the
                contract; obligation of the data subject to provide the personal
                data; possible consequences of not providing the data
              </p>
              <p class="pl80">
                We inform you that the provision of personal data is partly
                required by law (e.g. tax regulations) or may result from
                contractual regulations. It may sometimes be necessary for
                a contract to be concluded for a data subject to provide us with
                personal data which we must subsequently process. For
                example, the data subject is obliged to provide us with
                personal data if our company concludes a contract with him or
                her. Failure to provide personal data would mean that the
                contract could not be concluded with the data subject. The
                data subject must contact one of our employees before providing
                personal data. Our employee will inform the data subject on
                a case-by-case basis whether the provision of personal data
                is required by law or contract or is necessary for the
                conclusion of a contract, whether there is an obligation to
                provide the personal data and the consequences of not
                providing the personal data.
              </p>
              <p class="pl40">13. Rights of the data subject</p>
              <ul>
                <li style="list-style-type: none;" class="pl80">
                  <ul>
                    <li>
                      Right to confirmation Every data subject shall have
                      the right, granted by the European directive and regulation
                      maker, to obtain from the controller confirmation as to
                      whether personal data relating to him or her are
                      being processed. If a data subject wishes to exercise this
                      right of confirmation, he or she may at any time contact
                      the Data Protection Officer or another member of
                      staff of the controller.
                    </li>
                    <li>
                      Right of access Any person concerned by the
                      processing of personal data has the right, granted by
                      the European directive and regulation maker, to obtain at
                      any time, free of charge, from the controller,
                      information on the personal data relating to him which have
                      been stored and a copy of that information. Furthermore,
                      the European Data Protection Supervisor has granted
                      the data subject access to the following information:
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li style="list-style-type: none;padding-left: 160px;">
                  <ul>
                    <li style="list-style-type: none;">
                      <ul>
                        <li>the processing purposes</li>
                        <li>
                          die Kategorien personenbezogener Daten, die
                          verarbeitet werden
                        </li>
                        <li>
                          the recipients or categories of recipients to whom the
                          personal data have been or will be disclosed, in
                          particular recipients in third countries or international
                          organisations
                        </li>
                        <li>
                          if possible, the planned duration for which the
                          personal data will be stored or, if that is not
                          possible, the criteria for determining that duration
                        </li>
                        <li>
                          the existence of a right to the rectification or
                          erasure of personal data concerning him or her or
                          to the limitation of the processing carried out by the
                          controller or of a right to object to such processing
                        </li>
                        <li>
                          the existence of a right of appeal to a supervisory
                          authority
                        </li>
                        <li>
                          if the personal data are not collected from the data
                          subject: All available information on the origin
                          of the data
                        </li>
                        <li>
                          the existence of automated decision-making, including
                          profiling, in accordance with Article 22(1) and
                          (4) of the DSGVO and, at least in these cases,
                          meaningful information on the logic involved and the
                          scope and intended impact of such processing on
                          the data subject
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li style="list-style-type: none;padding-left: 80px;">
                  &nbsp;
                  <ul>
                    <li>
                      The data subject also has the right to know whether
                      personal data have been transferred to a third
                      country or to an international organisation. If this is
                      the case, the data subject shall also have the right
                      to obtain information on the appropriate safeguards in
                      connection with the transfer. If a data subject
                      wishes to exercise this right of access, he or she may at
                      any time contact the Data Protection Officer or
                      another member of staff of the controller.
                    </li>
                    <li>
                      Right to rectification Any person concerned by the
                      processing of personal data has the right, granted by
                      the European directive and regulation maker, to request
                      the rectification without delay of inaccurate
                      personal data concerning him or her. Furthermore, the data
                      subject shall have the right, having regard to the
                      purposes of the processing, to request the completion
                      of incomplete personal data, including by means of a supplementary
                      statement. If a data subject wishes to exercise this
                      right of rectification, he or she may at any time
                      contact the Data Protection Officer or another member of
                      staff of the controller.
                    </li>
                    <li>
                      Right to deletion (right to be forgotten) Any person
                      data subject to the processing of personal data shall have
                      the right, granted by the European directive and
                      regulation, to require the controller to erase
                      without delay personal data concerning him which are
                      subject to one of the following conditions and to the
                      extent that the processing is not necessary:
                      <ul class="pl40">
                        <li>
                          The personal data have been collected for such
                          purposes or processed in any other way for which
                          they are no longer necessary.
                        </li>
                        <li>
                          The data subject withdraws his consent on which the
                          processing was based pursuant to Art. 6 para. 1
                          letter a DSGVO or Art. 9 para. 2 letter a DSGVO
                          and there is no other legal basis for the processing.
                        </li>
                        <li>
                          The data subject objects to the processing under
                          Article 21(1) DSGVO and there are no overriding
                          legitimate reasons for the processing or the data
                          subject objects to the processing under Article 21(2)
                          DSGVO.
                        </li>
                        <li>Personal data have been processed unlawfully.</li>
                        <li>
                          The deletion of personal data is necessary to fulfil a
                          legal obligation under Union law or the law of
                          the Member States to which the data controller is subject.
                        </li>
                        <li>
                          The personal data were collected in relation to
                          information society services offered pursuant to
                          Art. 8 (1) DSGVO.
                        </li>
                      </ul>
                    </li>
                    <li>
                      If one of the above reasons applies and a person concerned
                      wishes to have personal data stored at {{ companyName }}
                      deleted, he or she can contact the data protection
                      officer or another employee of the data controller at any
                      time. The employee of {{ companyName }} will ensure that
                      the request for deletion is complied with
                      immediately.
                    </li>
                  </ul>
                </li>
              </ul>
              <p class="pl160">
                If the personal data have been made public by {{ companyName }} and if
                our company as the person responsible is obliged to delete
                the personal data in accordance with Art. 17 para. 1 DSGVO,
                {{ companyName }} shall take appropriate measures, including
                technical measures, taking into account the available technology
                and the implementation costs, to inform other persons
                responsible for data processing who process the published
                personal data that the person concerned has requested the
                deletion of all links to this personal data or copies or
                replications of this personal data from these other persons
                responsible for data processing, insofar as the processing
                is not necessary. The employee of {{ companyName }} will take the
                necessary steps in individual cases.
              </p>
              <ul>
                <li style="list-style-type: none;" class="pl80">
                  <ul>
                    <li>
                      Right to limitation of processing Any person data
                      subject to the processing of personal data has the right,
                      granted by the European directive and regulation, to
                      request the controller to limit the processing if one
                      of the following conditions is met:
                      <ul class="pl40">
                        <li>
                          The accuracy of the personal data is contested by the
                          data subject for a period of time which allows
                          the data controller to verify the accuracy of the
                          personal data.
                        </li>
                        <li>
                          The processing is unlawful, the data subject refuses
                          the deletion of the personal data and instead
                          requests the restriction of the use of the personal
                          data.
                        </li>
                        <li>
                          The controller no longer needs the personal data for
                          the purposes of processing, but the data subject
                          needs them for the assertion, exercise or defence
                          of legal claims.
                        </li>
                        <li>
                          The data subject has lodged an objection against the
                          processing pursuant to Art. 21 (1) DSGVO and it
                          is not yet clear whether the legitimate reasons
                          of the data controller outweigh those of the data
                          subject.
                        </li>
                      </ul>
                    </li>
                    <li>
                      If one of the above conditions is met and a person
                      concerned wishes to request the restriction of
                      personal data stored at {{ companyName }}, he or she can contact
                      the data protection officer or another employee of
                      the data controller at any time. The employee of 42
                      Ads GmbH will initiate the restriction of the processing.
                    </li>
                    <li>Right to data transferability</li>
                  </ul>
                </li>
              </ul>
              <p class="pl160">
                Any data subject involved in the processing of personal data has
                the right, granted by the European directive and
                regulation, to obtain personal data concerning him or her
                which have been provided by the data subject to a controller
                in a structured, common and machine-readable format. It also has
                the right to communicate these data to another controller
                without being hindered by the controller to whom the
                personal data have been provided, provided that the processing
                is based on the consent referred to in Art. 6 para. 1 letter a
                DSGVO or Art. 9 para. 2 letter a DSGVO or on a contract
                referred to in Art. 6 para. 1 letter b DSGVO and the
                processing is carried out by automated means, provided that
                the processing is not necessary for the performance of a
                task carried out in the public interest or in the exercise
                of official authority entrusted to the controller.
              </p>
              <p class="pl160">
                Furthermore, when exercising his right to data transferability
                pursuant to Art. 20 para. 1 DSGVO, the data subject shall
                have the right to obtain that the personal data be
                transferred directly from one responsible person to another
                responsible person, insofar as this is technically feasible
                and insofar as this does not impair the rights and freedoms
                of other persons.
              </p>
              <ul>
                <li style="list-style-type: none;">&nbsp;</li>
              </ul>
              <p class="pl40">&nbsp;</p>
              <p class="pl160">
                Furthermore, when exercising his right to data transferability
                pursuant to Art. 20 para. 1 DSGVO, the data subject shall
                have the right to obtain that the personal data be
                transferred directly from one responsible person to another
                responsible person, insofar as this is technically feasible
                and insofar as this does not impair the rights and freedoms
                of other persons.
              </p>
              <p class="pl160">
                In order to assert the right to data transferability, the person
                concerned can contact the data protection officer or
                another employee of {{ companyName }} at any time.
              </p>
              <ul class="pl80">
                <li style="list-style-type: none;">
                  <ul>
                    <li>
                      Right of opposition Any person data subject to the
                      processing of personal data has the right, granted by
                      the European directive and regulation maker, to object at
                      any time, for reasons relating to his particular
                      situation, to the processing of personal data
                      concerning him on the basis of Article 6(1)(e) or (f)
                      DSGVO. This also applies to profiling based on these
                      provisions.
                    </li>
                  </ul>
                </li>
              </ul>
              <p class="pl160">
                {{ companyName }} will no longer process the personal data in the
                event of an objection unless we can prove compelling
                reasons for the processing worthy of protection which
                outweigh the interests, rights and freedoms of the person concerned,
                or the processing serves the assertion, exercise or defence of
                legal claims.
              </p>
              <p class="pl160">
                If {{ companyName }} processes personal data in order to conduct
                direct advertising, the person concerned has the right to
                object at any time to the processing of personal data for
                the purpose of such advertising. This also applies to profiling
                as far as it is connected with such direct advertising. If
                the person concerned objects to {{ companyName }} processing the
                data for direct marketing purposes, {{ companyName }} will no
                longer process the personal data for these purposes.
              </p>
              <p class="pl160">
                In addition, the data subject has the right to object to the
                processing of personal data relating to him by {{ companyName }}
                for scientific or historical research purposes or for
                statistical purposes pursuant to Art. 89 (1) DSGVO for reasons
                arising from his particular situation, unless such
                processing is necessary for the performance of a task in
                the public interest.
              </p>
              <p class="pl160">
                To exercise the right to object, the person concerned can
                contact the data protection officer of {{ companyName }} or
                another employee directly. The data subject is also free to
                exercise his/her right of objection through automated
                procedures using technical specifications in connection
                with the use of information society services,
                notwithstanding Directive 2002/58/EC.
              </p>
              <ul style="padding-left:80px">
                <li style="list-style-type: none;">
                  <ul>
                    <li>
                      Automatic decision in individual cases including
                      profiling Any person data subject to the processing
                      of personal data has the right under the European
                      Directive and Regulation not to be subject to a decision
                      based solely on automated processing, including
                      profiling, which produces legal effects upon him or
                      her or significantly affects him or her in a similar
                      manner, provided that the decision (1) is not
                      necessary for the conclusion or performance of a contract
                      between the data subject and the data controller, or (2)
                      is authorised by Union or national law or by the
                      Member States to which the data controller is subject
                      and which provides for adequate measures to safeguard the
                      rights and freedoms and the legitimate interests of
                      the data subject, or (3) is taken with the express
                      consent of the data subject.
                    </li>
                  </ul>
                </li>
              </ul>
              <p class="pl160">
                If the decision (1) is necessary for the conclusion or
                performance of a contract between the data subject and the
                responsible person or (2) is made with the express consent
                of the data subject, {{ companyName }} shall take appropriate measures
                to safeguard the rights and freedoms as well as the legitimate
                interests of the data subject, including at least the right
                to obtain the intervention of a person on the part of the
                responsible person, to state his or her own position and to
                challenge the decision.
              </p>
              <p class="pl160">
                If the data subject wishes to exercise rights relating to
                automated decisions, he or she may at any time do so by
                contacting the Data Protection Officer or another member of
                staff of the controller.
              </p>
              <ul>
                <li style="list-style-type: none; padding-left: 80px;">
                  <ul>
                    <li>
                      Right to revoke consent under data law Any person
                      concerned by the processing of personal data has the
                      right, granted by the European directive and
                      regulation maker, to revoke consent to the processing
                      of personal data at any time.
                    </li>
                  </ul>
                </li>
              </ul>
              <p class="pl160">
                If the data subject wishes to exercise his or her right to
                withdraw consent, he or she may at any time do so by
                contacting the Data Protection Officer or another member of
                staff of the controller.
              </p>
              <p class="pl40">14. 42 Performance Ads</p>
              <p class="pl40">
                "Performance Ads" is a cookie-based targeting technology from
                {{ companyName }}. 42 Performance Ads collects pseudonymous
                information about user visits and interactions with Web
                sites for promotional purposes. Under no circumstances will
                the pseudonymised data be used to personally identify the
                user of the website. At the URL https://{{adverver}}/opt-out,
                users can object to the setting of cookies by 42 Performance Ads.
              </p>
              <p class="pl40">
                The following information is collected about the above scripts:
              </p>
              <ul class="pl40">
                <li style="list-style-type: none;">
                  <ul>
                    <li style="list-style-type: none;">
                      <ul style="list-style-type: circle;">
                        <li>User IP (per Oneway-Hash anonymisiert)</li>
                        <li>User Agent (per Oneway-Hash anonymisiert)</li>
                        <li>Timestamp</li>
                        <li>
                          Product IDs of the products being viewed, searched
                          for, or purchased.
                        </li>
                        <li>Page URLs of the viewed pages</li>
                        <li>search terms</li>
                        <li>Category IDs of the considered categories</li>
                        <li>Order numbers of purchases</li>
                        <li>Total prices of the respective purchases</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <p class="pl40">
                If the user is targeted, the following cookies are set in the
                user's browser:
              </p>
              <table
                style="width: 600px; border-collapse: collapse; height: 36px; margin-left:80px"
                border="1"
              >
                <tbody>
                  <tr style="border: none;">
                    <td
                      style="width: 50%; padding-left: 40px; border-right: 0px solid #ff0000; border-left: 0px solid #ff0000; height: 18px;"
                    >
                      opt-out
                    </td>
                    <td
                      style="width: 50%; border-right: 0px solid #ff0000; border-left: 0px solid #ff0000; height: 18px;"
                    >
                      Stores the user's decision to opt-out
                    </td>
                  </tr>
                  <tr style="border: none;">
                    <td
                      style="width: 50%; padding-left: 40px; border-right: 0px solid #ff0000; border-left: 0px solid #ff0000; height: 18px;"
                    >
                      uid
                    </td>
                    <td
                      style="width: 50%; border-right: 0px solid #ff0000; border-left: 0px solid #ff0000; height: 18px;"
                    >
                      Unique anonymous User ID
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="pl40">
                While providing our services we also operate under the
                {{adverver}} domain. We store a single cookie under this
                domain: "uid" which contains a unique ID established for a specific
                user. Data collected with our cookie on the client's website
                allow us to "retarget" visitors of such website with
                personalized ads displayed on other websites through the use
                of programmatic instantaneous auction system known as real-time
                bidding (RTB). Real-time bidding procedure begins when you
                enter a certain website where our clients' ads may be
                displayed to you &ndash; this triggers a 'bid request' being
                sent to us by our business partners. It is after receiving
                such bid request that our technology uses data about your
                browsing activity on our client's website to decide whether to
                respond to it and if so &ndash; which ad to display to you.
              </p>
              <p class="pl40">
                In order to opt out from {{adverver}} cookies please use the
                following link: <a :href="`https://${adverver}/opt-out`">Opt-Out</a>
              </p>
              <p class="pl40">
                All information is transmitted via SSL encrypted data transfers
                on servers and databases, which are protected against
                unauthorized access by means of private keys and passwords.
                All servers and databases are located within the Federal
                Republic of Germany. The data is stored for 120 days and
                then deleted. In addition, the data is enriched via product
                data feeds of the advertisers and is analyzed. In addition,
                the collected page URLs are called using crawlers, and is
                analyzed. No further data is purchased.
              </p>
              <p class="pl40">
                15. Changes to the Privacy Policy
              </p>
              <p class="pl40">
                Please note that we may change or update this privacy statement
                from time to time. We will post the changes here.
              </p>
            </div>
            <div v-if="loading" class="flip-square-loader"></div>
            <div class="wrapper mt-5 text-gray">
              <p class="footer-text">
                Copyright © {{ currentYear }} {{ companyName }}. All rights
                reserved.
              </p>
              <!-- <ul class="auth-footer text-gray">
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Cookie Policy</a>
                  </li>
                </ul>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Privacy",
  computed: {
    ...mapGetters(["loading", "error", "success", "warning", "registered"]),
    currentYear: () => new Date().getFullYear(),
    companyName: () => process.env.VUE_APP_COMPANY_NAME,
    authimage: () => "/wl/" + process.env.VUE_APP_NAME + "/bg_auth.jpg",
    headerlogo: () => '/wl/'+process.env.VUE_APP_NAME+'/logo.png',
    adverver:() => process.env.VUE_APP_ADSERVER,
    companyUrl: () => process.env.VUE_APP_COMPANY_URL,
    companyTel: () => process.env.VUE_APP_COMPANY_PHONE,
    companyEmail: () => process.env.VUE_APP_COMPANY_EMAIL,
    companyAddress(){
      let address = process.env.VUE_APP_COMPANY_ADDRESS.split("|").join('<br>');
      address += `<br />E-Mail: <a href="mailto:${this.companyEmail}">${this.companyEmail}</a><br>`;
      address += `Website: <a href="${this.companyUrl}">${this.companyUrl}</a><br>`;
      if(this.companyTel.length)  address += `Tel.: ${this.companyTel}`;
      return address;
    },
    protectionOfficer(){
      let protectionOfficer = process.env.VUE_APP_PROTECTION_OFFICER.split("|").join('<br>');
      protectionOfficer += `<br />E-Mail: <a href="mailto:${this.companyEmail}">${this.companyEmail}</a><br>`;
      if(this.companyTel.length)  protectionOfficer += `Tel.: ${this.companyTel}`;
      return protectionOfficer;
    },
  }
};
</script>

<style scoped>
.auth.theme-two .banner-section .slide-content.bg-1 {
  background-repeat: no-repeat;
  background-position: center center;
}

.hsb::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hsb {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  margin-right: 20px;
}

.pl40 {
  padding-left: 40px;
}


.pl80 {
  padding-left: 80px;
}

.pl160 {
  padding-left: 160px;
}

</style>

<style scoped>

  #logo {
      height: 43px;
      margin-top: 5px;
  }
  .bg-background {
    border-color: #00b297;
    border-style: solid;
    border-width: 2px;
  }

  .banner-section .slide-content.bg-2 {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .get-started-btn{
    border: 1px solid #fff;
      padding: 10px 20px;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      margin-left: 20px;
      border-radius: 50px;
  }
</style>
