<template>
  <form class="forms-sample">
    <div v-if="loading" class="flip-square-loader"></div>
    <div class="row" v-else>
      <div class="col-md-12">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="card" style="border: 0;">
          <div class="card-body">
            <h3 class="card-title mb-4">
              <span class="handwriting mr-2">{{ intBooking.name }}</span>
            </h3>
            <div class="row mb-3">
              <div class="col-md-5">
                <div class="card rounded border mb-2 d-none">
                  <div class="card-body p-3">
                    <div class="media">
                      <i
                        v-if="intBooking.goal === 'orders'"
                        class="icon-basket icon-sm align-self-center mr-3"
                      ></i>
                      <i
                        v-else-if="intBooking.goal === 'clicks'"
                        class="icon-people icon-sm align-self-center mr-3"
                      ></i>
                      <i
                        v-else
                        class="icon-flag icon-sm align-self-center mr-3"
                      ></i>

                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("general.goal") }}</h6>
                        <p
                          v-if="intBooking.goal[0] === 'orders'"
                          class="mb-0 text-muted"
                        >
                          {{ $t("goals.orders-title")
                          }}<span v-if="showGoalValue">
                            / {{ intBooking.goalValue }}€
                            {{ $t("orders.impressions-per") }}</span
                          >
                        </p>
                        <p
                          v-else-if="intBooking.goal[0] === 'clicks'"
                          class="mb-0 text-muted"
                        >
                          {{ $t("goals.clicks-title")
                          }}<span v-if="showGoalValue">
                            / {{ intBooking.goalValue }} €
                            {{ $t("goals.clicks-per") }}</span
                          >
                        </p>
                        <p v-else class="mb-0 text-muted">
                          {{ $t("goals.impressions-title")
                          }}<span v-if="showGoalValue">
                            / {{ intBooking.goalValue }} €
                            {{ $t("goals.impressions-per") }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card rounded border mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i
                        class="icon-calendar icon-sm align-self-center mr-3"
                      ></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("general.startdate") }}</h6>
                        <p class="mb-0 text-muted">
                          {{ `${getDayOfMonth()}-${getMonth()}-${getYear()}` }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card rounded border mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i
                        class="icon-calendar icon-sm align-self-center mr-3"
                      ></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("general.enddate") }}</h6>
                        <p class="mb-0 text-muted" v-if="intBooking.end">
                          {{
                            `${getDayOfMonth(true)}-${getMonth(true)}-${getYear(
                              true
                            )}`
                          }}
                        </p>
                        <p class="mb-0 text-muted" v-else>Never Ends</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card rounded border mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-wallet icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("general.budget") }}</h6>
                        <p
                          v-if="intBooking.dailyBudget"
                          class="mb-0 text-muted"
                        >
                          € {{ intBooking.dailyBudget }} /
                          {{ $tc("general.day",1) }}
                        </p>
                        <p v-else class="mb-0 text-muted">
                          € {{ intBooking.budget }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="card rounded border mb-2"
                  v-if="intBooking.target"
                >
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-link icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("general.targeturl") }}</h6>
                        <a class="mb-0" :href="intBooking.target" :title="intBooking.target" v-b-tooltip.hover>{{ getHostname }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card rounded border mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-people icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("labels.gender") }}</h6>
                        <p class="mb-0 text-muted">{{ getGender }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card rounded border mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-star icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("labels.age") }}</h6>
                        <p
                          class="mb-0 text-muted"
                          v-if="
                            intBooking.age.length == 0 ||
                              intBooking.age.length == 7
                          "
                        >
                          {{ $t("general.all") }}
                        </p>
                        <p class="mb-0 text-muted" v-else>{{ getAge }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="card mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-tag icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("labels.income") }}</h6>
                        <p class="mb-0 text-muted">{{ getIncome }}</p>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="card rounded border mb-2">
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-map icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">{{ $t("general.geo") }}</h6>
                        <p class="mb-0 text-muted">{{ getLocation() }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card rounded border mb-2" v-if="hasCDPSegments()">
                  <div class="card-body p-3">
                    <div class="media">
                      <i class="icon-grid icon-sm align-self-center mr-3"></i>
                      <div class="media-body">
                        <h6 class="mb-1">CDP Segments</h6>
                        <p class="mb-0">
                          <b-badge variant="outline-dark" v-for="(segment, index) in getCDPSegments" :key="index" class="mr-1" :title="segment.value" v-b-tooltip.hover>
                            {{ segment.name }}
                          </b-badge>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-inline">
                  <div
                    class="p-2 pl-3 pr-3 display-2"
                    style="background-color: #f9f9f9; border-left: 6px solid #00B297;"
                  >
                    <i class="icon-wallet"></i>
                  </div>
                  <div class="ml-3">
                    <h5
                      v-if="(!intBooking.budget || intBooking.budget == 0) && intBooking.dailyBudget"
                    >€ {{formatNumber(intBooking.dailyBudget, "en-EN")}} / ${this.$t("general.day")}</h5>
                    <h5
                      v-else-if="intBooking.budget != 0"
                    >€ {{formatNumber(intBooking.budget, "en-EN")}}</h5>
                    <h5
                      v-else
                    >not limited {{intBooking.budget}} {{intBooking.dailyBudget}}</h5>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- <div class="row mb-5">
              <div class="col my-auto text-center">
                <div
                  v-for="(category, index) in getCategories"
                  class="m-2 d-inline-block mx-auto"
                  :key="index"
                >
                  <div class="px-3 pb-1 text-center header-border border-success mr-2">
                    <label for="category" class="h4 text-nowrap">{{category}}</label>
                  </div>
                  <div class="d-flex justify-content-center card-border border-success mr-2">
                    <div
                      v-for="(channel, index) in restrictedChannels(category)"
                      :key="index"
                      class="m-2"
                    >
                      <img
                        :src="channel.image"
                        :title="channel.name"
                        :alt="channel.name"
                        class="m-2"
                        width="60"
                        height="60"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 my-auto" v-if="intBooking.allocation && intBooking.allocation.length > 1">
                <doughnut-chart :chart-data="intBooking.chartData" :options="chartOptions" />
              </div>
            </div> -->
            <div class="row">
              <div class="offset-md-2 col-md-3 mt-3 mb-5 text-right">
                <doughnut-chart
                  :chart-data="getChartData()"
                  :options="chartOptions"
                />
              </div>
              <div class="col-md-5 my-auto">
                <div id="issues-chart-legend" class="issues-chart-legend ml-2">
                  <ul class="legend1">
                    <div
                      v-for="(channels, category) in getAllocationData"
                      :key="category"
                      class="mb-3"
                    >
                      <div class="border-bottom mb-2">
                        <h6>{{ category }}</h6>
                      </div>
                      <li v-for="(channel, index) in channels" :key="index">
                        <span
                          class="legend-label"
                          :style="`background-color:${channel.color}`"
                        ></span
                        >{{ channel.name }}
                        <span class="legend-percentage ml-auto"
                          >{{ channel.weight }}%</span
                        >
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <!--<div class="row mt-3" v-if="getCreativesAndFiles().length">
              <div class="col-12">
                <h5 class="card-title">{{ $tc("general.creative", 2) }}</h5>
              </div>
              <div
                class="mb-3 col"
                v-for="(file, index) in getCreativesAndFiles()"
                :key="index"
              >
              <div>
                <div v-if="file.bannerType == 'video_ad'">
                <img
                  :src="getThumbnail(file.thumbnail)"
                  :title="file.name"
                  :alt="file.name"
                  :width="getPreviewWidth(file.width,file.height,100)"
                  :height="getPreviewHeight(file.width,file.height,100)"
                />
                <br>
                </div>
                <div v-else>
                  <iframe
                    style="pointer-events: none;"
                    border="0"
                    class="border-0"
                    scrolling="no"
                    :src="`https://${intBooking.uri}/banner?id=${file._id}&desc=bookingwizard&width=${getPreviewWidth(file.width,file.height,100)}&height=${getPreviewHeight(file.width,file.height,100)}`"
                    :width="getPreviewWidth(file.width,file.height,100)"
                    :height="getPreviewHeight(file.width,file.height,100)"
                  >
                  </iframe>
                <br><a target="_blank" :href="`https://${intBooking.uri}/banner?id=${file._id}&desc=bookingwizard`">{{ $t("actions.viewfullsize" )}}</a>
                </div>
              </div>
              </div>
            </div>-->
            <CreativeCardPanel
              v-if="lineItems.length > 0 && contracts.length > 0 && banners.length > 0"
              :lineItems="lineItems"
              :contracts="contracts"
              :banners="banners"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import CHANNELS from "@/constants/channels.js";
import DoughnutChart from "@/charts/Doughnut";
import CreativeCardPanel from "@/components/panels/CreativeCardPanel";

export default {
  name: "PendingBookingModal",
  components: {
    DoughnutChart,
    CreativeCardPanel,
  },
  props: ["booking"],
  created() {
    this.intBooking.categories = this.getCampaignCategories();
  },
  data() {
    return {
      intBooking: {
        goal: "",
        goalValue: "",
        budget: 0,
        dailyBudget: 0,
        start: "",
        end: "",
        channels: [],
        retargeting: [],
        income: [],
        domainlists: [],
        markers: [],
        target: "",
        gender: [],
        age: [],
        creatives: [],
        files: [],
        uri: process.env.VUE_APP_ADSERVER,
        allocation: [],
        chartData: {},
        splitBudget: false,
        lookups: {},
      },
      loading: false,
      error: "",
      chartOptions: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
        },
        maintainAspectRatio: true,
      },
      lineItems: [],
      contracts: [],
      banners: [],
      cdpSegments: require('@/data/cdp-segments.js'),
    };
  },
  mounted() {
    this.intBooking.name = this.booking.name;
    this.intBooking.budget = this.booking.budget;
    this.intBooking.dailyBudget = this.booking.dailyBudget;
    this.intBooking.goal = this.booking.goal;
    this.intBooking.goalValue = this.booking.goalValue;
    this.intBooking.start = this.booking.start;
    this.intBooking.end = this.booking.end;
    this.intBooking.channels = this.booking.channels;
    this.intBooking.retargeting = this.booking.retargeting;
    this.intBooking.income = this.booking.income;
    this.intBooking.domainlists = this.booking.domainlists;
    this.intBooking.markers = this.booking.markers;
    this.intBooking.target = this.booking.target;
    this.intBooking.gender = this.booking?.gender ? this.booking.gender : [];
    this.intBooking.age = this.booking?.age ? this.booking?.age : [];
    this.intBooking.creatives = this.booking.creatives;
    this.intBooking.files = this.booking.files;
    this.intBooking.allocation = this.booking?.allocation
      ? this.booking?.allocation
      : [];
    this.intBooking.splitBudget = this.booking?.allocation?.length
      ? true
      : false;
    this.intBooking.cdpSegments = this.booking?.cdpSegments ? this.booking.cdpSegments : undefined;
    this.intBooking.chartData = this.getChartData();

    const lookups = this.booking.lookups;
    this.intBooking.lookups = lookups;
    this.lineItems = lookups?.lineitems;
    this.contracts = lookups?.contracts;
    this.banners = lookups?.banners;
  },
  computed: {
    getCDPSegments(){
      // get names of segments from CDP
      return this.cdpSegments.filter(segment => this.intBooking.cdpSegments.includes(segment.value));
    },
    getFolder: () => process.env.VUE_APP_MEDIA_FOLDER,
    getBucket: () => process.env.VUE_APP_MEDIABUCKET,
    showGoalValue() {
      return this.intBooking.budget && this.intBooking.budget > 0;
    },
    getAllocationData() {
      let container = {};
      if (this.intBooking.splitBudget) {
        this.intBooking.allocation.forEach((allocation) => {
          const channelDetails = this.getChannelData(allocation.channel);

          if (channelDetails) {
            // maybe channel could not be found in active channel list. channels will be displayed as undefined
            if (container[channelDetails.category]) {
              container[channelDetails.category].push({
                name: channelDetails.name,
                color: channelDetails.color,
                weight: allocation.weight,
              });
            } else {
              container[channelDetails.category] = [];
              container[channelDetails.category].push({
                name: channelDetails.name,
                color: channelDetails.color,
                weight: allocation.weight,
              });
            }
          }
        });
      } else {
        for (let a = 0; a < this.intBooking.channels.length; a++) {
          const weight =
            Math.round((100 / this.intBooking.channels.length) * 100) /
            100;

          let channelDetails = this.intBooking.channels[a];
          if (typeof this.intBooking.channels[a] === "string") {
            channelDetails = this.getChannelData(
              this.intBooking.channels[a]
            );
          }

          if (container[channelDetails.category]) {
            container[channelDetails.category].push({
              name: channelDetails.name,
              color: channelDetails.color,
              weight: weight,
            });
          } else {
            container[channelDetails.category] = [];
            container[channelDetails.category].push({
              name: channelDetails.name,
              color: channelDetails.color,
              weight: weight,
            });
          }
        }
      }
      return container;
    },
    getHostname() {
      try {
        const url = new URL(this.intBooking.target);
        return url.hostname;
      } catch (e) {
        return "N/A";
      }
    },
    getIncome() {
      let options = {
        1: "Unknown",
        10: "10%",
        20: "11-20%",
        30: "21-30%",
        40: "31-40%",
        50: "41-50%",
        100: "50%",
      };

      if (
        !this.intBooking.income ||
        typeof this.intBooking.income == "undefined" ||
        this.intBooking.income.length == 0 ||
        this.intBooking.income.length == Object.keys(options).length
      )
        return this.$t("general.all");
      else
        return this.intBooking.income
          .map((e) => (e = options[e]))
          .join(", ");
    },
    getRetargeting() {
      return this.intBooking.retargeting &&
        this.intBooking.retargeting.length
        ? this.intBooking.retargeting
            .map((e) => (e = e.charAt(0).toUpperCase() + e.slice(1)))
            .join(", ")
        : this.$t("general.all");
    },
    getGender() {
      return this.intBooking.gender && this.intBooking.gender.length
        ? this.intBooking.gender
            .map((e) => (e = e.charAt(0).toUpperCase() + e.slice(1)))
            .join(", ")
        : this.$t("general.all");
    },
    getAge() {
      let options = {
        1: "Unknown",
        18: "< 18",
        25: "19-24",
        35: "25-34",
        45: "35-44",
        55: "45-54",
        65: "55-64",
        99: "> 65",
      };
      if (
        !this.intBooking.age ||
        typeof this.intBooking.age == "undefined" ||
        this.intBooking.age.length == 0 ||
        this.intBooking.age.length == Object.keys(options).length
      )
        return this.$t("general.all");
      else
        return this.intBooking.age.map((e) => (e = options[e])).join(", ");
    },
    getCategories() {
      let categories = [];
      let filtered = CHANNELS.filter((e) =>
        this.intBooking.channels.includes(e.key)
      );
      filtered.forEach((e) => {
        if (!categories.includes(e.category)) categories.push(e.category);
      });
      return categories;
    },
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    parseGeoLocations() {
      var geoLocations = [];
      this.intBooking?.markers?.forEach((item) => {
        geoLocations.push(item.properties.name);
      });
      return geoLocations;
    },
    parseDomainLists() {
      var domainLists = [];
      this.intBooking?.domainlists?.forEach((item) => {
        domainLists.push(item.name);
      });
      return domainLists;
    },
    anyTargeting() {
      let falseValues = 0;
      if (
        !this.intBooking.retargeting ||
        (this.intBooking.retargeting &&
          !this.intBooking.retargeting.length)
      )
        falseValues++;
      if (
        !this.intBooking.income ||
        (this.intBooking.income && !this.intBooking.income.length)
      )
        falseValues++;
      if (
        !this.intBooking.age ||
        (this.intBooking.age && !this.intBooking.age.length)
      )
        falseValues++;
      if (
        !this.intBooking.gender ||
        (this.intBooking.gender && !this.intBooking.gender.length)
      )
        falseValues++;
      if (
        !this.parseGeoLocations ||
        (this.parseGeoLocations && !this.parseGeoLocations.length)
      )
        falseValues++;
      if (
        !this.parseDomainLists ||
        (this.parseDomainLists && !this.parseDomainLists.length)
      )
        falseValues++;
      return falseValues < 6;
    },
  },
  methods: {
    hasCDPSegments(){
      return this.intBooking?.cdpSegments?.length > 0;
    },
    getChannelData(key) {
      const data = CHANNELS.find((ch) => ch.key === key);
      return data;
    },
    getVideoURL(id) {
      if (this.intBooking?.lookups?.videos) {
        const videos = this.intBooking.lookups.videos.find(
          (v) => v._id == id
        );
        return videos.downloadLink;
      }

      return "#";
    },
    getPreviewWidth(width, height, max) {
      return height / width > 1 ? max : (width / height) * max;
    },
    getPreviewHeight(width, height, max) {
      return height / width > 1 ? (height / width) * max : max;
    },
    hasDownloadLink(file) {
      if (file?.downloadLink) return true;
      if (file?.thumbnail?.downloadLink) return true;
      return false;
    },
    getSRC(entity) {
      if (entity?.downloadLink?.startsWith?.("https://storage.googleapis.com"))
        return entity.downloadLink;
      if (entity?.downloadLink?.startsWith?.("https://images.bannerbear.com"))
        return entity.downloadLink;
      if (entity?.downloadLink)
        return (
          `https://storage.googleapis.com/${this.getBucket}/${this.getFolder}/` +
          entity.downloadLink
        );
      if (
        entity?.thumbnail &&
        entity?.thumbnail?.downloadLink?.startsWith?.(
          "https://storage.googleapis.com"
        )
      )
        return entity.thumbnail.downloadLink;
      if (entity?.thumbnail?.downloadLink)
        return (
          `https://storage.googleapis.com/${this.getBucket}/${this.getFolder}/` +
          entity.thumbnail.downloadLink
        );
      return `https://${this.intBooking.uri}/banner?id=${entity._id}&desc=bookingwizard`;
    },
    getCreativesAndFiles() {
      let all = new Array();
      if (this.intBooking?.lookups?.banners?.length)
        all = all.concat(this.intBooking.lookups.banners);
      return all;
    },

    getLocation() {
      if (this.intBooking.markers.length === 0)
        return this.$t("general.all");
      const locations = [];
      this.intBooking.markers.forEach((marker) => {
        locations.push(marker.properties.currentPlace.formatted_address);
      });
      return locations.join(" | ");
    },
    getCampaignCategories() {
      const cat = [];
      this.intBooking.channels.forEach((channel) => {
        const channelObj = CHANNELS.find((ch) => ch.key === channel);
        if (channelObj) {
          if (!cat.includes(channelObj.category)) {
            cat.push(channelObj.category);
          }
        }
      });
      return cat;
    },
    restrictedChannels(category) {
      let filtered = CHANNELS.filter(
        (e) =>
          this.intBooking.channels.includes(e.key) &&
          e.category == category
      );
      return filtered;
    },
    getChartData() {
      const sets = {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
        labels: [],
      };

      if (this.intBooking.splitBudget) {
        for (let a = 0; a < this.intBooking.allocation.length; a++) {
          sets.datasets[0].data.push(this.intBooking.allocation[a].weight);

          const channelDetails = this.getChannelData(
            this.intBooking.allocation[a].channel
          );

          if (channelDetails) {
            sets.datasets[0].backgroundColor.push(channelDetails.color);
            sets.labels.push(channelDetails.name);
          } else {
            sets.datasets[0].backgroundColor.push("#cccccc");
            sets.labels.push("undefined");
          }
        }
      } else {
        for (let a = 0; a < this.intBooking.channels.length; a++) {
          const weight =
            Math.round((100 / this.intBooking.channels.length) * 100) /
            100;

          sets.datasets[0].data.push(weight);

          let channelDetails = this.intBooking.channels[a];
          if (typeof this.intBooking.channels[a] === "string") {
            channelDetails = this.getChannelData(
              this.intBooking.channels[a]
            );
          }

          if (channelDetails) {
            sets.datasets[0].backgroundColor.push(channelDetails.color);
            sets.labels.push(channelDetails.name);
          } else {
            sets.datasets[0].backgroundColor.push("#cccccc");
            sets.labels.push("undefined");
          }
        }
      }
      return sets;
    },
    cancel() {
      this.$bvModal.hide("pendingbookingModal");
    },
    getDayOfMonth(endDate) {
      return endDate
        ? new Date(this.intBooking.end).toDateString().split(" ")[2]
        : new Date(this.intBooking.start).toDateString().split(" ")[2];
    },
    getMonth(endDate) {
      return endDate
        ? new Date(this.intBooking.end).toDateString().split(" ")[1]
        : new Date(this.intBooking.start).toDateString().split(" ")[1];
    },
    getYear(endDate) {
      return endDate
        ? new Date(this.intBooking.end).toDateString().split(" ")[3]
        : new Date(this.intBooking.start).toDateString().split(" ")[3];
    },
    formatNumber(number, format) {
      return new Intl.NumberFormat(format).format(number);
    },
  },
};
</script>
