<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h3>
          <span class="handwriting mr-2">{{ $t("general.hello") }}</span>
          {{ getName }},
        </h3>
        <h4>{{ $t('headers.welcometo') }} {{ appTitle }}!</h4>
        <hr />
      </div>
      <div class="col-md-12" v-if="!showBalanceDanger && showBalanceWarning">
        <b-alert show variant="warning"
          v-html="$t('messages.lowbalance', {balance: formatNumber(advertiserBalance.toFixed(2), 'en-EN'), link: `/advertiser/finances/`})">
        </b-alert>
      </div>
      <div class="col-md-12" v-if="showBalanceDanger">
        <b-alert show variant="danger"
        v-html="$t('messages.outofbalance', {balance: formatNumber(advertiserBalance.toFixed(2), 'en-EN'), link: `/advertiser/finances/`})"
        >
        </b-alert>
      </div>
      <div class="col-md-12 grid-margin stretch-card" v-if="!isViewer">
        <div class="card bg-gradient text-white border-0">
          <div class="card-body">
            <div class="d-flex row justify-content-between">
              <div class="ml-4 my-3">
                <h3 class="font-weight-light">{{ $t('dashboard-banner.banner-smalltext') }}</h3>
                <h2>{{ $t('dashboard-banner.banner-bigtext') }}</h2>
              </div>
                  <router-link
                    :to="'/booking/create'"
                    tag="div"
                    class="action-button my-3 mr-5"
                  >
                    <div class="d-flex justify-content-between">
                      <i class="icon-rocket icon-lg ml-3 mr-3"></i>
                      <div class="my-auto mr-3" style="font-size: 1.5rem">{{ $t('dashboard-banner.banner-button') }}</div>
                    </div>
                  </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DashboardAnalyticsSelects
      :templates="dashboardAnalyticsTemplates"
      :campaigns="dashboardAnalyticsCampaigns"
      :activeAnalytics="loadAnalytics"
      @updateDashboard="updateAll"
      @activateAnalytics="activateAnalytics"
    />

    <div class="row">
      <div v-if="loading" class="flip-square-loader"></div>
    </div>

    <DashboardAnalytics v-if="!loading && loadAnalytics" :stats="dashboardAnalyticsStats" />
    <DashboardAnalyticsBookings v-if="!loading" :campaigns="campaigns" :dateRange="dateRange" @updateDashboard="updateActivePaused" @toast="makeToast" />

  </div>
  <!-- content-wrapper ends -->
  <!-- partial:partials/_footer.html -->
</template>

<script>
import { mapGetters } from "vuex";
import DashboardAnalyticsSelects from "@/components/dashboard/components/DashboardAnalyticsSelects";
import DashboardAnalytics from "@/components/dashboard/DashboardAnalytics";
import DashboardAnalyticsBookings from "@/components/dashboard/components/DashboardAnalyticsBookings";
const jwtDecode = require('jwt-decode');


//import CHANNELS from "@/constants/channels.js";

export default {
  name: "Dashboard",
  props: ['type'],
  data() {
    return {
      dashboardAnalyticsTemplates: [],
      dashboardAnalyticsCampaigns: [],
      dashboardAnalyticsStats: {},

      loading: false,
      loadAnalytics: false,

      selectedCampaigns: [],

      advertiser: false,
      advertiserBalance: false,
      campaigns: [],

      usedDailyBudget: 0,

      currentDateRange: {},
    };
  },
  async mounted() {
    if (this.getRole === "accounting") this.$router.push("/advertisers");
    const user = await this.$store.dispatch("getUser");
    if(user.selectedType == "publisher"){
      this.$router.push("/p");
    }
  },
  async created() {
    this.loading = true;
    await this.updateAdvertiser();
    await this.initialLoad();
    this.calculateUsedDailyBudget();
    this.currentDateRange = this.dateRange;
  },
  watch: {
    'dashboardActiveCampaigns.length': function() {
      this.calculateUsedDailyBudget();
    },
    'dashboardUpcomings.length': function() {
      this.calculateUsedDailyBudget();
    }

  },
  methods: {
    makeToast(title, text, variant) {
      this.$bvToast.toast(text, {
        title,
        variant,
        noAutoHide: true,
      });
    },
    async updateAdvertiser() {
      const advertiser = await this.$api.advertiser.get(
        localStorage.getItem(process.env.VUE_APP_ADVERTISER).replaceAll('"', "")
      );

      if(!this.isViewer){
        const balance = await this.$api.advertiser.getBalance(advertiser?._id ? advertiser._id : advertiser);
        advertiser.balance = balance;
        this.advertiserBalance = balance;
      }

      this.advertiser = advertiser;
    },

    async initialLoad(){
      this.loading = true;
      let promises = [];

      promises.push(this.$api.dashboard.getAnalytics({
        start: this.dateRange.startDate,
        end: this.dateRange.endDate
      }));
      promises.push(this.$api.dashboard.getDashboard({
        start: this.dateRange.startDate,
        end: this.dateRange.endDate
      }));

      Promise.all(promises)
        .then(results => {
          /* console.log("RESULTS: ",results) */
          this.dashboardAnalyticsCampaigns = results[0].campaigns;
          this.dashboardAnalyticsTemplates = results[0].templates;
          this.campaigns = results[1];
          this.loading = false;
        })
        .catch((/* err */) => { /* console.log(err) */ });
    },
    async activateAnalytics(){
      this.loadAnalytics = true;
      await this.updateAll(this.selectedCampaigns, true);
    },
    async updateActivePaused(){
      this.loading = true;
      let promises = [];
      promises.push(this.$api.dashboard.getDashboard({
        start: this.dateRange.startDate,
        end: this.dateRange.endDate
      }));
      Promise.all(promises).then(results => {
        this.campaigns = results[0];
        this.loading = false;
      }).catch((/* err */) => { /* console.log(err) */ });
    },
    async updateAll(campaigns, force = false) {
      let promises = [];

      let updateBookings = force;

      if (
        this.currentDateRange.startDate !== this.dateRange.startDate ||
        this.currentDateRange.endDate !== this.dateRange.endDate
      ) {
        this.currentDateRange = this.dateRange;
        updateBookings = true;
      }

      if(this.loadAnalytics){
        this.warning = "";
        this.selectedCampaigns = campaigns;
        promises.push(this.$api.dashboard.getAnalytics({
          start: this.dateRange.startDate,
          end: this.dateRange.endDate,
          campaigns: campaigns
        }));
      }

      if (updateBookings) {
        promises.push(this.$api.dashboard.getDashboard({
          start: this.dateRange.startDate,
          end: this.dateRange.endDate
        }));
      }

      if(promises.length > 0){
        this.loading = true;
        Promise.all(promises)
          .then(results => {
            let bookingIdx = 0;
            if(this.loadAnalytics){
              this.dashboardAnalyticsStats = results[0].stats;
              if(results[0].campaigns)  this.dashboardAnalyticsCampaigns = results[0].campaigns;
              if(results[0].templates)  this.dashboardAnalyticsTemplates = results[0].templates;
              bookingIdx = 1;
            }

            if(results[bookingIdx]){
              this.campaigns = results[bookingIdx];
            }

            this.loading = false;
          })
          .catch((/* err */) => { /* console.log(err) */ });
      }
    },
    async getDashboardStats(template, campaign, details) {
      let payload = {
        start: this.dateRange.startDate,
        end: this.dateRange.endDate,
        campaigns: this.selectedCampaigns
      };

      if (template) payload.template = template;
      if (campaign) payload.campaign = campaign;
      if (details) payload.details = details;

      return await this.$api.dashboard.getAnalytics(payload);
    },
    formatNumber(number, format) {
      return new Intl.NumberFormat(format).format(number);
    },
    isActiveOrUpcomingCampaign(campaign){

      const startTimestamp = new Date(this.dateRange.startDate).getUTC();
      const endTimestamp = new Date(this.dateRange.endDate).getUTC();

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const tomorrowTimestamp = tomorrow.getUTC();

      const booking = campaign.lookups.bookings[0];

      const bookingStart = new Date(booking.start).getUTC();
      let bookingEnd = new Date(Date.now() + 315569520000).getUTC();
      if (booking.end) bookingEnd = new Date(booking.end).getUTC();

      if (booking.status == "published") {
          // if booking is published and start and end are in the given daterange
          if (
            (bookingStart >= startTimestamp && bookingStart < endTimestamp) ||
            (bookingEnd >= startTimestamp && bookingEnd < endTimestamp) ||
            (bookingStart <= startTimestamp && bookingEnd >= endTimestamp) ||
            bookingStart >= tomorrowTimestamp
          ){
            return true;
          }
      }

      return false;
    },

    advertiserHasPrepaidContracts(){
      if(this.campaigns.length > 0){
        this.campaigns.forEach(campaign => {
          if(this.isActiveOrUpcomingCampaign(campaign)){
            if(campaign?.lookups?.contracts){
              for(let c = 0; c < campaign.lookups.contracts.length; c++){
                if(campaign.lookups.contracts[c].prepaid === true){
                  return true;
                }
              }
            }
          }
        });
      }

      if(this.campaigns.length > 0 && this.advertiser.billingType == 'prepaid'){
        return true;
      }
    },
    calculateUsedDailyBudget() {
      // reset used daily budget
      this.usedDailyBudget = 0;

      if(this.campaigns.length > 0){
        this.campaigns.forEach(campaign => {
          if(this.isActiveOrUpcomingCampaign(campaign)){
            const { bookings } = campaign.lookups;
            if(bookings.length) {
              bookings.forEach(booking => {
                // console.log(booking.dailyBudget)
                this.usedDailyBudget += booking.dailyBudget
              })
            }
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters(["dateRange"]),
    isDealer: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "dealer" ? true : false,
    isViewer: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "viewer" ? true : false,
    appEmail() {
      return process.env.VUE_APP_EMAIL_SUPPORT;
    },
    showBalanceWarning() {
      let showWarning = false;
      showWarning = !this.loading && this.advertiserBalance !== false &&
              this.advertiserBalance > parseInt(process.env.VUE_APP_BALANCE_DANGER) && this.advertiserBalance < parseInt(process.env.VUE_APP_BALANCE_WARNING) &&
              this.advertiserHasPrepaidContracts();
      if(this.usedDailyBudget > 0) {
        const totalDailyBudget = this.usedDailyBudget;
        if(totalDailyBudget > (this.advertiserBalance / 5)) {
          showWarning = true
        }
      }
      return showWarning;
    },
    showBalanceDanger(){
      let showDanger = false;
      showDanger = !this.loading && this.advertiserBalance !== false &&
              this.advertiserBalance <= parseInt(process.env.VUE_APP_BALANCE_DANGER) &&
              this.advertiserHasPrepaidContracts();
      if(this.usedDailyBudget > 0) {
        const totalDailyBudget = this.usedDailyBudget;
        if(totalDailyBudget > (this.advertiserBalance / 2)) {
          showDanger = true
        }
      }
      
      return showDanger;
    },
    dateRange: {
      get() {
        return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      }
    },
    appTitle() {
      return process.env.VUE_APP_APP_TITLE;
    },
    getName: () => localStorage.getItem(process.env.VUE_APP_NAME),
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    getHello: () => {
      const hellos = [
        "Hello",
        "Nǐn hǎo",
        "Hola",
        "Olá",
        "Ciao",
        "Hallo",
        "Witaj",
        "Zdravstvuyte",
        "Bonjour",
        "Namaste",
        "Konnichiwa",
        "Anyoung",
        "Ahlan",
        "Hej",
        "Habari",
        "Hoi",
        "Yassou",
        "Halo",
        "Hai",
        "Merhaba",
        "Shalom",
        "Hei"
      ];
      return hellos[(Math.random() * hellos.length).toFixed(0)];
    }
  },
  components: {
    DashboardAnalyticsSelects,
    DashboardAnalytics,
    DashboardAnalyticsBookings
  }
};
</script>

<style>
.toast {
  border-radius: 5px !important;
}

.toast-header {
  border-radius: 5px 5px 0 0;
  padding: 10px;
}

.toast-body {
  padding: 10px;
}
</style>

<style scoped>
.bg-gradient {
  background: linear-gradient(150deg, #00b297, #2e8be8 180%);
}

.action-button {
  cursor: pointer;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  color: #00b297;

  border-radius: 50px;
  padding: 1em;
  background: white;
  border: none;
  font-weight: 500;

}

.action-button:hover {
  transform: scale(1.07);
}

.list-wrapper i.checkbox {
  margin-right: 15px;
  position: absolute;
  top: 4px;
  left: 1px;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
}
.btn-white {
  background-color: #999;
  border-color: #999;
}
a.remove {
  color: #fff !important;
  font-size: 0.8em;
  width: auto;
  height: auto;
}

.alert{
  border-radius: 0px;
}
</style>