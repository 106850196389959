<template>
  <div class="row">
    <div class="col-auto my-auto" v-if="showResults">
      {{ $t("general.results") }}: {{items}}
    </div>
    <div class="col">
      <b-pagination
        v-model="page"
        :total-rows="itemCount"
        @input="changePage()"
        :per-page=perPage
        :class="paginationClass"
      ></b-pagination>
    </div>
    <div class="col-auto" v-if="showPerPage">
      <b-form-select
        style="width: auto"
        v-model="quantity"
        :options="paginationSelectOptions"
        :label="'Items per page'"
        @input="changeQuantity()"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Pagination",
  props: {
    itemCount: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 10
    },
    showResults: {
      type: Boolean,
      required: false,
      default: true
    },
    showPerPage: {
      type: Boolean,
      required: false,
      default: true
    },
    classs:{
      type: String,
      required: false,
      default: "pagination-success"
    }    
  },
  data() {
    return {
      page: this.currentPage,
      quantity: this.perPage,
      items: this.itemCount,
      paginationClass: `mx-auto justify-content-center mb-0 ${this.classs}`
    }
  },
  computed: {
    ...mapGetters([
      "paginationSelectOptions",
    ]),
  },
  watch: {
    currentPage(newValue) {
      this.page = newValue;
    },
    perPage(newValue) {
      this.quantity = newValue;
    },
    itemCount(newValue) {
      this.items = newValue;
    },
  },
  methods: {
    changePage(){
      this.$emit('changePage', this.page);
    },
    changeQuantity(){
      this.$emit('changeQuantity', this.quantity);
    },
    
  }
};
</script>

<style scoped>

</style>
