<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{$tc("general.subaccount", 2)}}</h4>
          <div class="col-12 text-right mb-2 pr-0">
            <button class="btn btn-sm btn-success" @click="gotoNewLogin">
              <i class="fa fa-plus"></i>{{ $t("actions.createx", { name: $tc("general.subaccount", 1) }) }}
            </button>
          </div>
          <div class="alert alert-danger" v-if="error">{{ error }}</div>
          <div class="alert alert-warning" v-if="warning">{{ warning }}</div>

          <div class="d-flex justify-content-center">
            <div class="form-group m-2 w-25" v-if="isSysOp">
                <label>{{$t('general.agency')}}</label>
                <v-select
                v-model="selectedLogin"
                label="email"
                class="form-control"
                :options="logins"
                :reduce="login => login._id"
                @input="reload"
                ></v-select>
              </div>
            <div class="form-group m-2 w-25">
                <label>{{$t('general.role')}}</label>
                <v-select
                v-model="selectedRole"
                label="name"
                class="form-control"
                :options="roles"
                :reduce="role => role.code"
                @input="reload"
                ></v-select>
              </div>
          </div>

          <div v-if="loading" class="flip-square-loader"></div>
          <div class="table-responsive" v-if="!loading">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="align-baseline">{{$t('general.user')}}</th>
                  <th class="align-baseline">{{$t('forms.email')}}</th>
                  <th class="align-baseline">{{$t('general.name')}}</th>
                  <th class="align-baseline">{{$t('general.role')}}</th>
                  <th class="align-baseline">{{$t('forms.loginallowed')}}</th>
                  <th class="align-baseline">{{$t('forms.lastlogin')}}</th>
                  <th class="align-baseline">{{$tc('general.action',2)}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="login in subaccounts" :key="login._id">
                  <td class="py-1">
                    <span
                      class="profileimgtable"
                      :style="'background-image: url(\''+(login.avatar || '/images/avatar.jpg')+'\');'"
                    >&nbsp;</span>
                  </td>
                  <td>
                    <div>
                      {{ login.email }}
                      <i v-if="login.doi && (new Date(login.doi).getUTC() != new Date(0).getUTC())"
                            class="icon-check ml-2 text-success"
                            :title="`Verified on ${new Date(login.doi).formatDatetime()}`"
                          />
                    </div>
                  </td>
                  <td>{{ login.name }} {{ login.surname }}</td>
                  <td>
                    {{ getRoleName(login.role) }}
                  </td>
                  <td>
                    <input type="checkbox" v-if="login.password" v-model="login.loginAllowed" @click="updateLoginAllowed(login)">
                    <input type="checkbox" v-else disabled="true" v-model="login.loginAllowed" @click="updateLoginAllowed(login)" :title="$t('descriptions.noPasswordYet')">
                  </td>
                  <td>
                    {{
                    login.lastLogin
                    ? new Date(login.lastLogin).formatDatetime()
                    : $t('general.never')
                    }}
                  </td>
                  <td>
                    <router-link
                      class="ml-2"
                      :to="`/subaccount/edit/${login._id}`"
                      style="cursor: pointer"
                    >
                      <i class="text-primary fa fa-edit"></i>
                    </router-link>
                    <i class="text-primary ml-2 fa fa-trash" style="cursor: pointer" :title="$t('actions.deletex', {name: $tc('general.subaccount', 1)})" @click="showArchiveModal(login._id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="zeroResults" class="d-flex justify-content-center mt-2">
                <p class="">{{$t('general.zeroresults')}}</p>
            </div>
          <ConfirmModal
            :id="'confirmModalSubaccountTable'"
            :titleText="$t('actions.deletex', {name: $tc('general.subaccount', 1)})"
            :bodyText="$t('messages.archive-subaccount')"
            :finishButtonText="$t('actions.deletex', {name: $tc('general.subaccount', 1)})"
            :cancelButtonText="$t('actions.cancel')"
            finishButtonVariant="danger"
            cancelButtonVariant="secondary"
            finishEvent="archiveSubaccount"
            cancelEvent="cancelArchive"
            @cancelArchive="archiveID = null"
            @archiveSubaccount="deleteSubAccount"
          />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const jwtDecode = require('jwt-decode');
import ConfirmModal from "@/components/modals/ConfirmModal";

export default {
  name: "Subaccounts",
  components: {
    ConfirmModal
  },
  computed: {
    ...mapGetters(["loading", "error", "success", "warning"]),
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    zeroResults(){
        return this.subaccounts?.length == 0
    },
    isSysOp: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "systemoperator" ? true : false,
  },
  data() {
    let locale = 'en';
    if(this.$i18n.locale) locale = this.$i18n.locale;

    return {
      industries: require(`@/locales/industries.${locale}.js`),
      logins: [],
      subaccounts: [],
      selectedRole: "",
      roles: [
        { name: this.$t("role.client"), code: "advertiser" },
        { name: this.$t("role.viewer"), code: "viewer" },
        { name: this.$t("role.co_worker"), code: "co_worker" },
      ],
      selectedLogin: "",
      archiveID: null
    };
  },
  methods: {
    getRoleName(role){
      if(role == "advertiser") return this.$t("role.client");
      if(role == "viewer") return this.$t("role.viewer");
      if(role == "co_worker") return this.$t("role.co_worker");
      return "";
    },
    showArchiveModal(id){
      this.archiveID = id;
      this.$bvModal.show('confirmModalSubaccountTable');
    },
    async deleteSubAccount(){
      this.subaccounts = this.subaccounts.filter( (subaccount) => subaccount?._id?.toString?.() != this.archiveID?.toString?.());
      await this.$api.user.deleteSubaccount(this.archiveID);
      this.archiveID = null;
    },
    getIndustryByID(id){
      const found = this.industries.find(element => element.value == id);
      if(found) return found.text;
      else      return id;
    },
    gotoNewLogin() {
      this.$router.push(`/subaccount/new`);
    },
    async updateLoginAllowed(login){
      if(typeof login.loginAllowed !== 'undefined'){
        const loginCopy = {...login};
        loginCopy.loginAllowed = !loginCopy.loginAllowed;
        await this.$api.user.updateSubaccount(loginCopy);
        login.loginAllowed = !login.loginAllowed;
      }
    },
    async reload(){
        let query = {};
        if(this.selectedLogin) query.parent = this.selectedLogin;
        if(this.selectedRole) query.role = this.selectedRole;
        this.subaccounts = await this.$api.user.getSubaccounts(query);
    }
  },
  async created() {
    if(this.isSysOp){
        const result = await this.$api.user.getAll();
        this.logins = result.filter( (login) => login.role === 'agency');
    }
    this.subaccounts = await this.$api.user.getSubaccounts();
  }
};
</script>

<style scoped>
.profileimgtable {
  border-radius: 50%;
  display: inline-block;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  margin-left: 0.75rem;
}
</style>